/* eslint-disable no-unused-vars */
import {
  successColor,
  whiteColor,
  grayColor,
  hexToRgb,
  primaryColor,
  warningColor,
  dangerColor,
  infoColor,
  roseColor,
} from '../../material-dashboard-react';

const dashboardStyle = {
  mainCon: {
  },
  mainRoot: {
    backgroundColor: 'white',
    height: '91vh',
  },
  root: {
    padding: '8px',
    paddingTop: '24px',
    boxShadow: `0 1px 4px 0 rgba(${hexToRgb('#000')}, 0.14)`,
    backgroundColor: '#f0f0f0',
    height: '840px',
    margin: 16,
  },
  grid1: { paddingRight: '30px', paddingLeft: '30px' },
  card1: {
    backgroundColor: 'white', marginLeft: 10, marginRight: 10, width: '100%',
  },
  card2: {
    backgroundColor: 'white', flex: 1, height: 320, marginLeft: 10, marginRight: 10,
  },
  card3: {
    backgroundColor: 'white', flex: 1.8, height: 480, marginLeft: 20, marginRight: 20,
  },
  donutCard: {
    backgroundColor: 'white', flex: 1, height: 320, marginLeft: 10, marginRight: 10,
  },
  pieCard: {
    backgroundColor: 'white', flex: 1.5, height: 320, marginLeft: 20, marginRight: 20,
  },
  card4: {
    backgroundColor: 'white', flex: 1, height: 320, marginLeft: 20, marginRight: 10,
  },
  card5: {
    backgroundColor: 'white', margin: 18, width: '100%', minWidth: 200,
  },
  card6: { backgroundColor: '#3D4977' },
  card7: { backgroundColor: '#3D4977' },
  card8: { backgroundColor: '#3D4977' },
  border: {
    backgroundColor: '#1A89FF',
    height: '3px',
    width: '30px',
    borderRadius: '1px',
    marginTop: '3px',
    marginLeft: '5px',
  },
  card2Body: {
    maxHeight: 260,
    display: 'flex',
    justifyContent: 'center',
    padding: 0,
    margin: 0,
  },
  cardPieBody: {
    maxWidth: '100%',
    maxHeight: 320,
    height: 240,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  cardDonutBody: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    height: 240,
    justifyContent: 'center',
  },
  boxcolorrequest: {
    height: 5,
    width: 30,
    backgroundColor: '#1A89FF',
    marginTop: 10,
  },
  boxcolorrequestClosed: {
    height: 5,
    width: 30,
    backgroundColor: '#80BDFE',
    marginTop: 10,
  },
  boxcolorrequestthree: {
    height: 5,
    width: 30,
    backgroundColor: '#B3D7FF',
    marginTop: 10,
  },
  pieData: {
    width: 200,
  },
  donutData: {
    display: 'flex',
    marginTop: 30,
  },
  pieDataTitle: {
    display: 'flex',
    marginTop: 5,
  },
  donutDataTitle: {
    display: 'flex',
    flexDirection: 'column',
    margin: 10,
  },
  boxcolorrequestTitle: {
    fontSize: '14px',
  },
  boxdonutTitle: {
    fontSize: '14px',
    marginTop: 10,
  },
  card3Body: {
    maxWidth: '400px',
    maxHeight: 320,
  },
  firstRowView: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 200,
    flex: 1,
  },
  secRowView: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 400,
    marginTop: 20,
  },
  thrdRowView: {
    display: 'flex',
    alignItems: 'center',
    maxHeight: 400,
    marginTop: 20,
    marginLeft: 10,
  },
  fourRowView: {
    display: 'flex',
    height: 300,
    marginTop: 20,
  },
  thrdRowCardView: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginLeft: 10,
    marginRight: 10,
  },
  thrdRowCard: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    background: whiteColor,
    boxShadow: `0 1px 4px 0 rgba(${hexToRgb('#000')}, 0.14)`,
  },
  successText: {
    color: successColor[0],
  },
  upArrowCardCategory: {
    width: '16px',
    height: '16px',
  },
  stats: {
    color: 'black',
    fontSize: '24px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '42px',
      height: '42px',
      marginRight: '10px',
      position: 'relative',
      color: '#CCC',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      fontSize: '30px',
      position: 'relative',
    },
  },
  stats_customers: {
    color: 'black',
    fontSize: '50px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      width: '60px',
      height: '60px',
      marginRight: '10px',
      position: 'relative',
      color: '#CCC',
    },
    '& .fab,& .fas,& .far,& .fal,& .material-icons': {
      fontSize: '30px',
      position: 'relative',
    },
  },
  cardCategory: {
    color: 'black',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginLeft: '5px',
    marginBottom: '0',
  },
  cardCategoryWhite: {
    color: 'white',
    margin: '0',
    fontSize: '14px',
    marginTop: '0',
    paddingTop: '10px',
    marginBottom: '0',
  },
  cardTitle: {
    color: grayColor[2],
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    alignItems: 'center',
    justifyContent: 'center',
    display: 'flex',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
  cardTitleWhite: {
    color: whiteColor,
    marginTop: '0px',
    minHeight: 'auto',
    fontWeight: '300',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: '3px',
    textDecoration: 'none',
    '& small': {
      color: grayColor[1],
      fontWeight: '400',
      lineHeight: '1',
    },
  },
};

export default dashboardStyle;
