import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import axios from 'axios';
import { toast } from 'react-toastify';
import CustomInput from '../../components/CustomInput/CustomInput';
import {
  shortDate,
  apiList,
  verifyLogin,
} from '../../helpers';

const useStyles = makeStyles({
  root: {
    flex: 1,
    borderLeft: '2px solid #eee',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  main: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    margin: 40,
  },
  leftDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rightDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginTop: '8px',
  },
  itemSelected: {
    backgroundColor: '#3D4977',
    marginTop: '8px',
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
  button: {
    margin: 16,
  },
  wrapper: {
    display: 'flex',
    justifyContent: 'center',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default function BasicDetails({ userDetails }) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [profile, setProfile] = useState({ ...userDetails });

  const updateProfile = () => {
    setLoading(true);
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.post(`${apiList.UPDATE_PROFILE}`, profile, config)
      .then((response) => {
        setLoading(false);
        if (response) {
          toast.success('Profile Updated.');
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      {profile ? (
        <div className={classes.main}>
          <div className={classes.leftDiv}>
            <CustomInput
              id='firstName'
              label='First Name'
              value={profile.firstName}
              onChange={(id) => {
                const obj = { firstName: id.target.value };
                const data = { ...profile, ...obj };
                setProfile(data);
              }}
            />
            <CustomInput
              id='email'
              label='Email'
              disabled
              value={profile.email}
            />
            <CustomInput
              id='createDate'
              label='Created Date'
              disabled
              value={shortDate(profile.createdAt)}
            />
          </div>
          <div className={classes.leftDiv}>
            <CustomInput
              id='lastName'
              label='Last Name'
              value={profile.lastName}
              onChange={(id) => {
                const obj = { lastName: id.target.value };
                const data = { ...profile, ...obj };
                setProfile(data);
              }}
            />
            <CustomInput
              id='mobile'
              type='number'
              label='Mobile Number'
              value={profile.alternateNumber}
              onChange={(id) => {
                if (id.target.value.length <= 10) {
                  const obj = { alternateNumber: id.target.value };
                  const data = { ...profile, ...obj };
                  setProfile(data);
                }
              }}
            />
          </div>
        </div>
      ) : null}
      <div className={classes.wrapper}>
        <Button
          disabled={loading}
          className={classes.button}
          variant='outlined'
          color='primary'
          onClick={() => { updateProfile(); }}
        >
          Update Profile
        </Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}
