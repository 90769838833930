import '../App.scss';
import ReactGa from 'react-ga';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Admin from '../Routers/AdminRouter';
import Login from './Login/Login';
import Home from './Home/Home';
// import Report from './Report/Report';
import CustomerAccount from './CustomerAccounts/CustomerAccounts';
import LoanDetails from './LoanDetails/LoanDetails';
import RequestDetails from './RequestDetails/RequestDetails';
import ConstructionDetails from './ConstructionDetails/ConstructionDetails';
import Property from './Property/Property';
import ConstructionPlan from './ConstructionPlan/ConstructionPlan';
import LoanMaps from './LoanMaps/LoanMaps';
import Request from './Request/Request';
import Profile from './Profile/Profile';
import PDFView from './PDFView/PDFView';
import LoanTasks from './LoanTasks/LoanTasks';
import GenerateConstructionProgress from './GenerateConstructionProgress/GenerateConstructionProgress';
import OldConstructionPlanList from './OldConstructionPlanList/OldConstructionPlanList';
import UncategorizedMedia from './UncatergorizedMedia/UncatergorizedMedia';

const Routes = () => {
  useEffect(() => {
    ReactGa.initialize('UA-163119480-1');
    ReactGa.pageview(window.location.pathname);
  }, []);
  return (
    <div>
      <Router>
        <Switch>
          <Route exact path='/' component={Login} />
          <Route exact path='/home' component={Home} />
          {/* <Route exact path='/reports' component={Report} /> */}
          <Route path='/admin' component={Admin} />
          <Route
            exact
            path='/profile'
            render={(props) => (
              <Profile {...props} />
            )}
          />
          <Route exact path='/customers-accounts' component={CustomerAccount} />
          <Route exact path='/property' component={Property} />
          <Route exact path='/document/:detailId' component={PDFView} />
          <Route
            exact
            path='/files/:detailId'
            render={(props) => (
              <GenerateConstructionProgress {...props} />
            )}
          />
          <Route exact path='/construction-plan' component={ConstructionPlan} />
          <Route exact path='/uncatergorized-media/:detailId' component={UncategorizedMedia} />
          <Route exact path='/view-loan-details/:detailId' component={LoanDetails} />
          <Route exact path='/view-request-details/:detailId' component={RequestDetails} />
          <Route exact path='/view-construction-details/:detailId' component={ConstructionDetails} />
          <Route exact path='/request' component={Request} />
          <Route exact path='/loan-tasks' component={LoanTasks} />
          <Route exact path='/loan-map' component={LoanMaps} />
          <Route exact path='/old-plans/:data' component={OldConstructionPlanList} />
        </Switch>
      </Router>
    </div>
  );
};

export default Routes;
