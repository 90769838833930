import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';

class PermissionConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      elemenntId: '',
      name: '',
      desc: '',
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId } = user;
    verifyUser(Number(userRoleId));
    this.getPermissionConfig();
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({ name: element.name, elemenntId: element.id, desc: element.description });
    } else {
      this.setState({ name: '', elemenntId: '', desc: '' });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      elemenntId, name, desc,
    } = this.state;
    if (name.length < 1 && desc.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = { name: name.trim(), description: desc.trim() };
    if (elemenntId) {
      params.id = elemenntId;
    }
    axios.post(`${apiList.UPDATE_PERMISSION_CONFIG}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          name: '', elemenntId: '', desc: '',
        });
        this.toggleModal(false);
        this.getPermissionConfig();
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  getPermissionConfig() {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_PERMISSION_CONFIG}`, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate] });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body=() => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      name={this.state.name}
      elemenntId={this.state.elemenntId}
      desc={this.state.desc}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      formOpen,
      data,
      page,
      rowsPerPage,
      newForm,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null }

        {result === false && (
        <Table
          data={Array.isArray(data) ? data : []}
          MainProps={this.props}
          handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
          handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
          handleShowAddForm={(key) => this.handleShowAddForm(key)}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          handleChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
        />
        )}

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={newForm ? 'Create Permission Config' : 'Update Permission Config'}
        />

      </div>
    );
  }
}

export default PermissionConfig;
