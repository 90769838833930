import React, { useState, useRef } from 'react';
import axios from 'axios';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import { toast } from 'react-toastify';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import ActivityConfigSubTable from './ActivityConfigSubTable';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelectOut from '../../components/CustomSelectOut/CustomSelectOut';
import ProgressMediaConfigTable from './ProgressMediaConfigTable';

import {
  apiList,
  verifyLogin,
} from '../../helpers';
import ComponentActivitiesConfigTable from './ComponentActivitiesConfigTable';

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const {
    classes, onClose,
  } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['Plan Details', 'Activity Config Sub', 'Progress Media Config', 'Component Activity Config'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  first_slide: {
    position: 'relative',
    width: 300,
    display: 'flex',
    flexDirection: 'column',
  },
  slide: {
    overflow: 'hidden',
    position: 'relative',
    height: 'calc(100vh - 147px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
}));

export default function OnBoardingDialog({
  open, handleClose, loanType, subscriberId,
  selectedPlanLoanID,
  planID,
  planName,
  planDescription,
  fetchPlans,
  canEdit,
  generateWOComp,
  floors,
  isCompoRequired,
}) {
  const ActivityConfigSubRef = useRef(null);
  const ProgressMediaConfigRef = useRef(null);
  const ComponentActivitiesConfigRef = useRef(null);
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [loanTypeId, setLoanTypeID] = useState(selectedPlanLoanID);
  const [noOfFloors, setNoOfFloors] = useState(floors);
  const [selectedPlanID, setSelectedPlanID] = useState(planID);
  const [desc, setDesc] = useState(planDescription);
  const [generateWOComputation, setGenerateWOComputation] = useState(generateWOComp);
  const [isComponentRequired, setComponentRequired] = useState(isCompoRequired);
  const [name, setName] = useState(planName);
  const [canSave, setCanSave] = useState(false);
  const [loading, setLoading] = useState(false);
  const steps = getSteps();

  const saveConstructionPlanModule = () => {
    if (name.length < 1) {
      toast.error('Please Provide Plan Name');
      return;
    }
    if (loanTypeId === -1) {
      toast.error('Please Select Loan Type');
      return;
    }

    setLoading(true);
    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      subscriberId,
      loanTypeId,
      noOfFloors,
      planName: name,
      isComponentRequired,
      generateWOComputation,
      description: desc,
    };
    // if (noOfFloors === -1) {
    //   params.noOfFloors = null;
    // }
    if (selectedPlanID) {
      params.id = selectedPlanID;
    }
    axios.post(`${apiList.UPDATE_CONSTRUCTION_PLAN_MODULE}`, params, config)
      .then((response) => {
        setLoading(false);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        ActivityConfigSubRef.current
          .handleAutoFilterConfigSub(selectedPlanID
            || response.data.data.id, subscriberId, loanTypeId);
        setSelectedPlanID(selectedPlanID || response.data.data.id);
        fetchPlans();
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  const markCompleteConstructionPlanModule = () => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    setLoading(true);

    if (selectedPlanID) {
      axios.post(`${apiList.MARK_COMPLETED_CONSTRUCTION_PLAN_MODULE}`, { id: selectedPlanID }, config)
        .then(() => {
          setLoading(false);
          handleClose();
          fetchPlans();
        })
        .catch((error) => {
          setLoading(false);
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  };

  const handleBack = () => {
    if (activeStep > 0) {
      switch (activeStep) {
        case 1:
          ActivityConfigSubRef.current.clearData();
          break;
        case 2:
          ProgressMediaConfigRef.current.clearData();
          ActivityConfigSubRef.current.resetCanSave();
          break;
        case 3:
          ComponentActivitiesConfigRef.current.clearData();
          break;
        default:
          break;
      }
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = () => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          if (canEdit && canSave) {
            saveConstructionPlanModule();
          } else {
            ActivityConfigSubRef.current
              .handleAutoFilterConfigSub(selectedPlanID, subscriberId, loanTypeId);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        case 1:
          if (canEdit && ActivityConfigSubRef.current.getCanSave()) {
            setLoading(true);
            ActivityConfigSubRef.current.handleSubmitData();
          } else {
            ProgressMediaConfigRef.current
              .handleAutoFilterConfigSub(selectedPlanID, subscriberId, loanTypeId);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        case 2:
          ComponentActivitiesConfigRef.current
            .getComponentActivitiesConfigData(selectedPlanID, subscriberId);
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 3:
          if (canEdit && isComponentRequired === 'true') {
            ComponentActivitiesConfigRef.current
              .checkDataAvailable();
          } else if (canEdit && isComponentRequired === 'false') {
            markCompleteConstructionPlanModule();
          } else {
            handleClose();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    if (canEdit) {
      switch (activeStep) {
        case 0:
          return 'SAVE';
        case 1:
          return 'UPDATE';
        case 2:
          return 'CONTINUE';
        case 3:
          return 'SAVE';
        default:
          return 'Next';
      }
    }
    return 'Next';
  };

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={handleClose}
    >
      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton id='plan' onClose={handleClose} />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >
            <div className={classes.slide}>
              <div className={classes.first_slide}>
                <CustomInput
                  id='name'
                  label='Name'
                  value={name}
                  disabled={!canEdit}
                  onChange={(id) => {
                    setCanSave(true);
                    setName(id.target.value);
                  }}
                />
                <CustomSelectOut
                  disabled={!canEdit}
                  id='loanTypeId'
                  title='Loan Type Name'
                  menuItems={loanType.length > 0 ? loanType.map((element) => (
                    <option value={element.id} key={element.id}>{element.loanType}</option>
                  )) : null}
                  handleChange={(event) => {
                    setCanSave(true);
                    setLoanTypeID(event.target.value === '#78' ? -1 : event.target.value);
                  }}
                  value={loanTypeId}
                />
                <CustomSelectOut
                  disabled={!canEdit}
                  id='noOfFloors'
                  title='Floor'
                  menuItems={[{ id: 1, name: 'GROUND' }, { id: 2, name: 'GROUND +1' }, { id: 3, name: 'GROUND +2' }].map((element) => (
                    <option value={element.id} key={element.id}>{element.name}</option>
                  ))}
                  handleChange={(event) => {
                    setCanSave(true);
                    console.log(event.target.value);
                    setNoOfFloors(event.target.value === '#78' ? -1 : event.target.value);
                  }}
                  value={noOfFloors}
                />
                <CustomInput
                  disabled={!canEdit}
                  id='desc'
                  label='Description'
                  multiline
                  rows={4}
                  value={desc}
                  onChange={(id) => {
                    setCanSave(true);
                    setDesc(id.target.value);
                  }}
                />
                <FormControlLabel
                  control={(
                    <Checkbox
                      checked={generateWOComputation === 'true'}
                      onChange={(event) => {
                        setCanSave(true);
                        setGenerateWOComputation(event.target.checked ? 'true' : 'false');
                        setComponentRequired(event.target.checked ? 'false' : 'false');
                      }}
                    />
                  )}
                  label='Basic Construction Plan'
                  labelPlacement='end'
                />
                <FormControlLabel
                  disabled={generateWOComputation === 'true'}
                  control={(
                    <Checkbox
                      checked={isComponentRequired === 'true'}
                      onChange={(event) => {
                        setCanSave(true);
                        setComponentRequired(event.target.checked ? 'true' : 'false');
                      }}
                    />
                  )}
                  label='Consider Floor Components'
                  labelPlacement='end'
                />
              </div>
            </div>
            <div className={classes.slide}>
              <ActivityConfigSubTable
                canEdit={canEdit}
                ref={ActivityConfigSubRef}
                subscriberId={subscriberId}
                loanTypeId={loanTypeId}
                onDataSave={(data) => {
                  setLoading(false);
                  if (data) {
                    ProgressMediaConfigRef.current
                      .handleAutoFilterConfigSub(selectedPlanID, subscriberId, loanTypeId, data);
                    setActiveStep((prevActiveStep) => prevActiveStep + 1);
                  }
                }}
              />
            </div>
            <div className={classes.slide}>
              <ProgressMediaConfigTable
                canEdit={canEdit}
                ref={ProgressMediaConfigRef}
              />
            </div>
            <div className={classes.slide}>
              <ComponentActivitiesConfigTable
                canEdit={canEdit && isComponentRequired === 'true'}
                subscriberId={subscriberId}
                ref={ComponentActivitiesConfigRef}
                isDataAvail={(avail) => {
                  if (avail) {
                    markCompleteConstructionPlanModule();
                  } else {
                    toast.error('Component Configs should not be empty.');
                  }
                }}
              />
            </div>
          </SwipeableViews>
        </div>

      </DialogContent>

      <MobileStepper
        variant='dots'
        steps={4}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' disabled={loading} onClick={handleNext}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>

        )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        )}
      />
    </Dialog>
  );
}
