import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import ListItemText from '@material-ui/core/ListItemText';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

import {
  apiList,
  verifyLogin,
} from '../../helpers';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  listItem: {
    paddingLeft: 16,
    padddingVertical: 16,
  },
});

class FormCreateConstructionPlan extends Component {
  constructor() {
    super();
    this.state = {
      suggestions: [],
      selectedPlan: {},
    };
  }

  componentDidMount() {
    this.searchLoans('');
  }

  searchLoans(searchQuery) {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    const url = `${apiList.GET_CONSTRUCTION_PLAN_MODULE}?noOfFloors=${this.props.noOfFloors}&loanTypeId=${this.props.loanTypeId}&searchQuery=${searchQuery}&completedOnly=${'true'}&subscriberId=${Number(this.props.subscriberId)}`;
    axios.get(url, config)
      .then((response) => {
        this.setState({ suggestions: response.data.data.paginate });
      })
      .catch(() => {
        this.setState({ suggestions: [] });
      });
  }

  render() {
    const { classes, setPlanID, planID } = this.props;
    const { suggestions } = this.state;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='formatID'
          title=''
          menuItems={suggestions.map((suggestion) => (
            <option value={suggestion.id} key={suggestion.id}>{suggestion.planName}</option>
          ))}
          handleChange={(event) => {
            if (event.target.value !== '#78') {
              setPlanID(event.target.value);
              // eslint-disable-next-line eqeqeq
              const selectedPlan = suggestions.find((item) => item.id == event.target.value);
              this.setState({ selectedPlan });
            } else {
              setPlanID(-1);
              this.setState({ selectedPlan: {} });
            }
          }}
          value={planID}
        />
        <ListItemText
          className={classes.listItem}
          primary={`${this.state.selectedPlan.planName || ''}`}
          secondary={this.state.selectedPlan.description || ''}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(FormCreateConstructionPlan);
