import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import moment from 'moment';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
    marginBottom: 16,
    marginTop: 16,
  },
}));

export default function CustomDatePicker(props) {
  const classes = useStyles();
  const {
    id, label, onChange, value,
  } = props;

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <KeyboardDatePicker
        disableToolbar
        className={classes.root}
        autoOk
        id={id}
        variant='inline'
        inputVariant='outlined'
        label={label}
        format='DD-MM-YYYY'
        value={value ? moment(value, 'DD-MM-YYYY HH:mm:ss') : null}
        onChange={onChange}
      />
    </MuiPickersUtilsProvider>

  );
}
