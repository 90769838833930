import React from 'react';

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Toolbar,
  Typography,
  Box,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    flex: '1 1 100%',
  },
  nodataDiv: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  nodata: {
    color: '#999',
    fontSize: 16,
  },
  paper: {
    flex: 1,
    paddingBottom: 50,
    alignSelf: 'center',
    flexDirection: 'column',
    display: 'flex',
    // height: '620px',
  },
  innerPaper: {
    margin: theme.spacing(2),
    width: 'max-content',
  },
  table: {
    width: 'auto', tableLayout: 'auto',
  },
  flexboxDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    // overflow: 'auto',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
}));

export default function CustomTable({
  tableHead, itemComponent, count, handleChangePage, page,
  tableName, icons, onIconClick, noDataText,
}) {
  const classes = useToolbarStyles();

  return (
    <>
      <Paper className={classes.paper}>
        {tableName && (
        <Toolbar className={classes.root}>
          <Typography className={classes.title} variant='h6' id='tableTitle' component='div'>
            {tableName}
          </Typography>
          {icons
          && icons.map((item, index) => (
            <Button
              color='primary'
              size='small'
              onClick={(key) => { onIconClick(key, index); }}
            >
              {item.name}
            </Button>
          ))}
        </Toolbar>
        )}
        {tableName && <Box borderBottom={0.7} borderColor='#EEEEEE' />}
        <div className={classes.flexboxDiv}>
          <Paper className={classes.innerPaper}>
            <TableContainer>
              <Table className={classes.table}>
                {tableHead && (
                <TableHead>
                  <TableRow>
                    {tableHead.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                )}
                <TableBody>
                  {itemComponent}
                </TableBody>
              </Table>
            </TableContainer>

          </Paper>
          {count > 1 && (
            <div className={classes.pagination}>
              <Pagination count={count} page={page} onChange={handleChangePage} />
            </div>
          )}
        </div>
        {!itemComponent && (
        <div className={classes.nodataDiv}>
          <Typography className={classes.nodata} variant='h10' id='tableTitle' component='div'>
            {noDataText || 'You have not added any data.'}
          </Typography>
        </div>
        )}

      </Paper>

    </>
  );
}
