import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  render() {
    const {
      classes, handleChange, activityType,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput id='activityType' label='Activity Type' value={activityType} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
