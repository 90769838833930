import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import OnBoardingDialog from './OnBoardingDialog';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

class UserRoles extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      permissionData: [],
      elemenntId: '',
      role: '',
      externalUser: false,
      externalRoleType: '',
      desc: '',
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.getUserRoles(Number(subscriberId));
    }
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    if (this.state.subscriberId === -1) {
      toast.error('Please Select Subscriber to add role.');
      return;
    }
    this.toggleModal(true);
    if (element) {
      this.setState({
        role: element.role,
        elemenntId: element.id,
        desc: element.desc,
        externalUser: element.externalUser === 'true',
        externalRoleType: element.externalRoleType,
      });
    } else {
      this.setState({
        role: '',
        elemenntId: '',
        desc: '',
        externalUser: false,
        externalRoleType: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData(elemenntId, role, desc, isExternalUser, externalRoleType, permissions) {
    if (role.length < 1 && desc.length < 1 && this.state.subscriberId === -1) {
      toast.error('There is no change/modification found');
      return;
    }
    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      role: role.trim(), desc: desc.trim(), subscriberId: this.state.subscriberId,
    };
    if (isExternalUser) {
      params.externalRoleType = externalRoleType;
      params.externalUser = 'true';
    } else {
      params.permissions = permissions;
      params.externalUser = 'false';
    }
    if (elemenntId) {
      params.id = elemenntId;
    }
    axios.post(`${apiList.UPDATE_USER_ROLE_API}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          elemenntId: '',
          role: '',
          desc: '',
          externalUser: false,
          externalRoleType: '',
          formOpen: false,
        });
        this.toggleModal(false);
        this.getUserRoles(this.state.subscriberId);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  handleDeleteData(key, id) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      this.setState({ result: true });
      axios.post(`${apiList.DELETE_USER_ROLE_API}`, {
        id,
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getUserRoles(this.state.subscriberId);
          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    }
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  getUserRoles(subscriberId) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_ROLES_API}?subscriberID=${subscriberId}`, config)
      .then((response) => {
        this.setState({ data: [...response.data.data] });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      formOpen,
      data,
      page,
      rowsPerPage,
      role, desc,
      externalUser,
      externalRoleType,
      elemenntId,
      permissionData,
      userRoleId,
      subscribers,
      subscriberId,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null }
        {userRoleId === 1 ? (
          <div className='select-fields'>
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                this.setState({ subscriberId: event.target.value });
                this.getUserRoles(event.target.value);
              }}
              value={subscriberId}
            />
          </div>
        ) : null}

        {result === false && (
        <Table
          data={Array.isArray(data) ? data : []}
          MainProps={this.props}
          handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
          handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
          handleShowAddForm={(key) => this.handleShowAddForm(key)}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          handleChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
        />
        )}

        {formOpen ? (
          <OnBoardingDialog
            open={formOpen}
            handleClose={() => this.setState({
              elemenntId: '',
              role: '',
              desc: '',
              formOpen: false,
            })}
            roleID={elemenntId}
            externalUser={externalUser}
            externalRole={externalRoleType}
            roleName={role}
            roleDescription={desc}
            handleSaveData={(roleID, roleName, description,
              isExternalUser, externalRole, permissions) => {
              this.handleSaveData(roleID, roleName, description,
                isExternalUser, externalRole, permissions);
            }}
            permissionData={permissionData}
          />
        ) : null}

      </div>
    );
  }
}

export default UserRoles;
