/* eslint-disable no-param-reassign */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import {
  ListItem, ListItemText,
} from '@material-ui/core';

import {
  apiList,
  shortDate,
  verifyLogin,
} from '../../../helpers';
import { groupDataByactivityTypeAndName } from '../../../helpers/utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
  },
  subRootParent1: {
    width: '25%',
  },
  subRootParent2: {
    width: '30%',
  },
  subRootParent3: {
    width: '30%',
  },
  subRoot: {
    backgroundColor: '#EEEEEE',
    width: '100%',
    padding: 8,
    height: 'calc(100vh - 220px)',
  },
  item: {
    backgroundColor: '#fff',
    height: 35,
    marginTop: 4,
    marginBottom: 4,
  },
  itemSelected: {
    backgroundColor: '#3D4977',
    '&:hover': {
      background: '#3D4977',
    },
    height: 35,
    marginTop: 4,
    marginBottom: 4,
  },
  activity: {
    height: 35,
    marginTop: 4,
    marginBottom: 4,
  },
  activitySelected: {
    backgroundColor: '#3D4977',
    '&:hover': {
      background: '#3D4977',
    },
    height: 35,
    marginTop: 4,
    marginBottom: 4,
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
  title_1: {
    fontSize: 18,
    color: '#EF5350',
    textAlign: 'center',
    paddingBottom: 10,
  },
  title_2: {
    fontSize: 18,
    color: '#AB47BC',
    paddingBottom: 10,
    textAlign: 'center',
  },
  title_3: {
    fontSize: 18,
    color: '#4DD0E1',
    paddingBottom: 10,
    textAlign: 'center',
  },
});

export default function Plans({
  plan, setPlan, loanId, planVersionId, setPlanVersionId, constructionPlanVersion,
  uncategorizedMedia, setUncategorizedMedia,
}) {
  const classes = useStyles();
  const versions = [...Array(constructionPlanVersion - 1).keys()];
  const [planList, setPlanList] = useState([]);
  const [uncategorizedMediaList, setUncategorizedMediaList] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();

  const getCount = (v, constructionPlan) => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    axios.get(`${apiList.GET_CONSTRUCTION_PLAN_PHOTO_COUNT}?propertyId=${loanId}&version=${v}`, config)
      .then((response) => {
        if (response?.data?.data) {
          const countData = response.data.data;
          const newPlan = constructionPlan.filter((item) => {
            const matchObj = countData.find(({ consPlanId }) => consPlanId === item.id);
            return matchObj;
          });
          constructionPlan = newPlan;
        }
        const groupedPlanList = groupDataByactivityTypeAndName(constructionPlan);
        setPlanList(groupedPlanList);
        setSelectedActivity(groupedPlanList[0]);
        setPlan(groupedPlanList[0].data[0]);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const getPlans = (v) => {
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${loanId}/${v}`)
      .then((response) => {
        if (response?.data?.result) {
          const { constructionPlan } = response.data.result;
          getCount(v, constructionPlan);
        }
      })
      .catch(() => {
      });
  };

  const getMediaCount = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'view' } };

    axios.get(`${apiList.GET_UNCATEGORIZED_MEDIA_BY_DATE}?loanId=${loanId}`, config)
      .then((response) => {
        if (response.data.data.length > 0) {
          const data = [...response.data.data];
          setUncategorizedMediaList(data);
          setUncategorizedMedia(data[0]);
        } else {
          setUncategorizedMediaList([]);
          setUncategorizedMedia();
        }
        setPlan(null);
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  useEffect(() => {
    getMediaCount();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.subRootParent1}>
        <div className={classes.title_1}>
          Construction Plan
        </div>
        <div className={classes.subRoot}>
          {uncategorizedMediaList.length > 0 ? (
            <ListItem
              onClick={() => {
                setPlanVersionId(0);
                getMediaCount();
              }}
              button
              className={planVersionId === 0
                ? classes.activitySelected : classes.activity}
            >
              <ListItemText
                primary='Uncategorized Media'
                className={planVersionId === 0
                  ? classes.textSelected : classes.text}
                disableTypography
              />
            </ListItem>
          ) : null}
          {versions.map((element, key) => (
            <ListItem
              onClick={() => {
                setPlanVersionId(key + 1);
                getPlans(key + 1);
              }}
              key={key}
              button
              className={planVersionId === (key + 1)
                ? classes.activitySelected : classes.activity}
            >
              <ListItemText
                primary={`Construction Plan Version ${key + 1}`}
                className={planVersionId === (key + 1)
                  ? classes.textSelected : classes.text}
                disableTypography
              />
            </ListItem>
          ))}
        </div>
      </div>

      <div className={classes.subRootParent2}>
        <div className={classes.title_2}>
          Activity Config
        </div>
        <div className={classes.subRoot}>
          {planVersionId === 0 ? uncategorizedMediaList.map((element, key) => (
            <ListItem
              onClick={() => {
                setUncategorizedMedia(element);
              }}
              key={key}
              button
              className={uncategorizedMedia?.date === element.date
                ? classes.activitySelected : classes.activity}
            >
              <ListItemText
                primary={`Date - ${shortDate(element.submittedDate)}, Media - ${element.count}`}
                className={uncategorizedMedia?.date === element.date
                  ? classes.textSelected : classes.text}
                disableTypography
              />
            </ListItem>
          ))
            : planList.map((element, key) => (
              <ListItem
                onClick={() => {
                  setSelectedActivity(element);
                  setPlan(element.data[0]);
                }}
                key={key}
                button
                className={selectedActivity?.activityConfigName === element.activityConfigName
                  ? classes.activitySelected : classes.activity}
              >
                <ListItemText
                  primary={element.activityConfigName}
                  className={selectedActivity?.activityConfigName === element.activityConfigName
                    ? classes.textSelected : classes.text}
                  disableTypography
                />
              </ListItem>
            ))}
        </div>
      </div>

      <div className={classes.subRootParent3}>
        <div className={classes.title_3}>
          Property Component
        </div>
        <div className={classes.subRoot}>
          {planVersionId !== 0 && selectedActivity ? selectedActivity.data.map((element, key) => (
            <ListItem
              onClick={() => setPlan(element)}
              key={key}
              button
              className={plan?.id === element.id ? classes.itemSelected : classes.item}
            >
              <ListItemText
                primary={element.propertyComponentFloorName
                  ? element.propertyComponentFloorName : `${element.activityConfigName} - ${element.activityTypeConfigName}`}
                className={plan?.id === element.id ? classes.textSelected : classes.text}
                disableTypography
              />
            </ListItem>
          )) : planVersionId === 0 && uncategorizedMediaList.length > 0
            ? (
              <ListItem
                button
                className={classes.itemSelected}
              >
                <ListItemText
                  primary='-'
                  className={classes.textSelected}
                  disableTypography
                />
              </ListItem>
            ) : null}
        </div>
      </div>
    </div>
  );
}
