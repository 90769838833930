import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
// import { toast } from 'react-toastify';

import { withStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomInput from '../../components/CustomInput/CustomInput';

import {
  apiList,
  verifyLogin,
} from '../../helpers';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
  },
  token: {
    fontSize: 16,
  },
});

class Form extends Component {
  constructor() {
    super();
    this.state = {
      token: '',
    };
  }

  componentDidMount() {
    this.getSubscribers();
  }

  getSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_SERVER_TOKEN}?subscriberId=${this.props.subscriberID}`, config)
      .then((response) => {
        if (response.data.data) {
          this.setState({ token: response.data.data });
        } else {
          this.setState({ token: '' });
        }
      })
      .catch((error) => {
        console.log(error);
        this.setState({ token: '' });
      });
  }

  // generateToken() {
  //   const { session } = verifyLogin();
  //   const config = { headers: { session } };
  //   const params = {
  //     subscriberId: this.props.subscriberID,
  //   };

  //   axios.post(`${apiList.GENERATE_SERVER_TOKEN}`, params, config)
  //     .then(() => {
  //       this.getSubscribers();
  //     })
  //     .catch((error) => {
  //       toast.error(`${error.response.data.error || error.response.data.message}`);
  //     });
  // }

  render() {
    const {
      classes, handleChange, subscriberName, subscriberType, mobile, email,
      mapCircleRadius, isMediaSubmissionAllowedWithoutConsPlan, setMediaSubmission,
      sopURL,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput id='subscriberName' label='Subscriber Name' value={subscriberName} onChange={(id) => handleChange(id)} />
        <CustomInput id='subscriberType' label='Subscriber Type' value={subscriberType} onChange={(id) => handleChange(id)} />
        <CustomInput
          id='mobile'
          label='Mobile'
          InputLabelProps={{
            shrink: true,
          }}
          value={mobile}
          onChange={(id) => {
            handleChange(id);
          }}
        />
        <CustomInput type='email' id='email' label='Email' value={email} onChange={(id) => handleChange(id)} />
        <CustomInput
          id='mapCircleRadius'
          type='number'
          label='Geotagging Radius'
          InputLabelProps={{
            shrink: true,
          }}
          value={mapCircleRadius}
          onChange={(id) => {
            handleChange(id);
          }}
        />
        <CustomInput id='sopURL' label='SOP URL' value={sopURL} onChange={(id) => handleChange(id)} />

        <FormControlLabel
          className={classes.checkBoxForm}
          control={(
            <Checkbox
              checked={isMediaSubmissionAllowedWithoutConsPlan === 'true'}
              onChange={(event) => {
                setMediaSubmission(event.target.checked ? 'true' : 'false');
              }}
              name='Allow Progress w/o Plan'
            />
          )}
          label='Allow Progress w/o Plan'
          labelPlacement='start'
        />
        {this.state.token ? <div className={classes.token}>{`Server Token: ${this.state.token}`}</div> : null}
        {/* <div
          className='buttonRight'
          color='primary'
          size='small'
          onClick={() => this.generateToken()}
        >
          generateToken
        </div> */}
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
