import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';

import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './FormCustomerDetails';

class UserLoansMapping extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
      loanId: '',
      userId: '',
      loanMappingID: '',
      userList: [],
      loanList: [],
      loanNum: '',
      userName: '',
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId } = user;
    verifyUser(Number(userRoleId));
    this.getLoanMappings();
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        userId: element.userId,
        loanId: element.loanId,
        loanMappingID: element.id,
        userName: element.Loan?.custName || '',
        loanNum: element.Loan?.loanNumber || '',
      });
    } else {
      this.setState({
        loanId: '',
        userId: '',
        loanMappingID: '',
        loanNum: '',
        userName: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleDeleteData(key, id) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      this.setState({ result: true });
      axios.post(`${apiList.DELETE_USER_LOAN_MAPPING_API}`, {
        id,
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getLoanMappings();
          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    }
  }

  getLoanMappings() {
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.VIEW_USERS_LOANS_MAPPING_API}`, config)
      .then((response) => {
        this.setState({ data: [...response.data.data] });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  body=() => (
    <Form
      ref={(el) => { this.refForm = el; }}
      loanMappingID={this.state.loanMappingID}
      userList={this.state.userList}
      loanList={this.state.loanList}
      loanId={this.state.loanId}
      userId={this.state.userId}
      loan={this.state.loanNum}
      user={this.state.userName}
      complete={() => {
        this.toggleModal(false);
        this.getLoanMappings();
      }}
    />
  )

  toggleModal(value) {
    this.setState({
      formOpen: value,
      loanId: '',
      userId: '',
      loanMappingID: '',
      loanNum: '',
      userName: '',
    });
  }

  render() {
    const {
      result,
      page,
      rowsPerPage,
      data,
      formOpen,
      newForm,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null }

        {result === false && (
        <Table
          data={Array.isArray(data) ? data : []}
          handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
          handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
          handleShowAddForm={(key) => this.handleShowAddForm(key)}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        )}

        {formOpen ? (
          <CustomModal
            width='md'
            open={formOpen}
            setOpen={(value) => this.toggleModal(value)}
            newForm={newForm}
            body={this.body()}
            handleSaveData={() => this.refForm.handleSaveData()}
            title={newForm ? 'Add Loan Mapping' : 'Update Loan Mapping'}
          />
        ) : null}

      </div>
    );
  }
}

export default UserLoansMapping;
