import React, { Component } from 'react';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import Table from './table';
import LoansWithoutMappingTable from './LoansWithoutMappingTable';
import Navbar from '../../components/navBar/navBar';
import { verifyLogin, apiList } from '../../helpers';
import OnBoardingDialog from './OnBoardingDialog';
import Button from '../../components/CustomButtons/Button';

import 'react-toastify/dist/ReactToastify.css';

class CustomerAccounts extends Component {
  constructor() {
    super();
    this.state = {
      sectionOne: true,
      sectionTwo: true,
      loanId: null,
      formOpen: false,
      loanTypes: [],
      permission: {},
    };
  }

  componentDidMount() {
    const { roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const permission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Loan');
      if (permission) {
        this.setState({ permission: permission || {} });
      }
    }
    this.getLoanTypes();
  }

  handleSectionOne(key) {
    key.preventDefault();
    const { sectionOne } = this.state;

    if (sectionOne === false) {
      this.setState({ sectionOne: true });
    } if (sectionOne === true) {
      this.setState({ sectionOne: false });
    }
  }

  handleSectionTwo(key) {
    key.preventDefault();
    const { sectionTwo } = this.state;

    if (sectionTwo === false) {
      this.setState({ sectionTwo: true });
    } if (sectionTwo === true) {
      this.setState({ sectionTwo: false });
    }
  }

  getLoanTypes() {
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.VIEW_USERS_LOAN_TYPE_API}`, config)
      .then((response) => {
        this.setState({ loanTypes: [...response.data.data.paginate] });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  deleteLoan(id) {
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Loan', feature: 'delete' } };
      axios.post(`${apiList.DELETE_LOAN}`, { id }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.loanTable.getLoans();
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  }

  render() {
    const {
      sectionOne, sectionTwo, formOpen, loanId, loanTypes, permission,
    } = this.state;
    verifyLogin();
    return (
      <div className='view-details'>
        <ToastContainer />

        <Navbar MainProps={this.props} />

        <div className='contents'>

          <div className='loan-details'>

            <div className='data-headerEdit'>
              <div>
                {sectionOne
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />}
                <span>
                  Loans
                </span>
              </div>
              {sectionOne && permission.add && (
                <Button
                  className='buttonRight'
                  color='primary'
                  size='small'
                  onClick={() => {
                    this.setState({
                      formOpen: true,
                      loanId: null,
                    });
                  }}
                >
                  Add Loan
                </Button>
              )}
            </div>

            {sectionOne === true && permission.view
              ? (
                <Table
                  permission={permission}
                  ref={(el) => { this.loanTable = el; }}
                  MainProps={this.props}
                  MainState={this.state}
                  handleShowEditForm={(element) => {
                    this.setState({
                      formOpen: true,
                      loanId: element.id,
                    });
                  }}
                  handleDeleteData={(id) => {
                    this.deleteLoan(id);
                  }}
                />
              ) : null}

            <div className='data-header'>
              {sectionTwo === true
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionTwo(key); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionTwo(key); }} />}
              <span>
                Loans without loan mapping
              </span>
            </div>

            {sectionTwo === true && permission.view
              && <LoansWithoutMappingTable MainProps={this.props} MainState={this.state} />}
          </div>
        </div>

        {formOpen ? (
          <OnBoardingDialog
            open={formOpen}
            loanTypes={loanTypes}
            handleClose={(reload) => {
              this.setState({ formOpen: false });
              if (reload) {
                this.loanTable.getLoans();
              }
            }}
            loanId={loanId}
          />
        ) : null}

      </div>
    );
  }
}

export default CustomerAccounts;
