/* eslint-disable no-undef */
import React, { Component } from 'react';
import axios from 'axios';
import Link from '@material-ui/core/Link';

import {
  withGoogleMap, GoogleMap, Marker,
  InfoWindow,
} from 'react-google-maps';
import {
  apiList,
  verifyLogin,
  encrypt,
} from '../../helpers';

const { MarkerClusterer } = require('react-google-maps/lib/components/addons/MarkerClusterer');

export class Googlemaps extends Component {
  constructor() {
    super();
    this.state = {
      markers: [],
      center: { lat: 26.2703424, lng: 72.9605034 },
      zoom: 12,
      infoBoxOpen: false,
      selectedMarker: {},
    };
  }

  componentDidMount() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.setState({
          center:
            { lat: position.coords.latitude, lng: position.coords.longitude },
        });
        this.getProperties(position.coords.latitude, position.coords.longitude);
      },
      (error) => {
        console.error(`Error Code = ${error.code} - ${error.message}`);
      });
      // navigator.geolocation.watchPosition((position) => {
      //   // console.log('Latitude is :', position.coords.latitude);
      //   // console.log('Longitude is :', position.coords.longitude);
      // });
    }
  }

  handleViewLoanDetails = (e, viewId) => {
    e.persist();
    e.preventDefault();
    const detailId = encrypt(viewId);
    const redirectUrl = `/view-loan-details/${detailId}`;
    if (e.type === 'click') {
      window.location.href = redirectUrl;
    } else if (e.type === 'contextmenu') {
      const menu = new nw.Menu();
      const menutItem = new nw.MenuItem({
        label: 'Open link in new tab',

        click: () => window.open(redirectUrl, '_blank'),
      });

      menu.append(menutItem);
      menu.popup(10, 10);
    }
  }

  onItemClick = (selectedMarker) => {
    this.setState({
      selectedMarker,
      infoBoxOpen: true,
      center: {
        lat: parseFloat(selectedMarker.lat.replaceAll(' ', '')),
        lng: parseFloat(selectedMarker.lng.replaceAll(' ', '')),
      },
      zoom: 16,
    });
  }

  onMarkerClick = (selectedMarker) => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Property Details', feature: 'view' } };

    axios.get(`${apiList.VIEW_PROPERTY_BY_LOAN}?loanId=${selectedMarker.loanId}`, config)
      .then((response) => {
        this.onItemClick(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getProperties(lat, lng) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Property Details', feature: 'view' } };

    axios.get(`${apiList.GET_NEARBY_PROPERTY}?lat=${lat}&lng=${lng}`, config)
      .then((response) => {
        const bounds = new google.maps.LatLngBounds();
        const newData = response.data.data.filter((item) => item.lat && item.lng);
        newData.forEach((marker) => {
          if (marker?.lat && marker?.lng) {
            const latitude = parseFloat(marker.lat.replaceAll(' ', ''));
            const longitude = parseFloat(marker.lng.replaceAll(' ', ''));
            bounds.extend(new google.maps.LatLng(latitude, longitude));
          }
        });
        this.map.fitBounds(bounds);
        this.setState({
          markers: [...newData],
          zoom: this.map.getZoom(),
          center: this.map.getCenter(),
        });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    const GoogleMaps = withGoogleMap((props) => (
      <GoogleMap
        defaultCenter={this.state.center}
        defaultZoom={this.state.zoom}
        ref={(el) => { this.map = el; }}
        onDragEnd={() => {
          // const bounds = this.map.getBounds();
          // const center = this.map.getCenter();
        }}
        onZoomChanged={() => {
          // const bounds = this.map.getBounds();
          // const center = this.map.getCenter();
        }}
      >
        {/* <Marker
          position={this.state.center}
          onClick={props.onToggleOpen}
        /> */}

        <MarkerClusterer
          averageCenter
          enableRetinaIcons
          gridSize={60}
        >
          {this.state.markers.map((marker) => (
            <Marker
              onClick={() => {
                this.onMarkerClick(marker);
              }}
              tracksViewChanges={false}
              key={`${marker.id}`}
              position={{ lat: parseFloat(marker.lat.replaceAll(' ', '')), lng: parseFloat(marker.lng.replaceAll(' ', '')) }}
            >
              {this.state.infoBoxOpen && this.state.selectedMarker?.lat
                && this.state.selectedMarker.lng && marker.id === this.state.selectedMarker.id && (
                  <InfoWindow
                    defaultPosition={new google.maps.LatLng(this.state.selectedMarker?.lat || 0,
                      this.state.selectedMarker?.lng || 0)}
                    onCloseClick={props.onToggleOpen}
                    options={{ closeBoxURL: '', enableEventPropagation: true }}
                  >
                    <div
                      style={{
                        minWidth: 140,
                        padding: 5,
                      }}
                    >
                      <Link
                        underline='always'
                        style={{
                          fontSize: '16px',
                          fontColor: '#08233B',
                          paddingBottom: 4,
                          cursor: 'pointer',
                        }}
                        href={`/view-loan-details/${encrypt(this.state.selectedMarker.loanId)}`}
                      >
                        {`Loan Number: ${this.state.selectedMarker?.Loan.loanNumber}`}
                      </Link>
                      <div style={{
                        fontSize: '16px', fontColor: '#08233B', paddingBottom: 4,
                      }}
                      >
                        {`Customer Name: ${this.state.selectedMarker?.Loan.custName}`}
                      </div>
                      <div style={{
                        fontSize: '16px', fontColor: '#08233B',
                      }}
                      >
                        {`Property Name: ${this.state.selectedMarker?.propertyName}`}
                      </div>

                    </div>
                  </InfoWindow>

              )}
            </Marker>
          ))}
        </MarkerClusterer>
      </GoogleMap>
    ));
    return (
      <div className='map-container'>
        <GoogleMaps
          containerElement={(
            <div
              style={{ height: window.innerHeight - 104, width: window.innerWidth - 364 }}
            />
          )}
          mapElement={<div style={{ height: '100%' }} />}
        />
      </div>
    );
  }
}

export default Googlemaps;
