import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { DialogActions } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { apiList, verifyLogin } from '../../helpers';
import CustomSelect from '../CustomSelect/CustomSelect';

const ColorButton = withStyles(() => ({
  root: {
    width: 100,
    alignSelf: 'center',
  },
}))(Button);

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

class ReportFormatList extends Component {
  constructor() {
    super();
    this.state = {
      formats: [],
      selectedFormat: -1,
    };
  }

  componentDidMount() {
    const { session } = verifyLogin();
    this.getFormats(session);
  }

  getFormats(session) {
    const config = { headers: { session } };
    const { type } = this.props;
    axios.get(`${apiList.GET_REPORT_FORMAT}?type=${type}`, config)
      .then((response) => {
        if (response?.data?.data) {
          this.setState({
            formats: response.data.data,
            selectedFormat: response.data.data[0].id,
          });
        }
      })
      .catch(() => {
      });
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      formats,
      selectedFormat,
    } = this.state;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='formatID'
          title=''
          menuItems={formats.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            if (event.target.value !== '#78') {
              this.setState({ selectedFormat: event.target.value });
            } else {
              this.setState({ selectedFormat: -1 });
            }
          }}
          value={selectedFormat}
        />
        <DialogActions>
          <ColorButton
            autoFocus
            size='small'
            variant='contained'
            onClick={() => {
              if (selectedFormat !== -1) {
                this.props.OnContinue(this.state.selectedFormat);
              }
            }}
            color='primary'
          >
            Generate
          </ColorButton>
        </DialogActions>
      </div>
    );
  }
}

export default withStyles(useStyles)(ReportFormatList);
