import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';

import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';

class Subscribers extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: {},
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      subscriberName: '',
      subscriberType: '',
      mobile: '',
      email: '',
      subscriberID: '',
      sopURL: '',
      mapCircleRadius: '',
      isMediaSubmissionAllowedWithoutConsPlan: 'false',
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId } = user;
    verifyUser(Number(userRoleId));
    this.getSubscribers();
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        subscriberName: element.subscriberName,
        subscriberType: element.subscriberType,
        mapCircleRadius: element.mapCircleRadius,
        isMediaSubmissionAllowedWithoutConsPlan: element.isMediaSubmissionAllowedWithoutConsPlan,
        mobile: element.mobile,
        sopURL: element.sopURL,
        email: element.email,
        subscriberID: element.id,
      });
    } else {
      this.setState({
        subscriberName: '',
        mapCircleRadius: '',
        isMediaSubmissionAllowedWithoutConsPlan: 'false',
        subscriberType: '',
        mobile: '',
        sopURL: '',
        email: '',
        subscriberID: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      subscriberID, subscriberName, subscriberType, mobile, email, sopURL,
      mapCircleRadius, isMediaSubmissionAllowedWithoutConsPlan,
    } = this.state;
    if (subscriberName.length < 1
      && subscriberType.length < 1
      && mobile.length < 1
      && email.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      subscriberName,
      subscriberType,
      mobile: `+91${mobile}`,
      email: email.trim(),
      mapCircleRadius,
      sopURL,
      isMediaSubmissionAllowedWithoutConsPlan,
    };
    if (subscriberID) {
      params.id = subscriberID;
    }
    axios.post(`${apiList.UPDATE_SUBSCRIBER_API}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          subscriberName: '',
          subscriberType: '',
          mobile: '',
          email: '',
          sopURL: '',
          subscriberID: '',
          mapCircleRadius: '',
          isMediaSubmissionAllowedWithoutConsPlan: 'false',
        });
        this.toggleModal(false);
        this.getSubscribers();
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  handleDeleteData(key, id) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      this.setState({ result: true });
      axios.post(`${apiList.DELETE_SUBSCRIBER_API}`, {
        id,
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getSubscribers();
          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    }
  }

  getSubscribers() {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ data: { ...response.data.data } });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body = () => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      subscriberName={this.state.subscriberName}
      subscriberType={this.state.subscriberType}
      subscriberID={this.state.subscriberID}
      mobile={this.state.mobile}
      sopURL={this.state.sopURL}
      email={this.state.email}
      mapCircleRadius={this.state.mapCircleRadius}
      isMediaSubmissionAllowedWithoutConsPlan={this.state.isMediaSubmissionAllowedWithoutConsPlan}
      setMediaSubmission={(isMediaSubmissionAllowedWithoutConsPlan) => {
        this.setState({ isMediaSubmissionAllowedWithoutConsPlan });
      }}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      data,
      page,
      rowsPerPage,
      formOpen,
      subscriberID,
    } = this.state;
    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null}

        {result === false && (
          <Table
            data={Array.isArray(data.paginate) ? data.paginate : []}
            handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
            handleShowEditForm={(key, elemennt) => this.handleShowAddForm(key, elemennt)}
            handleShowAddForm={(key) => this.handleShowAddForm(key)}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        )}

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={!subscriberID}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={!subscriberID ? 'Create New Subscriber' : 'Update Subscriber'}
        />

      </div>
    );
  }
}

export default Subscribers;
