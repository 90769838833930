import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '36ch',
    height: 400,
    position: 'relative',
    overflow: 'auto',
    backgroundColor: theme.palette.background.paper,
  },
  inline: {
    display: 'inline',
  },
}));

export default function LocationListAuto(props) {
  const classes = useStyles();
  const {
    item,
  } = props;

  return (
    <List className={classes.root}>
      {item}
    </List>

  );
}
