import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import {
  shortDate,
  apiList,
  encrypt,
  verifyLogin,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';

const columns = [
  {
    id: 'Id', label: 'Request ID',
  },
  {
    id: 'RequestType', label: 'Request Type',
  },
  {
    id: 'SubmittedDate', label: 'Submitted Date',
  },
  {
    id: 'Status',
    label: 'Status',
  },
  {
    id: 'ServicedOn',
    label: 'Serviced On',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
});

class DisbursalTable extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 5,
      pageCount: 0,
      data: [],
    };
  }

  componentDidMount() {
    this.getLoanDisbursal(this.state.page, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.getLoanDisbursal(newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getLoanDisbursal(1, +event.target.value);
  };

  getLoanDisbursal(page, rowsPerPage) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };

    axios.get(`${apiList.GET_LOAN_DISBURSAL_REQUESTS_API}?loanId=${this.props.loanId}&page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        this.setState({
          data: [...response.data.data.paginate],
          pageCount: Math.ceil(response.data.data.pageCount),
        });
      })
      .catch(() => {
        this.setState({
          data: [],
          pageCount: 0,
        });
      });
  }

  getViewDetailsUrl(viewId, requestDate) {
    const params = {
      loanId: this.props.loanId,
      disRequestID: viewId,
      disRequestDate: requestDate,
    };
    const detailId = encrypt(JSON.stringify(params));
    return `/view-request-details/${detailId}`;
  }

  render() {
    const { classes, subscriberName, branchIFSC } = this.props;

    const {
      data,
      pageCount,
      page,
    } = this.state;
    return (
      <div className={classes.mainContainer}>
        <>
          <CustomTable
            showPagination
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell>
                  <Link
                    underline='always'
                    href={this.getViewDetailsUrl(element.id, shortDate(`${element.requestDate}`))}
                  >
                    {`${subscriberName}${branchIFSC}${element.id}`}
                  </Link>
                </TableCell>
                <TableCell>
                  {element.requestType}
                </TableCell>
                <TableCell>
                  {shortDate(element.requestDate)}
                </TableCell>
                <TableCell>
                  {element.requestStatus}
                </TableCell>
                <TableCell>
                  {shortDate(element.closedDate)}
                </TableCell>
              </TableRow>
            )) : null}
            count={pageCount}
            rowsPerPage={0}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </>
      </div>
    );
  }
}

export default withStyles(useStyles)(DisbursalTable);
