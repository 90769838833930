import React, { Component } from 'react';
import axios from 'axios';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from 'react-toastify';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';

import {
  apiList,
  variables,
  summation,
  verifyLogin,
} from '../../helpers';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';

const errorTimeOut = variables.SUCCUSS_TIMEOUT;

const columns = [
  {
    id: 'ActivityType', label: 'Activity Type',
  },
  {
    id: 'ActivityName', label: 'Activity Name',
  },
  {
    id: 'ActivityLevel', label: 'Activity Level',
  },
  {
    id: 'ispaymentms', label: 'Is Payment MS',
  },
  {
    id: 'Weightage', label: 'Weightage',
  },
  {
    id: 'NoPictures', label: 'No of Pictures',
  },
  {
    id: 'NoVideos', label: 'No of Videos',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = (theme) => ({
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    overflow: 'auto',
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
  floatingButton: {
    height: 36,
    width: 36,
    cursor: 'pointer',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  btn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class ActivityConfigSub extends Component {
  constructor() {
    super();
    this.state = {
      rowId: null,
      data: [],
      activityTypes: {},
      loanTypeId: -1,
      subscriberId: -1,
      planID: -1,
      activityType: '',
      activityName: '',
      activitylevel: '',
      weightage: '',
      isPaymentMS: 'True',
      noOfImages: '',
      noOfVideos: '',
      activityNameContainer: [],
      formOpen: false,
      newForm: true,
      canSave: false,
    };
  }

  componentDidMount() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    this.handleFetchActivityType(config);
  }

  handleFetchActivityType(config) {
    axios.get(`${apiList.VIEW_USERS_ACTIVITY_TYPE_CONFIG_API}?subscriberId=${this.props.subscriberId}`, config)
      .then((response) => {
        this.setState({ activityTypes: { ...response.data.data } });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  async handleActivityNameAutoSelect(key, activityId) {
    key.preventDefault();
    const { activityTypes } = this.state;

    const filteredData = await activityTypes.paginate
      .find((element) => element.id === Number(activityId));

    if (filteredData !== undefined) {
      this.setState({ activityNameContainer: [...filteredData.ActivityConfig] });
    }
    if (activityId.length < 1) {
      this.setState({
        activityNameContainer: [],
        activitylevel: '',
      });
    }
  }

  async handleActivityLevelAutoSelect(key, activityTypeId) {
    key.preventDefault();
    const { activityNameContainer } = this.state;

    const filteredData = await activityNameContainer
      .find((element) => element.id === Number(activityTypeId));
    if (filteredData !== undefined) {
      this.setState({
        activitylevel: filteredData.activitylevel,
      });
    }
    if (activityTypeId.length < 1) {
      this.setState({
        activitylevel: '',
      });
    }
  }

  handleShowAddForm(key) {
    key.preventDefault();
    this.toggleModal(true);
    this.setState({ newForm: true });

    this.setState({
      rowId: '',
      activityNameContainer: [],
      weightage: '',
      noOfImages: '',
      isPaymentMS: 'True',
      noOfVideos: '',
      activitylevel: '',
      activityName: '',
      activityType: '',
    });
  }

  handleHideAddForm(key) {
    key.preventDefault();
    this.setState({
      rowId: null,
      activityType: '',
      activityName: '',
      activitylevel: '',
      weightage: '',
      isPaymentMS: 'True',
      noOfImages: '',
      noOfVideos: '',
    });
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  async handleAdd(key) {
    key.preventDefault();
    const {
      data, activityTypes, activityNameContainer,
      activityType, activityName, activitylevel, weightage, isPaymentMS,
      noOfImages, noOfVideos,
      subscriberId, loanTypeId,
    } = this.state;
    if (activityType.length < 1 || activityName.length < 1
      || activitylevel.length < 1 || weightage.length < 1
       || noOfImages.length < 1 || noOfVideos.length < 1) {
      toast.error('Please fill the form fieds first');
      return;
    }

    if (activityNameContainer.length > 0) {
      const actconfiID = await activityTypes.paginate
        .find((element) => element.id === Number(activityType)).id;
      const activityTy = await activityTypes.paginate
        .find((element) => element.id === Number(activityType)).activityType;
      const acid = await activityNameContainer
        .find((element) => element.id === Number(activityName)).id;
      const acactivityName = await activityNameContainer
        .find((element) => element.id === Number(activityName)).activityName;
      const acactivitylevel = await activityNameContainer
        .find((element) => element.id === Number(activityName)).activitylevel;
      const acactivityTypeId = await activityNameContainer
        .find((element) => element.id === Number(activityName)).activityTypeId;

      const ActivityTypeConfig = {
        id: actconfiID,
        activityType: activityTy,
      };
      const ActivityConfig = {
        ActivityTypeConfig,
        id: acid,
        activityName: acactivityName,
        activitylevel: acactivitylevel,
        activityTypeId: acactivityTypeId,
      };
      const steps = {
        subscriberId,
        loanTypeId,
        activityConfigId: Number(activityName),
        isApplicable: true,
        ActivityConfig,
        isPaymentMS,
        weightage: Number(weightage),
        noOfImages: Number(noOfImages),
        noOfVideos: Number(noOfVideos),
      };

      let found = null;
      data.forEach((item) => {
        if (steps.activityConfigId === item.activityConfigId
          && steps.ActivityConfig?.activityName === item.ActivityConfig?.activityName
          && steps.ActivityConfig?.activitylevel === item.ActivityConfig?.activitylevel) {
          found = item;
        }
      });
      if (found) {
        toast.error('You can not add same config.');
        return;
      }

      data.push(steps);
      this.setState({
        weightage: '',
        isPaymentMS: 'True',
        noOfImages: '',
        noOfVideos: '',
        activityType: '',
        activityName: '',
        activitylevel: '',
        canSave: true,
      });
    }
    this.toggleModal(false);
  }

  async handleEdit(key) {
    key.preventDefault();
    const {
      rowId, data, activityTypes,
      activityNameContainer, subscriberId, loanTypeId,
      activityType, activityName, weightage, noOfImages, noOfVideos,
      activitylevel,
      isPaymentMS,
    } = this.state;
    if (activityType.length < 1 || activityName.length < 1
      || activitylevel.length < 1 || weightage.length < 1
      || noOfImages.length < 1 || noOfVideos.length < 1) {
      toast.error('Please update the form fieds first');
      return;
    }

    const newData = [...data];
    const index = rowId;

    newData[index].subscriberId = Number(subscriberId);
    newData[index].loanTypeId = Number(loanTypeId);

    newData[index].ActivityConfig.ActivityTypeConfig.id = await activityTypes
      .paginate.find((element) => element.id === Number(activityType)).id;
    newData[index].ActivityConfig.ActivityTypeConfig.activityType = await activityTypes
      .paginate.find((element) => element.id === Number(activityType)).activityType;

    newData[index].ActivityConfig.id = await activityNameContainer
      .find((element) => element.id === Number(activityName)).id;
    newData[index].ActivityConfig.activityName = await activityNameContainer
      .find((element) => element.id === Number(activityName)).activityName;
    newData[index].ActivityConfig.activitylevel = await activityNameContainer
      .find((element) => element.id === Number(activityName)).activitylevel;
    newData[index].ActivityConfig.activityTypeId = await activityNameContainer
      .find((element) => element.id === Number(activityName)).activityTypeId;

    newData[index].activityConfigId = Number(activityName);
    newData[index].weightage = Number(weightage);
    newData[index].isPaymentMS = isPaymentMS;
    newData[index].noOfImages = Number(noOfImages);
    newData[index].noOfVideos = Number(noOfVideos);

    this.setState({ data: newData });

    this.setState({
      activityType: '',
      activityName: '',
      activitylevel: '',
      weightage: '',
      isPaymentMS: 'True',
      noOfImages: '',
      noOfVideos: '',
      canSave: true,
    });
    this.toggleModal(false);
  }

  async handleEditSavedData(key, row) {
    key.preventDefault();
    this.setState({ newForm: false });
    const { data, activityTypes } = this.state;
    const filteredData = await data[row];
    const filteredNewData = await activityTypes
      .paginate.find((element) => element.id
    === Number(filteredData.ActivityConfig?.ActivityTypeConfig?.id));

    this.setState({
      rowId: row,
      activityNameContainer: [...filteredNewData.ActivityConfig],
      subscriberId: filteredData.subscriberId,
      loanTypeId: filteredData.loanTypeId,
      weightage: filteredData.weightage,
      isPaymentMS: filteredData.isPaymentMS,
      noOfImages: filteredData.noOfImages,
      noOfVideos: filteredData.noOfVideos,
      activitylevel: filteredData.ActivityConfig?.activitylevel,
      activityName: filteredData.ActivityConfig?.id,
      activityType: filteredData.ActivityConfig?.ActivityTypeConfig?.id,
    });
    this.toggleModal(true);
  }

  handleDeleteSavedData(key, rowIndex) {
    key.preventDefault();
    const { data } = this.state;

    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK and UPDATE buttons')) {
      this.setState({ data: data.filter((_, i) => i !== rowIndex), canSave: true });
    }
  }

  handleAutoFilterConfigSub(planID, subscriberId, loanTypeId) {
    this.setState({ subscriberId, loanTypeId, planID });
    if (subscriberId !== -1 && loanTypeId !== -1) {
      const { session } = verifyLogin();
      const config = { headers: { session } };

      axios.post(`${apiList.VIEW_USERS_ACTIVITIES_CONFIG_SUB_BY_SUBID_AND_LOANID_API}`, { planID }, config)
        .then((response) => {
          this.setState({ data: [...response.data.data] });
        })
        .catch(() => {
          // toast.error(`${error.response.data.error || error.response.data.message}`);
          this.setState({ data: [] });
        });
    } else {
      this.setState({ data: [] });
    }
  }

  handleSubmitData() {
    const {
      data, subscriberId, loanTypeId, planID,
    } = this.state;
    const weightageTotal = summation(data, 'weightage');
    if (weightageTotal !== 100) {
      toast.error('Total weightage should be 100%');
      this.props.onDataSave(null);
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.patch(`${apiList.UPDATE_USERS_ACTIVITY_CONFIG_SUB_API}`, {
      planID, subscriberId, loanTypeId, data,
    }, config)
      .then((response) => {
        this.setState({ data: [...response.data.data] });
        this.props.onDataSave(response.data.data);
        setTimeout(() => {
        }, errorTimeOut);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        this.props.onDataSave(null);
        setTimeout(() => {
        }, errorTimeOut);
      });
  }

  getCanSave() {
    return this.state.canSave;
  }

  body=() => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      activityType={this.state.activityType}
      activityTypes={this.state.activityTypes}
      activityName={this.state.activityName}
      activityNameContainer={this.state.activityNameContainer}
      activitylevel={this.state.activitylevel}
      weightage={this.state.weightage}
      isPaymentMS={this.state.isPaymentMS}
      setisPaymentMS={(event) => {
        this.setState({ isPaymentMS: event.target.checked ? 'True' : 'False' });
      }}
      noOfImages={this.state.noOfImages}
      newForm={this.state.newForm}
      noOfVideos={this.state.noOfVideos}
      setActivityType={(event) => {
        this.setState({ activityType: event.target.value });
        this.handleActivityNameAutoSelect(event, event.target.value);
      }}
      setActivityName={(event) => {
        this.setState({ activityName: event.target.value });
        this.handleActivityLevelAutoSelect(event, event.target.value);
      }}

    />
  )

  resetCanSave() {
    this.setState({ canSave: false });
  }

  clearData() {
    this.setState({ data: [] });
  }

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const { classes } = this.props;
    const {
      formOpen, newForm,
    } = this.state;
    let { data } = this.state;
    const totalWeight = data.reduce((n, { weightage }) => n + JSON.parse(weightage), 0);
    const Pictures = data.reduce((n, { noOfImages }) => n + JSON.parse(noOfImages), 0);
    const videos = data.reduce((n, { noOfVideos }) => n + JSON.parse(noOfVideos), 0);
    data = [...data, {}];
    return (
      <div className={classes.mainContainer}>
        {this.props.canEdit ? (
          <div className={classes.btn}>
            <AddCircleRoundedIcon
              color='primary'
              className={classes.floatingButton}
              onClick={(key) => {
                this.handleShowAddForm(key);
              }}
            />
          </div>
        ) : null}

        <CustomTable
          innerPaperMarginZero
          tableHead={columns}
          itemComponent={data.length > 1 ? data.map((element, index) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >
              <TableCell>
                {index < data.length - 1 ? (element.ActivityConfig?.ActivityTypeConfig
                  ? element.ActivityConfig?.ActivityTypeConfig?.activityType
                  : element.ActivityTypeConfig?.activityType) : 'Total'}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? element.ActivityConfig?.activityName : ''}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? element.ActivityConfig?.activitylevel : ''}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? (element.isPaymentMS || 'False') : ''}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? `${element.weightage}%` : `${totalWeight}%`}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? element.noOfImages : Pictures}
              </TableCell>
              <TableCell>
                {index < data.length - 1 ? element.noOfVideos : videos}
              </TableCell>
              {index < data.length - 1 && (
                <TableCell>
                  <Button
                    color='primary'
                    justIcon
                    simple
                    round
                    disabled={!this.props.canEdit}
                    onClick={(key) => { this.handleEditSavedData(key, index); }}
                    className={classes.buttonLink}
                  >
                    <EditIcon className={classes.icons} />
                  </Button>
                  <Button
                    color='danger'
                    justIcon
                    simple
                    disabled={!this.props.canEdit}
                    round
                    onClick={(key) => { this.handleDeleteSavedData(key, index); }}
                    className={classes.buttonLink}
                  >
                    <DeleteIcon className={classes.icons} />
                  </Button>

                </TableCell>
              )}
            </TableRow>
          )) : null}
          count={1}
          rowsPerPage={0}
          page={1}
        />

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={(key) => (newForm ? this.handleAdd(key) : this.handleEdit(key))}
          title={newForm ? 'Create New Activity Config' : 'Update Activity Config'}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ActivityConfigSub);
