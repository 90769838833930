import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import CameraAltOutlinedIcon from '@material-ui/icons/CameraAltOutlined';
import VideocamOutlinedIcon from '@material-ui/icons/VideocamOutlined';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';

const useStyles = makeStyles({
  root: {
    width: 300,
    margin: 10,
  },
  cardContent: {
    padding: '8px',
  },
  noImageIcon: {
    height: 150,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderBottom: '1px solid #ccc',
  },
});

export default function CustomCardMedia({
  component,
  title,
  image,
  onClick,
}) {
  const classes = useStyles();

  return (
    <div>
      <Card className={classes.root} onClick={onClick}>
        <CardActionArea>
          {component === 'img' && image
            ? (
              <CardMedia
                component='img'
                alt={title}
                height='150'
                image={image}
                title={title}
              />
            ) : component === 'img' ? (
              <CardContent
                height='150'
                className={classes.noImageIcon}
              >
                <CameraAltOutlinedIcon />
              </CardContent>
            ) : null}
          {component === 'video' && image
            ? (
              <CardContent
                height='150'
                className={classes.noImageIcon}
              >
                <PlayCircleFilledOutlinedIcon />
              </CardContent>
            ) : component === 'video' ? (
              <CardContent
                height='150'
                className={classes.noImageIcon}
              >
                <VideocamOutlinedIcon />
              </CardContent>
            ) : null}
          <CardContent className={classes.cardContent}>
            <Typography component='p'>
              {title}
            </Typography>
            {/* <Typography variant='body2' color='textSecondary' component='p'>
              {disc}
            </Typography> */}
          </CardContent>
        </CardActionArea>
      </Card>
      {/* {component === 'video' && (
      <VideoThumbnail
        videoUrl={image}
        thumbnailHandler={() => {
          // setThumbnail(thum);
        }}
        width={0}
        height={0}
      />
      )} */}
    </div>
  );
}
