import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Link from '@material-ui/core/Link';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  encrypt,
  apiList,
  shortDate,
  verifyLogin,
} from '../../helpers';
import Loading from '../../components/loading/loading';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';

const columns = [
  {
    id: 'LoanNumber', label: 'Loan Number',
  },
  {
    id: 'Task Name', label: 'Task Name',
  },
  {
    id: 'Task Owner', label: 'Task Owner',
  },
  {
    id: 'Customer Name', label: 'Customer Name',
  },
  {
    id: 'Planned Date', label: 'Planned Date',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class table extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 10,
      result: false,
      dataLoaded: false,
      pageCount: 0,
      data: [],
    };
  }

  componentDidMount() {
    this.getCustomer(this.state.page, this.state.rowsPerPage);
  }

  getViewLoanUrl(viewId) {
    const detailId = encrypt(viewId);
    return `/view-loan-details/${detailId}`;
  }

  handleChangePage = (event, newPage) => {
    this.getCustomer(newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getCustomer(1, +event.target.value);
  };

  getCustomer(page, rowsPerPage) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'view' } };

    axios.get(`${apiList.GET_PENDING_LOAN_TASKS}?page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        console.log(response.data.data.paginate);
        this.setState({
          data: [...response.data.data.paginate],
          dataLoaded: true,
          pageCount: Math.ceil(response.data.data.pageCount),
          result: false,
        });
      })
      .catch((error) => {
        console.log(error);
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1500);
      });
  }

  render() {
    const { classes } = this.props;
    const {
      result,
      data,
      dataLoaded,
      page,
      pageCount,
    } = this.state;

    return (
      <div className={classes.mainContainer}>

        <ToastContainer />

        {result === true && !dataLoaded ? <Loading MainProps={this.props.MainProps} /> : null}

        <CustomTable
          showPagination
          tableName='Properties'
          tableHead={columns}
          itemComponent={data.map((element) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >

              <TableCell>
                <Link
                  underline='always'
                  href={this.getViewLoanUrl(element.loanId)}
                >
                  {element.Loan?.loanNumber}
                </Link>
              </TableCell>
              <TableCell>
                {element.LoanTaskConfig?.name}
              </TableCell>
              <TableCell>
                {element.User ? (`${element.User.firstName} ${element.User.lastName}`) : ''}
              </TableCell>
              <TableCell>
                {element.Loan?.custName}
              </TableCell>
              <TableCell>
                {shortDate(element.plannedDate)}
              </TableCell>

            </TableRow>
          ))}
          count={pageCount}
          rowsPerPage={0}
          page={page}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(table);
