import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CustomTable from '../../components/CustomTableWithoutHeader/CustomTableWithoutHeader';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'branch', label: 'Branch Name',
  },
  {
    id: 'IFSCCode', label: 'IFSC Code',
  },
  {
    id: 'city', label: 'City',
  },
  {
    id: 'district',
    label: 'District',
  },
  {
    id: 'zone',
    label: 'Zone',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'country',
    label: 'Contry',
  },
];
const useStyles = makeStyles({
  root: {
    flex: 1,
    borderLeft: '2px solid #eee',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    margin: 40,
  },
  leftDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rightDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginTop: '8px',
  },
  itemSelected: {
    backgroundColor: '#3D4977',
    marginTop: '8px',
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
});

export default function BasicDetails({ data }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.leftDiv}>
          <CustomTable
            tableClass={{ width: '50%' }}
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {element.branchName || ''}
                </TableCell>
                <TableCell>
                  {element.ifscCode || ''}
                </TableCell>
                <TableCell>
                  {element.city || ''}
                </TableCell>
                <TableCell>
                  {element.district || ''}
                </TableCell>
                <TableCell>
                  {element.zone || ''}
                </TableCell>
                <TableCell>
                  {element.state || ''}
                </TableCell>
                <TableCell>
                  {element.country || ''}
                </TableCell>
              </TableRow>
            )) : null}
            count={1}
            rowsPerPage={0}
            page={1}
          />

        </div>
      </div>
    </div>
  );
}
