import axios from 'axios';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import Link from '@material-ui/core/Link';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';

import FormUpdateStatus from './FormUpdateStatus';
import {
  apiList,
  dencrypt,
  variables,
  verifyLogin,
  encrypt,
  shortDate,
} from '../../helpers';
import CustomModal from '../../components/CustomModal/CustomModal';
import Loading from '../../components/loading/loading';
import Navbar from '../../components/navBar/navBar';
import ReportFormatList from '../../components/ReportFormatList/ReportFormatList';

const successTimeOut = variables.SUCCESS_TIMEOUT;
const errorTimeOut = variables.SUCCUSS_TIMEOUT;

class RequestDetails extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      comments: '',
      requestStatus: '',
      sectionOne: true,
      data: {},
      dataLoaded: false,
      formOpen: false,
      reportFormatListModal: false,
      permission: {},
    };
  }

  componentDidMount() {
    this.setState({ result: true });
    const { roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const permission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Disbursal Requests');
      this.setState({ permission: permission || {} });
    }
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Disbursal Requests', feature: 'view' } };
    const parmasString = dencrypt(this.props.match.params.detailId);
    const params = JSON.parse(parmasString);

    axios.get(`${apiList.VEIW_REQUEST_API}/${params.disRequestID}`, config)
      .then((response) => {
        this.setState({
          data: { ...response.data.data },
          dataLoaded: true,
          comments: response.data.data?.comments,
          requestStatus: response.data.data?.requestStatus,
        });

        setTimeout(() => {
          this.setState({ result: false });
        }, successTimeOut);
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, errorTimeOut);
      });
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSubmitData(key) {
    key.preventDefault();
    const { data, comments, requestStatus } = this.state;

    if (!data && !data.id && data.id.length < 1) {
      toast.error('Something wrong occured, please try again');
      return;
    }

    if ((requestStatus !== 'Open' && requestStatus !== 'Close') || !comments || comments.length < 1) {
      toast.error('All fields are mandatory.');
      return;
    }

    if (requestStatus === '') {
      this.setState({ requestStatus: data.requestStatus });
      return;
    } if (comments === '') {
      this.setState({ comments: data.comments });
      return;
    } if (comments === '') {
      toast.error('Comment field is required');
      return;
    }

    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Disbursal Requests', feature: 'edit' } };

    axios.patch(`${apiList.UPDATE_REQUEST_API}/${data.id}`, { comments, requestStatus }, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({ data: { ...response.data.data }, dataLoaded: true, formOpen: false });

        setTimeout(() => {
          this.setState({ result: false });
        }, successTimeOut);
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, errorTimeOut);
      });
  }

  handleSectionOne(key) {
    key.preventDefault();
    const { sectionOne } = this.state;
    this.setState({ sectionOne: !sectionOne });
  }

  handlePDF(id) {
    const dataString = dencrypt(this.props.match.params.detailId);
    const data = JSON.parse(dataString);
    data.docType = 'disbursement';
    data.reportFormatType = id;
    const detailId = encrypt(JSON.stringify(data));
    const redirectUrl = `/document/${detailId}`;
    window.open(redirectUrl, '_blank');
  }

  getViewLoanUrl() {
    const detailId = encrypt(this.state.data.loanId);
    return `/view-loan-details/${detailId}`;
  }

  body = () => (
    <FormUpdateStatus
      handleChange={(id) => this.handleChange(id)}
      comments={this.state.comments}
      setStatus={(event) => {
        this.setState({ requestStatus: event.target.value });
      }}
      status={this.state.requestStatus}
    />
  )

  bodyReportFormat = () => (
    <ReportFormatList
      type={2}
      OnContinue={(id) => {
        this.handlePDF(id);
      }}
    />
  )

  render() {
    const {
      result,
      data,
      dataLoaded,
      sectionOne,
      formOpen,
      permission,
      reportFormatListModal,
    } = this.state;

    verifyLogin();
    return (
      <div className='view-details'>

        {result === true ? <Loading MainProps={this.props} /> : null}

        <ToastContainer />
        <CustomModal
          open={formOpen}
          setOpen={(value) => this.setState({ formOpen: value })}
          newForm={false}
          body={this.body()}
          handleSaveData={(key) => this.handleSubmitData(key)}
          title='Update Customer Request Status'
        />

        <CustomModal
          width='xs'
          open={reportFormatListModal}
          setOpen={(value) => this.setState({ reportFormatListModal: value })}
          newForm={false}
          body={this.bodyReportFormat()}
          showAction={false}
          handleSaveData={(key) => this.handleSubmitData(key)}
          title='Select Report Format'
        />

        <Navbar MainProps={this.props} />

        <div className='contents'>

          <div className='request-details-page'>

            <div>

              <div className='data-headerEdit'>
                <div>
                  {sectionOne
                    ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />
                    : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />}
                  <span>
                    Customer Request Details
                  </span>
                </div>
              </div>

              {sectionOne && dataLoaded && data && data.Loan
                ? (
                  <div>
                    <div className='container'>

                      <div className='login-form1'>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Loan Number :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>
                              <Link
                                underline='always'
                                className='map'
                                href={this.getViewLoanUrl()}
                              >
                                {`${data.Loan.loanNumber || 'N/A'}`}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Customer Name :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.Loan.custName || 'N/A'}`}</p>
                          </div>
                        </div>
                        {/* <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Loan Id :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.Loan.id || 'N/A'}`}</p>
                          </div>
                        </div> */}
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Request Type :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.requestType || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Request Status :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.requestStatus || 'N/A'}`}</p>
                          </div>
                        </div>

                      </div>

                      <div className='login-form1'>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Property Location :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${`${data.Loan.LoanProperty.addressLine1 || ''} ${data.Loan.LoanProperty.addressLine2 || ''} ${data.Loan.LoanProperty.cityTown || ''},  ${data.Loan.LoanProperty.district || ''}` || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Submitted On :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${shortDate(data.createdAt) || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Closed on :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.closedDate ? shortDate(data.closedDate) : 'N/A' || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Comment :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.comments || 'N/A'}`}</p>
                          </div>
                        </div>
                        {/* <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Completion% :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text' />
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Disbursement % :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text' />
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>TAT :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text' />
                          </div>
                        </div> */}

                      </div>

                    </div>

                  </div>

                )
                : null}
            </div>
            <div className='button-viewer'>
              {permission.edit ? (
                <button
                  type='submit'
                  className='verify'
                  onClick={() => {
                    this.setState({ formOpen: true });
                  }}
                >
                  Change Status
                </button>
              ) : null}

              {permission.edit && data.requestStatus === 'Open' ? (
                <button
                  type='submit'
                  className='verify'
                  onClick={() => {
                    this.setState({ reportFormatListModal: true });
                  }}
                >
                  Download DRF
                </button>
              ) : null}

            </div>
          </div>

        </div>
      </div>
    );
  }
}

export default RequestDetails;
