import React, { Component } from 'react';
import axios from 'axios';
import Map from './Googlemaps';
import Navbar from '../../components/navBar/navBar';
import AutoComplete from './AutoComplete';
import {
  apiList,
  verifyLogin,
} from '../../helpers';

class LoanMaps extends Component {
  onItemClick=(suggestion) => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Property Details', feature: 'view' } };

    axios.get(`${apiList.VIEW_PROPERTY_BY_LOAN}?loanId=${suggestion.id}`, config)
      .then((response) => {
        this.map.onItemClick(response.data.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  render() {
    verifyLogin();
    return (
      <div className='loan-root'>

        <Navbar MainProps={this.props} />
        <div className='mapdiv'>
          <Map ref={(el) => { this.map = el; }} />
          <AutoComplete onItemClick={this.onItemClick} />
        </div>

      </div>
    );
  }
}

export default LoanMaps;
