import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const DialogTitle = withStyles(styles)((props) => {
  const {
    children, classes, onClose, ...other
  } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    flex: 'display',
    justifyContent: 'center',
  },
}))(MuiDialogActions);

const useStyles = makeStyles(() => ({
  root: {
    width: 600,
  },
  flexboxDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
}));

const ColorButton = withStyles(() => ({
  root: {
    width: 100,
    alignSelf: 'center',
  },
}))(Button);

export default function CustomModal({
  open, setOpen, newForm, handleSaveData, body, title,
  width = 'sm', showAction = true,
}) {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };
  return (
    <Dialog
      fullWidth
      maxWidth={width}
      aria-labelledby={title}
      open={open}
    >
      <DialogTitle id={title} onClose={handleClose}>
        {title}
      </DialogTitle>
      <DialogContent dividers>
        <div className={classes.flexboxDiv}>
          {body}
        </div>
      </DialogContent>
      {showAction ? (
        <DialogActions>
          <ColorButton autoFocus size='small' variant='contained' onClick={handleSaveData} color='primary'>
            {newForm ? 'Save' : 'Update'}
          </ColorButton>
        </DialogActions>
      ) : null}
    </Dialog>
  );
}
