import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Switch, Route, Redirect } from 'react-router-dom';
import { adminRoutes, superAdminRoutes } from './routes';
import SideDrawer from '../components/SideDrawer/SideDrawer';
import { verifyLogin } from '../helpers';

import Navbar from '../components/Navbars/Navbar';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    backgroundColor: 'white',
  },
  routes: {
    width: '100%',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    padding: theme.spacing(3),
  },
}));

export default function AdminRouter({ ...rest }) {
  const { user } = verifyLogin();

  const routes = user.userRoleId === 1 ? superAdminRoutes : adminRoutes;
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);

  const handleDrawerClose = () => {
    setOpen(!open);
  };
  return (
    <div className={classes.root}>
      <SideDrawer
        handleDrawerClose={handleDrawerClose}
        routes={routes}
        routesCategories={routes}
        logoText='Home Hub'
        image='bgImage'
        open={open}
        color='green'
      />
      <div className={classes.routes}>
        <Navbar routes={routes} open={open} {...rest} />
        <div>
          <Switch>
            {routes.map((prop, key) => {
              if (prop.layout === '/admin') {
                return (
                  <Route
                    path={prop.layout + prop.path}
                    component={prop.component}
                    key={key}
                  />
                );
              }
              return null;
            })}
            <Redirect from='/admin' to='/admin/users' />
          </Switch>
        </div>
      </div>
    </div>
  );
}
