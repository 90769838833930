import React, { Component } from 'react';
import axios from 'axios';

import {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete';
import Divider from '@material-ui/core/Divider';
import ListItemText from '@material-ui/core/ListItemText';
import CustomInput from '../../components/CustomInput/CustomInput';
import LocationListAuto from './LocationListAuto';

import {
  apiList,
  verifyLogin,
} from '../../helpers';

export class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchQuery: '',
      suggestions: [],
    };
  }

  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });

    if (event.target.value.length > 1) {
      this.searchLoans(event.target.value);
    } else {
      this.setState({ suggestions: [] });
    }
  };

  handleSelect = (address) => {
    geocodeByAddress(address)
      .then((results) => getLatLng(results[0]))
      .then((latLng) => console.log('Success', latLng))
      .catch((error) => console.error('Error', error));
  };

  searchLoans(searchQuery) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };

    axios.get(`${apiList.VEIW_CUSTOMERS_API}?page=${1}&limit=${100}&searchQuery=${searchQuery}`, config)
      .then((response) => {
        if (this.state.searchQuery.length > 1) {
          this.setState({ suggestions: [...response.data.data.paginate] });
        } else {
          this.setState({ suggestions: [] });
        }
      })
      .catch((error) => {
        this.setState({ suggestions: [] });
        console.log(error);
      });
  }

  render() {
    return (
      <div className='side-view'>
        <div className='auto-complete-inner'>
          <CustomInput
            onChange={this.handleChange}
            value={this.state.searchQuery}
            id='location'
            label='Search Loans'
          />
          <LocationListAuto
            item={this.state.suggestions.map((suggestion) => (
              <>
                <ListItemText
                  onClick={() => this.props.onItemClick(suggestion)}
                  primary={suggestion.custName}
                  secondary={suggestion.loanNumber}
                />
                <Divider />
              </>
            ))}
          />
        </div>
      </div>
    );
  }
}

export default AutoComplete;
