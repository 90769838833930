/* eslint-disable react/require-default-props */
import React, { useState, useEffect } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import clsx from 'clsx';
import Fade from '@material-ui/core/Fade';

import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ListItemIcon from '@material-ui/core/ListItemIcon';

import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import styles from '../../assets/jss/material-dashboard-react/components/sidebarStyle';

const useStyles = makeStyles(styles);

export default function SideDrawer(props) {
  const classes = useStyles();
  const [path, setPath] = useState('');

  const location = useLocation();

  useEffect(() => {
    setPath(location.pathname);
  }, [location, setPath]);

  const activeRoute = (route) => route === path;
  const {
    color,
    open,
    handleDrawerClose,
    routesCategories,
  } = props;

  const items = (
    <>
      {routesCategories.map((prop, key) => {
        const listItemClasses = classNames({
          [` ${classes[color]}`]: activeRoute(prop.layout + prop.path),
        });
        const whiteFontClasses = classNames({
          [` ${classes.whiteFont}`]: activeRoute(prop.layout + prop.path),
        });
        return (
          <NavLink
            to={prop.layout + prop.path}
            className={classes.item}
            activeClassName='active'
            key={key}
          >
            <ListItem button className={classes.itemLink + listItemClasses}>
              <ListItemIcon
                className={classNames(classes.itemIcon, whiteFontClasses)}
              >
                <prop.icon
                  className={classNames(classes.itemIcon, whiteFontClasses)}
                />
              </ListItemIcon>
              <Fade in={open}>
                <ListItemText
                  primary={prop.name}
                  className={classNames(classes.itemText, whiteFontClasses)}
                  disableTypography
                />
              </Fade>
            </ListItem>
          </NavLink>
        );
      })}
    </>
  );

  return (
    <Drawer
      variant='permanent'
      className={clsx(classes.drawer, {
        [classes.drawerOpen]: open,
        [classes.drawerClose]: !open,
      })}
      classes={{
        paper: clsx({
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        }),
      }}
    >
      <div className={classes.background} />
      <div className={classes.toolbar}>
        <Fade in={open}>
          <ListItem>
            <ListItemText
              primary='Home Hub'
              className={classNames(classes.title)}
              disableTypography
            />
          </ListItem>
        </Fade>

        <IconButton onClick={handleDrawerClose}>
          {open ? (
            <ChevronLeftIcon className={classes.hamIcon} />
          ) : (
            <MenuIcon className={classes.hamIcon} />
          )}
        </IconButton>
      </div>
      <div className={classes.divider} />
      <div className={classes.sidebarWrapper}>{items}</div>
    </Drawer>
  );
}

SideDrawer.propTypes = {
  handleDrawerClose: PropTypes.func,
  open: PropTypes.bool,
};
