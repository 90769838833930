/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import {
  Page, Text, View, Document, StyleSheet,
} from '@react-pdf/renderer';
import axios from 'axios';
import {
  apiList, verifyLogin,
} from '../../helpers';
import {
  CONSENT_INDOSTAR, TERMS_AND_CONDITION,
} from '../../helpers/utils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'coloumn',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    padding: 50,
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  to: {
    width: '100%',
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottom: {
    width: '100%',
    marginTop: 30,
    paddingTop: 30,
    borderTop: '1px solid gray',
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  box: {
    border: '0.3px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    marginTop: 20,
  },
  box2: {
    border: '0.3px solid black',
    borderTop: '0px solid black',
    flexDirection: 'row',
    // alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  dividerleft: {
    borderLeft: '0.3px solid black',
    flex: 0.6,
  },
  textInbox: {
    flex: 0.4,
    fontSize: 10,
    margin: 8,
  },
  textLeftInbox: {
    flex: 0.4,
    fontSize: 10,
    margin: 8,
  },
  consentText: {
    marginTop: 8,
    fontSize: 10,
    lineHeight: 1.5,
  },
  tnsContainer: {
    flexDirection: 'row',
    alignSelf: 'flex-start',
  },
  bullet: {
    fontSize: 12,
    paddingLeft: 30,
  },
  tnsTitle: {
    marginTop: 20,
    marginLeft: 20,
    marginBottom: 10,
    fontSize: 11,
    alignSelf: 'flex-start',
  },
  tns: {
    fontSize: 10,
    lineHeight: 1.5,
    flexWrap: 'wrap',
    marginLeft: 20,
    marginRight: 40,
  },
});

// Create Document Component
export default function DisbursementForm({
  date, place, constructionCompletion,
  loanAccount, customerName, sanctionedLoanAmount, loanAmountDisbursed,
  disRequestID, disRequestDate, companyName,
  // reportFormatType,
}) {
  const [branchName, setbranchName] = useState('');
  const [called, setCalled] = useState(false);
  useEffect(() => {
    if (!called) {
      const { session } = verifyLogin();
      setCalled(true);
      const config = { headers: { session } };
      axios.get(`${apiList.GET_ACCESS_LEVEL_BY_IFSC}?ifscCode=${place}`, config)
        .then((response) => {
          if (response.data.data.branchName) {
            setbranchName(response.data.data.branchName);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  return (
    <Document>
      <Page size='A4' style={styles.page}>
        <Text style={styles.title}>Disbursement Request Form</Text>

        <View style={styles.to}>
          <Text style={styles.text}>{`To,\nThe Credit Manager,\n${companyName}`}</Text>
          <View>
            <Text style={styles.text}>
              {`Date : ${date}`}
            </Text>
            <Text style={styles.text}>
              {`Place : ${branchName}`}
            </Text>
          </View>

        </View>
        <View style={styles.to}>
          <Text style={styles.text}>
            {`\nSubject: Request for subsequent stage disbursement towards the Loan account No. ${loanAccount}\n\nDear Sir,\nThe construction of my property has been completed up to ${constructionCompletion ? constructionCompletion?.toFixed(2) : 0}%. Hence, I request you to release next tranche disbursal payment to the bank account.\n\nThe detail of the bank account to be as per the first tranche payment method.\n\nMy Loan account details are as follows:-`}
          </Text>
        </View>

        <View style={styles.box}>
          <Text style={styles.textLeftInbox}>Loan Account Number:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{loanAccount}</Text>
          </View>
        </View>
        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Branch Name:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{branchName}</Text>
          </View>
        </View>
        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Customer Name:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{customerName}</Text>
          </View>
        </View>
        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Sanctioned Loan Amount:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{sanctionedLoanAmount}</Text>
          </View>
        </View>
        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Loan Amount Disbursed:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{loanAmountDisbursed}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Disbursement Request ID:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{disRequestID}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textLeftInbox}>Disbursement Request Submitted Date:</Text>
          <View style={styles.dividerleft}>

            <Text style={styles.textInbox}>{disRequestDate}</Text>
          </View>
        </View>

        <Text style={styles.consentText}>
          {CONSENT_INDOSTAR}
        </Text>

        <Text style={styles.tnsTitle}>Terms and Conditions:</Text>
        {TERMS_AND_CONDITION.map((item) => (
          <View style={styles.tnsContainer}>
            <Text style={styles.bullet}>•</Text>
            <Text style={styles.tns}>{item}</Text>
          </View>
        ))}

        {/* <View style={styles.bottom}>
          <Text style={styles.subTitle}>
            Current disbursement summary based on the progress of construction
          </Text>
          <Text style={styles.text}>
            {'\nAs per the current stage progress
            report, the construction has been
            completed and verified up to _______ .
            Hence, I am recommending disbursal of loan amount of Rs. ___________.\n\n'}
          </Text>
          <Text style={styles.subTitle}>
            Recommended by
          </Text>
          <View style={styles.box}>
            <Text style={styles.textInbox}>Name: </Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox} />
            </View>
          </View>
          <View style={styles.box2}>
            <Text style={styles.textInbox}>EMP No.: </Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox} />
            </View>
          </View>
          <View style={styles.box2}>
            <Text style={styles.textInbox}>Date:</Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox} />
            </View>
          </View>
        </View> */}

      </Page>
    </Document>
  );
}
