import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';

class ComponentConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: {},
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
      componentName: '',
      componentID: '',
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId } = user;
    verifyUser(Number(userRoleId));
    this.getComponentConfigs();
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        componentName: element.componentName,
        componentID: element.id,
      });
    } else {
      this.setState({
        componentName: '',
        componentID: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      componentID, componentName,
    } = this.state;
    if (componentName.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      componentName,
    };
    if (componentID) {
      params.id = componentID;
    }
    axios.post(`${apiList.UPDATE_COMPONENT_CONFIG_API}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          componentName: '',
          componentID: '',
        });
        this.toggleModal(false);
        this.getComponentConfigs();
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  handleDeleteData(key, id) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      this.setState({ result: true });
      axios.post(`${apiList.DELETE_COMPONENT_CONFIG_API}`, {
        id,
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getComponentConfigs();
          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    }
  }

  getComponentConfigs() {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.VIEW_USERS_COMPONENT_CONFIG_API}`, config)
      .then((response) => {
        this.setState({ data: { ...response.data.data } });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body=() => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      componentName={this.state.componentName}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      data,
      page,
      rowsPerPage,
      formOpen,
      newForm,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result ? <Loading MainProps={this.props} /> : null }

        {!result && (
        <Table
          data={Array.isArray(data.paginate) ? data.paginate : []}
          handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
          handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
          handleShowAddForm={(key) => this.handleShowAddForm(key)}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        )}
        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={newForm ? 'Create New Component Config' : 'Update Component Config'}
        />
      </div>
    );
  }
}

export default ComponentConfig;
