import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Link from '@material-ui/core/Link';
import {
  shortDecimal, summation, encrypt,
} from '../../helpers';
import CustomTable from '../../components/CustomTableWithoutHeader/CustomTableWithoutHeader';
import { groupDataByactivityTypeAndName } from '../../helpers/utils';

const columns = [

  {
    id: 'ActivityTypeName', label: 'Activity Type Name',
  },
  {
    id: 'ActivityName',
    label: 'Activity Name',
  },
  {
    id: 'Weightage',
    label: 'Weightage%',
  },
  {
    id: 'NoImages',
    label: 'No Of Images',
  },
  {
    id: 'NoVideos',
    label: 'No Of Videos',
  },
  {
    id: 'Status',
    label: 'Status',
  },
  {
    id: 'Verified',
    label: 'Verified%',
  },
];

const useStyles = () => ({
  root: {
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 40,
    display: 'flex',
    justifyContent: 'center',
  },
  rootNoPadding: {
    padding: 0,
  },
  header: {
    padding: 10,
    paddingLeft: 20,
  },
  headerRadiusRemove: {
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  statusSubmit: {
    backgroundColor: '#BFBFBF',
  },
  statusInProgress: {
    backgroundColor: '#C8E6C9',
  },
  statusYetToStart: {
    backgroundColor: '#F2F2F2',
  },
  statusVerified: {
    backgroundColor: '#4CAF50',
  },
  statusDefault: {
    backgroundColor: '#fff',
  },
});

class ConstructionPlanTable extends Component {
  getViewDetailsUrl(data, constructionPlanVersion) {
    const detailId = encrypt(JSON.stringify({
      index: data,
      propertyId: this.props.propertyId,
      isCurrentPlan: this.props.isCurrentPlan,
      constructionPlanVersion,
    }));
    return `/view-construction-details/${detailId}`;
  }

  getStatusBackgroundColorClass(status) {
    const { classes } = this.props;
    switch (status) {
      case 'Submitted':
        return classes.statusSubmit;
      case 'In Progress':
        return classes.statusInProgress;
      case 'Verified':
        return classes.statusVerified;
      case 'Yet to start':
        return classes.statusYetToStart;
      default:
        return classes.statusDefault;
    }
  }

  render() {
    const { classes } = this.props;
    const {
      data, rootNoPadding,
    } = this.props;
    let filterData = groupDataByactivityTypeAndName(data);
    const totalAmount = summation(filterData, 'weightage');
    const verifiedData = data.filter(({ status }) => status === 'Verified');
    const percentage = verifiedData.length > 0 ? summation(verifiedData, 'weightage') : '0';
    filterData = [...filterData, {
      activityTypeConfigName: 'Total',
      activityConfigName: '',
      weightage: totalAmount,
      noOfImages: '',
      noOfVideos: '',
      status: '',
      percentage,
    }];
    return (
      <div className={[classes.root, rootNoPadding && classes.rootNoPadding]}>
        <CustomTable
          // removeMargin
          pages={false}
          tableHead={columns}
          itemComponent={filterData.length > 1 ? filterData.map((element, index) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >

              <TableCell>
                {element.activityTypeConfigName}
              </TableCell>
              <TableCell
                onClick={() => (!this.props.isCurrentPlan
                  ? window.open(this.getViewDetailsUrl(index, element.version), '_blank') : null)}
              >
                <Link
                  underline='always'
                  href={this.props.isCurrentPlan
                    ? this.getViewDetailsUrl(index, element.version) : null}
                >
                  {element.activityConfigName}
                </Link>
              </TableCell>
              <TableCell>
                {shortDecimal(element.weightage.toString())}
                %
              </TableCell>
              <TableCell>
                {element.noOfImages}
              </TableCell>
              <TableCell>
                {element.noOfVideos}
              </TableCell>
              <TableCell
                className={this.getStatusBackgroundColorClass(element.status)}
              >
                {element.status}
              </TableCell>
              <TableCell>
                {element.activityTypeConfigName === 'Total' ? `${shortDecimal(element.percentage.toString())}%` : `${shortDecimal(summation(element.floors, 'completed').toString())}%`}
              </TableCell>
            </TableRow>
          )) : null}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ConstructionPlanTable);
