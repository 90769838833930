import React, { Component } from 'react';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import Table from './table';
import SubmittedConstructionPlanTable from './SubmittedConstructionPlanTable';
import Navbar from '../../components/navBar/navBar';
import { verifyLogin } from '../../helpers';

class ConstructionPlan extends Component {
  constructor() {
    super();
    this.state = {
      sectionOne: true,
      sectionTwo: true,
      permission: {},
    };
  }

  componentDidMount() {
    const { roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const permission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Construction Plan');
      if (permission) {
        this.setState({ permission: permission || {} });
      }
    }
  }

  handleSectionOne(key) {
    key.preventDefault();
    const { sectionOne } = this.state;

    if (sectionOne === false) {
      this.setState({ sectionOne: true });
    } if (sectionOne === true) {
      this.setState({ sectionOne: false });
    }
  }

  handleSectionTwo(key) {
    key.preventDefault();
    const { sectionTwo } = this.state;

    if (sectionTwo === false) {
      this.setState({ sectionTwo: true });
    } if (sectionTwo === true) {
      this.setState({ sectionTwo: false });
    }
  }

  render() {
    const { sectionOne, sectionTwo, permission } = this.state;
    verifyLogin();
    return (
      <div className='view-details'>

        <Navbar MainProps={this.props} />

        <div className='contents'>

          <div className='loan-details'>

            <div className='data-header'>
              {sectionTwo
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionTwo(key); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionTwo(key); }} />}
              <span>
                Construction Progress - Submitted
              </span>
            </div>

            { sectionTwo && permission.view && (
            <SubmittedConstructionPlanTable
              MainProps={this.props}
              MainState={this.state}
            />
            )}

            <div className='data-header'>
              {sectionOne
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />}
              <span>
                Properties without Construction Plan
              </span>
            </div>

            { sectionOne && permission.view
            && <Table MainProps={this.props} MainState={this.state} />}

          </div>
        </div>

      </div>
    );
  }
}

export default ConstructionPlan;
