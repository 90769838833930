import React, { useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import axios from 'axios';

import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import {
  shortData, apiList,
  verifyLogin,
} from '../../helpers';
import CustomTable from '../../components/CustomTableWithoutHeader/CustomTableWithoutHeader';

const columns = [
  {
    id: 'name', label: 'Name',
  },
  {
    id: 'view', label: 'View',
  },
  {
    id: 'add', label: 'Add',
  },
  {
    id: 'edit', label: 'Edit',
  },
  {
    id: 'delete', label: 'Delete',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
  checkBoxCell: {
  },
  checkBox: {
    padding: 0,
    margin: 0,
  },
});

export default function OnBoardingDialog({ roleID, setPermissions, isExternalUser }) {
  const classes = useStyles();
  const [permissionData, setPermissionData] = useState([]);
  const handleChange = (index, type, value) => {
    const newPermissionData = [...permissionData];
    let pObj = newPermissionData[index];
    const typMap = {
      [type]: value,
    };
    pObj = { ...pObj, ...typMap };
    newPermissionData[index] = pObj;
    setPermissionData(newPermissionData);
    setPermissions(newPermissionData);
  };

  const getPermissionData = (data) => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_PERMISSION_CONFIG_BY_ROLEID}?id=${roleID}`, config)
      .then((response) => {
        const modifyData = data.map((item) => {
          const updatedPermissionData = response.data.data.paginate.find(
            ({ permissionConfigId }) => permissionConfigId === item.id,
          );
          return updatedPermissionData || item;
        });
        setPermissionData(modifyData);
        setPermissions(modifyData);
      })
      .catch(() => {
        // getUserRoles();
      });
  };

  const getUserRoles = () => {
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_PERMISSION_CONFIG}`, config)
      .then((response) => {
        const data = [...permissionData, ...response.data.data.paginate];
        const newPermissionData = data.map((v) => ({
          ...v, edit: false, view: false, add: false, delete: false,
        }));
        setPermissionData(newPermissionData);
        setPermissions(newPermissionData);
        if (roleID) {
          getPermissionData(newPermissionData);
        }
      })
      .catch(() => {
      });
  };

  useState(() => {
    getUserRoles();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <CustomTable
        tableClass={{ width: '50%' }}
        tableHead={columns}
        itemComponent={permissionData.length > 0
          ? permissionData.map((element, index) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >
              <TableCell>
                {shortData(element.name || element.PermissionConfig?.name)}
              </TableCell>
              <TableCell className={classes.checkBoxCell}>
                <Checkbox
                  disabled={isExternalUser}
                  className={classes.checkBox}
                  checked={element.view}
                  onChange={(event) => handleChange(index, 'view', event.target.checked)}
                  inputProps={{ 'aria-label': 'View' }}
                />
              </TableCell>
              <TableCell className={classes.checkBoxCell}>
                <Checkbox
                  disabled={isExternalUser}
                  className={classes.checkBox}
                  checked={element.add}
                  onChange={(event) => handleChange(index, 'add', event.target.checked)}
                  inputProps={{ 'aria-label': 'Add' }}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={isExternalUser}
                  className={classes.checkBox}
                  checked={element.edit}
                  onChange={(event) => handleChange(index, 'edit', event.target.checked)}
                  inputProps={{ 'aria-label': 'Edit' }}
                />
              </TableCell>
              <TableCell>
                <Checkbox
                  disabled={isExternalUser}
                  className={classes.checkBox}
                  checked={element.delete}
                  onChange={(event) => handleChange(index, 'delete', event.target.checked)}
                  inputProps={{ 'aria-label': 'Delete' }}
                />

              </TableCell>
            </TableRow>
          )) : null}
        count={1}
        rowsPerPage={0}
        page={1}
      />
    </div>
  );
}
