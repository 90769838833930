import React, { Component } from 'react';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import { toast } from 'react-toastify';
import AddCircleRoundedIcon from '@material-ui/icons/AddCircleRounded';
import {
  shortData,
  apiList,
  verifyLogin,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';

import 'react-confirm-alert/src/react-confirm-alert.css';

import Form from './ComponentActivitiesConfigForm';
import CustomModal from '../../components/CustomModal/CustomModal';

const columns = [
  {
    id: 'SubscriberName', label: 'Subscriber Name',
  },
  {
    id: 'ComponentName', label: 'Component Name',
  },
  {
    id: 'ActivityName', label: 'Activity Name',
  },
  {
    id: 'Applicable', label: 'Applicable',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = (theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    overflow: 'auto',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
  floatingButton: {
    height: 36,
    width: 36,
    cursor: 'pointer',
    marginRight: theme.spacing(3),
    marginTop: theme.spacing(2),
  },
  btn: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
});

class ComponentActivitiesConfigTable extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      activityConfig: [],
      componentConfig: [],
      activityId: '',
      subscriberId: '',
      componentId: '',
      isApplicable: 'TRUE',
      componentActivityID: '',
      formOpen: false,
      newForm: true,
    };
  }

  componentDidMount() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    this.handleFetchActivityConfig(config);
    this.handleFetchComponentConfig(config);
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        activityId: element.activityId,
        subscriberId: element.subscriberId,
        componentId: element.componentId,
        isApplicable: element.isApplicable,
        componentActivityID: element.id,
        newForm: false,
      });
    } else {
      this.setState({
        activityId: '',
        componentId: '',
        isApplicable: 'TRUE',
        componentActivityID: '',
        newForm: true,
      });
    }
  }

  handleFetchComponentConfig(config) {
    axios.get(`${apiList.VIEW_USERS_COMPONENT_CONFIG_API}`, config)
      .then((response) => {
        this.setState({
          componentConfig:
           [...response.data.data.paginate],
        });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  handleFetchActivityConfig(config) {
    axios.get(`${apiList.VIEW_USERS_ACTIVITY_CONFIG_API}?subscriberId=${this.props.subscriberId}`, config)
      .then((response) => {
        this.setState({
          activityConfig:
          [...response.data.data.paginate],
        });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  handleEditData(key) {
    key.preventDefault();
    const {
      subscriberId, componentId, activityId, isApplicable, componentActivityID, planID,
    } = this.state;

    if (subscriberId === '') {
      toast.error('Subscriber name is required');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.patch(`${apiList.UPDATE_USERS_COMPONENT_ACTIVITY_CONFIG_API}/${componentActivityID}`, {
      subscriberId, componentId, activityId, isApplicable, planID,
    }, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate] });
        this.setState({
          activityId: '',
          componentId: '',
          isApplicable: 'TRUE',
          componentActivityID: '',
          newForm: true,
        });
        this.toggleModal(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ data: [] });
      });
  }

  handleDeleteData(key, dataId) {
    key.preventDefault();
    const { subscriberId, planID } = this.state;

    if (subscriberId === '') {
      toast.error('Subscriber name is required');
      return;
    }
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      const { session } = verifyLogin();
      const config = { headers: { session } };
      axios.post(`${apiList.DELETE_USERS_COMPONENT_ACTIVITY_CONFIG_API}/${dataId}`, { subscriberId, planID }, config)
        .then((response) => {
          this.setState({ data: [...response.data.data.paginate] });
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  }

  handleSaveAdd(key) {
    key.preventDefault();
    const {
      subscriberId, componentId, activityId, isApplicable, planID,
    } = this.state;

    if (subscriberId.length < 1
      || componentId.length < 1 || activityId.length < 1) {
      toast.error('Please fill the form fieds first');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.post(`${apiList.SAVE_USERS_COMPONENT_ACTIVITY_CONFIG_API}`, {
      planID, subscriberId, componentName: componentId, activityName: activityId, isApplicable,
    }, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate] });
        this.setState({
          activityId: '',
          componentId: '',
          isApplicable: 'TRUE',
          componentActivityID: '',
          newForm: true,
        });
        this.toggleModal(false);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  getComponentActivitiesConfigData(planID, subscriberId) {
    this.setState({ planID, subscriberId });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.post(`${apiList.VIEW_USERS_COMPONENT_ACTIVITIES_CONFIG}`, { planID }, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate] });
      })
      .catch(() => {
        this.setState({ data: [] });
      });
  }

  body=() => (
    <Form
      newForm={this.state.newForm}
      handleChange={(id) => this.handleChange(id)}
      componentId={this.state.componentId}
      activityId={this.state.activityId}
      isApplicable={this.state.isApplicable}
      activityTypeList={this.state.activityTypeList}
      setComponentId={(event) => {
        this.setState({ componentId: event.target.value });
      }}
      setActivityId={(event) => {
        this.setState({ activityId: event.target.value });
      }}
      setApplicable={(event) => {
        this.setState({ isApplicable: event.target.checked ? 'TRUE' : 'FALSE' });
      }}
      componentConfig={this.state.componentConfig}
      activityConfig={this.state.activityConfig}
    />
  )

  checkDataAvailable() {
    this.props.isDataAvail(this.state.data.length > 0);
  }

  clearData() {
    this.setState({ data: [] });
  }

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      classes,
    } = this.props;
    const { data, formOpen, newForm } = this.state;

    return (
      <div className={classes.mainContainer}>
        {this.props.canEdit ? (
          <div className={classes.btn}>
            <AddCircleRoundedIcon
              color='primary'
              className={classes.floatingButton}
              onClick={(key) => {
                this.handleShowAddForm(key);
              }}
            />
          </div>
        ) : null}
        <CustomTable
          innerPaperMarginZero
          tableHead={columns}
          itemComponent={data.length > 0 ? data.map((element) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >
              <TableCell>
                {shortData(element.Subscribers.subscriberName || '')}
              </TableCell>
              <TableCell>
                {shortData(element.ComponentConfig.componentName || '')}
              </TableCell>

              <TableCell>
                {shortData(element.ActivityConfig.activityName || '')}
              </TableCell>

              <TableCell>
                {element.isApplicable}
              </TableCell>
              <TableCell>

                <Button
                  color='primary'
                  justIcon
                  simple
                  round
                  disabled={!this.props.canEdit}
                  onClick={(key) => { this.handleShowAddForm(key, element); }}
                  className={classes.buttonLink}
                >
                  <EditIcon className={classes.icons} />
                </Button>

                <Button
                  color='danger'
                  justIcon
                  simple
                  round
                  disabled={!this.props.canEdit}
                  onClick={(key) => { this.handleDeleteData(key, element.id); }}
                  className={classes.buttonLink}
                >
                  <DeleteIcon className={classes.icons} />
                </Button>

              </TableCell>
            </TableRow>
          )) : null}
          count={1}
          rowsPerPage={0}
          page={1}
        />

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={(key) => (newForm ? this.handleSaveAdd(key) : this.handleEditData(key))}
          title={newForm ? 'Create New Config' : 'Update Config'}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ComponentActivitiesConfigTable);
