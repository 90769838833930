import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {
  shortData,
} from '../../helpers';
import CustomTable from '../../components/CustomTable/CustomTable';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'name', label: 'Name',
  },
  {
    id: 'mobile', label: 'Mobile',
  },
  {
    id: 'email',
    label: 'Email',
  },
  {
    id: 'product',
    label: 'Product',
  },
  {
    id: 'role',
    label: 'Role',
  },
  {
    id: 'subscriberName',
    label: 'Subscriber Name',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
    display: 'flex',
    overflowX: 'auto',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class table extends Component {
  render() {
    const {
      classes, handleShowEditForm, handleDeleteData, data,
      handleChangePage, handleChangeRowsPerPage, handleShowAddForm, tableName,
    } = this.props;

    return (
      <>
        <div className={classes.mainContainer}>

          <CustomTable
            icons={[{ icon: AddCircleOutlineRoundedIcon, name: 'Add User' }]}
            tableName={tableName}
            onIconClick={(key) => {
              handleShowAddForm(key);
            }}
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {shortData(`${element.firstName} ${element.lastName}` || '')}
                </TableCell>
                <TableCell>
                  {shortData(element.alternateNumber || '' || '')}
                </TableCell>
                <TableCell>
                  {shortData(element.email || '')}
                </TableCell>
                <TableCell>
                  {shortData(element?.UserProduct?.productName || '')}
                </TableCell>
                <TableCell>
                  {shortData(element?.UserRole?.role || '')}
                </TableCell>
                <TableCell>
                  {shortData(element?.Subscribers?.subscriberName || '')}
                </TableCell>
                <TableCell>

                  <Button
                    color='primary'
                    justIcon
                    simple
                    round
                    onClick={(key) => { handleShowEditForm(key, element); }}
                    className={classes.buttonLink}
                  >
                    <EditIcon className={classes.icons} />
                  </Button>

                  <Button
                    color='danger'
                    justIcon
                    simple
                    round
                    onClick={(key) => { handleDeleteData(key, element.id); }}
                    className={classes.buttonLink}
                  >
                    <DeleteIcon className={classes.icons} />
                  </Button>

                </TableCell>
              </TableRow>
            )) : null}
            count={1}
            rowsPerPage={0}
            page={1}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(table);
