import axios from 'axios';
import React, { Component } from 'react';

import { ToastContainer, toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import {
  apiList, verifyLogin, dencrypt, encrypt,
} from '../../helpers';
import CustomCardMediaMultiSelect from '../../components/CustomCardMediaMultiSelect/CustomCardMediaMultiSelect';
import SelectMediaToolBar from '../../components/SelectMediaToolBar/SelectMediaToolBar';
import { groupConstructionMediaByActivityName } from '../../helpers/utils';

const useStyles = () => ({
  root: {
    position: 'relative',
    width: '100%',
    overflow: 'hidden',
  },
  scrollView: {
    position: 'relative',
    overflowY: 'scroll',
    overflowX: 'hidden',
    height: 'calc(100vh - 10px)',
    width: '100%',
  },
  titles: {
    width: '100%',
    marginTop: 50,
    backgroundColor: '#ccc',
  },
  main: {
    display: 'flex',
    position: 'relative',
    width: '100%',
  },
  leftTitle: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 20,
    width: 160,
    border: '0.5px solid #000',
  },
  title: {
    fontSize: 16,
    fontWeight: 'bold',
    padding: 20,
    border: '0.5px solid #000',
    width: 160,
  },
  inner: {
    display: 'flex',
    width: '100%',
  },
  sub: {
    border: '0.5px solid #000',
    display: 'flex',
    position: 'relative',
    width: '100%',
    flexWrap: 'wrap',
  },
  photoTypography: {
    border: '0.5px solid #000',
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    flexWrap: 'wrap',
    fontSize: 16,
    fontWeight: 'bold',
  },
  container: {
    width: '100%',
  },
  nodata: {
    textAlign: 'center',
    margin: 50,
  },
});

class GenerateConstructionProgress extends Component {
  constructor() {
    super();
    this.state = {
      photos: [],
      selectedData: [],
      showAlert: false,
    };
  }

  componentDidMount() {
    this.getData();
  }

  getData() {
    const detailId = dencrypt(this.props.match.params.detailId);
    const detailIdP = JSON.parse(detailId);
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_PROPERTY_MEDIA}?id=${detailIdP.propertyId}`, config)
      .then((response) => {
        if (response && response.data && response.data.data) {
          const media = groupConstructionMediaByActivityName(response.data.data);
          this.setState({ photos: media });
        }
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);
      });
  }

  renderSubCar(canShow) {
    if (canShow) {
      return null;
    }
    return null;
  }

  render() {
    const { classes } = this.props;
    const {
      selectedData, photos, showAlert,
    } = this.state;
    return (
      <div className={classes.root}>
        <ToastContainer />
        <SelectMediaToolBar
          imageCount={selectedData.length}
          showAlert={showAlert && selectedData.length < 1}
          onClose={() => this.setState({ showAlert: false })}
          onClick={() => {
            if (selectedData.length > 0) {
              const detailId = dencrypt(this.props.match.params.detailId);
              const detailIdP = JSON.parse(detailId);
              detailIdP.docType = 'loanProgress';
              detailIdP.photos = selectedData;
              const redirectUrl = `/document/${encrypt(JSON.stringify(detailIdP))}`;
              this.setState({ showAlert: false });
              setTimeout(() => {
                window.location.href = redirectUrl;
              }, 1000);
            } else {
              this.setState({ showAlert: true });
            }
          }}
        />
        <div className={classes.titles}>
          <div className={classes.main}>
            <div className={classes.title}>Activity Name</div>
            <div className={classes.title}>Floor Name</div>
            <div className={classes.title}>Component</div>
            <div className={classes.container}>
              <div className={classes.inner}>
                <div className={classes.leftTitle}>Uploaded By</div>
                <div className={classes.photoTypography}>
                  Photos
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.scrollView}>
          {photos.length > 0 ? photos.map((element) => {
            if (element.self || element.cont || element.pm || element.val) {
              return (
                <div className={classes.main}>
                  <div className={classes.title}>{element.activityConfigName}</div>
                  <div className={classes.title}>{element.propertyComponentFloorName}</div>
                  <div className={classes.title}>{element.propertyComponentName}</div>
                  <div className={classes.container}>
                    {element?.self.length > 0 ? (
                      <div className={classes.inner}>
                        <div className={classes.leftTitle}>Customer</div>
                        <div className={classes.sub}>
                          {element.self.map((subElement) => (
                            <div>
                              {subElement.url ? (
                                <CustomCardMediaMultiSelect
                                  key={subElement.id}
                                  component='img'
                                  isSelected={!!selectedData.find(
                                    (item) => item.id === subElement.id,
                                  )}
                                  image={subElement.url}
                                  onClick={() => {
                                    let data = [...selectedData];
                                    const selected = selectedData.find(
                                      (item) => item.id === subElement.id,
                                    );
                                    if (data.length < 6 || selected) {
                                      if (selected) {
                                        data = data.filter((item) => item.id !== subElement.id);
                                      } else {
                                        data = [...data, {
                                          id: subElement.id,
                                          url: subElement.url,
                                          date: subElement.submittedDate,
                                          lat: subElement.lat,
                                          lng: subElement.lng,
                                          by: subElement.submittedUserType,
                                        }];
                                      }
                                      this.setState({ selectedData: data });
                                    }
                                  }}
                                />

                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    ) : null}

                    {element?.cont.length > 0 ? (
                      <div className={classes.inner}>
                        <div className={classes.leftTitle}>Contractor</div>
                        <div className={classes.sub}>
                          {element.cont.map((subElement) => (
                            <div>
                              {subElement.url ? (
                                <CustomCardMediaMultiSelect
                                  key={subElement.id}
                                  component='img'
                                  isSelected={!!selectedData.find(
                                    (item) => item.id === subElement.id,
                                  )}
                                  image={subElement.url}
                                  onClick={() => {
                                    let data = [...selectedData];
                                    const selected = selectedData.find(
                                      (item) => item.id === subElement.id,
                                    );
                                    if (data.length < 6 || selected) {
                                      if (selected) {
                                        data = data.filter((item) => item.id !== subElement.id);
                                      } else {
                                        data = [...data, {
                                          id: subElement.id,
                                          url: subElement.url,
                                          date: subElement.submittedDate,
                                          lat: subElement.lat,
                                          lng: subElement.lng,
                                          by: subElement.submittedUserType,
                                        }];
                                      }
                                      this.setState({ selectedData: data });
                                    }
                                  }}
                                />

                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                      : null}
                    {element?.pm.length > 0 ? (
                      <div className={classes.inner}>
                        <div className={classes.leftTitle}>Relationship Manager</div>
                        <div className={classes.sub}>
                          {element.pm.map((subElement) => (
                            <div>
                              {subElement.url ? (
                                <CustomCardMediaMultiSelect
                                  key={subElement.id}
                                  component='img'
                                  isSelected={!!selectedData.find(
                                    (item) => item.id === subElement.id,
                                  )}
                                  image={subElement.url}
                                  onClick={() => {
                                    let data = [...selectedData];
                                    const selected = selectedData.find(
                                      (item) => item.id === subElement.id,
                                    );
                                    if (data.length < 6 || selected) {
                                      if (selected) {
                                        data = data.filter((item) => item.id !== subElement.id);
                                      } else {
                                        data = [...data, {
                                          id: subElement.id,
                                          url: subElement.url,
                                          date: subElement.submittedDate,
                                          lat: subElement.lat,
                                          lng: subElement.lng,
                                          by: subElement.submittedUserType,
                                        }];
                                      }
                                      this.setState({ selectedData: data });
                                    }
                                  }}
                                />

                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                      : null}
                    {element?.val.length > 0 ? (
                      <div className={classes.inner}>
                        <div className={classes.leftTitle}>Valuer</div>
                        <div className={classes.sub}>
                          {element.val.map((subElement) => (
                            <div>
                              {subElement.url ? (
                                <CustomCardMediaMultiSelect
                                  key={subElement.id}
                                  component='img'
                                  isSelected={!!selectedData.find(
                                    (item) => item.id === subElement.id,
                                  )}
                                  image={subElement.url}
                                  onClick={() => {
                                    let data = [...selectedData];
                                    const selected = selectedData.find(
                                      (item) => item.id === subElement.id,
                                    );
                                    if (data.length < 6 || selected) {
                                      if (selected) {
                                        data = data.filter((item) => item.id !== subElement.id);
                                      } else {
                                        data = [...data, {
                                          id: subElement.id,
                                          url: subElement.url,
                                          date: subElement.submittedDate,
                                          lat: subElement.lat,
                                          lng: subElement.lng,
                                          by: subElement.submittedUserType,
                                        }];
                                      }
                                      this.setState({ selectedData: data });
                                    }
                                  }}
                                />

                              ) : null}
                            </div>
                          ))}
                        </div>
                      </div>
                    )
                      : null}
                  </div>
                </div>
              );
            } return null;
          })
            : (
              <div className={classes.nodata}>
                No Photo found!
              </div>
            )}
        </div>
      </div>

    );
  }
}

export default withStyles(useStyles)(GenerateConstructionProgress);
