import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Alert from '@material-ui/lab/Alert';
import Button from '../CustomButtons/Button';

const useStyles = makeStyles({
  root: {
    width: '100%',
    height: 50,
    display: 'flex',
    backgroundColor: '#ccc',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'absolute',
  },
  title: {
    paddingLeft: 20,
  },
  buttonLink: {
    textTransform: 'none',
    marginRight: 20,
  },
});

export default function SelectMediaToolBar({
  imageCount,
  onClick,
  showAlert,
  onClose,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>{`Media Selected - ${imageCount} (Max-6)`}</div>
      {showAlert ? <Alert onClose={onClose} severity='warning'>You have not selected any images!</Alert> : null}
      <Button
        color='primary'
        onClick={onClick}
        className={classes.buttonLink}
      >
        Click Here after selecting images
      </Button>
    </div>
  );
}
