/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import '../../assets/style/react-datepicker.css';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    minWidth: 300,
    marginBottom: 16,
    marginTop: 16,
  },
  datePicker: {
    border: '1px solid #ccc',
    borderRadius: 5,
    height: 40,
    minWidth: 294,
    marginBottom: 16,
    marginTop: 16,
  },
});

class FormLoanDisbursement extends Component {
  render() {
    const {
      classes, disbursementAmount, disbursementPerc,
      handleChange, disbursementDate, setDisbursementDate, totalPercentage = 0,
    } = this.props;
    return (
      <div className={classes.root}>
        <CustomInput
          id='disbursementAmount'
          label='Disbursement Amount'
          type='number'
          InputLabelProps={{
            shrink: true,
          }}
          value={disbursementAmount}
          onChange={(id) => {
            handleChange(id);
          }}
        />
        <CustomInput
          id='disbursementPerc'
          label='Disbursement Stage %'
          type='number'
          InputLabelProps={{
            shrink: true,
          }}
          value={disbursementPerc}
          onChange={(id) => {
            if ((Number(totalPercentage) + Number(id.target.value)) <= 100) {
              handleChange(id);
            }
          }}
        />
        <CustomDatePicker
          id='disbursementDate'
          label='Disbursement Date'
          onChange={(date) => {
            setDisbursementDate(date?._isValid ? moment(date).format('DD-MM-YYYY HH:mm:ss') : null);
          }}
          value={disbursementDate}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(FormLoanDisbursement);
