import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  render() {
    const {
      classes, handleChange, ifscCode, city, district, zone, state, country, branchName,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput id='ifscCode' label='IFSC Code' value={ifscCode} onChange={(id) => handleChange(id)} />
        <CustomInput id='branchName' label='Branch Name' value={branchName} onChange={(id) => handleChange(id)} />
        <CustomInput id='city' label='City' value={city} onChange={(id) => handleChange(id)} />
        <CustomInput id='district' label='District' value={district} onChange={(id) => handleChange(id)} />
        <CustomInput id='zone' label='Zone' value={zone} onChange={(id) => handleChange(id)} />
        <CustomInput id='state' label='State' value={state} onChange={(id) => handleChange(id)} />
        <CustomInput id='country' label='Country' value={country} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
