import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardMedia from '@material-ui/core/CardMedia';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import PlayCircleFilledOutlinedIcon from '@material-ui/icons/PlayCircleFilledOutlined';

const useStyles = makeStyles({
  root: {
    width: 150,
    height: 150,
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#cccccc',
  },
  main: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    margin: 5,
  },
  checkBox: {
    position: 'absolute',
    right: 10,
    bottom: 10,
    cursor: 'pointer',
  },
  viewMedia: {
    position: 'absolute',
    right: 10,
    top: 10,
    cursor: 'pointer',
  },
});

export default function CustomCardMediaMultiSelect({
  title,
  component = 'img',
  image,
  isSelected,
  onClick,
  onOpenClick,
}) {
  const classes = useStyles();

  return (
    <div className={classes.main}>
      <Card className={classes.root} onClick={onClick}>
        {component === 'img'
          ? (
            <CardMedia
              component='img'
              alt={title}
              height='150'
              width='150'
              image={image}
              title={title}
            />
          ) : null}
        {component === 'video'
          ? (
            <PlayCircleFilledOutlinedIcon />
          ) : null}
      </Card>
      <FullscreenIcon
        onClick={onOpenClick}
        color='white'
        className={classes.viewMedia}
      />
      {isSelected ? (
        <CheckBoxIcon
          onClick={onClick}
          color='secondary'
          className={classes.checkBox}
        />
      ) : null}
    </div>
  );
}
