import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {
  TableRow,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import CustomTable from '../../components/CustomTableWithoutHeader/CustomTableWithoutHeader';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'Floor', label: 'Floor',
  },
  {
    id: 'Component', label: 'Component',
  },
  {
    id: 'Size',
    label: 'Size (ft X ft)',
  },
  {
    id: 'ComponentName', label: 'Component Name',
  },
  {
    id: 'Action', label: 'Action',
  },
];

const useStyles = (theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
  btnholder: {
    width: '95%',
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  icon: {
    color: '#3D4977',
    cursor: 'pointer',
  },
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    flex: '1 1 100%',
  },
});

class PropertyComponentDetailsTable extends Component {
  render() {
    const { classes } = this.props;

    const {
      data,
      handleEditData,
      handleDeleteProperty,
      propComponentsPermission,
      // handleShowAddForm,
    } = this.props;

    return (
      <div className={classes.mainContainer}>
        {/* <Paper className={classes.paper}> */}
        <>
          <CustomTable
            pages={false}
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {element.FloorNameConfig?.floorName}
                </TableCell>
                <TableCell>
                  {element.ComponentConfig.componentName}
                </TableCell>
                <TableCell>
                  {element.componentDimen}
                </TableCell>
                <TableCell>
                  {element.componentName}
                </TableCell>
                <TableCell>

                  <Button
                    color='primary'
                    justIcon
                    simple
                    disabled={!propComponentsPermission.edit}
                    round
                    onClick={(key) => { handleEditData(key, element); }}
                    className={classes.buttonLink}
                  >
                    <EditIcon className={classes.icons} />
                  </Button>

                  <Button
                    color='danger'
                    justIcon
                    disabled={!propComponentsPermission.delete}
                    simple
                    round
                    onClick={(key) => { handleDeleteProperty(key, element.id); }}
                    className={classes.buttonLink}
                  >
                    <DeleteIcon className={classes.icons} />
                  </Button>

                </TableCell>
              </TableRow>
            )) : null}
          />
        </>
        {/* </Paper> */}
      </div>
    );
  }
}

export default withStyles(useStyles)(PropertyComponentDetailsTable);
