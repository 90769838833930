import {
  blackColor,
  whiteColor,
  hexToRgb,
} from '../../material-dashboard-react';

const cardStyle = {
  card: {
    color: `rgba(${hexToRgb(blackColor)}, 0.87)`,
    background: whiteColor,
    boxShadow: `0 1px 4px 0 rgba(${hexToRgb(blackColor)}, 0.14)`,
    fontSize: '.875rem',
  },
  cardPlain: {
    background: 'transparent',
    boxShadow: 'none',
  },
  cardProfile: {
    marginTop: '30px',
    textAlign: 'center',
  },
  cardChart: {
    '& p': {
      marginTop: '0px',
      paddingTop: '0px',
    },
  },
};

export default cardStyle;
