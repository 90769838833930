import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CustomTable from '../../components/CustomTableWithoutHeader/CustomTableWithoutHeader';
import {
  shortData,
} from '../../helpers';

const columns = [
  {
    id: 'name', label: 'Name',
  },
  {
    id: 'view', label: 'View',
  },
  {
    id: 'add', label: 'Add',
  },
  {
    id: 'edit', label: 'Edit',
  },
  {
    id: 'delete', label: 'Delete',
  },
];
const useStyles = makeStyles({
  root: {
    flex: 1,
    borderLeft: '2px solid #eee',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    margin: 40,
  },
  leftDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rightDiv: {
    flex: 1,
    // height: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginTop: '8px',
  },
  itemSelected: {
    backgroundColor: '#3D4977',
    marginTop: '8px',
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
  listroot: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  list: {
    width: 300,
  },
});

export default function BasicDetails({ rolePermissions }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.leftDiv}>
          <CustomTable
            tableClass={{ width: '50%' }}
            tableHead={columns}
            itemComponent={rolePermissions.length > 0
              ? rolePermissions.map((element) => (
                <TableRow
                  className={classes.tablewo}
                  tabIndex={-1}
                  key={element.id}
                >
                  <TableCell>
                    {shortData(element.name || element.PermissionConfig?.name)}
                  </TableCell>
                  <TableCell>
                    {
                      element.view ? <CheckBoxIcon color='secondary' /> : <CheckBoxOutlineBlankIcon />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      element.add ? <CheckBoxIcon color='secondary' /> : <CheckBoxOutlineBlankIcon />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      element.edit ? <CheckBoxIcon color='secondary' /> : <CheckBoxOutlineBlankIcon />
                    }
                  </TableCell>
                  <TableCell>
                    {
                      element.delete ? <CheckBoxIcon color='secondary' /> : <CheckBoxOutlineBlankIcon />
                    }

                  </TableCell>
                </TableRow>
              )) : null}
            count={1}
            rowsPerPage={0}
            page={1}
          />
        </div>
      </div>

    </div>
  );
}
