import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {
  TableRow,
} from '@material-ui/core';
import Link from '@material-ui/core/Link';
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from 'react-toastify';
import axios from 'axios';
import {
  encrypt,
  verifyLogin,
  apiList,
  // encrypt,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'groupId', label: 'Group ID', align: 'center',
  },
  {
    id: 'submittedUserType', label: 'Submitted By', align: 'center',
  },
  {
    id: 'photos', label: 'No of Media', align: 'center',
  },
  // {
  //   id: 'videos', label: 'No of videos', align: 'center',
  // },
  {
    id: 'submitteddate', label: 'Submitted Date', align: 'center',
  },
  {
    id: 'action', label: 'Action', align: 'center',
  },
];

const useStyles = (theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: 'white',
    alignSelf: 'center',
    alignItems: 'center',
    padding: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
  btnholder: {
    width: '95%',
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  icon: {
    color: '#3D4977',
    cursor: 'pointer',
  },
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    flex: '1 1 100%',
  },
});

class UncategorizedMediaTable extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 5,
      pageCount: 0,
      uncategorizedMedia: [],
    };
  }

  componentDidMount() {
    this.getMediaList(this.state.page, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.getMediaList(newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getMediaList(1, +event.target.value);
  };

  getMediaList(page, rowsPerPage) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Construction Plan', feature: 'view' } };
    axios.get(`${apiList.GET_UNCATEGORIZED_MEDIA_LIST}?loanId=${this.props.loanId}&page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        const uncategorizedMedia = [...response.data.data.paginate];
        this.setState({
          uncategorizedMedia,
          pageCount: Math.ceil(response.data.data.pageCount),
        });
      })
      .catch((e) => {
        console.log('error', e);
      });
  }

  getViewDetailsUrl(groupId) {
    const detailId = encrypt(JSON.stringify({
      loanId: this.props.loanId,
      loanNumber: this.props.loanNumber,
      groupId,
      propertyDescription: this.props.propertyDescription,
      address: this.props.address,
      lat: this.props.lat,
      lng: this.props.lng,
    }));
    return `/uncatergorized-media/${detailId}`;
  }

  deleteMediaByGroupID(key, groupId) {
    key.preventDefault();
    if (confirm(`${groupId
    }CONFIRM TO PROCCESSED )): Your data will lost, if you press OK`)) {
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Construction Plan', feature: 'delete' } };
      axios.post(`${apiList.DELETE_UNCATEGORIZED_MEDIA}`, { groupId }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.setState({ page: 1, rowsPerPage: 5 });
          this.getMediaList(1, 5);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  }

  render() {
    const { classes } = this.props;
    const { uncategorizedMedia, pageCount, page } = this.state;

    return (
      <div className={classes.mainContainer}>
        <CustomTable
          showPagination
          noDataText='There is no construction progress media.'
          tableHead={columns}
          itemComponent={uncategorizedMedia.length > 0 ? uncategorizedMedia.map((element) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >
              <TableCell>
                <Link
                  underline='always'
                  href={this.getViewDetailsUrl(element.groupId)}
                >
                  {element.groupId}
                </Link>
              </TableCell>
              <TableCell>
                {element.submittedUserType}
              </TableCell>
              <TableCell>
                {element.count}
              </TableCell>
              <TableCell>
                {element.submittedDate}
              </TableCell>
              <TableCell>
                <Button
                  color='danger'
                  justIcon
                  simple
                  round
                  onClick={(key) => { this.deleteMediaByGroupID(key, element.groupId); }}
                  className={classes.buttonLink}
                >
                  <DeleteIcon className={classes.icons} />
                </Button>

              </TableCell>
            </TableRow>
          )) : null}
          count={pageCount}
          rowsPerPage={0}
          page={page}
          handleChangePage={this.handleChangePage}
          handleChangeRowsPerPage={this.handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(UncategorizedMediaTable);
