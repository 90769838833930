import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class FormUpdateStatus extends Component {
  constructor() {
    super();
    this.state = {
      status: [{ id: 'Close', value: 'Close' },
        { id: 'Open', value: 'Open' }],
    };
  }

  render() {
    const {
      classes, handleChange, comments, setStatus, status,
    } = this.props;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='status'
          title='Status'
          menuItems={this.state.status.map((element) => (
            <option value={element.id} key={element.id}>{element.value}</option>
          ))}
          handleChange={(event) => {
            setStatus(event);
          }}
          value={status}
        />
        <CustomInput
          multiline
          rowsMax={10}
          rows={5}
          id='comments'
          label='Comments'
          value={comments}
          onChange={(id) => handleChange(id)}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(FormUpdateStatus);
