import React from 'react';

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    flex: '1 1 100%',
  },
  paper: {
    flex: 1,
    height: '620px',
  },
  innerPaper: {
    margin: theme.spacing(2),
    width: 'max-content',
  },
  removeMargin: {
    margin: theme.spacing(0),
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  },
  table: {
    width: 'auto', tableLayout: 'auto',
  },
  flexboxDiv: {
    display: 'flex',
    justifyContent: 'center',
  },
  nodata: {
    color: '#999',
    fontSize: 16,
  },
}));

export default function CustomTableWithoutHeader({
  tableHead, itemComponent, noDataText, removeMargin,
}) {
  const classes = useToolbarStyles();

  return (
    <>
      <div>
        <div className={classes.flexboxDiv}>
          <Paper className={[classes.innerPaper, removeMargin && classes.removeMargin]}>
            <TableContainer className='mb-4'>
              <Table aria-label='simple table'>
                {tableHead && (
                <TableHead>
                  <TableRow>
                    {tableHead.map((column) => (
                      <TableCell
                        key={column.id}
                        align={column.align}
                        style={{ minWidth: column.minWidth, fontWeight: 'bold' }}
                      >
                        {column.label}
                      </TableCell>
                    ))}
                  </TableRow>
                </TableHead>
                )}
                <TableBody>
                  {itemComponent}
                </TableBody>
              </Table>
            </TableContainer>
          </Paper>

        </div>
        {!itemComponent && (
        <div className={classes.root}>
          <Typography className={classes.nodata} variant='h10' id='tableTitle' component='div'>
            {noDataText || 'You have not added any data.'}
          </Typography>
        </div>
        )}
      </div>
    </>
  );
}
