/* eslint-disable no-underscore-dangle */
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import moment from 'moment';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';
import '../../assets/style/react-datepicker.css';
import {
  apiList,
  verifyLogin,
} from '../../helpers';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
  margin: {
    minWidth: 300,
    marginBottom: 16,
    marginTop: 16,
  },
  datePicker: {
    border: '1px solid #ccc',
    borderRadius: 5,
    height: 40,
    minWidth: 294,
    marginBottom: 16,
    marginTop: 16,
  },
});

class FormLoanTask extends Component {
  constructor() {
    super();
    this.state = {
      loanTaskConfig: [],
      ifscUsers: [],
    };
  }

  componentDidMount() {
    this.getUsersByIFSCLevel();
    this.getTaskConfig();
  }

  getTaskConfig() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    const { subscriberId } = this.props;
    axios.get(`${apiList.GET_LOAN_TASK_CONFIG}?subscriberId=${subscriberId}`, config)
      .then((response) => {
        this.setState({ loanTaskConfig: [...response.data.data.paginate] });
      })
      .catch(() => {
        this.setState({ loanTaskConfig: [] });
      });
  }

  getUsersByIFSCLevel() {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Customer', feature: 'view' } };
    const { subscriberId, branchIFSC } = this.props;
    axios.get(`${apiList.GET_USERS_LOAN_TASKS}?subscriberId=${subscriberId}&ifsc=${branchIFSC}`, config)
      .then((response) => {
        const data = response.data.data.paginate.map(
          ({ User }) => ({ id: User.id, name: `${User?.firstName} ${User?.lastName}` }),
        );
        this.setState({ ifscUsers: [...data] });
      })
      .catch(() => {
        this.setState({ ifscUsers: [] });
      });
  }

  render() {
    const {
      classes, loanTaskDetails, setLoanTaskDetails,
    } = this.props;

    const {
      loanTaskConfig, ifscUsers,
    } = this.state;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='configID'
          title='Loan Task'
          menuItems={loanTaskConfig.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            loanTaskDetails.taskTypeId = event.target.value;
            setLoanTaskDetails(loanTaskDetails);
          }}
          value={loanTaskDetails.taskTypeId}
        />
        <CustomSelect
          id='ownerId'
          title='Owner'
          menuItems={ifscUsers.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            loanTaskDetails.ownerId = event.target.value;
            setLoanTaskDetails(loanTaskDetails);
          }}
          value={loanTaskDetails.ownerId}
        />
        <CustomDatePicker
          id='ownerId'
          label='Planned Date'
          onChange={(date) => {
            loanTaskDetails.plannedDate = date?._isValid ? moment(date).format('DD-MM-YYYY HH:mm:ss') : null;
            setLoanTaskDetails(loanTaskDetails);
          }}
          value={loanTaskDetails.plannedDate}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(FormLoanTask);
