import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import axios from 'axios';
import {
  summation,
  addCommas,
  verifyLogin,
  apiList,
  shortDate,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';

const columns = [
  // {
  //   id: 'Id', label: 'Id',
  // },
  {
    id: 'disbursementDate', label: 'Disbursement Date',
  },
  {
    id: 'amount', label: 'Amount',
  },
  {
    id: 'percentage',
    label: 'Disbursement Stage %',
  },
  {
    id: 'Action', label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
  icons: {
    width: 20,
    height: 20,
    marginBottom: -10,
  },
});

class DisbursementTable extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 5,
      data: [],
    };
  }

  componentDidMount() {
    this.getLoanDisbursal(this.state.page, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.getLoanDisbursal(newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getLoanDisbursal(1, +event.target.value);
  };

  getDisbursement() {
    this.setState({ rowsPerPage: 5, page: 1 });
    this.getLoanDisbursal(1, 5);
  }

  getLoanDisbursal() {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };

    axios.get(`${apiList.GET_LOAN_DISBURSEMENT_API}?loanId=${this.props.loanId}&page=${1}&limit=${1000}`, config)
      .then((response) => {
        const data = [...response.data.data.paginate];
        this.setState({
          data,
        });
      })
      .catch(() => {
        this.setState({
          data: [],
        });
      });
  }

  deleteDisbursement(key, id) {
    key.preventDefault();
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Disbursement', feature: 'delete' } };
      axios.post(`${apiList.DELETE_DISBURSEMENT}`, { id }, config)
        .then((response) => {
          this.getDisbursement();
          this.props.deleteDisbursement();
          toast.success(response.data.message);
        })
        .catch(() => {
          // console.log(error.response);
          // toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  }

  render() {
    const {
      classes, handleviewDetails, handleEditData, disbursementPermission,
    } = this.props;

    const {
      data,
    } = this.state;
    const disAmount = summation(data, 'disAmount');
    const disAmountPercentage = summation(data, 'disAmountPercentage');

    const filterData = [...data, {
      id: 'Total',
      disDate: 'Total',
      disAmount,
      disAmountPercentage: Number.isNaN(disAmountPercentage) ? 0 : disAmountPercentage,
    }];

    return (
      <div className={classes.mainContainer}>
        <>
          <CustomTable
            pages={false}
            tableHead={columns}
            itemComponent={filterData.length > 1 ? filterData.map((element) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
                onClick={(key) => { handleviewDetails(key, element.id); }}
              >
                {/* <TableCell>
                  {element.id === 'Total' ? 'Total' : index + 1}
                </TableCell> */}
                <TableCell>
                  {element.disDate
                    ? (element.disDate === 'Total' ? 'Total' : shortDate(element.disDate)) : ''}
                </TableCell>
                <TableCell>
                  {element.id === 'Total' ? addCommas(JSON.parse(element.disAmount)) : addCommas(element.disAmount)}
                </TableCell>
                <TableCell>
                  {`${element.id === 'Total' ? (element.disAmountPercentage * 100).toFixed(2)
                    : (parseFloat(element.disAmountPercentage ? element.disAmountPercentage : 0) * 100).toFixed(2)}%`}
                </TableCell>
                {element.id === 'Total' ? null : (
                  <TableCell>
                    <Button
                      color='primary'
                      justIcon
                      disabled={!disbursementPermission.edit}
                      simple
                      round
                      onClick={(key) => { handleEditData(key, element); }}
                      className={classes.buttonLink}
                    >
                      <EditIcon className={classes.icons} />
                    </Button>

                    <Button
                      color='danger'
                      justIcon
                      simple
                      disabled={!disbursementPermission.delete}
                      round
                      onClick={(key) => { this.deleteDisbursement(key, element.id); }}
                      className={classes.buttonLink}
                    >
                      <DeleteIcon className={classes.icons} />
                    </Button>

                  </TableCell>
                )}

              </TableRow>
            )) : null}
          // count={pageCount}
          // rowsPerPage={0}
          // page={page}
          // handleChangePage={this.handleChangePage}
          // handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </>
      </div>
    );
  }
}

export default withStyles(useStyles)(DisbursementTable);
