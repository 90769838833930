import LayersRoundedIcon from '@material-ui/icons/LayersRounded';
import ThumbsUpDownRoundedIcon from '@material-ui/icons/ThumbsUpDownRounded';
import NotificationsActiveRoundedIcon from '@material-ui/icons/NotificationsActiveRounded';
import MarkunreadMailboxRoundedIcon from '@material-ui/icons/MarkunreadMailboxRounded';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import ListAltIcon from '@material-ui/icons/ListAlt';
import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import BorderAllRoundedIcon from '@material-ui/icons/BorderAllRounded';
import DashboardRoundedIcon from '@material-ui/icons/DashboardRounded';
import AcUnitIcon from '@material-ui/icons/AcUnit';
import FormatAlignLeftIcon from '@material-ui/icons/FormatAlignLeft';
import BusinessRoundedIcon from '@material-ui/icons/BusinessRounded';
import DescriptionIcon from '@material-ui/icons/Description';
import AssessmentIcon from '@material-ui/icons/Assessment';

import UsersScreen from '../screens/UsersScreen/UsersScreen';
import UserRoles from '../screens/UserRoles/UserRoles';
import UserProducts from '../screens/UserProducts/UserProducts';
import LoanTypes from '../screens/LoanTypes/LoanTypes';
import UserLoansMapping from '../screens/UserLoansMapping/UserLoansMapping';
import Subscribers from '../screens/Subscribers/Subscribers';
import ComponentConfig from '../screens/ComponentConfig/ComponentConfig';
import ActivityConfig from '../screens/ActivityConfig/ActivityConfig';
import ActivityTypeConfig from '../screens/ActivityTypeConfig/ActivityTypeConfig';
import ConstrucionPlanConfig from '../screens/ConstrucionPlanConfig/ConstrucionPlanConfig';
import PermissionConfig from '../screens/PermissionConfig/PermissionConfig';
import AcessLevelConfig from '../screens/AcessLevelConfig/AcessLevelConfig';
import LoanTaskConfig from '../screens/LoanTaskConfig/LoanTaskConfig';
import ReportType from '../screens/ReportType/ReportType';
import ReportFormat from '../screens/ReportFormat/ReportFormat';

// core components/views for RTL layout

const adminRoutes = [
  {
    path: '/users',
    name: 'Users',
    icon: GroupRoundedIcon,
    component: UsersScreen,
    layout: '/admin',
  },
  {
    path: '/users-roles',
    name: 'User Roles',
    icon: AccountTreeRoundedIcon,
    component: UserRoles,
    layout: '/admin',
  },
  {
    path: '/branches',
    name: 'Access Level',
    icon: AcUnitIcon,
    component: AcessLevelConfig,
    layout: '/admin',
  },
  {
    path: '/construcion-plan-config',
    name: 'Construcion Plan Config',
    icon: HomeWorkIcon,
    component: ConstrucionPlanConfig,
    layout: '/admin',
  },
  {
    path: '/loan-task-config',
    name: 'Loan Task Config',
    icon: ListAltIcon,
    component: LoanTaskConfig,
    layout: '/admin',
  },
  {
    path: '/users-active-config',
    name: 'Activity Config',
    icon: DashboardRoundedIcon,
    component: ActivityConfig,
    layout: '/admin',
  },
];

const superAdminRoutes = [

  {
    path: '/users',
    name: 'Users',
    icon: GroupRoundedIcon,
    component: UsersScreen,
    layout: '/admin',
  },
  {
    path: '/users-roles',
    name: 'User Roles',
    icon: AccountTreeRoundedIcon,
    component: UserRoles,
    layout: '/admin',
  },
  {
    path: '/branches',
    name: 'Access Level',
    icon: AcUnitIcon,
    component: AcessLevelConfig,
    layout: '/admin',
  },
  {
    path: '/construcion-plan-config',
    name: 'Construcion Plan Config',
    icon: HomeWorkIcon,
    component: ConstrucionPlanConfig,
    layout: '/admin',
  },
  {
    path: '/loan-task-config',
    name: 'Loan Task Config',
    icon: ListAltIcon,
    component: LoanTaskConfig,
    layout: '/admin',
  },
  {
    path: '/users-active-config',
    name: 'Activity Config',
    icon: DashboardRoundedIcon,
    component: ActivityConfig,
    layout: '/admin',
  },
  {
    path: '/users-products',
    name: 'User Products',
    icon: MarkunreadMailboxRoundedIcon,
    component: UserProducts,
    layout: '/admin',
  },
  {
    path: '/users-loan-mapping',
    name: 'Users Loan Mapping',
    icon: ThumbsUpDownRoundedIcon,
    component: UserLoansMapping,
    layout: '/admin',
  },
  {
    path: '/users-subscribers',
    name: 'Subscribers',
    icon: NotificationsActiveRoundedIcon,
    component: Subscribers,
    layout: '/admin',
  },
  {
    path: '/users-loan-type',
    name: 'Loan Types',
    icon: LayersRoundedIcon,
    component: LoanTypes,
    layout: '/admin',
  },
  {
    path: '/users-component-config',
    name: 'Component Config',
    icon: BorderAllRoundedIcon,
    component: ComponentConfig,
    layout: '/admin',
  },
  {
    path: '/users-active-type-config',
    name: 'Activity Type Config',
    icon: BusinessRoundedIcon,
    component: ActivityTypeConfig,
    layout: '/admin',
  },
  {
    path: '/permission-config',
    name: 'Permission Config',
    icon: FormatAlignLeftIcon,
    component: PermissionConfig,
    layout: '/admin',
  },
  {
    path: '/report-type',
    name: 'Report Type',
    icon: DescriptionIcon,
    component: ReportType,
    layout: '/admin',
  },
  {
    path: '/report-format',
    name: 'Report Format',
    icon: AssessmentIcon,
    component: ReportFormat,
    layout: '/admin',
  },
];

export { adminRoutes, superAdminRoutes };
