import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class FormLoanProperty extends Component {
  render() {
    const {
      classes, handleChange, propertyDescription, addressLine1, addressLine2, lat, lng,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput
          id='propertyDescription'
          label='Property Details'
          multiline
          rowsMax={3}
          rows={3}
          value={propertyDescription}
          onChange={(id) => handleChange(id)}
        />
        <CustomInput id='addressLine1' label='Address Line1' value={addressLine1} onChange={(id) => handleChange(id)} />
        <CustomInput id='addressLine2' label='Address Line2' value={addressLine2} onChange={(id) => handleChange(id)} />
        <CustomInput id='lat' label='Lattitude' value={lat} onChange={(id) => handleChange(id)} />
        <CustomInput id='lng' label='Longitude' value={lng} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(FormLoanProperty);
