import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import ConstructionPlanModuleTable from './ConstructionPlanModuleTable';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Loading from '../../components/loading/loading';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import OnBoardingDialog from './OnBoardingDialog';
import CustomAlert from '../../components/CustomAlert/CustomAlert';

class ConstrucionPlanConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
      page: 1,
      rowsPerPage: 10,
      pageCount: 0,
      openOnBoard: false,
      loanType: [],
      selectedPlanLoanID: -1,
      planID: null,
      floors: -1,
      generateWOComp: 'false',
      planName: '',
      planDescription: '',
      planCompleted: false,
      canEdit: false,
      showContructionPlanDeleteAlert: false,
      isCompoRequired: 'true',
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.handleFetchConstructionPlanModule(Number(subscriberId),
        this.state.page, this.state.rowsPerPage);
    }
  }

  handleChangePage = (event, newPage) => {
    this.handleFetchConstructionPlanModule(this.state.subscriberId,
      newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleFetchConstructionPlanModule(subscriberId, page, rowsPerPage) {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    this.setState({ result: true });
    let url = `${apiList.GET_CONSTRUCTION_PLAN_MODULE}?page=${page}&limit=${rowsPerPage}`;
    if (subscriberId !== -1) {
      url = `${url}&subscriberId=${subscriberId}`;
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({
          data: [...response.data.data.paginate],
          pageCount: Math.ceil(response.data.data.pageCount),
          result: false,
        });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false, data: [] });
      });
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    this.handleFetchLoanType(config);
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  handleFetchLoanType(config) {
    axios.get(`${apiList.VIEW_USERS_LOAN_TYPE_API}`, config)
      .then((response) => {
        this.setState({ loanType: response.data.data.paginate });
      })
      .catch(() => {

      });
  }

  deleteConstructionPlanModule = () => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.post(`${apiList.DELETE_CONSTRUCTION_PLAN_MODULE}`, { id: this.state.deletePlanId }, config)
      .then(() => {
        this.setState({ showContructionPlanDeleteAlert: false, deletePlanId: null });

        this.handleFetchConstructionPlanModule(this.state.subscriberId,
          this.state.page, this.state.rowsPerPage);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  render() {
    const {
      result,
      data,
      subscribers,
      userRoleId,
      subscriberId,
      pageCount,
      rowsPerPage,
      page,
      openOnBoard,
      loanType,
      planID,
      generateWOComp,
      planName,
      planDescription,
      planCompleted,
      selectedPlanLoanID,
      canEdit,
      floors,
      showContructionPlanDeleteAlert,
      isCompoRequired,
    } = this.state;
    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        <CustomAlert
          open={showContructionPlanDeleteAlert}
          handleYes={() => {
            this.deleteConstructionPlanModule();
            this.setState({ showContructionPlanDeleteAlert: false });
          }}
          title='Do you wanna proceed?'
          content='Are you sure you want to delete this construction plan configuration?'
          handleClose={() => {
            this.setState({ showContructionPlanDeleteAlert: false, deletePlanId: null });
          }}
        />

        {result === true ? <Loading MainProps={this.props} /> : null}
        {userRoleId === 1 ? (
          <div className='select-fields'>
            <CustomSelect
              id='subscriberId'
              disabled={userRoleId === 2}
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                this.setState({ subscriberId: event.target.value });
                this.handleFetchConstructionPlanModule(event.target.value,
                  this.state.page, this.state.rowsPerPage);
              }}
              value={subscriberId}
            />
          </div>
        ) : null}

        <ConstructionPlanModuleTable
          data={Array.isArray(data) ? data : []}
          handleDeleteData={(key, id) => {
            this.setState({ showContructionPlanDeleteAlert: true, deletePlanId: id });
          }}
          handleShowEditForm={(key, element, editEnabled) => {
            this.setState({
              planID: element.id,
              generateWOComp: element.generateWOComputation,
              planName: element.planName,
              planDescription: element.description,
              planCompleted: element.isCompleted,
              isCompoRequired: element.isComponentRequired,
              selectedPlanLoanID: element.LoanType.id,
              floors: element.noOfFloors,
              openOnBoard: true,
              canEdit: editEnabled,
            });
          }}
          handleShowAddForm={() => {
            if (subscriberId === -1) {
              toast.error('Please select subcriber first.');
            } else {
              this.setState({
                openOnBoard: true,
                planID: null,
                generateWOComp: 'false',
                planName: '',
                planDescription: '',
                planCompleted: 'False',
                selectedPlanLoanID: -1,
                floors: -1,
                canEdit: true,
                isCompoRequired: 'true',
              });
            }
          }}
          handleChangePage={this.handleChangePage}
          rowsPerPage={rowsPerPage}
          page={page}
          pageCount={pageCount}
        />

        {openOnBoard && (
          <OnBoardingDialog
            open={openOnBoard}
            canEdit={canEdit}
            loanType={loanType}
            subscriberId={subscriberId}
            fetchPlans={() => {
              this.handleFetchConstructionPlanModule(this.state.subscriberId,
                this.state.page, this.state.rowsPerPage);
            }}
            handleClose={() => this.setState({
              planID: null,
              generateWOComp: 'false',
              planName: '',
              planDescription: '',
              planCompleted: 'False',
              selectedPlanLoanID: -1,
              floors: -1,
              openOnBoard: false,
              isCompoRequired: 'true',
            })}
            planID={planID}
            planName={planName}
            planDescription={planDescription}
            planCompleted={planCompleted}
            selectedPlanLoanID={selectedPlanLoanID}
            floors={floors}
            generateWOComp={generateWOComp}
            isCompoRequired={isCompoRequired}
          />
        )}

      </div>
    );
  }
}

export default ConstrucionPlanConfig;
