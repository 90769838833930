/* eslint-disable no-unreachable */
import axios from 'axios';
import React, { Component } from 'react';
import { ToastContainer, toast } from 'react-toastify';

import Lightbox from 'react-awesome-lightbox';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import { withStyles } from '@material-ui/core/styles';
import Link from '@material-ui/core/Link';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import Navbar from '../../components/navBar/navBar';
import Loading from '../../components/loading/loading';
import CustomAlert from '../../components/CustomAlert/CustomAlert';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import {
  apiList,
  dencrypt,
  variables,
  verifyLogin,
  encrypt,
  shortDate,
  shortDecimal,
} from '../../helpers';
import CustomCardMedia from '../../components/CustomCardMedia/CustomCardMedia';
import '../../assets/style/lightbox.css';
import CustomModal from '../../components/CustomModal/CustomModal';
import FormUpdateStatus from './FormUpdateStatus';
import { groupDataByFloorName, groupDataByactivityTypeAndName } from '../../helpers/utils';
import TransferMedia from './TransferMedia/TransferMedia';
import ModifyPlan from './ModifyPlan/ModifyPlan';

const videoAvatar = require('../../assets/images/videoAvatar.mp4').default;

const successTimeOut = variables.SUCCESS_TIMEOUT;
const errorTimeOut = variables.SUCCUSS_TIMEOUT;
const MEDIA_TABS = ['Customer', 'Relationship Manager',
  'Contractor', 'Valuer'];

const StyledTabsFloor = withStyles({
  root: {
    backgroundColor: '#E7EAEE',
    borderBottom: '1px solid #fff',
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabFloor = withStyles((theme) => ({
  root: {
    backgroundColor: '#E7EAEE',
    color: '#3D4977',
    fontWeight: theme.typography.fontWeightRegular,
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    display: 'flex',
  },
  selected: {
    backgroundColor: '#00838F',
  },
}))((props) => <Tab {...props} />);

const StyledTabsComponent = withStyles({
  root: {
    borderBottom: '1px solid #fff',
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
      backgroundColor: '#00838F',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabComponent = withStyles((theme) => ({
  root: {
    color: '#00838F',
    fontWeight: theme.typography.fontWeightRegular,
    '&$selected': {
      color: '#00838F',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    backgroundColor: '#fff',
  },
}))((props) => <Tab {...props} />);

const StyledTabsMedia = withStyles({
  root: {
    backgroundColor: '#E7EAEE',
    borderBottom: '1px solid #fff',
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabMedia = withStyles((theme) => ({
  root: {
    color: '#3D4977',
    backgroundColor: '#E7EAEE',
    fontWeight: theme.typography.fontWeightRegular,
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    backgroundColor: '#00838F',
  },
}))((props) => <Tab {...props} />);

class ConstructionDetails extends Component {
  constructor() {
    super();
    this.state = {
      selectedConstructionPlanIndex: -1,
      propertyId: null,
      result: false,
      data: {},
      dataLoaded: false,
      showAlert: false,
      viewerIsOpen: false,
      currentImage: 0,
      selectedPhotos: [],
      photos: [],
      videos: [],
      videoOpen: false,
      videoUrl: '',
      selectedVideodisc: '',
      selectedVideoTitle: '',
      permission: {},
      modifyPermission: {},
      remarks: '',
      status: '',
      floorTab: 0,
      componentTab: 0,
      mediaTab: 0,
      planList: [],
      selectedComponent: [],
      selectedItem: null,
      progressMedia: [],
      mediaAvailability: {},
      transferMediaOpen: false,
      isCurrentPlan: true,
      constructionPlanVersion: 'undef',
      modifyPlanOpen: false,
      selectedConstructionPlanId: -1,
    };
  }

  componentDidMount() {
    const detailId = dencrypt(this.props.match.params.detailId);
    const data = JSON.parse(detailId);
    this.setState({
      selectedConstructionPlanIndex: data.index,
      selectedConstructionPlanId: data.planId,
      propertyId: data.propertyId,
      isCurrentPlan: data.isCurrentPlan,
      constructionPlanVersion: data.constructionPlanVersion,
    });
    this.setState({ result: true });
    this.getConstructionPlan(data.index, data.planId,
      data.propertyId, null, data.constructionPlanVersion);
    const { roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
        modifyPermission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const permission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Construction Plan');
      const modifyPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'CP-Transfer Media');
      this.setState({ permission: permission || {}, modifyPermission: modifyPermission || {} });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleOpenMedia(key, media) {
    key.preventDefault();
    window.open(media, '_blank', 'height= 500px, width= 800px');
  }

  handleVerify() {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Construction Plan', feature: 'edit' } };
    if (this.state.remarks.length < 1 || this.state.status.length < 1) {
      toast.error('All fields are mandatory.');
      return;
    }
    const params = {
      status: this.state.status,
      id: this.state.data?.constructionPlan?.id || -1,
      remarks: this.state.remarks,
    };

    axios.post(`${apiList.VERIFY_CONSTRUCTION_PLAN}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.getConstructionPlan(this.state.selectedConstructionPlanIndex,
          this.state.selectedConstructionPlanId,
          this.state.propertyId, this.state.selectedItem, this.state.constructionPlanVersion);
        this.setState({
          result: false, showAlert: false, formOpen: false, status: '', remarks: '',
        });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  getConstructionPlan(index, planId, propertyId, selectedItem, constructionPlanVersion) {
    this.setState({ result: true });
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${propertyId}/${constructionPlanVersion}`)
      .then((response) => {
        if (response?.data?.result) {
          const { constructionPlan } = response.data.result;
          let planData = [];
          if (index !== null) {
            const groupedPlan = groupDataByactivityTypeAndName(constructionPlan);
            planData = groupedPlan[index].data;
          } else {
            const groupedPlan = groupDataByactivityTypeAndName(constructionPlan.filter(
              (plan) => plan.id === planId,
            ));
            planData = groupedPlan[0].data;
          }
          const planList = groupDataByFloorName(planData);
          const { floorTab, componentTab } = this.state;
          this.setState({
            planList,
            selectedComponent: planList[floorTab]?.data,
            selectedItem: planList[floorTab]?.data[componentTab]?.id,
          });
          this.getProgressMedia(selectedItem || planList[0]?.data[0]?.id, 'Customer');
          this.getData(selectedItem || planList[0]?.data[0]?.id);
        }

        this.setState({ result: false });
      })
      .catch(() => {
        this.setState({ result: false });
      });
  }

  getData(id) {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_CONSTRUCTION_DETAILS_API}/${id}`, config)
      .then((response) => {
        if (response && response.data && response.data.result) {
          console.log(response.data.result);
          this.setState({
            data: { ...response.data.result },
            dataLoaded: true,
          });
        }
        setTimeout(() => {
          this.setState({ result: false });
        }, successTimeOut);
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, errorTimeOut);
      });
  }

  getProgressMedia(planID, submittedUser) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    axios.get(`${apiList.GET_PROGRESS_MEDIA_BY_CONST_PLAN}?consPlanId=${planID}`, config)
      .then((response) => {
        if (response.data.data) {
          const progressMedia = response.data.data.map(({
            url, submittedDate, lat, lng, ProgressMediaPlan, submittedUserType, id,
          }) => ({
            src: url,
            id,
            submittedDate,
            lat,
            lng,
            url,
            mediaType: ProgressMediaPlan.mediaType,
            width: 4,
            height: 3,
            caption: ProgressMediaPlan.title,
            title: ProgressMediaPlan.title,
            disc: ProgressMediaPlan.instruction,
            submittedUserType,
          }));
          const customerMedia = progressMedia.filter(
            (item) => item.submittedUserType === MEDIA_TABS[0],
          );
          const relationshipManagerMedia = progressMedia.filter(
            (item) => item.submittedUserType === MEDIA_TABS[1],
          );
          const contractorMedia = progressMedia.filter(
            (item) => item.submittedUserType === MEDIA_TABS[2],
          );
          const valuerMedia = progressMedia.filter(
            (item) => item.submittedUserType === MEDIA_TABS[3],
          );

          const filteredProgressMedia = progressMedia.filter(
            (item) => item.submittedUserType === submittedUser,
          );

          const mediaAvailability = {
            Customer: customerMedia.length > 0,
            'Relationship Manager': relationshipManagerMedia.length > 0,
            Contractor: contractorMedia.length > 0,
            Valuer: valuerMedia.length > 0,
          };
          this.setState({ progressMedia, mediaAvailability });

          const photos = filteredProgressMedia.filter((item) => item.mediaType === 'IMAGE');
          const videos = filteredProgressMedia.filter((item) => item.mediaType === 'VIDEO');
          this.setState({ photos, videos });
        }
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);
      });
  }

  getViewLoanUrl(viewId) {
    const detailId = encrypt(viewId);
    return `/view-loan-details/${detailId}`;
  }

  body = () => (
    <FormUpdateStatus
      handleChange={(id) => this.handleChange(id)}
      remarks={this.state.remarks}
      setStatus={(event) => {
        this.setState({ status: event.target.value });
      }}
      status={this.state.status}
    />
  )

  render() {
    const {
      result, data, dataLoaded, showAlert,
      viewerIsOpen, currentImage, photos, videos, videoOpen, videoUrl,
      selectedPhotos,
      selectedVideodisc, selectedVideoTitle, permission, modifyPermission,
      formOpen, status, floorTab, componentTab, planList, selectedComponent,
      mediaTab, progressMedia, mediaAvailability,
      transferMediaOpen,
      selectedTransferMedia, isCurrentPlan, modifyPlanOpen, constructionPlanVersion,
    } = this.state;
    verifyLogin();
    return (
      <div className='view-details'>

        {result === true ? <Loading MainProps={this.props} /> : null}

        <ToastContainer />
        <CustomModal
          open={formOpen}
          setOpen={(value) => this.setState({ formOpen: value })}
          newForm={false}
          body={this.body()}
          handleSaveData={() => {
            if (status === 'Yet to start') {
              this.setState({ showAlert: true });
            } else {
              this.handleVerify();
            }
          }}
          title='Update Customer Request Status'
        />

        <Navbar MainProps={this.props} />
        {Array.isArray(planList) && planList.length > 0 && planList[0].floorName ? (
          <StyledTabsFloor
            value={floorTab}
            onChange={
              (event, newValue) => {
                this.setState({
                  floorTab: newValue,
                  selectedComponent: planList[newValue]?.data,
                  selectedItem: planList[newValue]?.data[0]?.id,
                  componentTab: 0,
                  mediaTab: 0,
                });
                this.getData(planList[newValue]?.data[0]?.id);
                this.getProgressMedia(planList[newValue]?.data[0]?.id, 'Customer');
              }
            }
            indicatorColor='primary'
            textColor='primary'
            variant='scrollable'
            scrollButtons='auto'
          >
            {Array.isArray(planList) ? planList.map(
              (item) => (item.floorName ? (
                <StyledTabFloor
                  label={(
                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                      {item.floorName}
                      {item.status === 'Verified'
                        ? <CheckCircleIcon style={{ fontSize: 20, marginLeft: 4 }} /> : null}
                      {item.status === 'Submitted' || item.status === 'In Progress'
                        ? <CheckCircleOutlineIcon style={{ fontSize: 20, marginLeft: 4 }} /> : null}
                    </div>
                  )}
                />
              ) : null),
            ) : null}

          </StyledTabsFloor>
        ) : null}
        {Array.isArray(selectedComponent)
          && selectedComponent.length > 0
          && selectedComponent[0].propertyComponentName
          ? (
            <StyledTabsComponent
              value={componentTab}
              onChange={(event, newValue) => {
                this.setState({
                  componentTab: newValue,
                  selectedItem: selectedComponent[newValue].id,
                  mediaTab: 0,
                });
                this.getData(selectedComponent[newValue].id);
                this.getProgressMedia(selectedComponent[newValue]?.id, 'Customer');
              }}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {Array.isArray(selectedComponent) ? selectedComponent.map(
                (item) => (item.propertyComponentName ? (
                  <StyledTabComponent
                    label={(
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {item.propertyComponentName}
                        {item.status === 'Verified'
                          ? <CheckCircleIcon style={{ fontSize: 20, marginLeft: 4 }} /> : null}
                        {item.status === 'Submitted' || item.status === 'In Progress'
                          ? (
                            <CheckCircleOutlineIcon
                              style={{ fontSize: 20, marginLeft: 4 }}
                            />
                          ) : null}
                      </div>
                    )}
                  />
                ) : null),
              ) : null}

            </StyledTabsComponent>
          ) : null}

        <div className='construction-content'>

          <div className='const-plan'>

            <div className='root-container'>

              {dataLoaded === true && data && data.loanProperty
                ? (
                  <div className='form-viewer'>

                    <div className='container'>

                      <div className='login-form1'>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Loan Number :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>
                              <Link
                                underline='always'
                                className='map'
                                href={this.getViewLoanUrl(data.loan?.id)}
                              >
                                {`${data.loan.loanNumber || 'N/A'}`}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Activity Name :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.constructionPlan.activityConfigName || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Activity Weightage :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>
                              {`${shortDecimal(data.constructionPlan.weightage)}%`}
                            </p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Status :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.constructionPlan?.status || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Construction Plan Version : </p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.constructionPlan?.version}(${isCurrentPlan ? 'Current Plan' : 'Old Plan'})`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Remarks :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.constructionPlan?.remarks || 'N/A'}`}</p>
                          </div>
                        </div>

                      </div>

                      <div className='login-form1'>
                        <div className='containerLabel'>

                          <div className='innercontainerLabel'>
                            <p className='label'>Property Details  :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.loanProperty.propertyDescription || 'N/A'}`}</p>
                          </div>
                        </div>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Property Address :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.loanProperty.addressLine1} ${data.loanProperty.addressLine2} ${data.loanProperty.cityTown},  ${data.loanProperty.district}`}</p>
                          </div>
                        </div>
                        {data?.loanProperty?.lat && (
                          <div className='containerLabel'>
                            <div className='innercontainerLabel'>
                              <p className='label'>Property Location :</p>
                            </div>
                            <div
                              className='innercontainerText'
                              onClick={() => window.open(`https://www.google.com/maps/place/${data.loanProperty.lat},${data.loanProperty.lng}`, '_blank')}
                            >
                              <p className='map'>{`${data.loanProperty.lat},${data.loanProperty.lng}`}</p>
                            </div>
                          </div>
                        )}

                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Verified at :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${data.constructionPlan?.verifiedAt ? shortDate(data.constructionPlan.verifiedAt) : 'N/A'}`}</p>
                          </div>
                        </div>

                        {data.constructionPlan ? (
                          <div className='containerLabel'>
                            <div className='innercontainerLabel'>
                              <p className='label'>Geo tagging :</p>
                            </div>
                            <div className='innercontainerText'>
                              <p className='text'>{`${(data.constructionPlan.mapCircleRadius === '-1' || data.constructionPlan?.mapCircleRadius === '-1.0') ? 'Disabled' : 'Enabled'}`}</p>
                            </div>
                          </div>
                        ) : null}

                      </div>

                    </div>
                  </div>
                )
                : null}

            </div>

            <StyledTabsMedia
              value={mediaTab}
              onChange={(event, newValue) => {
                this.setState({ mediaTab: newValue });
                const filteredProgressMedia = progressMedia.filter(
                  (item) => item.submittedUserType === MEDIA_TABS[newValue],
                );
                this.setState({
                  photos: filteredProgressMedia.filter((item) => item.mediaType === 'IMAGE'),
                  videos: filteredProgressMedia.filter((item) => item.mediaType === 'VIDEO'),
                });
              }}
              indicatorColor='primary'
              textColor='primary'
              variant='scrollable'
              scrollButtons='auto'
              aria-label='scrollable auto tabs example'
            >
              {Array.isArray(MEDIA_TABS) ? MEDIA_TABS.map(
                (item) => (
                  <StyledTabMedia
                    label={(
                      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                        {item}
                        {mediaAvailability[item]
                          ? (
                            <CheckCircleOutlineIcon
                              style={{ fontSize: 20, marginLeft: 4 }}
                            />
                          ) : null}
                      </div>
                    )}
                  />
                ),
              ) : null}
            </StyledTabsMedia>

            <div className='data-header'>
              <div className='form-viewer'>
                <div className='container'>
                  <div className='login-form1'>
                    {photos.length > 0 && photos[0]?.submittedDate && (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          {photos.length > 0
                            && <p className='label'>Submitted time :</p>}
                        </div>
                        <div className='innercontainerText'>
                          {photos.length > 0
                            && (
                              <p className='text'>
                                {`${shortDate(photos[0]
                                  ?.submittedDate) || 'N/A'}`}
                              </p>
                            )}
                        </div>
                      </div>
                    )}

                  </div>
                  <div className='login-form1'>
                    {photos.length > 0 && photos[0]?.lat && (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          <p className='label'>Media Clicked Location :</p>
                        </div>
                        <div
                          className='innercontainerText'
                          onClick={() => window.open(`https://www.google.com/maps/place/${photos[0]?.lat},
                          ${photos[0].lng}`, '_blank')}
                        >
                          <p className='map'>
                            {`${photos[0]?.lat},${photos[0].lng}`}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>

                </div>
              </div>

              <div className='imageVideoTitle'>Images</div>
              <div className='divBorder' />
              {photos.length > 0 ? (
                <div className='image-video-viewer'>
                  {photos.map((element, index) => (
                    <CustomCardMedia
                      key={element.id}
                      component='img'
                      title={element.title}
                      image={element.src}
                      onClick={() => {
                        this.setState({
                          currentImage: index,
                          viewerIsOpen: true,
                          selectedPhotos: photos,
                        });
                      }}
                      disc={element.disc}
                    />
                  ))}
                </div>
              ) : <div className='nocontent'><div>No data available</div></div>}

              <div className='imageVideoTitle'>Videos</div>
              <div className='divBorder' />
              {videos.length > 0 ? (
                <div className='image-video-viewer'>
                  {videos.map((element) => (
                    <CustomCardMedia
                      key={element.id}
                      component='video'
                      title={element.title}
                      image={element.src}
                      onClick={() => {
                        this.setState({
                          videoUrl: element.src || videoAvatar,
                          videoOpen: true,
                          selectedVideodisc: element.disc,
                          selectedVideoTitle: element.title,
                        });
                      }}
                      disc={element.disc}
                    />
                  ))}

                </div>
              ) : <div className='nocontent'>No data available</div>}
            </div>
            {isCurrentPlan
              ? (
                <div style={{
                  display: 'flex', flexDirection: 'row', justifyContent: 'center',
                }}
                >
                  {modifyPermission.edit && constructionPlanVersion > 1 ? (
                    <div className='button-viewer'>
                      <button
                        type='submit'
                        className='verify'
                        onClick={
                          () => {
                            this.setState({
                              modifyPlanOpen: true,
                              selectedTransferMedia: {
                                currentPlanActName: data.constructionPlan.activityConfigName,
                                currentPlanComponent:
                                data.constructionPlan.propertyComponentFloorName
                                || data.constructionPlan.activityTypeConfigName,
                                currentPlanID: data.constructionPlan.id,
                                noOfImages: data.constructionPlan.noOfImages,
                                noOfVideos: data.constructionPlan.noOfVideos,
                                submittedUser: mediaTab,
                                loanId: data.loan?.id,
                                constructionPlanVersion,
                              },
                            });
                          }
                        }
                      >
                        Get Progress
                      </button>
                    </div>
                  ) : null}

                  {permission.edit && (photos.length > 0 || videos.length > 0)
                    ? (
                      <div className='button-viewer'>
                        <button
                          type='submit'
                          className='verify'
                          onClick={
                            () => {
                              this.setState({
                                transferMediaOpen: true,
                                selectedTransferMedia: {
                                  photos,
                                  videos,
                                  currentPlanActName: data.constructionPlan.activityConfigName,
                                  currentPlanComponent:
                                  data.constructionPlan.propertyComponentFloorName
                                  || data.constructionPlan.activityTypeConfigName,
                                  currentPlanID: data.constructionPlan.id,
                                  submittedUser: MEDIA_TABS[mediaTab],
                                  loanId: data.loan?.id,
                                  mapCircleRadius: data.constructionPlan.mapCircleRadius,
                                },
                              });
                            }
                          }
                        >
                          Transfer Media
                        </button>
                      </div>
                    )
                    : null}
                  {permission.edit
                    ? (
                      <div className='button-viewer'>
                        <button
                          type='submit'
                          className='verify'
                          onClick={() => { this.setState({ formOpen: true }); }}
                        >
                          Change Status
                        </button>
                      </div>
                    )
                    : null}
                </div>
              )
              : null}

          </div>

        </div>

        <CustomAlert
          open={showAlert}
          handleYes={() => {
            this.setState({ showAlert: false });
            this.handleVerify('Yet to start');
          }}
          title='Reject Constrution Details'
          content={data.constructionPlan?.status === 'Verified' ? 'Are you sure you want to reject this request? It is already verified.' : 'Are you sure you want to reject this request?'}
          handleClose={() => {
            this.setState({ showAlert: false });
          }}
        />
        {transferMediaOpen ? (
          <TransferMedia
            open={transferMediaOpen}
            selectedTransferMedia={selectedTransferMedia}
            handleClose={(reload) => {
              if (reload) {
                this.setState({ mediaTab: 0 });
                this.getConstructionPlan(this.state.selectedConstructionPlanIndex,
                  this.state.selectedConstructionPlanId,
                  this.state.propertyId, this.state.selectedItem,
                  this.state.constructionPlanVersion);
              }
              this.setState({ transferMediaOpen: false });
            }}
          />
        ) : null}
        {modifyPlanOpen ? (
          <ModifyPlan
            open={modifyPlanOpen}
            selectedTransferMedia={selectedTransferMedia}
            handleClose={(reload) => {
              if (reload) {
                this.setState({ mediaTab: 0 });
                this.getConstructionPlan(this.state.selectedConstructionPlanIndex,
                  this.state.selectedConstructionPlanId,
                  this.state.propertyId, this.state.selectedItem,
                  this.state.constructionPlanVersion);
              }
              this.setState({ modifyPlanOpen: false });
            }}
          />
        ) : null}
        {viewerIsOpen && selectedPhotos.length > 0 && (
          <Lightbox
            onClose={() => this.setState({ viewerIsOpen: false, currentImage: 0 })}
            images={selectedPhotos}
            allowRotate={false}
            startIndex={currentImage}
            doubleClickZoom={0}
          />
        )}
        <VideoPlayer
          url={videoUrl}
          isOpen={videoOpen}
          disc={selectedVideodisc}
          title={selectedVideoTitle}
          onClose={() => this.setState({ videoOpen: false })}
        />
      </div>
    );
  }
}

export default ConstructionDetails;
