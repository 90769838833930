import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomInput from '../../components/CustomInput/CustomInput';
import PermissionTable from './permissionTable';

import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const { classes, onClose } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['Basic Details', 'Set Role Permissions'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    position: 'relative',
    height: 'calc(100vh - 250px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

const EXTERNAL_ROLE_TYPES = [{ id: 1, name: 'External Valuer' }, { id: 2, name: 'Contractor' }];

export default function OnBoardingDialog({
  open, handleClose,
  roleID,
  roleName,
  externalUser,
  externalRole,
  roleDescription,
  handleSaveData,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [desc, setDesc] = useState(roleDescription);
  const [role, setRole] = useState(roleName);
  const [isExternalUser, setIsExternalUser] = useState(externalUser);
  const [externalRoleType, setExternalRoleType] = useState(externalRole || -1);
  const [permissions, setPermissions] = useState(roleName);
  const steps = getSteps();

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = () => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          if (role.length < 1) {
            toast.error('Role can not be empty.');
            return;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 1:
          handleSaveData(roleID, role, desc, isExternalUser, externalRoleType, permissions);
          break;
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    switch (activeStep) {
      case 0:
        return 'CONTINUE';
      case 1:
        return 'SAVE';
      default:
        return 'Next';
    }
  };
  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
    >
      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton id='plan' onClose={handleClose} />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >
            <div className={classes.slide}>
              <CustomInput
                id='role'
                label='Role'
                value={role}
                onChange={(id) => {
                  setRole(id.target.value);
                }}
              />
              <CustomInput
                id='desc'
                label='Description'
                multiline
                rows={4}
                value={desc}
                onChange={(id) => {
                  setDesc(id.target.value);
                }}
              />
              <FormControlLabel
                className={classes.checkBoxForm}
                control={(
                  <Checkbox
                    disabled={roleID}
                    checked={isExternalUser}
                    onChange={(event) => setIsExternalUser(event.target.checked)}
                    name='Is External User'
                  />
)}
                label='Is External User'
                labelPlacement='start'
              />

              {isExternalUser ? (
                <CustomSelect
                  disabled={roleID}
                  id='subscriberId'
                  title='External Role Type'
                  menuItems={EXTERNAL_ROLE_TYPES.map((element) => (
                    <option value={element.id} key={element.id}>{element.name}</option>
                  ))}
                  handleChange={(event) => {
                    setExternalRoleType(event.target.value !== '#78' ? event.target.value : -1);
                  }}
                  value={externalRoleType}
                />
              ) : null}
            </div>
            <div className={classes.slide}>
              <PermissionTable
                roleID={roleID}
                isExternalUser={isExternalUser}
                setPermissions={(perm) => setPermissions(perm)}
              />
            </div>
          </SwipeableViews>
        </div>

      </DialogContent>

      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' onClick={handleNext}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
          </div>

      )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
      )}
      />
    </Dialog>
  );
}
