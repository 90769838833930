import React, { Component } from 'react';

import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import Table from './table';
import Navbar from '../../components/navBar/navBar';
import { verifyLogin } from '../../helpers';

class LoanTasks extends Component {
  constructor() {
    super();
    this.state = {
      sectionOne: true,
      permission: {},
    };
  }

  componentDidMount() {
    const { roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const permission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Loan Tasks');
      this.setState({ permission: permission || {} });
    }
  }

  handleSectionOne(key) {
    key.preventDefault();
    const { sectionOne } = this.state;

    if (sectionOne === false) {
      this.setState({ sectionOne: true });
    } if (sectionOne === true) {
      this.setState({ sectionOne: false });
    }
  }

  render() {
    const { sectionOne, permission } = this.state;

    verifyLogin();
    return (
      <div className='view-details'>

        <Navbar MainProps={this.props} />

        <div className='contents'>

          <div className='loan-details'>

            <div className='data-header'>
              {sectionOne === true
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleSectionOne(key); }} />}
              <span>
                Loan Tasks - Pending
              </span>
            </div>

            { sectionOne && permission.view && (
            <Table MainProps={this.props} MainState={this.state} />
            )}

          </div>
        </div>

      </div>
    );
  }
}

export default LoanTasks;
