import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class ComponentActivitiesConfigForm extends Component {
  render() {
    const {
      classes, componentId,
      activityId, isApplicable,
      setComponentId,
      setActivityId, setApplicable,
      componentConfig,
      activityConfig,
    } = this.props;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='componentId'
          title='Component Name'
          menuItems={componentConfig.map((element) => (
            <option value={element.id} key={element.id}>{element.componentName}</option>
          ))}
          handleChange={(event) => {
            setComponentId(event);
          }}
          value={componentId}
        />
        <CustomSelect
          id='activityId'
          title='Activity Name'
          menuItems={activityConfig.filter((item) => item.activityTypeId === 3).map((element) => (
            <option value={element.id} key={element.id}>{element.activityName}</option>
          ))}
          handleChange={(event) => {
            setActivityId(event);
          }}
          value={activityId}
        />
        <FormControlLabel
          control={(
            <Checkbox
              checked={isApplicable === 'TRUE'}
              onChange={(event) => {
                setApplicable(event);
              }}
              name='Applicable'
            />
)}
          label='Applicable'
          labelPlacement='start'
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ComponentActivitiesConfigForm);
