import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
import Button from '@material-ui/core/Button';
import CircularProgress from '@material-ui/core/CircularProgress';
import CustomInput from '../../components/CustomInput/CustomInput';
import {
  apiList,
  verifyLogin,
} from '../../helpers';

const useStyles = makeStyles({
  root: {
    flex: 1,
    borderLeft: '2px solid #eee',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    display: 'flex',
    margin: 40,
  },
  leftDiv: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  rightDiv: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginTop: '8px',
  },
  itemSelected: {
    backgroundColor: '#3D4977',
    marginTop: '8px',
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
  button: {
    margin: 16,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
});

export default function ChangePassword() {
  const classes = useStyles();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [reNewPassword, setReNewPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const { session, user } = verifyLogin();
  const config = { headers: { session } };

  const changePassword = () => {
    if (oldPassword.length < 1 || newPassword.length < 1 || oldPassword.reNewPassword < 1) {
      toast.error('Password can not be empty.');
      return;
    }
    if (newPassword !== reNewPassword) {
      toast.error('New Password does not match with Re-entered new password.');
      return;
    }
    setLoading(true);
    axios.post(`${apiList.CHANGE_PASSWORD}`, {
      id: user.id,
      password: oldPassword,
      newPassword,
    }, config)
      .then((response) => {
        setLoading(false);
        if (response) {
          toast.success('Password Changed successfully.');
          setOldPassword('');
          setNewPassword('');
          setReNewPassword('');
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        setLoading(false);
      });
  };

  return (
    <div className={classes.root}>
      <div className={classes.main}>
        <div className={classes.leftDiv}>
          <CustomInput
            disabled={loading}
            id='oldPassword'
            label='Old Password'
            value={oldPassword}
            type='password'
            onChange={(id) => {
              setOldPassword(id.target.value);
            }}
          />
          <CustomInput
            disabled={loading}
            id='newPassword'
            label='New Password'
            type='password'
            value={newPassword}
            onChange={(id) => {
              setNewPassword(id.target.value);
            }}
          />
          <CustomInput
            disabled={loading}
            id='reNewPassword'
            label='New Password'
            type='password'
            value={reNewPassword}
            onChange={(id) => {
              setReNewPassword(id.target.value);
            }}
          />
          <div className={classes.wrapper}>
            <Button
              disabled={loading}
              className={classes.button}
              variant='outlined'
              color='primary'
              onClick={() => { changePassword(); }}
            >
              Change Password
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>

        </div>
      </div>

    </div>
  );
}
