/* eslint-disable no-unused-expressions */
/* eslint-disable react/no-access-state-in-setstate */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable import/order */

import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';
import Link from '@material-ui/core/Link';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import {
  encrypt,
  apiList,
  addCommas,
  shortDate,
  shortData,
  verifyLogin,
} from '../../helpers';
import Loading from '../../components/loading/loading';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';

const columns = [
  {
    id: 'Id', label: 'Id', fieldName: 'id',
  },
  {
    id: 'name', label: 'Customer Name', fieldName: 'custName',
  },
  {
    id: 'branchName', label: 'Branch Code', fieldName: 'branchName',
  },
  {
    id: 'LoanNumber',
    label: 'Loan Number',
    fieldName: 'loanNumber',
  },
  {
    id: 'SanctionedAmount',
    label: 'Sanctioned Amount',
    fieldName: 'sanctionedAmount',
  },
  {
    id: 'SanctionedDate',
    label: 'Sanctioned Date',
    fieldName: 'sanctionedDate',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    alignSelf: 'center',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 20,
  },
  innerContainer: {
    alignItems: 'flex-end',
    display: 'flex',
    flexDirection: 'column',
    padding: 20,
  },
  inputContainer: {
    width: 300,
    marginRight: 30,
    display: 'flex',
    alignItems: 'right',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class table extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 10,
      result: false,
      dataLoaded: false,
      pageCount: 0,
      data: [],
      searchQuery: '',
    };
  }

  componentDidMount() {
    this.getCustomer(this.state.page, this.state.rowsPerPage, this.state.searchQuery);
  }

  getViewLoanUrl(viewId) {
    const detailId = encrypt(viewId);
    return `/view-loan-details/${detailId}`;
  }

  handleChangePage = (event, newPage) => {
    this.getCustomer(newPage, this.state.rowsPerPage, this.state.searchQuery);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getCustomer(1, +event.target.value, this.state.searchQuery);
  };

  handleChange = (event) => {
    this.setState({ searchQuery: event.target.value });
    this.getCustomer(this.state.page, this.state.rowsPerPage, event.target.value);
  };

  getLoans() {
    this.setState({ rowsPerPage: 10, page: 1, data: [] });
    this.getCustomer(1, 10, '');
  }

  getCustomer(page, rowsPerPage, searchQuery) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };

    axios.get(`${apiList.VEIW_CUSTOMERS_API}?searchQuery=${searchQuery}&page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        this.setState({
          data: [...response.data.data.paginate],
          dataLoaded: true,
          pageCount: Math.ceil(response.data.data.pageCount),
        });
        setTimeout(() => {
          this.setState({ result: false });
        }, 1500);
      })
      .catch((error) => {
        console.log(error);
        this.setState({
          data: [],
          dataLoaded: true,
          pageCount: 0,
        });
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1500);
      });
  }

  render() {
    const {
      classes, handleShowEditForm, handleDeleteData, permission,
    } = this.props;
    const {
      result,
      data,
      dataLoaded,
      page,
      pageCount,
      searchQuery,
    } = this.state;

    return (
      <div className={classes.mainContainer}>

        <ToastContainer />

        {result === true && !dataLoaded ? <Loading MainProps={this.props.MainProps} /> : null }
        <div className={classes.innerContainer}>
          <div className={classes.inputContainer}>
            <CustomInput
              topMargin
              btargin
              onChange={this.handleChange}
              value={searchQuery}
              id='users'
              label='Search Loans'
            />
          </div>

          <CustomTable
            showPagination
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (

              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell> {(page - 1) * 10 + index + 1}</TableCell>
                <TableCell>
                  <Link
                    underline='always'
                    href={this.getViewLoanUrl(element.id)}
                  >{shortData(element.custName)}
                  </Link>
                </TableCell>
                <TableCell> {element?.branch || element?.AccessLevelConfig?.branchName || 'N/A'}</TableCell>
                <TableCell> {shortData(element.loanNumber)}</TableCell>
                <TableCell> {addCommas(element.sanctionedAmount)}</TableCell>
                <TableCell> {shortDate(element.sanctionedDate)}</TableCell>
                <TableCell>

                  <Button
                    color='primary'
                    justIcon
                    simple
                    disabled={!permission.edit}
                    round
                    onClick={() => { handleShowEditForm && handleShowEditForm(element); }}
                    className={classes.buttonLink}
                  >
                    <EditIcon />
                  </Button>

                  <Button
                    color='danger'
                    justIcon
                    simple
                    disabled={!permission.delete}
                    round
                    onClick={() => { handleDeleteData && handleDeleteData(element.id); }}
                    className={classes.buttonLink}
                  >
                    <DeleteIcon />
                  </Button>

                </TableCell>
              </TableRow>

            )) : null}
            count={pageCount}
            rowsPerPage={0}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(table);
