import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';

import {
  ListItem, ListItemText,
} from '@material-ui/core';

import {
  apiList,
} from '../../../helpers';
import { groupDataByactivityTypeAndName } from '../../../helpers/utils';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexDirection: 'row',
    flex: 1,
    justifyContent: 'space-around',
  },
  subRoot: {
    backgroundColor: '#EEEEEE',
    width: '30%',
    padding: 20,
  },
  subRoot2: {
    backgroundColor: '#EEEEEE',
    width: '50%',
    padding: 20,
  },
  item: {
    backgroundColor: '#fff',
    height: 35,
    marginBottom: 8,
  },
  itemSelected: {
    backgroundColor: '#00838F',
    '&:hover': {
      background: '#00838F',
    },
    marginBottom: 8,
    height: 35,
  },
  activity: {
    marginBottom: 8,
    height: 35,
  },
  activitySelected: {
    border: '1px solid #00838F',
    marginBottom: 8,
    height: 35,
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
});

export default function Plans({
  plan, setPlan, loanId, currentPlanID,
}) {
  const classes = useStyles();
  const [planList, setPlanList] = useState([]);
  const [selectedActivity, setSelectedActivity] = useState();

  const getPlans = () => {
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${loanId}/null`)
      .then((response) => {
        if (response?.data?.result) {
          const { constructionPlan } = response.data.result;
          const filteredList = constructionPlan.filter((item) => item.id !== currentPlanID);
          const groupedPlanList = groupDataByactivityTypeAndName(filteredList);
          setPlanList(groupedPlanList);
          setSelectedActivity(groupedPlanList[0]);
          setPlan(groupedPlanList[0].data[0]);
        }
      })
      .catch(() => {
      });
  };

  useEffect(() => {
    getPlans();
  }, []);

  return (
    <div className={classes.root}>
      <div className={classes.subRoot}>
        {planList.map((element, key) => (
          <ListItem
            onClick={() => {
              setSelectedActivity(element);
              setPlan(element.data[0]);
            }}
            key={key}
            button
            className={selectedActivity?.activityConfigName === element.activityConfigName
              ? classes.activitySelected : classes.activity}
          >
            <ListItemText
              primary={element.activityConfigName}
              className={classes.text}
              disableTypography
            />
          </ListItem>
        ))}
      </div>
      <div className={classes.subRoot2}>
        {selectedActivity ? selectedActivity.data.map((element, key) => (
          <ListItem
            onClick={() => setPlan(element)}
            key={key}
            button
            className={plan?.id === element.id ? classes.itemSelected : classes.item}
          >
            <ListItemText
              primary={element.propertyComponentFloorName
                ? element.propertyComponentFloorName : `${element.activityConfigName} - ${element.activityTypeConfigName}`}
              className={plan?.id === element.id ? classes.textSelected : classes.text}
              disableTypography
            />
          </ListItem>
        )) : null}
      </div>
    </div>
  );
}
