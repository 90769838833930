import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { makeStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = makeStyles(() => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function FormProgressMedia({
  activityType,
  activityName,
  mediaType,
  title,
  instruction,
  handleChange,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>

      <CustomInput
        disabled
        id='activityType'
        label='Activity Type'
        value={activityType}
      />
      <CustomInput
        disabled
        id='activityName'
        label='Activity Name'
        value={activityName}
      />
      <CustomInput
        disabled
        id='mediaType'
        label='Media Type'
        value={mediaType}
      />
      <CustomInput
        id='title'
        label='Title'
        value={title}
        onChange={(id) => handleChange(id)}
      />
      <CustomInput
        id='instruction'
        label='Instructions'
        value={instruction}
        multiline
        rows={4}
        onChange={(id) => handleChange(id)}
      />

    </div>
  );
}
