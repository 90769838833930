import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CustomCardMediaMultiSelect from '../../../components/CustomCardMediaMultiSelect/CustomCardMediaMultiSelect';

const useStyles = makeStyles({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
});

export default function PhotoGrid({
  photos, component, currentImages,
  setCurrentImages, enableSelect,
}) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {photos.map((element) => (
        <CustomCardMediaMultiSelect
          key={element.id}
          component={component}
          isSelected={!!currentImages.find(
            (item) => item.id === element.id,
          )}
          image={element.url}
          onClick={() => {
            if (enableSelect) {
              const data = [...currentImages];
              const selected = currentImages.find(
                (item) => item.id === element.id,
              );
              if (!selected) {
                data.splice(0, 0, element);
                data.pop();
                setCurrentImages(data);
              }
            }
          }}
        />
      ))}
    </div>
  );
}
