const verifyUser = (userRoleId) => {
  try {
    if (userRoleId !== 1 && userRoleId !== 2) {
      window.location.replace('/customers-accounts');
      return null;
    }

    return userRoleId;
  } catch (error) {
    window.location.replace('/customers-accounts');
    return error.toString();
  }
};

const verifyLogin = () => {
  try {
    const data = localStorage.getItem('data');
    if (data) {
      const unstringfyData = JSON.parse(data);
      const { user } = unstringfyData.data;
      const { session } = unstringfyData.data;
      const { roleMapping } = unstringfyData.data;
      return { user, session, roleMapping };
    }

    window.location.replace('/');
    return null;
  } catch (error) {
    window.location.replace('/');
    return error.toString();
  }
};

const encrypt = (data) => {
  try {
    return btoa(data);
  } catch (error) {
    // better to have not found page
    console.log(error);
    // window.location.replace('/');
    return error.toString();
  }
};

const dencrypt = (data) => {
  try {
    return atob(data);
  } catch (error) {
    // better to have not found page
    window.location.replace('/');
    return error.toString();
  }
};

export {
  verifyUser,
  verifyLogin,
  encrypt,
  dencrypt,
};
