import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '../CustomButtons/Button';
import styles from '../../assets/jss/material-dashboard-react/components/headerStyle';
import ToolBarItems from './ToolBarItems';

const useStyles = makeStyles(styles);

export default function Header(props) {
  const classes = useStyles();
  function makeBrand() {
    let name;
    props.routes.map((prop) => {
      if (window.location.href.indexOf(prop.layout + prop.path) !== -1) {
        name = props.rtlActive ? prop.rtlName : prop.name;
      }
      return null;
    });
    return name;
  }
  const { open } = props;

  return (
    <AppBar
      className={[classes.appBar, {
        [classes.appBarShift]: open,
      }]}
    >
      <Toolbar className={classes.container}>
        <div className={classes.flex}>
          <Button color='transparent' href='#' className={classes.title}>
            {makeBrand()}
          </Button>
        </div>
        <ToolBarItems />
      </Toolbar>
    </AppBar>
  );
}
