/* eslint-disable eqeqeq */
import React from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { makeStyles } from '@material-ui/core/styles';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = makeStyles(() => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
}));

export default function Form({
  setActivityType, activityType, activityTypes,
  activityName,
  weightage,
  noOfImages,
  noOfVideos,
  handleChange,
  newForm,
  setActivityName,
  activitylevel,
  activityNameContainer,
  isPaymentMS,
  setisPaymentMS,
}) {
  const classes = useStyles();
  return (
    <div className={classes.root}>

      <CustomSelect
        disabled={!newForm}
        id='activityType'
        title='Activity Type'
        menuItems={activityTypes.paginate.map((element) => (
          <option value={element.id} key={element.id}>{element.activityType}</option>
        ))}
        handleChange={(event) => {
          setActivityType(event);
        }}
        value={activityType}
      />
      {activityNameContainer.length > 0 && (
        <CustomSelect
          id='activityName'
          title='Activity Name'
          menuItems={activityNameContainer.map((element) => (
            <option value={element.id} key={element.id}>{element.activityName}</option>
          ))}
          handleChange={(event) => {
            setActivityName(event);
          }}
          value={activityName}
        />
      )}
      {activitylevel && (
        <CustomInput
          id='activitylevel'
          label='Activity Level'
          InputProps={{
            readOnly: true,
          }}
          value={activitylevel}
        />
      )}
      <CustomInput
        id='weightage'
        label='Weightage'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={weightage}
        onChange={(id) => {
          if (id.target.value == '' || (id.target.value > 0 && id.target.value < 101)) {
            handleChange(id);
          }
        }}
      />
      <CustomInput
        id='noOfImages'
        label='No of Images(Max 10)'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={noOfImages}
        onChange={(id) => {
          if (id.target.value == '' || (id.target.value > -1 && id.target.value < 11)) {
            handleChange(id);
          }
        }}
      />
      <CustomInput
        id='noOfVideos'
        label='No of Videos(Max 2)'
        type='number'
        InputLabelProps={{
          shrink: true,
        }}
        value={noOfVideos}
        onChange={(id) => {
          if (id.target.value == '' || id.target.value == 0 || id.target.value == 1 || id.target.value == 2) {
            handleChange(id);
          }
        }}
      />
      <FormControlLabel
        control={(
          <Checkbox
            checked={isPaymentMS === 'True'}
            onChange={(event) => {
              setisPaymentMS(event);
            }}
            name='Applicable'
          />
)}
        label='Is Payment MS'
        labelPlacement='start'
      />

    </div>
  );
}
