import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const useStyles = makeStyles({
  root: {
    width: 220,
    backgroundColor: '#fff',
    height: 'calc(100vh - 60px)',
    display: 'flex',
    flexDirection: 'column',
  },
  item: {
    marginTop: '8px',
  },
  itemSelected: {
    backgroundColor: '#00838F',
    '&:hover': {
      background: '#00838F',
    },
    marginTop: '8px',
  },
  text: {
    fontSize: 14,
  },
  textSelected: {
    fontSize: 14,
    color: '#fff',
  },
  title: {
    fontSize: 16,
    color: '#00838F',
    padding: 8,
  },
  list: {
    marginLeft: 12,
    marginRight: 12,
  },
});

export default function LeftMenu({ items, selectedItem, setSelectedItem }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.title}>
        Profile
      </div>
      <div className={classes.list}>
        {items.map((prop, key) => (
          <ListItem
            onClick={() => setSelectedItem(key)}
            key={key}
            button
            className={selectedItem === key ? classes.itemSelected : classes.item}
          >
            <ListItemText
              primary={prop.name}
              className={selectedItem === key ? classes.textSelected : classes.text}
              disableTypography
            />
          </ListItem>
        ))}
      </div>
    </div>
  );
}
