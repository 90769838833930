import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import {
  TableRow,
} from '@material-ui/core';

import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';

import { toast } from 'react-toastify';
import axios from 'axios';
import {
  verifyLogin,
  apiList,
  shortDate,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';

const columns = [
  // {
  //   id: 'Id', label: 'Id',
  // },
  {
    id: 'taskName', label: 'Task Name',
  },
  {
    id: 'taskOwner', label: 'Task Owner',
  },
  {
    id: 'plannedDate',
    label: 'Planned Date',
  },
  {
    id: 'completed', label: 'Completed',
  },
  {
    id: 'completedDate', label: 'Completed Date',
  },
  {
    id: 'Action', label: 'Action',
  },
];

const useStyles = (theme) => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 40,
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 16,
    height: 16,
    margin: 0,
    padding: 0,
  },
  btnholder: {
    width: '95%',
    flexDirection: 'row-reverse',
    display: 'flex',
  },
  icon: {
    color: '#3D4977',
    cursor: 'pointer',
  },
  toolbar: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  title: {
    flex: '1 1 100%',
  },
});

class TaskTable extends Component {
  constructor() {
    super();
    this.state = {
      page: 1,
      rowsPerPage: 5,
      pageCount: 0,
      data: [],
    };
  }

  componentDidMount() {
    this.getTaskConfig(this.state.page, this.state.rowsPerPage);
  }

  handleChangePage = (event, newPage) => {
    this.getTaskConfig(newPage, this.state.rowsPerPage);
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 1 });
    this.getTaskConfig(1, +event.target.value);
  };

  getTasks() {
    this.setState({ rowsPerPage: 5, page: 1 });
    this.getTaskConfig(1, 5);
  }

  getTaskConfig(page, rowsPerPage) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'view' } };

    axios.get(`${apiList.GET_LOAN_TASKS}?loanId=${this.props.loanId}&page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        const data = [...response.data.data.paginate];
        this.setState({
          data,
          pageCount: Math.ceil(response.data.data.pageCount),
        });
      })
      .catch((e) => {
        console.log('disburment error', e);
        this.setState({
          data: [],
          pageCount: 0,
        });
      });
  }

  deleteLoanTask(key, id) {
    key.preventDefault();
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'delete' } };
      axios.post(`${apiList.DELETE_LOAN_TASK}`, { id }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getTaskConfig(1, 5);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  }

  render() {
    const { classes } = this.props;

    const {
      handleEditData,
      loanTaskPermission,
    } = this.props;

    const {
      pageCount,
      page,
      // rowsPerPage,
      data,
    } = this.state;

    return (
      <div className={classes.mainContainer}>
        {/* <Paper className={classes.paper}> */}
        <>
          <CustomTable
            showPagination
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                {/* <TableCell>
                  {(page - 1) * rowsPerPage + index + 1}
                </TableCell> */}
                <TableCell>
                  {element.LoanTaskConfig?.name}
                </TableCell>
                <TableCell>
                  {`${element.User?.firstName || ''} ${element.User?.lastName || ''}`}
                </TableCell>
                <TableCell>
                  {shortDate(element.plannedDate)}
                </TableCell>
                <TableCell>
                  {element.isCompeted}
                </TableCell>
                <TableCell>
                  {shortDate(element.completedDate)}
                </TableCell>
                <TableCell>
                  {element.isCompeted && element.isCompeted === 'false' ? (
                    <Button
                      color='primary'
                      justIcon
                      simple
                      disabled={!loanTaskPermission.edit}
                      round
                      onClick={(key) => { handleEditData(key, element); }}
                      className={classes.buttonLink}
                    >
                      <EditIcon className={classes.icons} />
                    </Button>
                  ) : null}

                  {element.isCompeted && element.isCompeted === 'false' ? (
                    <Button
                      color='danger'
                      justIcon
                      simple
                      disabled={!loanTaskPermission.delete}
                      round
                      onClick={(key) => { this.deleteLoanTask(key, element.id); }}
                      className={classes.buttonLink}
                    >
                      <DeleteIcon className={classes.icons} />
                    </Button>
                  ) : null}

                </TableCell>
              </TableRow>
            )) : null}
            count={pageCount}
            rowsPerPage={0}
            page={page}
            handleChangePage={this.handleChangePage}
            handleChangeRowsPerPage={this.handleChangeRowsPerPage}
          />
        </>
        {/* </Paper> */}
      </div>
    );
  }
}

export default withStyles(useStyles)(TaskTable);
