/* eslint-disable no-unused-expressions */
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {
  shortData,
  shortDate,
} from '../../helpers';
import CustomTable from '../../components/CustomTable/CustomTable';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'productName', label: 'product Name',
  },
  {
    id: 'Description', label: 'Description',
  },
  {
    id: 'CreatedDate',
    label: 'Created Date',
  },
  {
    id: 'UpdatedDate',
    label: 'Updated Date',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
    display: 'flex',
    overflowX: 'auto',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class table extends Component {
  render() {
    const {
      classes, handleShowEditForm, handleDeleteData, data,
      handleChangePage, handleChangeRowsPerPage, handleShowAddForm,
      //  handleViewLoanDetails,
    } = this.props;

    return (
      <div className={classes.mainContainer}>
        <CustomTable
          icons={[{ icon: AddCircleOutlineRoundedIcon, name: 'Add Product' }]}
          tableName='User Products'
          onIconClick={(key) => {
            handleShowAddForm && handleShowAddForm(key);
          }}
          tableHead={columns}
          itemComponent={data.length > 0 ? data.map((element, index) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
              // onClick={(key) => { handleViewLoanDetails(key, element.id); }}
            >
              <TableCell>
                {index + 1}
              </TableCell>
              <TableCell>
                {shortData(element.productName || '')}
              </TableCell>
              <TableCell>
                {shortData(element.description || '')}
              </TableCell>
              <TableCell>
                {shortDate(element.createdAt || '')}
              </TableCell>
              <TableCell>
                {shortDate(element.updatedAt || '')}
              </TableCell>
              <TableCell>

                <Button
                  color='primary'
                  justIcon
                  simple
                  round
                  onClick={(key) => { handleShowEditForm && handleShowEditForm(key, element); }}
                  className={classes.buttonLink}
                >
                  <EditIcon className={classes.icons} />
                </Button>

                <Button
                  color='danger'
                  justIcon
                  simple
                  round
                  onClick={(key) => { handleDeleteData && handleDeleteData(key, element.id); }}
                  className={classes.buttonLink}
                >
                  <DeleteIcon className={classes.icons} />
                </Button>

              </TableCell>
            </TableRow>
          )) : null}
          count={1}
          rowsPerPage={0}
          page={1}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(table);
