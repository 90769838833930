import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { ThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import NetworkDetector from '../components/NetworkDetector/NetworkDetector';
import Routes from './Routes';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#3D4977', // This is an orange looking color
    },
    secondary: {
      main: '#ffcc80', // Another orange-ish color
    },
  },
});
const App = () => (
  <ThemeProvider theme={theme}>
    <Router>
      <Routes />
    </Router>
  </ThemeProvider>
);

export default NetworkDetector(App);
