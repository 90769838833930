import axios from 'axios';
import React, { Component } from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { browserName, browserVersion } from 'react-device-detect';
import Loading from '../../components/loading/loading';

import {
  validateLogin, apiList,
  validateEmail,
  validatePassword,
} from '../../helpers';
import { version } from '../../../package.json';
import { getUID } from '../../helpers/utils';

const Logo = require('../../assets/images/logo_long2.png').default;
const Background = require('../../assets/images/homeloan.png').default;

class Login extends Component {
  constructor() {
    super();
    this.state = {
      loading: false,
      error: null,
      success: null,
      email: '',
      password: '',
      emailClass: '',
      passwordClass: '',
      forgotPassword: false,
      resetPassword: false,
      ipAddress: '',
    };
  }

  componentDidMount() {
    this.getIp();
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({
      error: null,
      success: null,
      emailClass: '',
      passwordClass: '',
      [key.target.id]: key.target.value,
    });
  }

  async handleLogin(key) {
    key.preventDefault();
    const {
      error, emailClass, passwordClass,
    } = validateLogin(this.state);

    if (error) {
      this.setState({
        error, emailClass, passwordClass,
      });
      return;
    }

    this.setState({ loading: true });

    const { email, password, ipAddress } = this.state;
    axios.post(apiList.LOGIN_API, {
      email,
      password,
      appVersion: version,
      deviceId: getUID(),
      ipAddress,
      osVersion: browserVersion,
      deviceName: browserName,
    })
      .then((response) => {
        localStorage.setItem('data', JSON.stringify(response.data));
        window.location.href = '/customers-accounts';
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err?.response?.data?.error || err?.response?.data?.message || 'Something went wrong. Please try again.',
        });
      });
  }

  getIp() {
    const that = this;
    try {
      fetch('https://api.ipify.org?format=json').then((res) => res.json()).then((data) => {
        if (data && data.ip) {
          that.setState({ ipAddress: data.ip });
        }
      }).catch((error) => console.log(error));
    } catch (e) {
      console.log(e);
    }
  }

  sendOtp = () => {
    const { forgotEmail } = this.state;
    const {
      error, emailClass,
    } = validateEmail(forgotEmail);

    if (error) {
      this.setState({
        error, emailClass,
      });
      return;
    }

    this.setState({ loading: true });
    axios.post(`${apiList.FORGOT_PASSWORD}`, {
      email: forgotEmail,
    })
      .then((response) => {
        this.setState({ loading: false, resetPassword: true, forgotPassword: false });
        if (response) {
          this.setState({ success: 'Homehub sent you an otp. Please check your email.' });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err?.response?.data?.error || err?.response?.data?.message || 'Something went wrong. Please try again.',
        });
      });
  };

  resetPassword = () => {
    const {
      forgotEmail, otp, rePassword, rerePassword,
    } = this.state;
    if (otp.length < 6) {
      this.setState({ error: 'OTP must be 6 digit long.' });
      return;
    }
    const {
      error, passwordClass,
    } = validatePassword(rePassword);
    if (error) {
      this.setState({
        error, passwordClass,
      });
      return;
    }
    if (rePassword !== rerePassword) {
      this.setState({
        error: 'Password should be same.', passwordClass,
      });
      return;
    }

    this.setState({ loading: true });
    axios.post(`${apiList.RESET_PASSWORD}`, {
      email: forgotEmail,
      otp,
      password: rePassword,
    })
      .then((response) => {
        this.setState({
          loading: false,
          resetPassword: false,
          forgotEmail: '',
          otp: '',
          rePassword: '',
        });
        if (response) {
          this.setState({ success: 'Password has been updated successfully.' });
        }
      })
      .catch((err) => {
        this.setState({
          loading: false,
          error: err?.response?.data?.error || err?.response?.data?.message || 'Something went wrong. Please try again.',
        });
      });
  };

  render() {
    const {
      email,
      password,
      emailClass,
      passwordClass,
      forgotPassword,
      resetPassword,
      forgotEmail,
      otp,
      rePassword,
      rerePassword,
      error,
      success,
      loading,
    } = this.state;
    return (
      <div className='login-component'>

        <div className='login-box'>

          {!forgotPassword && !resetPassword ? (
            <div className='right-container'>

              <div className='login-container'>
                <div className='header-logo'>
                  <img className='logo' src={Logo} alt='logo' />
                </div>
                <div className='login-header'>
                  <h3>Sign In</h3>
                </div>

                <form className='login-form'>
                  {error ? (
                    <div className='form-error'>
                      {error}
                    </div>
                  ) : null}
                  {success ? (
                    <div className='form-success'>
                      {success}
                    </div>
                  ) : null}
                  <input
                    id='email'
                    value={email}
                    type='email'
                    placeholder='email'
                    className={emailClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  <input
                    id='password'
                    value={password}
                    type='password'
                    placeholder='Password'
                    className={passwordClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  {loading ? (
                    <Loading MainProps={this.props} Case />
                  ) : null}

                  <button
                    type='button'
                    onClick={(key) => { this.handleLogin(key); }}
                  >
                    Sign in
                  </button>

                  <div
                    className='login-forget'
                    onClick={() => { this.setState({ forgotPassword: true }); }}
                  >
                    Forgot password?
                  </div>

                </form>

              </div>

            </div>
          ) : null}

          {forgotPassword ? (
            <div className='right-container'>

              <div className='login-container'>
                <div className='header-logo'>
                  <CloseIcon
                    className='close-icon'
                    onClick={() => this.setState({
                      forgotPassword: false,
                      result: '',
                      resetPassword: false,
                      success: null,
                      error: null,
                      forgotEmail: '',
                      otp: '',
                      rePassword: '',
                    })}
                  />
                </div>
                <div className='login-header'>
                  <h3>Forgot Password</h3>
                </div>

                <form className='login-form'>
                  {error ? (
                    <div className='form-error'>
                      {error}
                    </div>
                  ) : null}

                  {success ? (
                    <div className='form-success'>
                      {success}
                    </div>
                  ) : null}
                  <input
                    id='forgotEmail'
                    value={forgotEmail}
                    type='email'
                    placeholder='Email'
                    className={emailClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  {loading ? (
                    <Loading MainProps={this.props} Case />
                  ) : null}

                  <button
                    type='button'
                    onClick={(key) => { this.sendOtp(key); }}
                  >
                    Send OTP
                  </button>
                </form>

              </div>

            </div>
          ) : null}

          {resetPassword ? (
            <div className='right-container'>

              <div className='login-container'>
                <div className='header-logo'>
                  <CloseIcon
                    className='close-icon'
                    onClick={() => this.setState({
                      forgotPassword: false,
                      result: '',
                      success: null,
                      error: null,
                      resetPassword: false,
                      forgotEmail: '',
                      otp: '',
                      rePassword: '',
                      rerePassword: '',
                    })}
                  />

                </div>
                <div className='login-header'>
                  <h3>Reset Password</h3>
                </div>

                <form className='login-form'>
                  {error ? (
                    <div className='form-error'>
                      {error}
                    </div>
                  ) : null}

                  {success ? (
                    <div className='form-success'>
                      {success}
                    </div>
                  ) : null}
                  <input
                    id='otp'
                    value={otp}
                    type='password'
                    placeholder='OTP'
                    className={emailClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  <input
                    id='rePassword'
                    value={rePassword}
                    type='password'
                    placeholder='New Password'
                    className={passwordClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  <input
                    id='rerePassword'
                    value={rerePassword}
                    type='password'
                    placeholder='Confirm New Password'
                    className={passwordClass}
                    onChange={(id) => this.handleChange(id)}
                  />

                  {loading ? (
                    <Loading MainProps={this.props} Case />
                  ) : null}

                  <button
                    type='button'
                    onClick={(key) => { this.resetPassword(key); }}
                  >
                    Reset Password
                  </button>

                </form>

              </div>

            </div>
          ) : null}

          <img className='left-container' src={Background} alt='Background' />

        </div>
      </div>
    );
  }
}

export default Login;
