import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {
  shortData,
} from '../../helpers';
import CustomTable from '../../components/CustomTable/CustomTable';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'ActivityTypeID', label: 'Activity Type ID',
  },
  {
    id: 'ActivityType', label: 'Activity Type',
  },
  {
    id: 'ActivityName', label: 'Activity Name',
  },
  {
    id: 'Level', label: 'Level',
  },
  {
    id: 'Title', label: 'Title',
  },
  {
    id: 'Instruction', label: 'Instruction',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
    display: 'flex',
    overflowX: 'auto',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  input: {
    width: 300,
    marginRight: 30,
    display: 'flex',
    alignItems: 'end',
  },
});

class table extends Component {
  render() {
    const {
      classes, handleShowEditForm, handleDeleteData, data,
      handleChangePage, handleChangeRowsPerPage, handleShowAddForm,
    } = this.props;

    return (
      <>
        <div className={classes.mainContainer}>
          <CustomTable
            icons={[{ icon: AddCircleOutlineRoundedIcon, name: 'Add Config' }]}
            tableName='Activity Config'
            onIconClick={(key) => {
              handleShowAddForm && handleShowAddForm(key);
            }}
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {element.activityTypeId}
                </TableCell>
                <TableCell>
                  {shortData(element.ActivityTypeConfig?.activityType || '')}
                </TableCell>
                <TableCell>
                  {shortData(element.activityName || '')}
                </TableCell>
                <TableCell>
                  {shortData(element.activitylevel || '')}
                </TableCell>
                <TableCell>
                  {element.title || ''}
                </TableCell>
                <TableCell style={{ width: 200 }}>
                  {element.instruction || ''}
                </TableCell>
                <TableCell>

                  <Button
                    color='primary'
                    justIcon
                    simple
                    round
                    onClick={(key) => { handleShowEditForm && handleShowEditForm(key, element); }}
                    className={classes.buttonLink}
                  >
                    <EditIcon className={classes.icons} />
                  </Button>

                  <Button
                    color='danger'
                    justIcon
                    simple
                    round
                    onClick={(key) => { handleDeleteData && handleDeleteData(key, element.id); }}
                    className={classes.buttonLink}
                  >
                    <DeleteIcon className={classes.icons} />
                  </Button>

                </TableCell>
              </TableRow>
            )) : null}
            count={1}
            rowsPerPage={0}
            page={1}
            handleChangePage={handleChangePage && handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage && handleChangeRowsPerPage}
          />
        </div>
      </>

    );
  }
}

export default withStyles(useStyles)(table);
