import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

class ReportFormat extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
      name: '',
      description: '',
      configID: '',
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
      reportTypeId: -1,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.getFormats(Number(subscriberId));
    }
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        reportTypeId: element.reportTypeId,
        name: element.name,
        description: element.description,
        configID: element.id,
      });
    } else {
      this.setState({
        reportTypeId: -1,
        name: '',
        description: '',
        configID: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      name,
      description,
      configID,
      subscriberId,
      reportTypeId,
    } = this.state;

    if (reportTypeId === -1) {
      toast.error('Select Report Type');
      return;
    }
    if (name.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      reportTypeId, name: name.trim(), description: description.trim(), subscriberId,
    };
    if (configID) {
      params.id = configID;
    }
    axios.post(`${apiList.UPDATE_REPORT_FORMAT_ADMIN}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          name: '',
          description: '',
          configID: '',
          reportTypeId: -1,
        });
        this.toggleModal(false);
        this.getFormats(subscriberId);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  getFormats(subscriberId) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_REPORT_FORMAT_ADMIN}?subscriberId=${subscriberId}`, config)
      .then((response) => {
        this.setState({ data: [...response.data.data] });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ data: [] });
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body = () => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      setReportType={(reportTypeId) => this.setState({ reportTypeId })}
      name={this.state.name}
      description={this.state.description}
      reportTypeId={this.state.reportTypeId}
      configID={this.state.configID}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      data,
      page,
      rowsPerPage,
      formOpen,
      newForm,
      userRoleId,
      subscribers,
      subscriberId,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null}

        {userRoleId === 1 ? (
          <div className='select-fields'>
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                if (event.target.value !== '#78') {
                  this.setState({ subscriberId: event.target.value });
                  this.getFormats(event.target.value);
                } else {
                  this.setState({ subscriberId: -1, data: [] });
                }
              }}
              value={subscriberId}
            />
          </div>
        ) : null}

        {result === false && (
          <Table
            data={Array.isArray(data) ? data : []}
            handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
            handleShowAddForm={(key) => this.handleShowAddForm(key)}
            rowsPerPage={rowsPerPage}
            page={page}
          />
        )}

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={newForm ? 'Add Report Format' : 'Update Report Format'}
        />

      </div>
    );
  }
}

export default ReportFormat;
