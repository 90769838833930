const getBackendUrl = (env) => {
  switch (env) {
    case 'development':
      return 'https://brickez.com:5000/api';
    case 'production':
      return 'https://brickez.com:5000/api';
      // return 'http://13.235.46.38:5000/api';
    default:
      return 'http://localhost:5000/api';
  }
};

const getJavaBackendUrl = (env) => {
  switch (env) {
    case 'development':
      return 'http://13.234.153.101:8090/api';
      // return 'https://brickez.com:8090/api';
    case 'production':
      return 'https://brickez.com:8090/api';
      // return 'http://13.234.153.101:8090/api';
    default:
      return 'http://13.234.153.101:8090/api';
  }
};

export const HARSH_URL = getJavaBackendUrl(process.env.NODE_ENV);
export const BACKEND_URL = getBackendUrl(process.env.NODE_ENV);
export const SUCCESS_TIMEOUT = 1500;
export const ERROR_TIMEOUT = 3500;
export const LIMITS = 100;

export {
  getBackendUrl,
  getJavaBackendUrl,
};
