import axios from 'axios';
import React, { Component } from 'react';
import {
  PDFViewer,
} from '@react-pdf/renderer';

import moment from 'moment';
import {
  dencrypt, verifyLogin, apiList, summation, addCommas, shortDate,
} from '../../helpers';
import DisbursementForm from '../../components/FormPDF/DisbursementForm';
import CurrentStageForm from '../../components/FormPDF/CurrentStageForm';

class PDFView extends Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      constructionCompletion: 0,
      place: '',
      companyName: '',
      loanAccount: '',
      customerName: '',
      sanctionedLoanAmount: '',
      subscriberName: '',
      branchName: '',
      propCORDs: '',
      noOfFloors: '',
      disbursedPerSoFar: 0,
      branchCode: '',
      reportDate: moment().format('DD-MM-YYYY'),
      loanNumber: '',
      sanctionedAmount: '',
      sanctionedDate: '',
      propertyAddress: '',
      landArea: '',
      builtUpArea: '',
      buidlingStructure: '',
      propertyCost: '',
      ltv: '',
      propertyId: '',
      loanAmountDisbursed: '',
      disbursedYetToBe: '',
      disbursedSoFar: '',
    };
  }

  componentDidMount() {
    this.getLoanDetails();
  }

  handleFetchExistingPlan(propertyId) {
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${propertyId}/null`)
      .then((response) => {
        const { constructionPlan } = response.data.result;
        const constructionCompletion = constructionPlan.reduce(
          (n, { weightage, status }) => (n + (status === 'Verified' ? JSON.parse(weightage) : 0)), 0,
        );
        console.log(constructionPlan);
        this.setState({
          constructionCompletion,
          loading: false,
        });
      })
      .catch((error) => {
        // toast.error(`${JSON.stringify(error.response)}`);
        console.log(error.response);
      });
  }

  getLoanDetails() {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    const detailIdString = dencrypt(this.props.match.params.detailId);
    const detail = JSON.parse(detailIdString);
    const {
      loanId, disRequestID,
    } = detail;
    axios.get(`${apiList.VEIW_CUSTOMERS_DETAILS_API}/${loanId}`, config)
      .then((response) => {
        if (response?.data?.data) {
          const newData = { ...response.data.data };
          if (detail.docType === 'disbursement') {
            this.updateGeneratePDFHistory({ id: disRequestID, loanId });
            this.setState({
              loading: false,
              date: moment().format('DD-MM-YYYY'),
              place: newData.branchIFSC,
              companyName: newData.Subscribers?.subscriberName,
              loanAccount: newData.loanNumber,
              customerName: newData.custName,
              sanctionedLoanAmount: addCommas(newData.sanctionedAmount),
              subscriberName: `${newData.Subscribers?.subscriberName}${newData.branchIFSC}`,
              branchName: newData.branchName,
            });
            if (newData?.LoanDisbursal.length > 0) {
              const loanAmountDisbursed = JSON.parse(summation(newData.LoanDisbursal, 'disAmount'));
              this.setState({
                loanAmountDisbursed: addCommas(loanAmountDisbursed),
              });
            }
            if (newData?.LoanProperty?.id) {
              this.handleFetchExistingPlan(newData.LoanProperty.id);
            }
          } else if (detail.docType === 'loanProgress') {
            this.updateGeneratePDFHistory({ loanId });
            this.setState({
              propCORDs: `${newData.LoanProperty.lat},${newData.LoanProperty.lng}`,
              noOfFloors: newData.LoanProperty.noOfFloors,
              companyName: newData.Subscribers?.subscriberName,
              disbursedPerSoFar: newData && newData.LoanDisbursal.length > 0
                ? summation(newData.LoanDisbursal, 'disAmountPercentage').toFixed(2) * 100 : 0,
              branchCode: newData.branchIFSC,
              branchName: newData.branchName,
              reportDate: moment().format('DD-MM-YYYY'),
              customerName: newData.custName,
              loanNumber: newData.loanNumber,
              sanctionedAmount: addCommas(newData.sanctionedAmount),
              sanctionedDate: shortDate(newData.sanctionedDate),
              propertyAddress: this.createAddress(newData),
              landArea: newData.LoanProperty.landArea,
              builtUpArea: newData.LoanProperty.builtUpArea,
              buidlingStructure: newData.LoanProperty.structureType === '1' ? 'RCC Frame' : 'Load Bearing',
              propertyCost: addCommas(newData.propertyValue),
              ltv: newData.ltv,
              propertyId: newData.LoanProperty.id,
              loading: false,
            });

            if (newData?.LoanDisbursal.length > 0) {
              const loanAmountDisbursed = JSON.parse(summation(newData.LoanDisbursal, 'disAmount'));
              const loanAmountTObeDisbursed = newData.sanctionedAmount - loanAmountDisbursed;
              this.setState({
                disbursedYetToBe: addCommas(loanAmountTObeDisbursed),
                disbursedSoFar: addCommas(loanAmountDisbursed),
              });
            }
          }
        }
        console.log(response);
      })
      .catch((error) => {
        console.log(error.response);
      });
  }

  updateGeneratePDFHistory(params) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Disbursal Requests', feature: 'view' } };
    axios.post(apiList.UPDATE_FORM_DOWNLOAD_HISTORY, params, config)
      .then(() => {
      })
      .catch(() => {
      });
  }

  createAddress(data) {
    let address = '';
    if (data.LoanProperty.addressLine1) {
      address = `${data.LoanProperty.addressLine1},`;
    }
    if (data.LoanProperty.addressLine2) {
      address = `${address} ${data.LoanProperty.addressLine2},`;
    }
    if (data.LoanProperty.cityTown) {
      address = `${address} ${data.LoanProperty.cityTown},`;
    }
    if (data.LoanProperty.district) {
      address = `${address} ${data.LoanProperty.district},`;
    }
    if (data.LoanProperty.state) {
      address = `${address} ${data.LoanProperty.state},`;
    }
    if (data.LoanProperty.pincode) {
      address = `${address} ${data.LoanProperty.pincode}`;
    }
    return address;
  }

  renderDocument() {
    const detailIdString = dencrypt(this.props.match.params.detailId);
    const detail = JSON.parse(detailIdString);
    const {
      disRequestDate,
      disRequestID,
      photos,
      docType,
      reportFormatType,
    } = detail;
    console.log('reportFormatType', reportFormatType);
    const {
      loanAmountDisbursed,
      date,
      place,
      constructionCompletion,
      loanAccount,
      customerName,
      sanctionedLoanAmount,
      subscriberName,
      branchName,
      propCORDs,
      noOfFloors,
      companyName,
      disbursedPerSoFar,
      branchCode,
      sanctionedAmount,
      reportDate,
      loanNumber,
      sanctionedDate,
      disbursedYetToBe,
      disbursedSoFar,
      propertyAddress,
      landArea,
      builtUpArea,
      buidlingStructure,
      propertyCost,
      ltv,
      propertyId,
    } = this.state;
    switch (docType) {
      case 'disbursement':
        return (
          <DisbursementForm
            date={date}
            place={place}
            reportFormatType={reportFormatType}
            companyName={companyName}
            loanAccount={loanAccount}
            customerName={customerName}
            constructionCompletion={constructionCompletion}
            sanctionedLoanAmount={sanctionedLoanAmount}
            loanAmountDisbursed={loanAmountDisbursed}
            disRequestID={`${subscriberName}${disRequestID}`}
            disRequestDate={disRequestDate}
            branchName={branchName}
          />
        );
      case 'loanProgress':
        return (
          <CurrentStageForm
            reportFormatType={reportFormatType}
            propCORDs={propCORDs}
            noOfFloors={noOfFloors}
            companyName={companyName}
            disbursedPerSoFar={disbursedPerSoFar}
            branchCode={branchCode}
            branchName={branchName}
            reportDate={reportDate}
            customerName={customerName}
            loanNumber={loanNumber}
            sanctionedAmount={sanctionedAmount}
            sanctionedDate={sanctionedDate}
            disbursedYetToBe={disbursedYetToBe}
            disbursedSoFar={disbursedSoFar}
            propertyAddress={propertyAddress}
            landArea={landArea}
            builtUpArea={builtUpArea}
            buidlingStructure={buidlingStructure}
            propertyCost={propertyCost}
            ltv={ltv}
            photos={photos}
            propertyId={propertyId}
          />
        );
      default:
        return <div />;
    }
  }

  render() {
    const { loading } = this.state;
    return (
      <div>
        {!loading ? (
          <PDFViewer style={{ width: '100%', height: '100vh', alignSelf: 'center' }} fileName='somename.pdf'>
            {this.renderDocument()}
          </PDFViewer>
        ) : <div />}
      </div>

    );
  }
}

export default PDFView;
