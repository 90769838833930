import {
  defaultFont,
} from '../../material-dashboard-react';

const headerLinksStyle = (theme) => ({

  linkText: {
    zIndex: '4',
    ...defaultFont,
    fontSize: '14px',
    margin: '0px',
  },
  buttonLink: {
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      margin: '10px 15px 0',
      width: '-webkit-fill-available',
    },
    color: 'white',
    '&:hover': {
      color: '#ccc',
    },
  },
  icons: {
    color: 'white',
  },

});

export default headerLinksStyle;
