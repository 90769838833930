import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
  dimenroot: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  title: {
    fontSize: '14px',
    marginTop: 16,
    marginBottom: 16,
  },
  txt: {
    fontSize: '14px',
    margin: 5,
    marginTop: -5,
  },
});

class FormPropertyComponent extends Component {
  handleFloorNamesOptions() {
    const requiredValues = [];
    const { data, floorNameConfig } = this.props;
    for (let i = 0; i < data.LoanProperty.noOfFloors; i += 1) {
      requiredValues.push(
        <option
          value={floorNameConfig[i].id}
          key={i}
        >
          {floorNameConfig[i].floorName}
        </option>,
      );
    }
    return requiredValues.length > 0 ? requiredValues : <div />;
  }

  render() {
    const {
      classes, handleChange, floorNumber, setFloorNum,
      componentConfigId, componentConfig, setComponentConfigId, length, width,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomSelect
          id='floorNumber'
          title='Floor Number'
          menuItems={this.handleFloorNamesOptions()}
          handleChange={(event) => {
            setFloorNum(event);
          }}
          value={floorNumber}
        />
        <CustomSelect
          id='componentConfigId'
          title='Component Name'
          menuItems={componentConfig.map((element) => (
            <option value={element.id} key={element.id}>{element.componentName}</option>
          ))}
          handleChange={(event) => {
            setComponentConfigId(event);
          }}
          value={componentConfigId}
        />
        <h className={classes.title}>Component Dimension</h>
        <div className={classes.dimenroot}>
          <CustomInput
            topMargin
            rowStyle
            id='length'
            label='Length-ft'
            type='number'
            value={length}
            onChange={(id) => handleChange(id)}
          />
          <h className={classes.txt}>x</h>
          <CustomInput
            topMargin
            rowStyle
            id='width'
            label='Width-ft'
            type='number'
            value={width}
            onChange={(id) => handleChange(id)}
          />
        </div>

      </div>
    );
  }
}

export default withStyles(useStyles)(FormPropertyComponent);
