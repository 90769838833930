/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import {
  Page, Text, View, Document, StyleSheet, Image,
} from '@react-pdf/renderer';
import axios from 'axios';
import {
  apiList, shortDecimal, verifyLogin, summation, shortDate,
} from '../../helpers';
import { groupDataByactivityTypeAndName } from '../../helpers/utils';

const styles = StyleSheet.create({
  page: {
    flexDirection: 'coloumn',
    backgroundColor: '#FFFFFF',
    alignItems: 'center',
    padding: 50,
  },
  title: {
    fontSize: 16,
    marginBottom: 20,
  },
  header: {
    fontSize: 10,
    marginBottom: 10,
    marginTop: 10,
    textAlign: 'left',
    fontWeight: 'bold',
    width: '100%',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  to: {
    width: '100%',
    marginTop: 15,
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  bottom: {
    width: '100%',
    marginTop: 30,
    paddingTop: 30,
    flexDirection: 'row',
    justifyContent: 'space-between',
    borderTop: '1px solid blue',
  },
  text: {
    fontSize: 10,
    lineHeight: 1.5,
  },
  box: {
    border: '0.3px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  imageBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 8,
  },
  box2: {
    border: '0.3px solid black',
    borderTop: '0px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
  },
  dividerleft: {
    borderLeft: '0.3px solid black',
    flex: 0.7,
  },
  textInbox: {
    flex: 0.3,
    fontSize: 10,
    color: '#424242',
    margin: 8,
  },
  textImage: {
    fontSize: 10,
    color: 'gray',
    padding: 2,
  },
  posText: {
    fontSize: 8,
    color: 'gray',
    padding: 2,
  },
  cpsummarybox: {
    border: '0.3px solid black',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%',
    backgroundColor: '#F2F2F2',
  },
  cpsummaryboxText: {
    fontSize: 10,
    padding: 8,
  },
  cpsummaryboxTextRight: {
    fontSize: 10,
    padding: 8,
    borderLeft: '0.3px solid black',
  },
  imageInnerBox: {
    margin: 4,
    '@media orientation: landscape': {
      width: 150,
      height: 150,
    },
  },
  posBox: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  image: {
    width: 150,
    height: 150,
    objectFit: 'contain',
    backgroundColor: '#FAFAFA',
  },
});
export default function CurrentStageForm({
  companyName, branchCode,
  reportDate, loanNumber, customerName, sanctionedAmount,
  sanctionedDate, disbursedSoFar, disbursedYetToBe, propertyAddress, disbursedPerSoFar,
  landArea, builtUpArea, buidlingStructure, propertyCost, ltv, photos, propertyId,
  noOfFloors, propCORDs,
  // reportFormatType,
}) {
  photos.map((p) => console.log(p.url));
  const [constructionPlan, setconstructionPlan] = useState([]);
  const [constructionPlanFull, setconstructionPlanFull] = useState([]);
  const [branchName, setbranchName] = useState('');
  const [constructionModuleplanName, setConstructionModuleplanName] = useState('');
  const [verifiedPer, setVerifiedPer] = useState('0');
  const photosByArray = photos.map((o) => o.by);
  const progressUpdatedBy = [...new Set(photosByArray)];
  const [called, setCalled] = useState(false);
  useEffect(() => {
    if (!called) {
      const { session } = verifyLogin();
      const config = { headers: { session } };
      axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${propertyId}/undef`)
        .then((response) => {
          if (response?.data?.result?.constructionPlanModuleName) {
            setConstructionModuleplanName(response.data.result.constructionPlanModuleName);
            setCalled(true);
            const verifiedData = response.data.result.constructionPlan.filter(({ status }) => status === 'Verified');
            setconstructionPlan(groupDataByactivityTypeAndName([...verifiedData]));
            setconstructionPlanFull([...verifiedData]);
            setVerifiedPer(verifiedData.length > 0 ? summation(verifiedData, 'weightage') : '0');
          }
        })
        .catch(() => {
        });

      axios.get(`${apiList.GET_ACCESS_LEVEL_BY_IFSC}?ifscCode=${branchCode}`, config)
        .then((response) => {
          if (response.data.data.branchName) {
            setbranchName(response.data.data.branchName);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [propertyId]);

  return (
    <Document title='DICB'>
      <Page size='A4' style={styles.page} pageNumber={1}>
        <Text style={styles.title}>Current Stage Progress Report</Text>

        <Text style={styles.header}>Branch Details -</Text>
        <View style={styles.box}>
          <Text style={styles.textInbox}>Company Name</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{companyName}</Text>
          </View>
        </View>
        {/* <View style={styles.box2}>
          <Text style={styles.textInbox}>Branch Code:</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{branchCode}</Text>
          </View>
        </View> */}
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Branch Name:</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{branchName}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Report Date:</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{reportDate}</Text>
          </View>

        </View>

        <Text style={styles.header}>Loan Details -</Text>
        <View style={styles.box}>
          <Text style={styles.textInbox}>Loan Number</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{loanNumber}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Customer Name</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{customerName}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Sanctioned Amount</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{sanctionedAmount}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Sanctioned Date</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{sanctionedDate}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Disbursed so far</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{disbursedSoFar}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Yet to be disbursed</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{disbursedYetToBe}</Text>
          </View>

        </View>

        <Text style={styles.header}>Property Details -</Text>
        <View style={styles.box}>
          <Text style={styles.textInbox}>Property Address </Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{propertyAddress}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Land Area (Sqft)</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{landArea}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Built Up Area (Sqft)</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{builtUpArea}</Text>
          </View>

        </View>
        <View style={styles.box2}>
          <Text style={styles.textInbox}>Building Structure</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{buidlingStructure}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textInbox}>No of Floors</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{noOfFloors}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textInbox}>Property Coordinates</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{propCORDs}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textInbox}>Property Cost</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{propertyCost}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textInbox}>LTV</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{ltv}</Text>
          </View>
        </View>

        <View style={styles.box2}>
          <Text style={styles.textInbox}>Construction Plan Name</Text>
          <View style={styles.dividerleft}>
            <Text style={styles.textInbox}>{constructionModuleplanName}</Text>
          </View>
        </View>

      </Page>

      <Page size='A4' style={styles.page} pageNumber={2}>
        {constructionPlan?.length > 0
          ? <Text style={styles.header}>Construction Progress Summary -</Text>
          : null}

        {constructionPlanFull?.length > 0 ? (
          <View style={styles.cpsummarybox}>
            <Text style={[styles.cpsummaryboxText, { width: 400 }]}>Activity Name</Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 200 }]}>Floor</Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 200 }]}>Verified Date</Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 250 }]}>Completed%</Text>
          </View>
        ) : null}
        {constructionPlanFull?.length > 0 ? constructionPlanFull.map((item) => (
          <View style={[styles.cpsummarybox, { borderTop: '0px solid black', backgroundColor: '#FFF' }]}>
            <Text style={[styles.cpsummaryboxText, { width: 400, fontSize: 9 }]}>
              {item.activityConfigName}
            </Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 200, fontSize: 9 }]}>
              {item.propertyComponentFloorName}
            </Text>
            {/* <Text style={[styles.cpsummaryboxTextRight, { width: 350, fontSize: 9 }]}>
              {item.propertyComponentName}
            </Text> */}
            <Text style={[styles.cpsummaryboxTextRight, { width: 200, fontSize: 9 }]}>
              {item.verifiedAt ? shortDate(`${item.verifiedAt}`) : ''}
            </Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 250, fontSize: 9 }]}>
              {`${shortDecimal(item.weightage.toString())}%`}
            </Text>
            {/* <View style={{ flexDirection: 'column', width: 900 }}>
              {item.floors.map((floor) => (
                <View style={{
                  borderTop: '0px solid black',
                  backgroundColor: '#FFF',
                  border: '0.3px solid black',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  width: '100%',
                }}
                >

                </View>
              ))}
            </View> */}
          </View>
        ))
          : null}
        {constructionPlanFull?.length > 0 ? (
          <View style={[styles.cpsummarybox, { borderTop: '0px solid black' }]}>
            <Text style={[styles.cpsummaryboxText, { width: 400 }]}>Total</Text>
            <Text style={[styles.cpsummaryboxTextRight, { width: 200 }]} />
            {/* <Text style={[styles.cpsummaryboxTextRight, { width: 350 }]} /> */}
            <Text style={[styles.cpsummaryboxTextRight, { width: 200 }]} />
            <Text style={[styles.cpsummaryboxTextRight, { width: 250 }]}>{`${verifiedPer}%`}</Text>
          </View>
        )
          : null}
        <View style={styles.imageBox} wrap={false}>
          <Text style={styles.header}>Current Stage Photos – </Text>
          {photos.map((item) => (
            <View style={styles.imageInnerBox}>
              <Image
                style={styles.image}
                cache
                source={{
                  uri: item.url,
                  headers: { Pragma: 'no-cache', 'Cache-Control': 'no-cache' },
                }}
              />
              <View style={styles.posBox}>
                <Text style={styles.posText}>{`Date : ${item.date ? shortDate(item.date) : 'N/A'}`}</Text>
              </View>
              <View style={styles.posBox}>
                <Text style={styles.posText}>{`Latitude : ${item.lat ? item.lat : 'N/A'}`}</Text>
              </View>
              <View style={styles.posBox}>
                <Text style={styles.posText}>{`Longitude : ${item.lng ? item.lng : 'N/A'}`}</Text>
              </View>
            </View>
          ))}
        </View>
        <View style={styles.imageBox} wrap={false}>

          <View style={styles.box}>
            <Text style={styles.textInbox}>Completion % Verified so Far</Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox}>{`${verifiedPer}%`}</Text>
            </View>
          </View>
          <View style={styles.box2}>
            <Text style={styles.textInbox}>Disbursed Stage % so Far</Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox}>{`${disbursedPerSoFar}%`}</Text>
            </View>
          </View>
          <View style={styles.box2}>
            <Text style={styles.textInbox}>Progress Updated by</Text>
            <View style={styles.dividerleft}>
              <Text style={styles.textInbox}>{`${progressUpdatedBy}`}</Text>
            </View>
          </View>
          <View style={styles.box2}>
            <Text style={styles.textInbox}>{'Remarks\n\n'}</Text>
            <View style={styles.dividerleft} />
          </View>
        </View>

      </Page>

    </Document>
  );
}
