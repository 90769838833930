import moment from 'moment';

const shortData = (data) => {
  try {
    if (data === null || data === undefined) return null;
    const length = 25;
    const trimmedData = data.substring(0, length);
    return trimmedData;
  } catch (error) {
    return error.toString();
  }
};

const shortDecimal = (data) => {
  try {
    if (data === null || data === undefined) return null;
    const length = 3;
    const trimmedData = data.substring(0, length);
    return trimmedData;
  } catch (error) {
    return error.toString();
  }
};

const shortDate = (data) => {
  try {
    if (data === null || data === undefined) return null;
    const length = 10;
    const trimmedData = data.substring(0, length);
    return trimmedData;
  } catch (error) {
    return error.toString();
  }
};

const summation = (array, value) => {
  try {
    const sum = array.length > 0 ? array.map(
      (element) => Number(element[value]),
    ).reduce((prev, current) => prev + current) : 0;
    return sum;
  } catch (error) {
    return error.toString();
  }
};

const validateLogin = (state) => {
  try {
    // Validate a strong password
    const getInsertedUserPassword = state.password;
    const giveFormatInsertedUserPassword = getInsertedUserPassword.toString().trim().toLowerCase();
    const passwordLength = giveFormatInsertedUserPassword.length < 6
      || giveFormatInsertedUserPassword.length > 16;
    // const passwordContainNumber = (giveFormatInsertedUserPassword.search(/[0-9]/) === -1);
    // const passwordContainCharacter = (giveFormatInsertedUserPassword.
    // search(/[! @ # $ % & * ~ ` : "" '' ' " ? / , .]/) === -1);

    // validate email or phone number
    const getInsertedUserEmail = state.email;
    const giveFormatInsertedUserEmail = getInsertedUserEmail.toString().trim().toLowerCase();
    const email = giveFormatInsertedUserEmail;
    const emailLength = email.length > 40;
    const goodEmail = /^(([a-zA-Z0-9 .-_]{2,6})+)@(([a-zA-Z0-9]{2,6})+).([a-z]{2,6})(.[a-z]{2,6})$/;
    const checkedEmailCredential = (!goodEmail.test(email));

    let error = null;

    if (state.email.length < 1 && state.password.length < 1) {
      error = 'Empty form found';
      const formClass = 'form-result';
      const emailClass = 'email-input';
      const passwordClass = 'password-input';
      return {
        error, formClass, emailClass, passwordClass,
      };
    }

    if (state.email.length < 1) {
      error = 'Email is required';
      const formClass = 'form-result';
      const emailClass = 'email-input';
      return {
        error, formClass, emailClass,
      };
    }

    if (checkedEmailCredential || emailLength) {
      error = 'Invalid email';
      const emailClass = 'email-input';
      const formClass = 'form-result';
      return {
        error, formClass, emailClass,
      };
    }

    if (state.password.length < 1) {
      error = 'Password is required';
      const formClass = 'form-result';
      const passwordClass = 'password-input';
      return {
        error, formClass, passwordClass,
      };
    }

    if (passwordLength) {
      error = 'Incorrect password';
      const passwordClass = 'password-input';
      const formClass = 'form-result';
      return {
        error, formClass, passwordClass,
      };
    }

    return { error };
  } catch (error) {
    return error.toString();
  }
};

const validateEmail = (data) => {
  try {
    // validate email or phone number
    const getInsertedUserEmail = data;
    const giveFormatInsertedUserEmail = getInsertedUserEmail.toString().trim().toLowerCase();
    const email = giveFormatInsertedUserEmail;
    const emailLength = email.length > 40;
    const goodEmail = /^(([a-zA-Z0-9 .-_]{2,6})+)@(([a-zA-Z0-9]{2,6})+).([a-z]{2,6})(.[a-z]{2,6})$/;
    const checkedEmailCredential = (!goodEmail.test(email));

    let error = null;

    if (data.length < 1) {
      error = 'Email is required';
      const formClass = 'form-result';
      const emailClass = 'email-input';
      return {
        error, formClass, emailClass,
      };
    }

    if (checkedEmailCredential || emailLength) {
      error = 'Incorrect email';
      const emailClass = 'email-input';
      const formClass = 'form-result';
      return {
        error, formClass, emailClass,
      };
    }

    return { error };
  } catch (error) {
    return error.toString();
  }
};

const validatePassword = (password) => {
  try {
    // Validate a strong password
    const getInsertedUserPassword = password;
    const giveFormatInsertedUserPassword = getInsertedUserPassword.toString().trim().toLowerCase();
    const passwordLength = giveFormatInsertedUserPassword.length < 6
      || giveFormatInsertedUserPassword.length > 16;
    const passwordContainNumber = (giveFormatInsertedUserPassword.search(/[0-9]/) === -1);
    const passwordContainCharacter = (giveFormatInsertedUserPassword.search(/[! @ # $ % & * ~ ` : "" '' ' " ? / , .]/) === -1);

    let error = null;

    if (password.length < 1) {
      error = 'Password is required';
      const formClass = 'form-result';
      const passwordClass = 'password-input';
      return {
        error, formClass, passwordClass,
      };
    }

    if (passwordLength || passwordContainNumber || passwordContainCharacter) {
      error = 'Incorrect password';
      const passwordClass = 'password-input';
      const formClass = 'form-result';
      return {
        error, formClass, passwordClass,
      };
    }

    return { error };
  } catch (error) {
    return error.toString();
  }
};

const addCommas = (data) => {
  try {
    if (data === null || data === undefined) return null;
    const commas = Number(data).toLocaleString();
    return commas;
  } catch (error) {
    return error.toString();
  }
};

const daysPassed = (requestDate) => {
  const start = moment();
  console.log(requestDate);
  const end = moment(requestDate);
  const dayPassed = Math.floor(moment.duration(start.diff(end)).asDays());
  return dayPassed > 0 ? dayPassed : 0;
};

const validateLoanDetails = (loan) => {
  let error = null;
  if (!loan.loanTypeId) {
    error = 'Loan Type is required';
  } else if (!loan.branchIFSC) {
    error = 'Branch IFSC is required';
  } else if (!loan.loanNumber) {
    error = 'Loan Number should not be empty.';
  } else if (!loan.custFirstName) {
    error = 'First name should not be empty.';
  } else if (!loan.custLastName) {
    error = 'Last name should not be empty.';
  } else if (!loan.custMobile) {
    error = 'Customer mobile should not be empty.';
  } else if (!loan.sanctionedAmount) {
    error = 'Sanctioned amount should not be empty.';
  } else if (!loan.sanctionedDate) {
    error = 'Sanctioned Date should not be empty.';
  } else if (!loan.ltv) {
    error = 'LTV should not be empty.';
  }
  return error;
};

const validatePropertyDetails = (property) => {
  let error = null;
  const pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');

  if (!property.builtUpArea) {
    error = 'Built Up Area should not be empty.';
  } else if (!property.structureType) {
    error = 'Please select structure type.';
  } else if (!property.noOfFloors) {
    error = 'No of floors should not be empty.';
  } else if (property.lat && property.lat.length > 0 && !pattern.test(property.lat)) {
    error = 'Invalid latitude';
  } else if (property.lng && property.lng.length > 0 && !pattern.test(property.lng)) {
    error = 'Invalid longitude';
  }
  return error;
};

export {
  shortData,
  shortDecimal,
  shortDate,
  daysPassed,
  summation,
  validateLogin,
  addCommas,
  validateEmail,
  validatePassword,
  validateLoanDetails,
  validatePropertyDetails,
};
