/* eslint-disable no-unused-vars */
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import {
  shortData,
  shortDate,
} from '../../helpers';
import CustomTable from '../../components/CustomTable/CustomTable';
import Button from '../../components/CustomButtons/Button';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  render() {
    const {
      classes, handleChange, name, desc, elemenntId,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput id='name' disabled={elemenntId} label='Name' value={name} onChange={(id) => handleChange(id)} />
        <CustomInput id='desc' label='Description' value={desc} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
