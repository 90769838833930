import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
import ListItemText from '@material-ui/core/ListItemText';
import Divider from '@material-ui/core/Divider';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';

import { colors } from '@material-ui/core';
import {
  apiList,
  verifyLogin,
} from '../../helpers';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    display: 'flex',
  },
  listDiv: {
    width: '300px',
    height: '300px',
    overflow: 'auto',
    backgroundColor: colors.grey['100'],
    margin: 16,
    borderRadius: 5,
  },
  listItem: {
    paddingLeft: 16,
    padddingVertical: 16,
  },
  listHeader: {
    width: '300px',
    backgroundColor: colors.grey['100'],
  },
});

class FormCustomerDetails extends Component {
  constructor() {
    super();
    this.state = {
      user: '',
      loan: '',
      userId: -1,
      loanId: -1,
      loanMappingID: -1,
      suggestions: [],
      loanSuggestion: [],
      roleType: 1,
    };
  }

  componentDidMount() {
    const {
      user, loan, userId,
      loanId, loanMappingID,
    } = this.props;
    this.setState({
      user,
      loan,
      userId,
      loanId,
      loanMappingID,
    });
    this.getLoans();
    this.getUsers();
  }

  handleChange(key, userRoleId) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
    this.setState({ roleType: userRoleId });
    if (userRoleId === 1) {
      this.getUsers(key.target.value, userRoleId);
    }
  }

  handleSaveData() {
    const {
      loanId,
      userId,
      loanMappingID,
    } = this.state;
    const { complete } = this.props;
    if (userId.length < 1 && loanId.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      userId, loanId,
    };
    if (loanMappingID && loanMappingID !== -1) {
      params.id = loanMappingID;
    }
    axios.post(`${apiList.UPDATE_USER_LOAN_MAPPING_API}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        complete();
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  getListHeaderTitle() {
    switch (this.state.roleType) {
      case 1:
        return 'Users';
      case 2:
        return 'Loans';
      default:
        return 'Users';
    }
  }

  getUsers(searchQuery, role) {
    const { session, user } = verifyLogin();
    const { subscriberId, userRoleId } = user;

    const config = { headers: { session, permissionConfig: 'Customer', feature: 'view' } };
    let url = `${apiList.GET_USERS}?searchQuery=${searchQuery}&userProduct=${2}`;
    if (Number(userRoleId) === 2) {
      url = `${url}&subscriberId=${Number(subscriberId)}`;
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({ roleType: role, suggestions: response.data.data.paginate });
      })
      .catch(() => {
      });
  }

  getLoans() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VEIW_CUSTOMERS_API}?page=${1}&limit=${100}`, config)
      .then((response) => {
        this.setState({ loanSuggestion: [...response.data.data.paginate] });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  render() {
    const { classes } = this.props;
    const {
      user, loan, suggestions, roleType, loanSuggestion,
    } = this.state;
    const data = roleType === 1 ? suggestions : loanSuggestion;
    return (
      <div className={classes.main}>
        <div className={classes.root}>
          <CustomInput id='user' label='User' value={user} onChange={(id) => this.handleChange(id, 1)} />
          <CustomInput id='loan' label='Loan' value={loan} onChange={(id) => this.handleChange(id, 2)} />
        </div>
        <div className={classes.listDiv}>
          <List
            subheader={(
              <ListSubheader className={classes.listHeader}>
                {this.getListHeaderTitle()}
              </ListSubheader>
            )}

          >
            {data.map((suggestion) => (
              <>
                <ListItemText
                  className={classes.listItem}
                  onClick={() => {
                    switch (roleType) {
                      case 1:
                        this.setState({
                          user: `${suggestion.firstName} ${suggestion.lastName}`,
                          userId: suggestion.id,
                          suggestions: [],
                          roleType: 1,
                        });
                        break;
                      case 2:
                        this.setState({
                          loan: `${suggestion.custName} - ${suggestion.loanNumber}`,
                          loanId: suggestion.id,
                          suggestions: [],
                          roleType: 1,
                        });
                        break;
                      default:
                        break;
                    }
                  }}
                  primary={roleType === 1
                    ? `${suggestion.firstName} ${suggestion.lastName}`
                    : `${suggestion.custName} - ${suggestion.loanNumber}`}
                />
                <Divider />
              </>
            ))}
          </List>
        </div>
      </div>
    );
  }
}

export default withStyles(useStyles)(FormCustomerDetails);
