import React from 'react';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  root: {
    minWidth: 300,
    marginBottom: 16,
    marginTop: 16,
  },
  minWidthChange: {
    minWidth: 80,
    maxWidth: 140,
  },
  marginTop: {
    marginTop: 0,
  },
  marginBottom: {
    marginBottom: 0,
  },
  height: {
    padding: 0,
    margin: 0,
    fontSize: 14,
  },
}));

export default function CustomInput(props) {
  const classes = useStyles();
  const {
    id, label, value, onChange,
    rowStyle,
    topMargin,
    btargin,
  } = props;

  return (
    <TextField
      className={[classes.root, rowStyle && classes.minWidthChange,
        topMargin && classes.marginTop, btargin && classes.marginBottom]}
      id={id}
      label={label}
      size='small'
      value={value}
      onChange={onChange}
      variant='outlined'
      {...props}
    />

  );
}
