import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

class LoanTaskConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
      name: '',
      desc: '',
      configID: '',
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.getTaskConfig(Number(subscriberId));
    }
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        name: element.name,
        desc: element.description,
        configID: element.id,
      });
    } else {
      this.setState({
        name: '',
        desc: '',
        configID: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      name,
      desc,
      configID,
      subscriberId,
    } = this.state;
    if (name.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = { name: name.trim(), desc: desc.trim(), subscriberId };
    if (configID) {
      params.id = configID;
    }
    axios.post(`${apiList.UPDATE_LOAN_TASK_CONFIG}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          name: '',
          desc: '',
          configID: '',
        });
        this.toggleModal(false);
        this.getTaskConfig(subscriberId);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  getTaskConfig(subscriberId) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_LOAN_TASK_CONFIG}?subscriberId=${subscriberId}`, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate] });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ data: [] });
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body=() => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      name={this.state.name}
      desc={this.state.desc}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      data,
      page,
      rowsPerPage,
      formOpen,
      newForm,
      userRoleId,
      subscribers,
      subscriberId,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true ? <Loading MainProps={this.props} /> : null }

        {userRoleId === 1 ? (
          <div className='select-fields'>
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                if (event.target.value !== '#78') {
                  this.setState({ subscriberId: event.target.value });
                  this.getTaskConfig(event.target.value);
                } else {
                  this.setState({ subscriberId: -1, data: [] });
                }
              }}
              value={subscriberId}
            />
          </div>
        ) : null}

        {result === false && (
        <Table
          data={Array.isArray(data) ? data : []}
          handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
          handleShowAddForm={(key) => this.handleShowAddForm(key)}
          rowsPerPage={rowsPerPage}
          page={page}
        />
        )}

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={newForm ? 'Create New Task' : 'Update Task'}
        />

      </div>
    );
  }
}

export default LoanTaskConfig;
