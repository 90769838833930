import React, { Component } from 'react';
import Loader from 'react-loader-spinner';
import 'react-loader-spinner/dist/loader/css/react-spinner-loader.css';

class Loading extends Component {
  render() {
    if (this.props.Case) {
      return (
        <div>
          <Loader
            type='TailSpin'
            color='#3D4977'
            height={20}
            width={40}
          />
        </div>
      );
    }

    return (
      <div className='main-loading'>
        <Loader
          type='TailSpin'
          color='#3D4977'
          height={50}
          width={100}
        />
      </div>
    );
  }
}

export default Loading;
