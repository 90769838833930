import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import {
  verifyLogin,
  variables,
  apiList,
  verifyUser,
} from '../../helpers';
import Table from './table';
import Loading from '../../components/loading/loading';
import CustomSelect from '../../components/CustomSelect/CustomSelect';
import CustomInput from '../../components/CustomInput/CustomInput';

import OnBoardingDialog from './OnBoardingDialog';

const successTimeOut = variables.SUCCESS_TIMEOUT;
const errorTimeOut = variables.SUCCUSS_TIMEOUT;

class UsersScreen extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      subscribers: [],
      subscriberId: -1,
      firstName: '',
      accessType: -1,
      lastName: '',
      phone: '',
      email: '',
      elementId: null,
      password: '',
      confirmPassword: '',
      dataLoaded: false,
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      showingAdminUsers: false,
      role: -1,
      userRoleId: -1,
      userProductID: -1,
    };
  }

  componentDidMount() {
    const { user, session } = verifyLogin();
    const config = { headers: { session } };
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      result: true,
      role: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers(config);
    if (Number(userRoleId) === 2) {
      this.handleFetchUsers(config, Number(subscriberId));
    }
  }

  handleChangePage = (event, newPage) => {
    this.setState({ page: newPage });
  };

  handleChangeRowsPerPage = (event) => {
    this.setState({ rowsPerPage: +event.target.value });
    this.setState({ page: 0 });
  };

  handleShowAddForm(key) {
    key.preventDefault();
    this.setState({
      formOpen: true,
      result: false,
      elementId: null,
      userProductID: '',
      userRoleId: '',
      firstName: '',
      accessType: -1,
      lastName: '',
      phone: '',
      email: '',
      password: '',
      confirmPassword: '',
    });
  }

  handleShowEditForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    this.setState({
      formOpen: true,
      result: false,
      elementId: element.id,
      subscriberId: element.subscriberId,
      userProductID: element.userProduct,
      userRoleId: element.userRoleId,
      firstName: element.firstName,
      accessType: Number.isNaN(element.accessType) ? -1 : element.accessType,
      lastName: element.lastName,
      phone: element.alternateNumber ? element.alternateNumber.slice(-10) : '',
      email: element.email,
      password: '',
      confirmPassword: '',
    });
  }

  handleFetchSubscribers(config) {
    this.setState({ result: true });
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({
          subscribers: [...response.data.data.paginate],
        });
        setTimeout(() => {
          this.setState({ result: false });
        }, successTimeOut);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, errorTimeOut);
      });
  }

  handleDeleteData(key, elementId) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    this.setState({ result: true });
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      axios.delete(`${apiList.DELETE_WEB_USERS_API}/${Number(elementId)}`, config)
        .then((response) => {
          toast.success(response.data.message);
          this.handleFetchUsers(config, this.state.subscriberId);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    } else {
      this.setState({ result: false });
    }
  }

  handleFetchUsers(config, subscriberId, searchQuery) {
    let url = `${apiList.VIEW_WEB_USERS_API}`;
    if (subscriberId) {
      url = `${url}?subscriberId=${subscriberId}`;
      this.setState({ showingAdminUsers: false });
    } else {
      this.setState({ showingAdminUsers: true });
    }
    if (searchQuery) {
      url = `${url}&searchQuery=${searchQuery}`;
    }
    axios.get(url, config)
      .then((response) => {
        if (response?.data?.data) {
          this.setState({ data: [...response.data.data], dataLoaded: true });
        }
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch(() => {
        // toast.error(`${error.response?.data?.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  toggleModal(value) {
    this.setState({
      formOpen: value,
    });
  }

  render() {
    const {
      data,
      result,
      dataLoaded,
      rowsPerPage,
      page,
      formOpen,
      subscribers,
      subscriberId,
      role,
      showingAdminUsers,
    } = this.state;

    const { MainProps } = this.props;
    verifyLogin();
    return (
      <div className='users'>
        <ToastContainer />

        {result === true && !dataLoaded ? <Loading MainProps={MainProps} /> : null }
        <div className='select-fields'>
          {role === 1 ? (
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                this.setState({ subscriberId: event.target.value === '#78' ? -1 : event.target.value });
                const { session } = verifyLogin();
                const config = { headers: { session } };
                if (event.target.value !== '#78') {
                  this.handleFetchUsers(config, event.target.value);
                } else {
                  this.setState({ data: [] });
                }
              }}
              value={subscriberId}
            />
          ) : null}
          <CustomInput
            height
            btargin
            disabled={subscriberId < 1}
            onChange={(event) => {
              if (subscriberId > 0) {
                const { session } = verifyLogin();
                const config = { headers: { session } };
                this.handleFetchUsers(config, subscriberId, event.target.value);
              }
            }}
            id='users'
            label='Search Users'
          />
        </div>
        <Table
          data={data}
          subscriberId={subscriberId}
          tableName={showingAdminUsers ? 'Admin Users' : 'Web Users'}
          MainProps={this.props}
          handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
          handleShowEditForm={(key, element) => this.handleShowEditForm(key, element)}
          handleShowAddForm={(key) => {
            if (subscriberId !== -1 && subscriberId !== '#78') {
              this.handleShowAddForm(key);
            } else {
              toast.error('Please Select Subscriber');
            }
          }}
          rowsPerPage={rowsPerPage}
          page={page}
          handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
          handleChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
        />

        {formOpen ? (
          <OnBoardingDialog
            open={formOpen}
            handleClose={(dataChanged) => {
              if (dataChanged) {
                const { session } = verifyLogin();
                const config = { headers: { session } };
                this.handleFetchUsers(config, subscriberId);
              }
              this.setState({
                formOpen: false,
                result: false,
                userProductID: '',
                userRoleId: '',
                firstName: '',
                accessType: -1,
                lastName: '',
                phone: '',
                email: '',
                elementId: null,
                password: '',
                confirmPassword: '',
              });
            }}
            user={{
              elementId: this.state.elementId,
              subscriberId,
              firstName: this.state.firstName,
              accessType: this.state.accessType,
              lastName: this.state.lastName,
              phone: this.state.phone,
              email: this.state.email,
              password: this.state.password,
              confirmPassword: this.state.confirmPassword,
              userRoleId: this.state.userRoleId,
              userProductID: this.state.userProductID,
            }}
          />
        ) : null}

      </div>
    );
  }
}

export default UsersScreen;
