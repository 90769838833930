/* eslint-disable max-len */
import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Lightbox from 'react-awesome-lightbox';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  verifyLogin,
  apiList,
} from '../../../helpers';
import PhotoGrid from './PhotoGrid';
import Plans from './Plans';
import CustomAlert from '../../../components/CustomAlert/CustomAlert';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const { classes, onClose } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['Select plan you want to modify.', 'Select Media you want to transfer.'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    position: 'relative',
    paddingTop: 20,
    width: '100%',
    display: 'flex',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  list: {
    width: 300,
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  subTitle: {
    fontSize: 12,
    marginTop: 12,
    fontWeight: 'bold',
  },
}));

export default function TransferMedia({
  open, handleClose, selectedTransferMedia,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentVideos, setCurrentVideos] = useState([]);
  const [enableImageSelect, setEnableImageSelect] = useState(false);
  const [enableVideoSelect, setEnableVideoSelect] = useState(false);
  const [plan, setPlan] = useState(null);
  const [hasMedia, setHasMedia] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [lightBoxPref, setLightBoxPref] = useState({ show: false, index: 0 });
  const [videoPref, setVideoPref] = useState({ show: false, data: null });

  const steps = getSteps();

  const handleBack = () => {
    if (activeStep > 0) {
      setHasMedia(false);
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const transferMedia = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Construction Plan', feature: 'edit' } };
    const params = {
      currentPlanId: selectedTransferMedia.currentPlanID,
      newPlanId: plan.id,
      mediaIds: [...currentImages.map(({ id }) => id), ...currentVideos.map(({ id }) => id)],
      submittedUserType: selectedTransferMedia.submittedUser,
      updateMedia: false,
      mapCircleRadius: selectedTransferMedia.mapCircleRadius,
    };
    axios.post(`${apiList.TRANSFER_MEDIA}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        handleClose(true);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  const getProgressMedia = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    axios.get(`${apiList.GET_PROGRESS_MEDIA_BY_CONST_PLAN}?consPlanId=${plan.id}`, config)
      .then((response) => {
        setHasMedia(false);
        if (response.data.data) {
          const progressMediaAvailable = response.data.data.filter(
            (item) => item.submittedUserType === selectedTransferMedia.submittedUser,
          );
          if (progressMediaAvailable.length > 0) {
            setHasMedia(true);
          }
        }
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);
      });
  };

  const onNextClick = () => {
    if (enableImageSelect && currentImages.length !== plan.noOfImages) {
      toast.error('You can not select more or less than selected plan media.');
      return;
    }
    if (enableVideoSelect && currentImages.length !== plan.noOfImages) {
      toast.error('You can not select more or less than selected plan media.');
      return;
    }
    transferMedia();
  };

  const handleNext = () => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          if (!plan || !selectedTransferMedia) {
            toast.error('Choose plan to continue');
            break;
          }
          if (plan.noOfImages && selectedTransferMedia.photos && selectedTransferMedia.photos.length > 0) {
            if (plan.noOfImages >= selectedTransferMedia.photos.length) {
              setCurrentImages(selectedTransferMedia.photos);
              setEnableImageSelect(false);
            } else {
              const newarray = selectedTransferMedia.photos.slice(0, plan.noOfImages);
              setCurrentImages(newarray);
              setEnableImageSelect(true);
            }
          }

          if (plan.noOfVideos && selectedTransferMedia.videos && selectedTransferMedia.videos.length > 0) {
            if (plan.noOfVideos >= selectedTransferMedia.videos.length) {
              setCurrentVideos(selectedTransferMedia.videos);
              setEnableVideoSelect(false);
            } else {
              setCurrentVideos(selectedTransferMedia.videos.slice(0, plan.noOfVideos));
              setEnableVideoSelect(true);
            }
          }
          getProgressMedia();
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          break;
        case 1:
          if (hasMedia) {
            setShowAlert(true);
          } else {
            setShowAlert(false);
            onNextClick();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    switch (activeStep) {
      case 0:
        return 'Continue';
      case 1:
        return 'Transfer Photos';
      default:
        return 'Next';
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={() => handleClose(false)}
    >
      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{activeStep === index ? label : ''}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton
        id='plan'
        onClose={() => handleClose(false)}
      />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >

            <div className={classes.slide}>
              <Plans
                plan={plan}
                setPlan={setPlan}
                loanId={selectedTransferMedia.loanId}
                currentPlanID={selectedTransferMedia.currentPlanID}
              />
            </div>

            <div className={classes.slide}>
              <div>
                <div className={classes.title}>{`Selected Plan: ${plan?.activityConfigName} - Photos: ${plan?.noOfImages}, Videos: ${plan?.noOfVideos}`}</div>
                {selectedTransferMedia?.photos?.length > 0 && plan?.noOfImages > 0
                  ? <div className={classes.subTitle}>Photos: </div> : null}
                {selectedTransferMedia?.photos?.length > 0 && plan?.noOfImages > 0
                  ? (
                    <PhotoGrid
                      onOpenClick={(index) => {
                        console.log(selectedTransferMedia.photos);
                        setLightBoxPref({ show: true, index });
                      }}
                      enableSelect={enableImageSelect}
                      photos={selectedTransferMedia.photos}
                      component='img'
                      currentImages={currentImages}
                      setCurrentImages={setCurrentImages}
                    />
                  ) : null}
                {selectedTransferMedia?.videos?.length > 0 && plan?.noOfVideos > 0
                  ? <div className={classes.subTitle}>Video: </div> : null}
                {selectedTransferMedia?.videos?.length > 0 && plan?.noOfVideos > 0
                  ? (
                    <PhotoGrid
                      onOpenClick={(index) => {
                        console.log(selectedTransferMedia.videos[index]);
                        setVideoPref({ show: true, data: selectedTransferMedia.videos[index] });
                      }}
                      enableSelect={enableVideoSelect}
                      photos={selectedTransferMedia.videos}
                      component='video'
                      currentImages={currentVideos}
                      setCurrentImages={setCurrentVideos}
                    />
                  ) : null}
              </div>
            </div>
          </SwipeableViews>
        </div>

      </DialogContent>

      {lightBoxPref?.show && selectedTransferMedia?.photos?.length > 0 && (
        <Lightbox
          onClose={() => setLightBoxPref({ show: false, index: 0 })}
          images={selectedTransferMedia.photos}
          allowRotate={false}
          startIndex={lightBoxPref.index}
          doubleClickZoom={0}
        />
      )}

      <VideoPlayer
        url={videoPref?.data?.src}
        isOpen={videoPref.show}
        disc={videoPref?.data?.disc}
        title={videoPref?.data?.title}
        onClose={() => setVideoPref({ show: false, data: null })}
      />

      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' onClick={handleNext}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
          </div>

        )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        )}
      />
      <CustomAlert
        open={showAlert}
        okTitle='Ok'
        handleYes={() => {
          setShowAlert(false);
          onNextClick();
        }}
        title='Media Conflict!'
        content={`${plan?.activityConfigName} already have media, if continuing this, current media of ${plan?.activityConfigName} will be lost.`}
        handleClose={() => setShowAlert(false)}
      />
    </Dialog>
  );
}
