import React, { Component } from 'react';

import GroupRoundedIcon from '@material-ui/icons/GroupRounded';
import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { ToastContainer } from 'react-toastify';
import Navbar from '../../components/navBar/navBar';
import {
  verifyLogin,
  apiList,
} from '../../helpers';
import LeftMenu from './LeftMenu';
import BasicDetails from './BasicDetails';
import Access from './Access';
import ChangePassword from './ChangePassword';
import Roles from './Roles';

const useStyles = () => ({
  root: {
    flex: 1,
    backgroundColor: '#fff',
  },
  mainDiv: {
    flex: 1,
    display: 'flex',
  },
});

const items = [{
  name: 'Basic Details',
  icon: GroupRoundedIcon,
}, {
  name: 'Role and Permission',
  icon: GroupRoundedIcon,
}, {
  name: 'Access',
  icon: GroupRoundedIcon,
}, {
  name: 'Reset Password',
  icon: GroupRoundedIcon,
}];
class Profile extends Component {
  constructor() {
    super();
    this.state = {
      userDetails: null,
      rolePermissions: [],
      accessLevel: [],
      selectedItem: 0,
    };
  }

  componentDidMount() {
    this.getUserDetails();
    this.getRoles();
    this.getPermissionConfig();
  }

  getUserDetails() {
    const { session, user } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_USER_DETAILS}?id=${user.id}`, config)
      .then((response) => {
        this.setState({ userDetails: response.data.data });
      })
      .catch(() => {
      });
  }

  getRoles() {
    const { session, user } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_PERMISSION_CONFIG_BY_ROLEID}?id=${user.userRoleId}`, config)
      .then((response) => {
        this.setState({ rolePermissions: [...response.data.data.paginate] });
      })
      .catch((error) => {
        console.log(error);
      });
  }

  getPermissionConfig() {
    const { session, user } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_ACCESS_LEVEL_BY_USERID}?subscriberId=${user.id}`, config)
      .then((response) => {
        console.log(response);
        const data = response.data.data.paginate;
        this.setState({ accessLevel: [...data] });
      })
      .catch(() => {
      });
  }

  render() {
    const {
      classes,
    } = this.props;
    const {
      selectedItem, userDetails, rolePermissions, accessLevel,
    } = this.state;
    return (
      <div className={classes.root}>
        <ToastContainer />

        <Navbar MainProps={this.props} />

        <div className={classes.mainDiv}>
          <LeftMenu
            items={items}
            selectedItem={selectedItem}
            setSelectedItem={(key) => this.setState({ selectedItem: key })}
          />
          {selectedItem === 0 && userDetails ? <BasicDetails userDetails={userDetails} /> : null}
          {selectedItem === 1 && rolePermissions
            ? <Roles rolePermissions={rolePermissions} /> : null}
          {selectedItem === 2 ? <Access data={accessLevel} /> : null}
          {selectedItem === 3 ? <ChangePassword userDetails={userDetails} /> : null}

        </div>
      </div>
    );
  }
}
export default withStyles(useStyles)(Profile);
