import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import KeyboardArrowDownRoundedIcon from '@material-ui/icons/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@material-ui/icons/KeyboardArrowUpRounded';
import moment from 'moment';
import Button from '../../components/CustomButtons/Button';
import Loading from '../../components/loading/loading';
import Navbar from '../../components/navBar/navBar';

import {
  apiList,
  encrypt,
  dencrypt,
  variables,
  verifyLogin,
  shortDate,
  addCommas,
  shortData,
  summation,
} from '../../helpers';
import DisbursementTable from './DisbursementTable';
import TaskTable from './TaskTable';
import PropertyComponentDetailsTable from './PropertyComponentDetailsTable';
import ConstructionPlanTable from './ConstructionPlanTable';
import DisbursalTable from './DisbursalTable';
import CustomAlert from '../../components/CustomAlert/CustomAlert';

import CustomModal from '../../components/CustomModal/CustomModal';
import FormLoanProperty from './FormLoanProperty';
import FormPropertyComponent from './FormPropertyComponent';
import FormCustomerDetails from './FormCustomerDetails';
import FormCreateConstructionPlan from './FormCreateConstructionPlan';
import FormLoanTask from './FormLoanTask';
import FormLoanDisbursement from './FormLoanDisbursement';
import UncategorizedMediaTable from './UncategorizedMediaTable';
import ReportFormatList from '../../components/ReportFormatList/ReportFormatList';

const successTimeOut = variables.SUCCESS_TIMEOUT;
const errorTimeOut = variables.SUCCUSS_TIMEOUT;

class LoanDetails extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      sectionOne: true,
      sectionTwo: true,
      sectionThree: true,
      sectionFour: true,
      sectionFive: true,
      sectionSix: true,
      sectionSeven: true,
      sectionEight: true,
      sectionNine: true,
      constructionPlanVersion: -1,
      isCurrentPlan: true,
      data: {},
      floorNameConfig: [],
      componentConfig: [],
      constructionPlan: [],

      dataLoaded: false,
      constructionPlanLoaded: false,

      propertyDescription: '',
      addressLine1: '',
      addressLine2: '',
      lat: '',
      lng: '',
      planID: -1,
      floorNumber: '',
      componentConfigId: '',
      componentDimen: '',
      width: '',
      length: '',

      formOpen: false,
      newForm: true,

      formCustomerIDsOpen: false,
      formAddPlanIDOpen: false,
      reportFormatListModal: false,

      formOpenpc: false,
      newFormpc: true,
      showAlert: false,

      loanTasks: [],
      loanTaskDetails: {},
      showContructionPlanModuleAlert: false,
      showDownloadPDFError: false,
      disbursementAmount: '',
      disbursementPerc: '',
      totalPercentage: 0,
      disbursementDate: '',
      disbursementId: null,
      disbursementModalOpen: false,

      loanPermission: {},
      customerPermission: {},
      disbursementPermission: {},
      propDetailsPermission: {},
      propComponentsPermission: {},
      constructionPlanPermission: {},
      loanTaskPermission: {},
      disRequestPermission: {},
      geoTaggPermission: {},
      mediaSubmissionNoPlanPermission: {},
      mediaSubmissionPeriodPermission: {},
      showPropertyComponents: false,
      constructionModuleplanName: '',
      reportFormats: [],
    };
    this.disbumentTableRef = React.createRef();
    this.taskConfigTableRef = React.createRef();
  }

  componentDidMount() {
    this.setState({ result: true });
    const { session, roleMapping, user } = verifyLogin();
    if (user.userRoleId === 1 || user.userRoleId === 2) {
      this.setState({
        loanPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        customerPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        disbursementPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        propDetailsPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        propComponentsPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        constructionPlanPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        loanTaskPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        disRequestPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        permission: {
          view: true, add: true, edit: true, delete: true,
        },
        geoTaggPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        mediaSubmissionNoPlanPermission: {
          view: true, add: true, edit: true, delete: true,
        },
        mediaSubmissionPeriodPermission: {
          view: true, add: true, edit: true, delete: true,
        },
      });
    } else {
      const loanPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Loan');
      const customerPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Customer');
      const disbursementPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Disbursement');
      const propDetailsPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Property Details');
      const propComponentsPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Property Component');
      const constructionPlanPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Construction Plan');
      const loanTaskPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Loan Tasks');
      const disRequestPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Disbursal Requests');
      const geoTaggPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Geo Tag E-D');
      const mediaSubmissionNoPlanPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Allow Direct Media');
      const mediaSubmissionPeriodPermission = roleMapping.find((obj) => obj.PermissionConfig?.name === 'Reset Cooling Period');
      this.setState({
        loanPermission: loanPermission || {},
        customerPermission: customerPermission || {},
        disbursementPermission: disbursementPermission || {},
        propDetailsPermission: propDetailsPermission || {},
        propComponentsPermission: propComponentsPermission || {},
        constructionPlanPermission: constructionPlanPermission || {},
        loanTaskPermission: loanTaskPermission || {},
        disRequestPermission: disRequestPermission || {},
        geoTaggPermission: geoTaggPermission || {},
        mediaSubmissionNoPlanPermission: mediaSubmissionNoPlanPermission || {},
        mediaSubmissionPeriodPermission: mediaSubmissionPeriodPermission || {},
      });
    }
    const config = { headers: { session } };
    this.getLoanDetails();
    this.handleFetchFloorNameConfig(config);
    this.handleFetchComponentConfig(config);
  }

  handleFetchExistingPlan(loanId, version) {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Construction Plan', feature: 'view' } };
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${loanId}/${version}`, config)
      .then((response) => {
        if (response?.data?.result?.constructionPlan) {
          console.log(response.data.result);
          const { constructionPlanModuleName, constructionPlan } = response.data.result;
          console.log(constructionPlan, constructionPlanModuleName);
          this.setState({
            constructionPlan: [...constructionPlan],
            constructionModuleplanName: constructionPlanModuleName,
            constructionPlanLoaded: true,
            isCurrentPlan: !version,
          });
          if (!version) {
            this.setState({
              constructionPlanVersion: constructionPlan[0].version,
            });
          }
        }
        this.setState({ result: false });
      })
      .catch((e) => {
        console.log(e);
        this.setState({ result: false });
      });
  }

  handleFetchFloorNameConfig(config) {
    this.setState({ result: true });
    axios.get(`${apiList.VIEW_USERS_FLOOR_CONFIG_API}`, config)
      .then((response) => {
        this.setState({ floorNameConfig: [...response.data.data.paginate] });

        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        this.setState({ result: false });
      });
  }

  handleFetchComponentConfig(config) {
    this.setState({ result: true });
    axios.get(`${apiList.VIEW_USERS_COMPONENT_CONFIG_API}`, config)
      .then((response) => {
        this.setState({ componentConfig: [...response.data.data.paginate] });

        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        this.setState({ result: false });
      });
  }

  handleCollapse(key, id) {
    key.preventDefault();
    this.setState((prevState) => ({ [id]: !prevState[id] }));
  }

  handleShowAddForm(key) {
    key.preventDefault();
    const { data } = this.state;

    if (data.LoanProperty.id.length < 1) {
      toast.error('Loan Property id is required');
      return;
    }

    this.setState({
      formOpenpc: true,
      newFormpc: true,
      componentConfigId: '',
      componentDimen: '',
      floorNumber: '',
      width: '',
      length: '',
    });
  }

  handleCreateConstructionPlan(key, propertyId, planID) {
    key.preventDefault();
    this.setState({ result: true });
    const { user } = verifyLogin();

    const url = `${apiList.CREATE_CONSTRUCTION_PLAN_API}/${propertyId}/${planID}/${user.id}`;

    axios.get(url)
      .then((response) => {
        if (response?.data?.result?.constructionPlan) {
          const { constructionPlanModuleName, constructionPlan } = response.data.result;
          this.setState({
            constructionPlan: [...constructionPlan],
            constructionModuleplanName: constructionPlanModuleName,
            constructionPlanLoaded: true,
            constructionPlanVersion: constructionPlan[0].version,
            isCurrentPlan: true,
          });
        }
        toast.success(`${JSON.stringify(response.data.message)}`);
        this.setState({ result: false, formAddPlanIDOpen: false });
      })
      .catch((error) => {
        toast.error(`${JSON.stringify(error.response)}`);
        this.setState({ result: false });
      });
  }

  handleCreateConstructionPlanButton(key) {
    key.preventDefault();
    const { data, planID } = this.state;

    if (!data || !data.LoanProperty
      || (data.LoanProperty.id.length < 1 && data.subscriberId.length < 1)) {
      toast.error('There is not data to generate plan.');
      return;
    }
    if (planID === -1) {
      toast.error('Please select Construction Plan Config.');
      return;
    }
    this.handleCreateConstructionPlan(key, data.LoanProperty.id, planID);
  }

  handleViewConstructionDetails(key, data) {
    key.preventDefault();
    const detailId = encrypt(JSON.stringify(data));
    const redirectUrl = `/view-construction-details/${detailId}`;
    window.open(redirectUrl, '_blank');
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleDeleteProperty(key, id) {
    key.preventDefault();
    const { data } = this.state;

    if (id.length < 1) {
      toast.error('Something wrong occured please try again');
      return;
    }

    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Property Details', feature: 'delete' } };
      this.setState({ result: true });

      axios.post(`${apiList.DELETE_CUSTOMERS_PROPERTY_COMPONENT_API}/${data.id}`, { id }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.setState({ data: { ...response.data.data }, dataLoaded: true });

          setTimeout(() => {
            this.setState({ result: false });
          }, successTimeOut);
        })
        .catch((error) => {
          this.setState({ data: {}, dataLoaded: false });
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, errorTimeOut);
        });
    } else {
      this.setState({ result: false });
    }
  }

  handleEditData(key, element) {
    key.preventDefault();
    this.setState({
      formOpenpc: true,
      newFormpc: false,
      propertyId: element.id,
      componentConfigId: element.componentConfigId,
      componentDimen: element.componentDimen,
      width: element.componentDimen.split('x')[1],
      length: element.componentDimen.split('x')[0],
      floorNumber: element.floorNumber,
    });
  }

  handleSaveAdd(key) {
    key.preventDefault();
    const {
      data, newFormpc, propertyId, componentConfig, floorNumber, componentConfigId, width, length,
    } = this.state;

    const componentDimen = `${length}x${width}`;
    if (data.id.length < 1 || floorNumber.length < 1 || componentConfigId.length < 1
      || componentDimen.length < 1 || floorNumber === '#78' || componentConfigId === '#78') {
      toast.error('Please fill the form fieds first');
      return;
    }

    this.setState({ result: true });
    const { session } = verifyLogin();

    if (!newFormpc) {
      const config = { headers: { session, permissionConfig: 'Property Component', feature: 'edit' } };
      axios.patch(`${apiList.UPDATE_CUSTOMERS_PROPERTY_COMPONENT_API}/${data.id}`, {
        id: propertyId,
        propertyId: data.LoanProperty.id,
        floorNumber,
        componentConfigId,
        componentDimen,
        componentName: componentConfigId ? `${componentConfig.find((element) => element.id === Number(componentConfigId)).componentName} ${componentDimen ? `(${componentDimen})` : '(0 x 0)'}` : '',
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.setState({ data: { ...response.data.data }, dataLoaded: true, formOpenpc: false });
          setTimeout(() => {
            this.setState({ result: false });
          }, successTimeOut);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
          setTimeout(() => {
            this.setState({ result: false });
          }, errorTimeOut);
        });
      return;
    }
    const config = { headers: { session, permissionConfig: 'Property Component', feature: 'add' } };
    axios.post(`${apiList.SAVE_CUSTOMERS_PROPERTY_COMPONENT_API}/${data.id}`, {
      propertyId: data.LoanProperty.id,
      floorNumber,
      componentConfigId,
      componentDimen,
      componentName: componentConfigId ? `${componentConfig.find((element) => element.id === Number(componentConfigId)).componentName} ${componentDimen ? `(${componentDimen})` : '(0 x 0)'}` : '',
    }, config)
      .then((response) => {
        this.setState({ data: { ...response.data.data }, dataLoaded: true, formOpenpc: false });

        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        this.setState({ result: false });
      });
  }

  getLoanDetails() {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    const detailId = dencrypt(this.props.match.params.detailId);
    axios.get(`${apiList.VEIW_CUSTOMERS_DETAILS_API}/${detailId}`, config)
      .then((response) => {
        const newData = { ...response.data.data };
        this.setState({
          data: newData,
          dataLoaded: true,
        });
        if (newData?.LoanDisbursal.length > 0) {
          const loanAmountDisbursed = JSON.parse(summation(newData.LoanDisbursal, 'disAmount'));
          const loanAmountTObeDisbursed = newData.sanctionedAmount - loanAmountDisbursed;
          const lastDisbarsalDate = moment.max(newData.LoanDisbursal.map((dis) => moment(dis.disDate, 'DD-MM-YYYY HH:mm:ss'))).format('DD-MM-YYYY');
          const lastDisbarsalAmount = newData.LoanDisbursal.find(
            (element) => moment(element.disDate, 'DD-MM-YYYY HH:mm:ss').format('DD-MM-YYYY') === lastDisbarsalDate,
          )?.disAmount;
          this.setState({
            loanAmountDisbursed,
            loanAmountTObeDisbursed,
            lastDisbarsalAmount,
            lastDisbarsalDate,
          });
        }

        if (response.data.data?.LoanProperty) {
          this.handleFetchExistingPlan(response.data.data.LoanProperty.id);
        }
        if (response.data.data?.LoanProperty?.PropertyComponent
          && response.data.data.LoanProperty.PropertyComponent.length > 0) {
          this.setState({ showPropertyComponents: true });
        }

        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error?.response?.data?.error || error?.response?.data?.message || 'Error Occuered'}`);
        this.setState({ result: false });
      });
  }

  bodyReportFormat = () => (
    <ReportFormatList
      type={1}
      OnContinue={(id) => {
        const params = {
          propertyId: this.state.data.LoanProperty.id,
          loanId: dencrypt(this.props.match.params.detailId),
          reportFormatType: id,
        };
        const detailId = encrypt(JSON.stringify(params));
        const redirectUrl = `/files/${detailId}`;
        window.open(redirectUrl, '_blank');
      }}
    />
  )

  body = () => (
    <FormLoanProperty
      handleChange={(id) => this.handleChange(id)}
      propertyDescription={this.state.propertyDescription}
      addressLine1={this.state.addressLine1}
      addressLine2={this.state.addressLine2}
      lat={this.state.lat}
      lng={this.state.lng}
    />
  )

  bodypc = () => (
    <FormPropertyComponent
      data={this.state.data}
      floorNameConfig={this.state.floorNameConfig}
      handleChange={(id) => this.handleChange(id)}
      setFloorNum={(event) => this.setState({ floorNumber: event.target.value })}
      setComponentConfigId={(event) => this.setState({ componentConfigId: event.target.value })}
      floorNumber={this.state.floorNumber}
      componentConfigId={this.state.componentConfigId}
      componentConfig={this.state.componentConfig}
      componentDimen={this.state.componentDimen}
      length={this.state.length}
      width={this.state.width}
      lng={this.state.lng}
    />
  )

  loanTaskBody = () => (
    <FormLoanTask
      branchIFSC={this.state.data.branchIFSC}
      subscriberId={this.state.data?.subscriberId}
      loanTaskDetails={this.state.loanTaskDetails}
      setLoanTaskDetails={(loanTaskDetails) => {
        this.setState({ loanTaskDetails });
      }}
    />
  )

  disbursementBody = () => (
    <FormLoanDisbursement
      disbursementAmount={this.state.disbursementAmount}
      disbursementPerc={this.state.disbursementPerc}
      disbursementDate={this.state.disbursementDate}
      totalPercentage={this.state.totalPercentage}
      handleChange={(id) => this.handleChange(id)}
      setDisbursementDate={(disbursementDate) => {
        this.setState({ disbursementDate });
      }}
    />
  )

  customerDetailsBodyForm = () => (
    <FormCustomerDetails
      loanID={this.state.data.id}
      ref={(el) => { this.refFromCustomer = el; }}
      owner={this.state.owner}
      contractor={this.state.contractor}
      projectManager={this.state.projectManager}
      externalValuer={this.state.externalValuer}
      ownerID={this.state.ownerID}
      contractorID={this.state.contractorID}
      projectManagerID={this.state.projectManagerID}
      externalValuerID={this.state.externalValuerID}
      geoTaggPermission={this.state.geoTaggPermission}
      mediaSubmissionNoPlanPermission={this.state.mediaSubmissionNoPlanPermission}
      mediaSubmissionPeriodPermission={this.state.mediaSubmissionPeriodPermission}
      complete={() => {
        this.getLoanDetails();
        this.setState({ formCustomerIDsOpen: false });
      }}
      mapCircleRadius={this.state.mapCircleRadius}
      isMediaSubmissionAllowedWithoutConsPlan={this.state.isMediaSubmissionAllowedWithoutConsPlan}
      gapForNextMediaSubmission={this.state.gapForNextMediaSubmission}
      disbursementSubmissionAllow={this.state.disbursementSubmissionAllow}
    />
  )

  createConstructionPlanBody = () => (
    <FormCreateConstructionPlan
      subscriberId={this.state.data?.subscriberId}
      planName={this.state.planName}
      noOfFloors={this.state.data?.LoanProperty?.noOfFloors}
      loanTypeId={this.state.data?.loanTypeId}
      planID={this.state.planID}
      setPlanID={(planID) => {
        this.setState({ planID });
      }}

    />
  )

  saveCustomerDetails = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'edit' } };
    const params = {};
    const {
      ownerID, contractorID, projectManagerID, externalValuerID,
    } = this.state;
    const { complete } = this.props;
    if (ownerID === -1 && contractorID === -1
      && projectManagerID === -1 && externalValuerID === -1) {
      return;
    }
    if (ownerID) {
      params.ownerID = ownerID;
    }
    if (contractorID) {
      params.contractorID = contractorID;
    }
    if (projectManagerID) {
      params.projectManagerID = projectManagerID;
    }
    if (externalValuerID) {
      params.externalValuerID = externalValuerID;
    }
    axios.post(`${apiList.SAVE_CUSTOMER_ID_DETAILS}`, params, config)
      .then((response) => {
        if (response) {
          complete();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  updatePropertyDetails(key) {
    key.preventDefault();
    const {
      data, propertyDescription, addressLine1, addressLine2, lat, lng,
    } = this.state;

    const pattern = new RegExp('^-?([1-8]?[1-9]|[1-9]0)\\.{1}\\d{1,6}');
    if (!pattern.test(lat)) {
      toast.error('Invalid latitude');
      return;
    }
    if (!pattern.test(lng)) {
      toast.error('Invalid longitude');
      return;
    }

    if (data.id.length < 1 || addressLine1.length < 1
      || addressLine2.length < 1 || lat.length < 1 || lng.length < 1) {
      toast.error('Please fill the form fieds first');
      return;
    }

    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Property Details', feature: 'edit' } };
    axios.patch(`${apiList.UPDATE_CUSTOMERS_PROPERTY_DETAILS}`, {
      id: data.LoanProperty.id,
      loanId: data.id,
      propertyDescription,
      addressLine1,
      addressLine2,
      lat,
      lng,
    }, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({ formOpen: false });
        this.setState({ data: { ...response.data.data }, dataLoaded: true, formOpenpc: false });
        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  saveLoanTask() {
    const { loanTaskDetails, data } = this.state;
    if (loanTaskDetails.taskTypeId?.length < 1
      || loanTaskDetails.ownerId?.length < 1
      || !loanTaskDetails?.plannedDate || loanTaskDetails?.plannedDate?.length < 1) {
      toast.error('Please Fill all the details');
      return;
    }
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: loanTaskDetails.id ? 'edit' : 'add' } };
    const params = loanTaskDetails;
    params.loanId = data.id;
    axios.post(`${apiList.SAVE_LOAN_TASK}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          loanTaskDetails: {},
        });
        this.setState({ formLoanTaskOpen: false });
        this.taskConfigTableRef.current.getTasks();
        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  saveLoanDisbursement() {
    const {
      disbursementAmount, disbursementPerc,
      disbursementDate, data, disbursementId,
    } = this.state;
    console.log('disbursementDate', disbursementDate);
    if (disbursementAmount.length < 1 || !disbursementDate || disbursementDate?.length < 1) {
      toast.error('Please Fill all the details');
      return;
    }
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Disbursement', feature: disbursementId ? 'edit' : 'add' } };
    const params = {
      loanId: data.id,
      disAmountPercentage: disbursementPerc / 100,
      disAmount: disbursementAmount.trim(),
      disDate: disbursementDate,
    };
    if (disbursementId) {
      params.id = disbursementId;
    }
    axios.post(`${apiList.UPDATE_DISBURSEMENT}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.disbumentTableRef.current.getDisbursement();
        this.setState({
          disbursementAmount: '',
          disbursementPerc: '',
          totalPercentage: 0,
          disbursementDate: '',
          disbursementId: null,
          disbursementModalOpen: false,
        });
        this.getLoanDetails();
        this.setState({ result: false });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  render() {
    const {
      result,
      sectionOne,
      sectionTwo,
      sectionThree,
      sectionFour,
      sectionFive,
      sectionSix,
      sectionSeven,
      sectionNine,

      data,
      constructionPlan,

      dataLoaded,
      constructionPlanLoaded,

      propertyDescription,
      addressLine1,
      addressLine2,
      lat,
      lng,

      formOpen,
      newForm,
      formOpenpc,
      newFormpc,
      showAlert,
      formCustomerIDsOpen,
      formAddPlanIDOpen,

      sectionEight,
      formLoanTaskOpen,
      loanTaskDetails,
      showContructionPlanModuleAlert,
      showDownloadPDFError,
      disbursementId,
      disbursementModalOpen,

      loanPermission,
      customerPermission,
      disbursementPermission,
      propDetailsPermission,
      propComponentsPermission,
      constructionPlanPermission,
      loanTaskPermission,
      disRequestPermission,
      loanAmountDisbursed,
      loanAmountTObeDisbursed,
      lastDisbarsalDate,
      lastDisbarsalAmount,
      showPropertyComponents,
      constructionModuleplanName,
      constructionPlanVersion,
      isCurrentPlan,
      geoTaggPermission,
      mediaSubmissionNoPlanPermission,
      mediaSubmissionPeriodPermission,
      reportFormatListModal,

    } = this.state;

    verifyLogin();
    return (
      <div className='view-details'>

        {result === true ? <Loading MainProps={this.props} /> : null}

        <ToastContainer />

        <CustomModal
          width='xs'
          open={reportFormatListModal}
          setOpen={(value) => this.setState({ reportFormatListModal: value })}
          newForm={false}
          body={this.bodyReportFormat()}
          showAction={false}
          handleSaveData={(key) => this.handleSubmitData(key)}
          title='Select Report Format'
        />

        <CustomAlert
          open={showAlert}
          negtiveBtn='Ok'
          title='No Property!'
          content='Please link property to Loan to add property components'
          handleClose={() => {
            this.setState({ showAlert: false });
          }}
        />

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.setState({ formOpen: value })}
          newForm={newForm}
          body={this.body()}
          handleSaveData={(key) => this.updatePropertyDetails(key)}
          title='Update Property Details'
        />

        <CustomModal
          open={formOpenpc}
          setOpen={(value) => this.setState({ formOpenpc: value })}
          newForm={newFormpc}
          body={this.bodypc()}
          handleSaveData={(key) => this.handleSaveAdd(key)}
          title={newFormpc ? 'Add Property Component' : 'Update Property Component'}
        />

        <CustomModal
          open={formLoanTaskOpen}
          setOpen={(value) => this.setState({ formLoanTaskOpen: value })}
          newForm={!loanTaskDetails.id}
          body={this.loanTaskBody()}
          handleSaveData={() => this.saveLoanTask()}
          title={!loanTaskDetails.id ? 'Add Loan Task' : 'Update Loan Task'}
        />

        <CustomModal
          open={disbursementModalOpen}
          setOpen={(value) => this.setState({ disbursementModalOpen: value })}
          newForm={!disbursementId}
          body={this.disbursementBody()}
          handleSaveData={() => this.saveLoanDisbursement()}
          title={!disbursementId ? 'Add Disbursement' : 'Update Disbursement'}
        />

        <CustomModal
          // width='md'
          open={formCustomerIDsOpen}
          setOpen={(value) => this.setState({ formCustomerIDsOpen: value })}
          newForm={false}
          body={this.customerDetailsBodyForm()}
          handleSaveData={() => this.refFromCustomer.saveCustomerDetails()}
          title='Update Customer Details'
        />

        <CustomAlert
          open={showContructionPlanModuleAlert}
          handleYes={() => {
            this.setState({
              formAddPlanIDOpen: true,
              planID: -1,
              planName: '',
              showContructionPlanModuleAlert: false,
            });
          }}
          title='Do you wanna proceed?'
          content='Are you sure you want to Reset plan? It will delete all the data and progress summited for this loan.'
          handleClose={() => {
            this.setState({ showContructionPlanModuleAlert: false });
          }}
        />

        <CustomAlert
          open={showDownloadPDFError}
          okTitle='Ok'
          hideCancel
          handleYes={() => {
            this.setState({
              showDownloadPDFError: false,
            });
          }}
          title='Take action on Submitted Activities'
          content='You need to verify or reject activities which are under submitted/uploading status for this loan before you can download interim valuation report. '
          handleClose={() => {
            this.setState({ showDownloadPDFError: false });
          }}
        />

        <CustomModal
          width='xs'
          open={formAddPlanIDOpen}
          setOpen={(value) => this.setState({ formAddPlanIDOpen: value, planID: -1, planName: '' })}
          newForm={false}
          body={this.createConstructionPlanBody()}
          handleSaveData={(key) => {
            this.handleCreateConstructionPlanButton(key);
            this.setState({ formAddPlanIDOpen: false });
          }}
          title='Choose Construction Plan Module'
        />

        <Navbar MainProps={this.props} />

        <div className='contents'>

          <div className='loan-details'>

            <div className='data-headerEdit'>
              <div>
                {sectionOne
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionOne'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionOne'); }} />}
                <span>
                  Customer Details
                </span>
              </div>
              {sectionOne && (geoTaggPermission.edit
                || mediaSubmissionNoPlanPermission.edit
                 || mediaSubmissionPeriodPermission.edit) && (
                 <Button
                   className='buttonRight'
                   color='primary'
                   size='small'
                   onClick={() => {
                     this.setState({
                       formCustomerIDsOpen: true,
                       owner: data.Owner ? `${data.Owner?.firstName} ${data.Owner?.lastName}` : '',
                       contractor: data.Contractor ? `${data.Contractor?.firstName} ${data.Contractor?.lastName}` : '',
                       projectManager: data.ProjectManager ? `${data.ProjectManager?.firstName} ${data.ProjectManager?.lastName}` : '',
                       externalValuer: data.ExternalValuer ? `${data.ExternalValuer?.firstName} ${data.ExternalValuer?.lastName}` : '',
                       ownerID: data.Owner ? data.Owner?.id : -1,
                       contractorID: data.Contractor ? data.Contractor?.id : -1,
                       projectManagerID: data.ProjectManager ? data.ProjectManager?.id : -1,
                       externalValuerID: data.ExternalValuer ? data.ExternalValuer?.id : -1,
                       mapCircleRadius: data.mapCircleRadius,
                       isMediaSubmissionAllowedWithoutConsPlan:
                          data.isMediaSubmissionAllowedWithoutConsPlan,
                       gapForNextMediaSubmission: data.gapForNextMediaSubmission,
                       disbursementSubmissionAllow: data.disbursementSubmissionAllow,
                     });
                   }}
                 >
                   Edit Customer
                 </Button>
              )}
            </div>

            {sectionOne && customerPermission.view && dataLoaded && data
              ? (
                <div className='container'>

                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Name :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.custName || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Mobile :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.custMobile || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Email :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.custEmail || 'N/A'}`}</p>
                      </div>
                    </div>
                    {geoTaggPermission.view ? (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          <p className='label'>Geo Tagging :</p>
                        </div>
                        <div className='innercontainerText'>
                          <p className='text'>{data?.mapCircleRadius === '-1' ? 'Disabled' : 'Enabled'}</p>
                        </div>
                      </div>
                    ) : null}

                    {mediaSubmissionNoPlanPermission.view ? (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          <p className='label'>Allow Progress w/o Plan :</p>
                        </div>
                        <div className='innercontainerText'>
                          <p className='text'>{data.isMediaSubmissionAllowedWithoutConsPlan === 'true' ? 'True' : 'False'}</p>
                        </div>
                      </div>
                    ) : null}

                    {mediaSubmissionPeriodPermission.view ? (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          <p className='label'>Media Submission Period :</p>
                        </div>
                        <div className='innercontainerText'>
                          <p className='text'>{`${data.gapForNextMediaSubmission || 0} days`}</p>
                        </div>
                      </div>
                    ) : null}

                    {/* <p className='label'>Name :</p> */}

                  </div>
                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Eligible for Request :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{data.disbursementSubmissionAllow === 'false' ? 'No' : 'Yes'}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Contractor :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.Contractor ? (`${data.Contractor?.firstName} ${data.Contractor?.lastName}`) : 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Project Manager :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.ProjectManager ? (`${data.ProjectManager?.firstName} ${data.ProjectManager?.lastName}`) : 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>External Valuer :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.ExternalValuer ? (`${data.ExternalValuer?.firstName} ${data.ExternalValuer?.lastName}`) : 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Created At :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${shortDate(data.createdAt) || 'N/A'}`}</p>
                      </div>
                    </div>

                  </div>

                </div>
              )
              : null}

            <div className='data-header'>
              {sectionTwo
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionTwo'); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionTwo'); }} />}
              <span>
                Loan Details
              </span>
            </div>

            {sectionTwo && loanPermission.view && dataLoaded && data
              ? (
                <div className='container'>
                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Loan Number :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.loanNumber || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Loan Type :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{data?.LoanType?.loanType}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Branch Name :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data?.AccessLevelConfig?.branchName || 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Property Value :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${addCommas(data.propertyValue) || 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Construction Value :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${addCommas(data.constructionValue) || 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Land Value :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${addCommas(data.landValue) || 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>LTV :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{data.ltv ? `${data.ltv}%` : 'N/A'}</p>
                      </div>
                    </div>

                  </div>
                  <div className='login-form1'>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Sanctioned Date :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${shortDate(data.sanctionedDate) || 'N/A'}`}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Sanctioned Amount :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${addCommas(data.sanctionedAmount) || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Loan amount disbursed :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>
                          {`${loanAmountDisbursed ? addCommas(loanAmountDisbursed) : 'N/A'}`}
                        </p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Loan amount to be disbursed :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>
                          {`${loanAmountTObeDisbursed ? addCommas(loanAmountTObeDisbursed) : 'N/A'}`}
                        </p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Last disbursal date :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>
                          {lastDisbarsalDate || '-'}
                        </p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Last disbursal amount :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>
                          {`${lastDisbarsalAmount ? addCommas(lastDisbarsalAmount) : 'N/A'}`}
                        </p>
                      </div>
                    </div>

                  </div>

                </div>
              )
              : null}

            <div className='data-headerEdit'>
              <div>
                {sectionFour
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionFour'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionFour'); }} />}
                <span>
                  Property Details
                </span>
              </div>

              {data.LoanProperty && propDetailsPermission.edit && sectionFour && (
                <Button
                  className='buttonRight'
                  color='primary'
                  size='small'
                  onClick={() => {
                    this.setState({
                      formOpen: true,
                      propertyDescription: data?.LoanProperty?.propertyDescription || '',
                      addressLine2: data?.LoanProperty?.addressLine2 || '',
                      addressLine1: data?.LoanProperty?.addressLine1 || '',
                      lat: data?.LoanProperty?.lat || '',
                      lng: data?.LoanProperty?.lng || '',
                    });
                  }}
                >
                  Edit Property
                </Button>
              )}
            </div>

            {sectionFour && propDetailsPermission.view && dataLoaded && data.LoanProperty
              ? (
                <div className='container'>
                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Property Details  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.propertyDescription || propertyDescription || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Address Line 1 :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.addressLine1 || addressLine1 || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Address Line 2 :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.addressLine2 || addressLine2 || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Pincode  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.pincode || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>City  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.cityTown || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>District  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.district || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>State  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.state || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div>
                      {(!data?.LoanProperty?.PropertyComponent
                        || data.LoanProperty.PropertyComponent.length < 1)
                        && !showPropertyComponents ? (
                          <div className='containerLabel'>
                            <div className='innercontainerLabel'>
                              <p className='label'>Property Components :</p>
                            </div>
                            <div
                              className='innercontainerText'
                              onClick={() => {
                                this.setState({ showPropertyComponents: true });
                              }}
                            >
                              <p className='linktext'>Click here to add</p>
                            </div>
                          </div>
                        ) : null}
                    </div>

                  </div>
                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Basement :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{data.LoanProperty.basement === 'true' ? 'Yes' : 'No'}</p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Structure Type :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>
                          {`${data.LoanProperty.structureType
                            ? (data.LoanProperty.structureType === '1' ? 'RCC Frame' : 'Load Bearing') : 'N/A'}`}
                        </p>
                      </div>
                    </div>

                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Land Area(sqft) :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.landArea || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>No of Floor :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.noOfFloors || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>BUA(sqft) :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.builtUpArea || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Latitude :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.lat || lat || 'N/A'}`}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Longitude  :</p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{`${data.LoanProperty.lng || lng || 'N/A'}`}</p>
                      </div>
                    </div>
                    {(data.LoanProperty.lng || lng) && (data.LoanProperty.lat || lat) ? (
                      <div className='containerLabel'>
                        <div className='innercontainerLabel'>
                          <p className='label'>Google Map Link :</p>
                        </div>
                        <div
                          className='innercontainerText'
                          onClick={() => {
                            if ((data.LoanProperty.lng || lng) && (data.LoanProperty.lat || lat)) {
                              const longitude = data.LoanProperty.lng || lng;
                              const latitude = data.LoanProperty.lat || lat;
                              window.open(`https://www.google.com/maps/place/${latitude},${longitude}`, '_blank');
                            }
                          }}
                        >
                          <p className='map'>Open Location in Google Map</p>
                        </div>
                      </div>
                    ) : null}

                  </div>

                </div>
              )
              : null}

            {showPropertyComponents ? (
              <div className='data-headerEdit'>
                <div>
                  {sectionFive
                    ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionFive'); }} />
                    : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionFive'); }} />}
                  <span>
                    Property Component Details
                  </span>
                </div>

                {sectionFive && propComponentsPermission.add && (
                  <Button
                    className='buttonRight'
                    color='primary'
                    size='small'
                    onClick={(key) => (data.LoanProperty
                      ? this.handleShowAddForm(key) : this.setState({ showAlert: true }))}
                  >
                    Add Property Component
                  </Button>
                )}
              </div>
            ) : null}

            {sectionFive && propComponentsPermission.view && showPropertyComponents && (
              <PropertyComponentDetailsTable
                propComponentsPermission={propComponentsPermission}
                handleShowAddForm={(key) => { this.handleShowAddForm(key); }}
                data={data?.LoanProperty?.PropertyComponent
                  ? data.LoanProperty.PropertyComponent : []}
                handleEditData={(key, element) => { this.handleEditData(key, element); }}
                handleDeleteProperty={(key, i) => { this.handleDeleteProperty(key, i); }}
              />
            )}

            <div className='data-headerEdit'>
              <div>
                {sectionSix
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionSix'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionSix'); }} />}
                <span>
                  Construction Plan Details
                </span>
              </div>
              {sectionSix && constructionPlanVersion > 1 && constructionPlanPermission.view ? (
                <Button
                  className='buttonRight'
                  color='primary'
                  size='small'
                  onClick={() => {
                    const params = {
                      constructionPlanPermission,
                      propertyId: data.id,
                      version: constructionPlanVersion,
                    };
                    const paramsEnc = encrypt(JSON.stringify(params));
                    const redirectUrl = `/old-plans/${paramsEnc}`;
                    window.open(redirectUrl, '_blank');
                  }}
                >
                  Old Plans
                </Button>
              ) : null}
            </div>

            {sectionSix && constructionPlanPermission.view && constructionPlanVersion > 0
              ? (
                <div className='container'>
                  <div className='login-form1'>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Construction Plan Name : </p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{constructionModuleplanName}</p>
                      </div>
                    </div>
                    <div className='containerLabel'>
                      <div className='innercontainerLabel'>
                        <p className='label'>Construction Plan Version : </p>
                      </div>
                      <div className='innercontainerText'>
                        <p className='text'>{constructionPlanVersion}</p>
                      </div>
                    </div>
                  </div>
                </div>
              )
              : null}
            {sectionSix && data && data.LoanProperty && constructionPlanPermission.view && (
              <>
                <ConstructionPlanTable
                  isCurrentPlan
                  propertyId={data.LoanProperty.id}
                  constructionPlanPermission={constructionPlanPermission}
                  data={constructionPlanLoaded === true
                    && constructionPlan && constructionPlan.length > 0 ? constructionPlan : []}
                />
              </>
            )}

            {isCurrentPlan && sectionSix && constructionPlanPermission.add && (
              <div className='extra-button'>
                <button
                  type='submit'
                  className='updateBtn'
                  onClick={() => {
                    if (constructionPlan.length > 0) {
                      this.setState({ showContructionPlanModuleAlert: true });
                    } else {
                      this.setState({
                        formAddPlanIDOpen: true,
                        planID: -1,
                        planName: '',
                      });
                    }
                  }}
                >
                  {constructionPlan.length > 0 ? 'Reset Plan' : 'GENERATE PLAN'}
                </button>
                {constructionPlan.length > 0 && !constructionPlan.every(({ status }) => status === 'Yet to start') ? (
                  <button
                    type='submit'
                    className='updateBtn'
                    onClick={() => {
                      if (constructionPlan.some(({ status }) => status === 'Submitted' || status === 'Uploading')) {
                        this.setState({ showDownloadPDFError: true });
                      } else {
                        this.setState({ reportFormatListModal: true });
                      }
                    }}
                  >
                    Download Progress Report
                  </button>
                ) : null}

              </div>
            )}

            <div className='data-headerEdit'>
              <div>
                {sectionNine
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionNine'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionNine'); }} />}
                <span>
                  Construction Progress (Photos & Videos)
                </span>
              </div>
            </div>

            {sectionNine && data?.LoanProperty && constructionPlanPermission.view && (
              <UncategorizedMediaTable
                loanId={dencrypt(this.props.match.params.detailId)}
                loanNumber={data.loanNumber}
                propertyDescription={propertyDescription}
                address={`${data.LoanProperty.addressLine1} ${data.LoanProperty.addressLine2} ${data.LoanProperty.cityTown},  ${data.LoanProperty.district}`}
                lat={data.LoanProperty.lat}
                lng={data.LoanProperty.lng}
              />
            )}

            <div className='data-headerEdit'>
              <div>
                {sectionEight
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionEight'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionEight'); }} />}
                <span>
                  Task
                </span>
              </div>

              {sectionEight && loanTaskPermission.add && (
                <Button
                  className='buttonRight'
                  color='primary'
                  size='small'
                  onClick={() => {
                    this.setState({
                      loanTaskDetails: { loanId: data.id },
                      formLoanTaskOpen: true,
                    });
                  }}
                >
                  Add Loan Task
                </Button>
              )}
            </div>

            {sectionEight && loanTaskPermission.view && (
              <TaskTable
                ref={this.taskConfigTableRef}
                loanId={dencrypt(this.props.match.params.detailId)}
                loanTaskPermission={loanTaskPermission}
                handleEditData={(key, element) => {
                  this.setState({
                    loanTaskDetails: element,
                    formLoanTaskOpen: true,
                  });
                }}
              />
            )}

            <div className='data-header'>
              {sectionSeven
                ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionSeven'); }} />
                : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionSeven'); }} />}
              <span>
                Disbursal Requests  Details
              </span>

            </div>

            {sectionSeven && disRequestPermission.view && (
              <DisbursalTable
                subscriberName={data.Subscribers?.subscriberName}
                branchIFSC={data.branchIFSC}
                loanAmountDisbursed={loanAmountDisbursed}
                loanId={dencrypt(this.props.match.params.detailId)}
                disRequestPermission={disRequestPermission}
              />
            )}

            <div className='data-headerEdit'>
              <div>
                {sectionThree
                  ? <KeyboardArrowDownRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionThree'); }} />
                  : <KeyboardArrowUpRoundedIcon className='icon' onClick={(key) => { this.handleCollapse(key, 'sectionThree'); }} />}
                <span>
                  Disbursement  Details
                </span>
              </div>

              {sectionThree && disbursementPermission.add && (
                <Button
                  className='buttonRight'
                  color='primary'
                  size='small'
                  onClick={() => {
                    this.setState({
                      disbursementModalOpen: true,
                      disbursementId: null,
                      disbursementDate: null,
                      disbursementAmount: '',
                      disbursementPerc: '',
                      totalPercentage: dataLoaded && data && data.LoanDisbursal.length > 0
                        ? summation(data.LoanDisbursal, 'disAmountPercentage').toFixed(2) * 100 : 0,
                    });
                  }}
                >
                  Add Disbursement
                </Button>
              )}
            </div>

            {sectionThree && disbursementPermission.view && (
              <DisbursementTable
                ref={this.disbumentTableRef}
                data={dataLoaded && data
                  && data.LoanDisbursal?.length > 0 ? data.LoanDisbursal : []}
                loanId={dencrypt(this.props.match.params.detailId)}
                disbursementPermission={disbursementPermission}
                deleteDisbursement={() => {
                  this.getLoanDetails();
                }}
                handleviewDetails={() => { }}
                handleEditData={(key, element) => {
                  this.setState({
                    disbursementModalOpen: true,
                    disbursementId: element.id,
                    disbursementDate: element.disDate,
                    disbursementAmount: element.disAmount,
                    disbursementPerc: shortData(element.disAmountPercentage) * 100,
                    totalPercentage: dataLoaded && data && data.LoanDisbursal.length > 0
                      ? (summation(data.LoanDisbursal, 'disAmountPercentage').toFixed(2) - element.disAmountPercentage) * 100 : 0,
                  });
                }}
              />
            )}

          </div>

        </div>
      </div>
    );
  }
}

export default LoanDetails;
