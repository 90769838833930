import React, { Component } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faKey,
  faUser,
  faQuestion,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import Button from '@material-ui/core/Button';
import AccountCircleRoundedIcon from '@material-ui/icons/AccountCircleRounded';
import { fade, withStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import HelpIcon from '@material-ui/icons/Help';
import Paper from '@material-ui/core/Paper';
import { Link } from '@material-ui/core';
import axios from 'axios';
import { apiList, verifyLogin } from '../../helpers';
import Loading from '../loading/loading';

const Logo = require('../../assets/images/logo.png').default;

const StyledMenu = withStyles({
  paper: {
    backgroundColor: '#3D4977',
  },
})((props) => (
  <Paper>
    <Menu
      elevation={5}
      getContentAnchorEl={null}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'center',
      }}
      {...props}
    />
  </Paper>
));

const useStyles = (theme) => ({
  grow: {
    flexGrow: 1,
    height: 64,
  },
  paper: {
    border: '1px solid #d3d4d5',
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  logo: {
    height: 30,
    width: 30,
    marginRight: 20,
    filter: 'invert(1%) sepia(1%) saturate(1%) hue-rotate(1deg) brightness(1000%) contrast(80%)',
  },
  buttonSelected: {
    color: 'orange',
  },
  button: {
    color: '#cccccc',
  },
  menuItem: {
    width: 160,
    color: '#fff',
    height: 30,
    fontWeight: '100',
  },
  subMenuItem: {
    paddingLeft: 48,
    width: 160,
    color: '#fff',
    backgroundColor: '#000',
    height: 30,
    fontSize: 14,
  },
  menuLink: {
    color: '#fff',
  },
  icon: {
    marginRight: 16,
    fontSize: '14px',
    cursor: 'pointer',
    color: 'white',
  },
  mainContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  leftContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
  },
  profilePicture: {
    height: 42,
    width: 42,
    borderRadius: 24,
    border: 1,
    cursor: 'pointer',
  },
  title: {
    padding: 20,
    color: 'gray',
  },
  titleSelected: {
    padding: 20,
    color: 'black',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(3),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  sectionDesktop: {
    display: 'none',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
    },
  },
  sectionMobile: {
    display: 'flex',
    [theme.breakpoints.up('md')]: {
      display: 'none',
    },
  },
});

class navbar extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      userName: '',
      userRoleId: null,
      anchorEl: null,
      sopURL: '',
      openHelp: false,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    this.setState({
      userName: user.firstName,
      userRoleId: user.userRoleId,
    });
    this.getSOP();
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null, openHelp: false });
  };

  handleLogout(key) {
    key.preventDefault();
    this.setState({ result: true });
    localStorage.removeItem('data');

    const redirectUrl = '/';
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, 1000);
  }

  handleRedirect(key, redirectUrl) {
    key.preventDefault();
    this.setState({ result: true });
    setTimeout(() => {
      window.location.href = redirectUrl;
    }, 500);
  }

  getSOP() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(apiList.GET_SUBS_SOP, config)
      .then((response) => {
        console.log(response.data.data);
        if (response.data.data) {
          this.setState({ sopURL: response.data.data });
        }
      })
      .catch((e) => {
        console.log(e);
        this.setState({ result: false });
      });
  }

  render() {
    const { classes } = this.props;

    const {
      result,
      userName,
      userRoleId,
      anchorEl,
      sopURL,
      openHelp,
    } = this.state;

    return (
      <div className={classes.grow}>

        {result === true ? <Loading MainProps={this.props.MainProps} /> : null}
        <AppBar position='static' color='primary'>
          <Toolbar>
            <div className={classes.mainContainer}>
              <div className={classes.leftContainer}>
                <img
                  className={classes.logo}
                  src={Logo}
                  alt='logo'
                //  onClick={(key) => { this.handleRedirect(key, '/home'); }}
                />
                {/* <Button href='/home' className=
                 {this.props.MainProps.match.path === '/home' ?
                 classes.buttonSelected : classes.button}>Home</Button> */}
                <Button href='/customers-accounts' className={this.props.MainProps.match.path === '/customers-accounts' ? classes.buttonSelected : classes.button}>Loans</Button>
                {/* <Button href='/property' className={this.props.MainProps.match.path
                  === '/property' ?
                  classes.buttonSelected : classes.button}>Property</Button> */}
                <Button href='/construction-plan' className={this.props.MainProps.match.path === '/construction-plan' ? classes.buttonSelected : classes.button}>Construction Plans</Button>
                <Button href='/request' className={this.props.MainProps.match.path === '/request' ? classes.buttonSelected : classes.button}>Requests</Button>
                <Button href='/loan-map' className={this.props.MainProps.match.path === '/loan-map' ? classes.buttonSelected : classes.button}>Maps</Button>
                <Button
                  href='/loan-tasks'
                  className={this.props.MainProps
                    .match.path === '/loan-tasks'
                    ? classes.buttonSelected : classes.button}
                >
                  Loan Tasks
                </Button>
                {/* <Button
                  href='/reports'
                  className={this.props.MainProps
                    .match.path === '/reports'
                    ? classes.buttonSelected : classes.button}
                >
                  Reports
                </Button> */}
              </div>
              <div>
                <Button className={classes.button}>{userName}</Button>

                <IconButton
                  aria-label='account of current user'
                  aria-controls='menu-appbar'
                  aria-haspopup='true'
                  onClick={this.handleMenu}
                  onMouseOver={this.handleMenu}
                  color='inherit'
                >
                  {/* <AccountCircle /> */}
                  <AccountCircleRoundedIcon fontSize='large' />

                </IconButton>
                <StyledMenu
                  id='menu-appbar'
                  anchorEl={anchorEl}
                  keepMounted
                  open={!!this.state.anchorEl}
                  onClose={this.handleClose}
                >
                  <MenuItem className={classes.menuItem}>
                    <Link className={classes.menuLink} href='/profile'>
                      <FontAwesomeIcon
                        icon={faUser}
                        className={classes.icon}
                      />
                      Profile
                    </Link>

                  </MenuItem>
                  {(userRoleId === 1 || userRoleId === 2)
                    && (
                      <MenuItem
                        className={classes.menuItem}
                      >
                        <Link className={classes.menuLink} href='/admin'>
                          <FontAwesomeIcon icon={faKey} className={classes.icon} />
                          Admin
                        </Link>
                      </MenuItem>
                    )}
                  <MenuItem
                    className={classes.menuItem}
                    onMouseOver={() => this.setState({ openHelp: !openHelp })}
                    onClick={() => this.setState({ openHelp: !openHelp })}
                  >
                    <HelpIcon icon={faQuestion} className={classes.icon} />
                    Help
                  </MenuItem>
                  {openHelp ? (
                    <>
                      <MenuItem
                        className={classes.subMenuItem}
                        onClick={() => {
                          this.setState({ openHelp: false });
                          window.open('https://app-related-images-prod.s3.ap-south-1.amazonaws.com/HomeHub+User+Manual.pdf', '_blank');
                        }}
                      >
                        User Manual
                      </MenuItem>
                      <MenuItem
                        className={classes.subMenuItem}
                        onClick={() => {
                          this.setState({ openHelp: false });
                          window.open(sopURL, '_blank');
                        }}
                      >
                        SOP
                      </MenuItem>
                    </>
                  ) : null}
                  <MenuItem
                    className={classes.menuItem}
                    onClick={(key) => { this.handleLogout(key); }}
                  >
                    <FontAwesomeIcon icon={faSignOutAlt} className={classes.icon} />
                    Logout
                  </MenuItem>

                </StyledMenu>
              </div>
            </div>

          </Toolbar>
        </AppBar>

      </div>
    );
  }
}

export default withStyles(useStyles)(navbar);
