import React, { useEffect, useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
// import moment from 'moment';
import {
  apiList,
  verifyLogin,
  validateLoanDetails,
  validatePropertyDetails,
} from '../../helpers';
import FormLoan from './FormLoan';
import FormLoanProperty from './FormLoanProperty';
import Loading from '../../components/loading/loading';

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const { classes, onClose } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['Loan Detail', 'Property Detail'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    position: 'relative',
    height: 'calc(100vh - 147px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  list: {
    width: 300,
  },
}));

export default function OnBoardingDialog({
  open, handleClose, loanId, loanTypes,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [newLoan, setNewLoan] = useState({ });
  const [newProperty, setNewProperty] = useState({});
  const steps = getSteps();

  const saveLoan = () => {
    setLoading(true);
    const { session, user } = verifyLogin();
    const { subscriberId } = user;
    const params = { ...newLoan, ...newProperty };
    const config = { headers: { session, permissionConfig: 'Loan', feature: params.id ? 'edit' : 'add' } };
    params.propertyId = newProperty.id;
    params.id = newLoan.id;
    params.custName = `${newLoan.custFirstName} ${newLoan.custLastName}`;
    params.subscriberId = newLoan.subscriberId || Number(subscriberId);
    axios.post(`${params.id ? apiList.UPDATE_LOAN : apiList.CREATE_LOAN}`, params, config)
      .then(() => {
        setLoading(false);
        handleClose(true);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const handleNext = () => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
        {
          const error = validateLoanDetails(newLoan);
          console.log(newLoan);
          if (error) {
            toast.error(error);
            break;
          } else {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        }
        case 1:
        {
          const error = validatePropertyDetails(newProperty);
          if (error) {
            toast.error(error);
            break;
          } else {
            saveLoan();
          }
          break;
        }
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    switch (activeStep) {
      case 0:
        return 'CONTINUE';
      case 1:
        return 'SAVE';
      default:
        return 'Next';
    }
  };

  useEffect(() => {
    if (loanId) {
      setLoading(true);
      const { session } = verifyLogin();
      const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
      axios.get(`${apiList.VEIW_CUSTOMERS_DETAILS_API}/${loanId}`, config)
        .then((response) => {
          setLoading(false);
          setNewLoan({ ...response.data.data });
          setNewProperty({ ...response.data.data?.LoanProperty });
        })
        .catch((error) => {
          setLoading(false);
          console.log(error);
          setNewLoan({});
        });
    }
  }, [loanId]);

  return (
    <Dialog
      fullScreen
      fullWidth
      maxWidth='lg'
      open={open}
      onClose={() => {
        handleClose();
      }}
    >
      {loading ? <Loading /> : null}
      <ToastContainer />

      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton
        id='plan'
        onClose={() => {
          handleClose();
        }}
      />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >
            <div className={classes.slide}>
              <FormLoan
                newLoan={newLoan}
                setNewLoan={setNewLoan}
                loanTypes={loanTypes}
              />
            </div>
            <div className={classes.slide}>
              <FormLoanProperty newProperty={newProperty} setNewProperty={setNewProperty} />
            </div>
          </SwipeableViews>
        </div>

      </DialogContent>

      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' onClick={handleNext}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
          </div>

        )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={loading || activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        )}
      />
    </Dialog>
  );
}
