/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment';

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';
import CustomDatePicker from '../../components/CustomDatePicker/CustomDatePicker';

import CustomInput from '../../components/CustomInput/CustomInput';
import { verifyLogin, apiList } from '../../helpers';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
  },
  container: {
    width: '400',
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'column',
  },
}));

export default function FormLoan({
  newLoan = {}, setNewLoan, loanTypes,
}) {
  const classes = useStyles();
  const [accessLevels, setAccessLevel] = useState([]);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    const accessLevel = accessLevels.find((item) => item.ifscCode === newLoan.branchIFSC);
    if (accessLevel) {
      setInputValue(accessLevel.branchName);
    }
  }, [newLoan.branchIFSC]);

  const getBranches = (searchQuery) => {
    const { session } = verifyLogin();

    const config = { headers: { session } };
    let url = apiList.GET_SUBSCRIBERS_BRANCHES;
    if (searchQuery) {
      url = `${url}?searchQuery=${searchQuery}`;
    }
    axios.get(url, config)
      .then((response) => {
        if (response?.data?.data) {
          setAccessLevel(response?.data?.data);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    getBranches();
  }, []);

  return (
    <div className={classes.mainContainer}>
      <div className={classes.container}>
        <CustomInput
          id='custFirstName'
          label='Customer First Name*'
          InputLabelProps={{
            shrink: true,
          }}
          value={newLoan.custFirstName}
          onChange={(id) => {
            // const obj = { custFirstName: id.target.value.trim() };
            const obj = { custFirstName: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='custLastName'
          label='Customer Last Name*'
          InputLabelProps={{
            shrink: true,
          }}
          value={newLoan.custLastName}
          onChange={(id) => {
            const obj = { custLastName: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='custEmail'
          label='Customer Email'
          InputLabelProps={{
            shrink: true,
          }}
          type='email'
          value={newLoan.custEmail}
          onChange={(id) => {
            const obj = { custEmail: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='custMobile'
          type='number'
          label='Customer Mobile*'
          InputLabelProps={{
            shrink: true,
          }}
          value={newLoan.custMobile}
          onChange={(id) => {
            if (id.target.value.length <= 10) {
              const obj = { custMobile: id.target.value };
              const data = { ...newLoan, ...obj };
              setNewLoan(data);
            }
          }}
        />
      </div>

      <div className={classes.container}>

        <CustomSelect
          id='loanTypeId'
          title='Loan Type*'
          menuItems={loanTypes.map((element) => (
            <option value={element.id} key={element.id}>{element.loanType}</option>
          ))}
          handleChange={(event) => {
            const obj = { loanTypeId: event.target.value === '#78' ? null : event.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
          value={newLoan.loanTypeId}
        />
        <Autocomplete
          id='branchIFSC'
          style={{ width: 300 }}
          inputValue={inputValue}
          getOptionLabel={(option) => option.branchName}
          options={accessLevels}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
            getBranches(newInputValue);
          }}
          getOptionSelected={(option, value) => option.ifscCode === value.ifscCode}
          onChange={(event, element) => {
            if (element) {
              const obj = {
                branchIFSC: element.ifscCode,
                branchName: element.branchName,
              };
              const data = { ...newLoan, ...obj };
              setNewLoan(data);
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              label='Branch*'
              variant='outlined'
            />
          )}
        />
        <CustomInput
          id='loanNumber'
          label='Loan Number*'
          InputLabelProps={{
            shrink: true,
          }}
          value={newLoan.loanNumber}
          onChange={(id) => {
            let loanNumber = id.target.value;
            if (id.currentTarget.value.includes(' ')) {
              loanNumber = id.currentTarget.value.replace(/\s/g, '');
            }
            const obj = { loanNumber };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='sanctionedAmount'
          label='Sanctioned Amount*'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newLoan.sanctionedAmount}
          onChange={(id) => {
            const obj = { sanctionedAmount: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomDatePicker
          id='sanctionedDate'
          label='Sanctioned Date*'
          onChange={(date) => {
            // eslint-disable-next-line no-underscore-dangle
            const obj = { sanctionedDate: date?._isValid ? moment(date).format('DD-MM-YYYY HH:mm:ss') : null };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
          value={newLoan.sanctionedDate}
        />
      </div>

      <div className={classes.container}>

        <CustomInput
          id='landValue'
          label='Land Value'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newLoan.landValue}
          onChange={(id) => {
            const obj = { landValue: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='constructionValue'
          label='Construction Value'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newLoan.constructionValue}
          onChange={(id) => {
            const obj = { constructionValue: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='propertyValue'
          label='Property Value'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newLoan.propertyValue}
          onChange={(id) => {
            const obj = { propertyValue: id.target.value };
            const data = { ...newLoan, ...obj };
            setNewLoan(data);
          }}
        />
        <CustomInput
          id='ltv'
          label='LTV*'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newLoan.ltv}
          onChange={(id) => {
            if (id.target.value < 101) {
              const obj = { ltv: id.target.value };
              const data = { ...newLoan, ...obj };
              setNewLoan(data);
            }
          }}
        />
      </div>
    </div>
  );
}
