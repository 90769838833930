import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import CustomSelect from '../../components/CustomSelect/CustomSelect';

import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomModal from '../../components/CustomModal/CustomModal';
import Form from './Form';

class ActivityConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: {},
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      newForm: true,
      activityTypeId: '',
      activityType: '',
      activityName: '',
      activitylevel: '',
      acitivityConfigID: '',
      activityTypeList: [],
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
      title: '',
      instruction: '',
    };
  }

  componentDidMount() {
    this.getActivityTypes();
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.getActivityConfigs(Number(subscriberId));
    }
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        activityTypeId: element.activityTypeId,
        activityType: element.ActivityTypeConfig?.activityType,
        activityName: element.activityName,
        activitylevel: element.activitylevel,
        title: element.title,
        instruction: element.instruction,
        acitivityConfigID: element.id,
      });
    } else {
      this.setState({
        activityTypeId: '',
        activityType: '',
        activityName: '',
        activitylevel: '',
        acitivityConfigID: '',
        title: '',
        instruction: '',
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      activityTypeId,
      activityName,
      activitylevel,
      acitivityConfigID,
      subscriberId,
      title,
      instruction,
    } = this.state;
    if (activityTypeId.length < 1 && activityName.length < 1 && activitylevel.length < 1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      activityTypeId,
      activityName,
      activitylevel,
      subscriberId,
      title,
      instruction,
    };
    if (acitivityConfigID) {
      params.id = acitivityConfigID;
    }
    axios.post(`${apiList.UPDATE_ACTIVITY_CONFIG_API}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          activityTypeId: '',
          activityType: '',
          activityName: '',
          activitylevel: '',
          title: '',
          instruction: '',
          acitivityConfigID: '',
        });
        this.toggleModal(false);
        this.getActivityConfigs(this.state.subscriberId);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  handleDeleteData(key, id) {
    key.preventDefault();
    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (confirm('CONFIRM TO PROCCESSED )): Your data will lost, if you press OK')) {
      this.setState({ result: true });
      axios.post(`${apiList.DELETE_ACTIVITY_CONFIG_API}`, {
        id,
      }, config)
        .then((response) => {
          toast.success(response.data.message);
          this.getActivityConfigs(this.state.subscriberId);
          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);

          setTimeout(() => {
            this.setState({ result: false });
          }, 1000);
        });
    }
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  getActivityConfigs(subscriberId, searchQuery) {
    const { session } = verifyLogin();
    let url = `${apiList.VIEW_USERS_ACTIVITY_CONFIG_API}?subscriberId=${subscriberId}`;
    const config = { headers: { session } };
    if (searchQuery) {
      url = `${url}&searchQuery=${searchQuery}`;
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({ data: response.data.data });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch(() => {
        this.setState({ data: [] });
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  getActivityTypes() {
    this.setState({ result: true });
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.VIEW_USERS_ACTIVITY_TYPE_CONFIG_API}`, config)
      .then((response) => {
        this.setState({ activityTypeList: { ...response.data.data } });

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  body = () => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      activityType={this.state.activityType}
      activityName={this.state.activityName}
      activitylevel={this.state.activitylevel}
      title={this.state.title}
      instruction={this.state.instruction}
      activityTypeId={this.state.activityTypeId}
      activityTypeList={this.state.activityTypeList}
      setActivityType={(event) => {
        this.setState({ activityTypeId: event.target.value });
      }}
      setActivityLevel={(event) => {
        this.setState({ activitylevel: event.target.value });
      }}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      data,
      page,
      rowsPerPage,
      formOpen,
      newForm,
      userRoleId,
      subscriberId,
      subscribers,
    } = this.state;

    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />
        <div className='select-fields'>
          {userRoleId === 1 ? (
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                this.setState({ subscriberId: event.target.value });
                this.getActivityConfigs(event.target.value);
              }}
              value={subscriberId}
            />
          ) : null}

          <CustomInput
            height
            btargin
            disabled={subscriberId < 1}
            onChange={(event) => {
              if (subscriberId > 0) {
                this.getActivityConfigs(subscriberId, event.target.value);
              }
            }}
            id='users'
            label='Search Users'
          />
        </div>
        {result === true ? <Loading MainProps={this.props} /> : null}

        {!result
          && (
            <Table
              subscriberId={subscriberId}
              data={Array.isArray(data.paginate) ? data.paginate : []}
              handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
              handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
              handleShowAddForm={(key) => this.handleShowAddForm(key)}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          )}
        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={newForm}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={newForm ? 'Create New Activity Config' : 'Update Activity Config'}
        />
      </div>
    );
  }
}

export default ActivityConfig;
