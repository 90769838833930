/* eslint-disable no-unreachable */
import axios from 'axios';
import React, { Component } from 'react';
import { ToastContainer } from 'react-toastify';

import Lightbox from 'react-awesome-lightbox';
import { Link } from '@material-ui/core';
import Navbar from '../../components/navBar/navBar';
import Loading from '../../components/loading/loading';
import VideoPlayer from '../../components/VideoPlayer/VideoPlayer';
import {
  apiList,
  verifyLogin,
  encrypt,
  dencrypt,
} from '../../helpers';
import CustomCardMedia from '../../components/CustomCardMedia/CustomCardMedia';
import '../../assets/style/lightbox.css';
import FormUpdateStatus from './FormUpdateStatus';
import TransferMedia from './TransferMedia/TransferMedia';
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import TabsWithPicker from './TabsWithPicker';
// import 'react-calendar/dist/Calendar.css';

const videoAvatar = require('../../assets/images/videoAvatar.mp4').default;

const MEDIA_TABS = ['Customer', 'Relationship Manager',
  'Contractor', 'Valuer'];

class UncategorizedMedia extends Component {
  constructor() {
    super();
    this.state = {
      selectedConstructionPlanIndex: -1,
      propertyId: null,
      result: false,
      data: {},
      viewerIsOpen: false,
      currentImage: 0,
      selectedPhotos: [],
      photos: [],
      videos: [],
      videoOpen: false,
      videoUrl: '',
      selectedVideodisc: '',
      selectedVideoTitle: '',
      // permission: {},
      modifyPermission: {},
      remarks: '',
      status: '',
      mediaTab: 0,
      selectedItem: null,
      transferMediaOpen: false,
      loanDetails: {},
    };
  }

  componentDidMount() {
    const detailId = dencrypt(this.props.match.params.detailId);
    const loanDetails = JSON.parse(detailId);
    console.log(loanDetails);
    this.setState({ loanDetails });
    this.getMediaCount(loanDetails.loanId, loanDetails.groupId);
    // const { roleMapping, user } = verifyLogin();
    // if (user.userRoleId === 1 || user.userRoleId === 2) {
    //   this.setState({
    //     // permission: {
    //     //   view: true, add: true, edit: true, delete: true,
    //     // },
    //     modifyPermission: {
    //       view: true, add: true, edit: true, delete: true,
    //     },
    //   });
    // } else {
    //   // const permission = roleMapping.find((obj) => obj.
    // PermissionConfig?.name === 'Construction Plan');
    //   const modifyPermission = roleMapping.find((obj) =>
    // obj.PermissionConfig?.name === 'CP-Transfer Media');
    //   this.setState({ modifyPermission: modifyPermission || {} });
    // }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleOpenMedia(key, media) {
    key.preventDefault();
    window.open(media, '_blank', 'height= 500px, width= 800px');
  }

  getMediaCount(loanId, groupId) {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'view' } };

    axios.get(`${apiList.GET_UNCATEGORIZED_MEDIA}?loanId=${loanId}&groupId=${groupId}`, config)
      .then((response) => {
        console.log(response);
        const data = [...response.data.data];
        this.setState({
          data,
        });

        const progressMedia = response.data.data.map(({
          url, submittedDate, lat, lng, submittedUserType, id, fileType,
        }) => ({
          src: url,
          id,
          submittedDate,
          lat,
          lng,
          url,
          mediaType: fileType === 'mp4' ? 'VIDEO' : 'IMAGE',
          width: 4,
          height: 3,
          caption: '',
          title: submittedDate,
          disc: '',
          submittedUserType,
        }));
        const customerMedia = progressMedia.filter(
          (item) => item.submittedUserType === MEDIA_TABS[0],
        );
        const relationshipManagerMedia = progressMedia.filter(
          (item) => item.submittedUserType === MEDIA_TABS[1],
        );
        const contractorMedia = progressMedia.filter(
          (item) => item.submittedUserType === MEDIA_TABS[2],
        );
        const valuerMedia = progressMedia.filter(
          (item) => item.submittedUserType === MEDIA_TABS[3],
        );

        const filteredProgressMedia = progressMedia.filter(
          (item) => item.submittedUserType === 'Customer',
        );

        const mediaAvailability = {
          Customer: customerMedia.length > 0,
          'Relationship Manager': relationshipManagerMedia.length > 0,
          Contractor: contractorMedia.length > 0,
          Valuer: valuerMedia.length > 0,
        };
        console.log('mediaAvailability', mediaAvailability);
        this.setState({ progressMedia, mediaAvailability });

        const photos = filteredProgressMedia.filter((item) => item.mediaType === 'IMAGE');
        const videos = filteredProgressMedia.filter((item) => item.mediaType === 'VIDEO');
        this.setState({ photos, videos });
      })
      .catch((e) => {
        console.log('error', e);
      });
  }

  getViewLoanUrl(viewId) {
    const detailId = encrypt(viewId);
    return `/view-loan-details/${detailId}`;
  }

  body = () => (
    <FormUpdateStatus
      handleChange={(id) => this.handleChange(id)}
      remarks={this.state.remarks}
      setStatus={(event) => {
        this.setState({ status: event.target.value });
      }}
      status={this.state.status}
    />
  )

  render() {
    const {
      result, data,
      viewerIsOpen, currentImage, photos, videos, videoOpen, videoUrl,
      selectedPhotos,
      selectedVideodisc, selectedVideoTitle, modifyPermission,
      mediaTab,
      transferMediaOpen,
      progressMedia,
      selectedTransferMedia,
      loanDetails,
      mediaAvailability,
    } = this.state;
    console.log(modifyPermission);
    return (
      <div className='view-details'>
        {result === true ? <Loading MainProps={this.props} /> : null}
        <ToastContainer />
        <Navbar MainProps={this.props} />

        <div className='construction-content'>

          <div className='const-plan'>

            <div className='root-container'>

              {loanDetails
                ? (
                  <div className='form-viewer'>

                    <div className='container'>

                      <div className='login-form1'>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Loan Number :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>
                              <Link
                                underline='always'
                                className='map'
                                href={this.getViewLoanUrl(loanDetails?.loanId)}
                              >
                                {`${loanDetails.loanNumber || 'N/A'}`}
                              </Link>
                            </p>
                          </div>
                        </div>
                        <div className='containerLabel'>

                          <div className='innercontainerLabel'>
                            <p className='label'>Property Details  :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{`${loanDetails.propertyDescription || 'N/A'}`}</p>
                          </div>
                        </div>
                      </div>
                      <div className='login-form1'>
                        <div className='containerLabel'>
                          <div className='innercontainerLabel'>
                            <p className='label'>Property Address :</p>
                          </div>
                          <div className='innercontainerText'>
                            <p className='text'>{loanDetails.address || 'N/A'}</p>
                          </div>
                        </div>
                        {loanDetails?.lat && (
                          <div className='containerLabel'>
                            <div className='innercontainerLabel'>
                              <p className='label'>Google Map Link :</p>
                            </div>
                            <div
                              className='innercontainerText'
                              onClick={() => window.open(`https://www.google.com/maps/place/${loanDetails?.lat},${data?.lng}`, '_blank')}
                            >
                              <p className='map'>Open Location in Google Map</p>
                            </div>
                          </div>
                        )}

                      </div>

                    </div>
                  </div>
                )
                : null}

            </div>

            <TabsWithPicker
              mediaAvailability={mediaAvailability}
              mediaTab={mediaTab}
              setMediaTab={(newValue) => {
                this.setState({ mediaTab: newValue });
                const filteredProgressMedia = progressMedia.filter(
                  (item) => item.submittedUserType === MEDIA_TABS[newValue],
                );
                this.setState({
                  photos: filteredProgressMedia.filter((item) => item.mediaType === 'IMAGE'),
                  videos: filteredProgressMedia.filter((item) => item.mediaType === 'VIDEO'),
                });
              }}
            />

            <div className='data-header'>
              <div className='imageVideoTitle'>Images</div>
              <div className='divBorder' />
              {photos.length > 0 ? (
                <div className='image-video-viewer'>
                  {photos.map((element, index) => (
                    <CustomCardMedia
                      key={element.id}
                      component='img'
                      title={element.title}
                      image={element.src}
                      onClick={() => {
                        this.setState({
                          currentImage: index,
                          viewerIsOpen: true,
                          selectedPhotos: photos,
                        });
                      }}
                      disc={element.disc}
                    />
                  ))}
                </div>
              ) : <div className='nocontent'><div>No data available</div></div>}

              <div className='imageVideoTitle'>Videos</div>
              <div className='divBorder' />
              {videos.length > 0 ? (
                <div className='image-video-viewer'>
                  {videos.map((element) => (
                    <CustomCardMedia
                      key={element.id}
                      component='video'
                      title={element.title}
                      image={element.src}
                      onClick={() => {
                        this.setState({
                          videoUrl: element.src || videoAvatar,
                          videoOpen: true,
                          selectedVideodisc: element.disc,
                          selectedVideoTitle: element.title,
                        });
                      }}
                      disc={element.disc}
                    />
                  ))}

                </div>
              ) : <div className='nocontent'>No data available</div>}
            </div>
            <div style={{
              display: 'flex', flexDirection: 'row', justifyContent: 'center',
            }}
            >

              {modifyPermission.edit && (photos.length > 0 || videos.length > 0)
                ? (
                  <div className='button-viewer'>
                    <button
                      type='submit'
                      className='verify'
                      onClick={
                        () => {
                          this.setState({
                            transferMediaOpen: true,
                            selectedTransferMedia: {
                              photos,
                              videos,
                              currentPlanActName: data.constructionPlan.activityConfigName,
                              currentPlanID: data.constructionPlan.id,
                              submittedUser: MEDIA_TABS[mediaTab],
                              loanId: data.loan?.id,
                              mapCircleRadius: data.constructionPlan.mapCircleRadius,
                            },
                          });
                        }
                      }
                    >
                      Transfer Media
                    </button>
                  </div>
                )
                : null}
            </div>

          </div>

        </div>

        {transferMediaOpen ? (
          <TransferMedia
            open={transferMediaOpen}
            selectedTransferMedia={selectedTransferMedia}
            handleClose={(reload) => {
              if (reload) {
                this.setState({ mediaTab: 0 });
                this.getConstructionPlan(this.state.selectedConstructionPlanIndex,
                  this.state.selectedConstructionPlanId,
                  this.state.propertyId, this.state.selectedItem,
                  this.state.constructionPlanVersion);
              }
              this.setState({ transferMediaOpen: false });
            }}
          />
        ) : null}
        {viewerIsOpen && selectedPhotos.length > 0 && (
          <Lightbox
            onClose={() => this.setState({ viewerIsOpen: false, currentImage: 0 })}
            images={selectedPhotos}
            allowRotate={false}
            startIndex={currentImage}
            doubleClickZoom={0}
          />
        )}
        <VideoPlayer
          url={videoUrl}
          isOpen={videoOpen}
          disc={selectedVideodisc}
          title={selectedVideoTitle}
          onClose={() => this.setState({ videoOpen: false })}
        />
      </div>
    );
  }
}

export default UncategorizedMedia;
