import React from 'react';

import {
  TableBody,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from '@material-ui/core';
import Pagination from '@material-ui/lab/Pagination';
import { makeStyles } from '@material-ui/core/styles';

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
  },
  title: {
    flex: '1 1 100%',
  },
  nodata: {
    color: '#999',
    fontSize: 16,
  },
  paper: {
    flex: 1,
    paddingBottom: 50,
    alignSelf: 'center',
    flexDirection: 'column',
    display: 'flex',
    // height: '620px',
  },
  innerPaper: {
    margin: theme.spacing(2),
    width: 'max-content',
  },
  table: {
    width: 'auto', tableLayout: 'auto',
  },
  flexboxDiv: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  pagination: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
  },
  innerPaperMarginZero: {
    marginTop: 0,
  },
}));

export default function CustomTablePageNoHeader({
  tableHead, itemComponent, count, handleChangePage, page,
  noDataText, innerPaperMarginZero,
}) {
  const classes = useToolbarStyles();

  return (
    <>
      <div className={classes.flexboxDiv}>
        <Paper className={[classes.innerPaper,
          innerPaperMarginZero ? classes.innerPaperMarginZero : null]}
        >
          <TableContainer>
            <Table stickyHeader className={classes.table}>
              {tableHead && (
              <TableHead>
                <TableRow>
                  {tableHead.map((column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      style={{ fontWeight: 'bold' }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                </TableRow>
              </TableHead>
              )}
              <TableBody>
                {itemComponent}
              </TableBody>
            </Table>
          </TableContainer>

        </Paper>
        {count > 1 && (
        <div className={classes.pagination}>
          <Pagination count={count} page={page} onChange={handleChangePage} />
        </div>
        )}
      </div>
      {!itemComponent && (
      <div className={classes.root}>
        <Typography className={classes.nodata} id='tableTitle' component='div'>
          {noDataText || 'You have not added any data.'}
        </Typography>
      </div>
      )}

    </>
  );
}
