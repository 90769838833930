import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';
import { apiList, verifyLogin } from '../../helpers';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  constructor() {
    super();
    this.state = {
      formats: [],
    };
  }

  componentDidMount() {
    this.getReportTypes();
  }

  getReportTypes() {
    const { session } = verifyLogin();
    const config = { headers: { session } };

    axios.get(`${apiList.GET_REPORT_TYPE}`, config)
      .then((response) => {
        this.setState({ formats: [...response.data.data] });
      })
      .catch(() => {
      });
  }

  render() {
    const {
      classes, handleChange, name, description,
      setReportType, reportTypeId, configID,
    } = this.props;
    const { formats } = this.state;

    return (
      <div className={classes.root}>
        <CustomSelect
          id='reportTypeId'
          title='Report Type'
          disabled={configID}
          menuItems={formats.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            if (event.target.value !== '#78') {
              setReportType(event.target.value);
            } else {
              setReportType(-1);
            }
          }}
          value={reportTypeId}
        />
        <CustomInput
          id='name'
          disabled={configID}
          label='Name'
          value={name}
          onChange={(id) => handleChange(id)}
        />
        <CustomInput id='description' label='Description' value={description} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
