import React, { Component } from 'react';
import axios from 'axios';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import {
  verifyLogin,
  apiList,
} from '../../helpers';
import CustomTable from '../../components/CustomTablePageNoHeader/CustomTablePageNoHeader';
import Button from '../../components/CustomButtons/Button';
import CustomModal from '../../components/CustomModal/CustomModal';
import FormProgressMedia from './FormProgressMedia';

const columns = [
  {
    id: 'ActivityType', label: 'Activity Type',
  },
  {
    id: 'ActivityName', label: 'Activity Name',
  },
  {
    id: ' MediaType', label: ' Media Type',
  },
  {
    id: 'Title', label: 'Title',
  },
  {
    id: 'Instructions', label: 'Instructions',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    overflow: 'auto',
    width: '100%',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class ProgressMediaConfigTable extends Component {
  constructor() {
    super();
    this.state = {
      data: [],
      formOpen: false,
      rowID: '',
      activityType: '',
      activityName: '',
      mediaType: '',
      title: '',
      instruction: '',
      subscriberId: -1,
      loanTypeId: -1,
      planID: -1,
    };
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleAutoFilterConfigSub(planID, subscriberId, loanTypeId, savedData) {
    this.setState({
      subscriberId, loanTypeId, planID,
    });
    if (savedData) {
      this.setState({ data: savedData });
    }
    if (!savedData && subscriberId !== -1 && loanTypeId !== -1) {
      const { session } = verifyLogin();
      const config = { headers: { session } };

      axios.post(`${apiList.VIEW_USERS_ACTIVITIES_CONFIG_SUB_BY_SUBID_AND_LOANID_API}`, { planID }, config)
        .then((response) => {
          this.setState({ data: [...response.data.data] });
        })
        .catch(() => {
          this.setState({ data: [] });
        });
    }
  }

  handleHideEditProgressMediaForm() {
    this.setState({
      rowID: '',
      activityType: '',
      activityName: '',
      mediaType: '',
      title: '',
      instruction: '',
    });
  }

  handleShowEditProgressMediaForm(key, rowID,
    activityType, activityName, mediaType, title, instruction) {
    key.preventDefault();
    this.setState({
      rowID,
      activityType,
      activityName,
      mediaType,
      title,
      instruction,
      formOpen: true,
    });
  }

  handleSubmitRows(key) {
    key.preventDefault();
    const {
      title, instruction, subscriberId, loanTypeId, rowID, planID,
    } = this.state;

    if (title.length < 1) {
      toast.error('No changes found in Title');
      return;
    }

    if (instruction.length < 1) {
      toast.error('No changes found in Instruction');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.patch(`${apiList.UPDATE_USERS_ACTIVITY_MEDIA_CONFIG_ROW_API}/${rowID}`, {
      planID,
      subscriberId,
      loanTypeId,
      title,
      instruction,
    }, config)
      .then((response) => {
        this.setState({ data: response.data.data });
        this.setState({
          rowID: '', formOpen: false, activityType: '', activityName: '', mediaType: '', title: '', instruction: '',
        });
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  }

  bodyProgressMedia=() => (
    <FormProgressMedia
      handleChange={(id) => this.handleChange(id)}
      instruction={this.state.instruction}
      activityType={this.state.activityType}
      activityName={this.state.activityName}
      mediaType={this.state.mediaType}
      title={this.state.title}
    />
  )

  clearData() {
    this.setState({ data: [] });
  }

  render() {
    const {
      classes,
    } = this.props;
    const { data, formOpen } = this.state;
    return (
      <div className={classes.mainContainer}>
        <CustomTable
          tableHead={columns}
          itemComponent={data.length > 0 ? data.map((element) => (
            element.id
              ? (element.ProgressMediaConfig.map((childElement, i) => (
                <TableRow
                  className={classes.tablewo}
                  tabIndex={-1}
                  key={`${i}`}
                >
                  <TableCell>
                    {element.ActivityConfig?.ActivityTypeConfig?.activityType}
                  </TableCell>
                  <TableCell>
                    {element.ActivityConfig?.activityName}
                  </TableCell>
                  <TableCell>
                    {childElement.mediaType}
                  </TableCell>
                  <TableCell>
                    {childElement.title}
                  </TableCell>
                  <TableCell style={{ width: 200 }}>
                    {childElement.instruction}
                  </TableCell>
                  <TableCell>

                    <Button
                      color='primary'
                      justIcon
                      simple
                      disabled={!this.props.canEdit}
                      round
                      onClick={(key) => {
                        this.handleShowEditProgressMediaForm(key,
                          childElement.id,
                          element.ActivityConfig?.ActivityTypeConfig?.activityType,
                          element.ActivityConfig?.activityName,
                          childElement.mediaType,
                          childElement.title,
                          childElement.instruction);
                      }}
                      className={classes.buttonLink}
                    >
                      <EditIcon className={classes.icons} />
                    </Button>
                  </TableCell>
                </TableRow>
              ))) : null
          )) : null}
          count={1}
          rowsPerPage={0}
          page={1}
        />
        <CustomModal
          open={formOpen}
          setOpen={() => {
            this.setState({ formOpen: false });
            this.handleHideEditProgressMediaForm();
          }}
          newForm={false}
          body={this.bodyProgressMedia()}
          handleSaveData={(key) => this.handleSubmitRows(key)}
          title='Update Progress Media'
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(ProgressMediaConfigTable);
