import React from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import '../../assets/style/lightbox.css';

const MEDIA_TABS = ['Customer', 'Relationship Manager',
  'Contractor', 'Valuer'];

const StyledTabsMedia = withStyles({
  root: {
    flex: 1,
    backgroundColor: '#E7EAEE',
    borderBottom: '1px solid #fff',
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabMedia = withStyles((theme) => ({
  root: {
    color: '#3D4977',
    backgroundColor: '#E7EAEE',
    fontWeight: theme.typography.fontWeightRegular,
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    backgroundColor: '#00838F',
  },
}))((props) => <Tab {...props} />);

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}));

export default function TabsWithPicker({
  mediaTab,
  setMediaTab,
  mediaAvailability,
}) {
  console.log(mediaAvailability);
  const classes = useStyles();
  return (
    <>
      <div className={classes.root}>

        <StyledTabsMedia
          value={mediaTab}
          onChange={(event, newValue) => {
            setMediaTab(newValue);
          }}
          indicatorColor='primary'
          textColor='primary'
          variant='scrollable'
          scrollButtons='auto'
          aria-label='scrollable auto tabs example'
        >
          {Array.isArray(MEDIA_TABS) ? MEDIA_TABS.map(
            (item) => (
              <StyledTabMedia
                label={(
                  <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    {item}
                    {mediaAvailability && mediaAvailability[item]
                      ? (
                        <CheckCircleOutlineIcon
                          style={{ fontSize: 20, marginLeft: 4 }}
                        />
                      ) : null}
                  </div>
                )}
              />
            ),
          ) : null}
        </StyledTabsMedia>
      </div>
    </>
  );
}
