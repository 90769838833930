import React from 'react';
import ReactPlayer from 'react-player';
import IconButton from '@material-ui/core/IconButton';
import CancelRoundedIcon from '@material-ui/icons/CancelRounded';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  buttonView: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
  },
}));

export default function VideoPlayer(props) {
  const classes = useStyles();

  const {
    isOpen, onClose, url,
  } = props;
  return (
    <Dialog
      fullWidth
      maxWidth='xl'
      open={isOpen}
      onClose={onClose}
    >
      <div className={classes.buttonView}>
        <IconButton aria-label='close' onClick={onClose}>
          <CancelRoundedIcon />
        </IconButton>
      </div>
      <DialogContent>
        <ReactPlayer
          url={url}
          width='100%'
          controls
          height='calc(100vh - 130px)'
        />
      </DialogContent>
    </Dialog>
  );
}
