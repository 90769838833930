import React, { useState } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Lightbox from 'react-awesome-lightbox';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import RadioButtonUncheckedIcon from '@material-ui/icons/RadioButtonUnchecked';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import axios from 'axios';
import {
  verifyLogin,
  apiList,
} from '../../../helpers';
import Plans from './Plans';
import PhotoGrid from './PhotoGrid';
import CustomAlert from '../../../components/CustomAlert/CustomAlert';
import VideoPlayer from '../../../components/VideoPlayer/VideoPlayer';

const MEDIA_TABS = ['Customer', 'Relationship Manager',
  'Contractor', 'Valuer'];

const StyledTabsMedia = withStyles({
  root: {
    backgroundColor: '#fff',
    borderBottom: '1px solid #fff',
    marginTop: 12,
  },
  indicator: {
    display: 'flex',
    backgroundColor: 'transparent',
    '& > span': {
      width: '100%',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);

const StyledTabMedia = withStyles((theme) => ({
  root: {
    color: '#3D4977',
    backgroundColor: '#E7EAEE',
    fontWeight: theme.typography.fontWeightRegular,
    '&$selected': {
      color: '#fff',
      fontWeight: theme.typography.fontWeightMedium,
    },
  },
  selected: {
    backgroundColor: '#00838F',
  },
}))((props) => <Tab {...props} />);

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const { classes, onClose } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <CheckCircleIcon className={classes.completed} />
        : <RadioButtonUncheckedIcon className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['From', 'What?', 'Review & Complete'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    position: 'relative',
    paddingTop: 20,
    width: '100%',
    display: 'flex',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  list: {
    width: 300,
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 12,
    marginTop: 12,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 'bold',
    marginTop: 12,
  },
  step2TitleDiv: {
    display: 'flex',
    alignItems: 'center',
  },
  step2TitleInnerDiv1: {
    marginRight: 16,
  },
  step2TitleInnerDiv2: {
    marginLeft: 16,
  },
  step2Title: {
    fontSize: 14,
    marginBottom: 12,
    marginTop: 12,
  },
  step3Title: {
    fontSize: 14,
    margin: 12,
  },
}));

export default function ModifyPlan({
  open, handleClose, selectedTransferMedia,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [currentImages, setCurrentImages] = useState([]);
  const [currentVideos, setCurrentVideos] = useState([]);
  const [progressMedia, setProgressMedia] = useState([]);
  const [photos, setPhotos] = useState([]);
  const [videos, setVideos] = useState([]);
  const [enableImageSelect, setEnableImageSelect] = useState(false);
  const [enableVideoSelect, setEnableVideoSelect] = useState(false);
  const [plan, setPlan] = useState(null);
  const [planVersionId, setPlanVersionId] = useState(0);
  const [mediaTab, setMediaTab] = useState();
  const [hasMedia, setHasMedia] = useState(true); // to-do
  const [showAlert, setShowAlert] = useState(false);
  const [lightBoxPref, setLightBoxPref] = useState({ show: false, index: 0 });
  const [videoPref, setVideoPref] = useState({ show: false, data: null });
  const [uncategorizedMedia, setUncategorizedMedia] = useState();
  const [mediaAvailability, setMediaAvailability] = useState({});
  const steps = getSteps();

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const transferMedia = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'CP-Transfer Media', feature: 'edit' } };
    const params = {
      currentPlanId: plan.id,
      newPlanId: selectedTransferMedia.currentPlanID,
      mediaIds: [...currentImages.map(({ id }) => id), ...currentVideos.map(({ id }) => id)],
      submittedUserType: MEDIA_TABS[mediaTab],
      updateMedia: true,
      mapCircleRadius: plan.mapCircleRadius,
    };
    axios.post(`${apiList.TRANSFER_MEDIA}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        handleClose(true);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  const transferMediaFromUncategorizedMedia = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'CP-Transfer Media', feature: 'edit' } };
    const params = {
      newPlanId: selectedTransferMedia.currentPlanID,
      mediaIds: [...currentImages.map(({ id }) => id), ...currentVideos.map(({ id }) => id)],
      submittedUserType: MEDIA_TABS[mediaTab],
      mapCircleRadius: currentImages[0].mapCircleRadius,
    };
    axios.post(`${apiList.TRANSFER_MEDIA_FROM_UNCATEGORIZED_MEDIA}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        handleClose(true);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  const reAssignPhotos = (pm, tabValue) => {
    setCurrentImages([]);
    setEnableImageSelect(false);
    setCurrentVideos([]);
    setEnableVideoSelect(false);
    const filteredProgressMedia = pm.filter(
      (item) => item.submittedUserType === MEDIA_TABS[tabValue],
    );
    const cPhotos = filteredProgressMedia.filter((item) => item.mediaType === 'IMAGE');
    const cVideos = filteredProgressMedia.filter((item) => item.mediaType === 'VIDEO');
    setPhotos(cPhotos);
    setVideos(cVideos);
    if (selectedTransferMedia.noOfImages && cPhotos.length > 0) {
      if (selectedTransferMedia.noOfImages >= cPhotos.length) {
        setCurrentImages(cPhotos);
        setEnableImageSelect(false);
      } else {
        setCurrentImages(cPhotos.slice(0, selectedTransferMedia.noOfImages));
        setEnableImageSelect(true);
      }
    }

    if (selectedTransferMedia.noOfVideos && cVideos.length > 0) {
      if (selectedTransferMedia.noOfVideos >= cVideos.length) {
        setCurrentVideos(cVideos);
        setEnableVideoSelect(false);
      } else {
        setCurrentVideos(cVideos.slice(0, selectedTransferMedia.noOfVideos));
        setEnableVideoSelect(true);
      }
    }
  };

  const getProgressMedia = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'view' } };
    axios.get(`${apiList.GET_PROGRESS_MEDIA_BY_CONST_PLAN}?consPlanId=${plan.id}&showDeleted=true`, config)
      .then((response) => {
        if (response.data.data) {
          const pm = response.data.data.map(({
            url, submittedDate, lat, lng, ProgressMediaPlan, submittedUserType, id,
          }) => ({
            src: url,
            id,
            submittedDate,
            lat,
            lng,
            url,
            mediaType: ProgressMediaPlan.mediaType,
            width: 4,
            height: 3,
            caption: ProgressMediaPlan.title,
            title: ProgressMediaPlan.title,
            disc: ProgressMediaPlan.instruction,
            submittedUserType,
          }));
          const customerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[0],
          );
          const relationshipManagerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[1],
          );
          const contractorMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[2],
          );
          const valuerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[3],
          );
          setMediaAvailability({
            Customer: customerMedia.length > 0,
            'Relationship Manager': relationshipManagerMedia.length > 0,
            Contractor: contractorMedia.length > 0,
            Valuer: valuerMedia.length > 0,
          });
          setMediaTab(selectedTransferMedia.submittedUser);
          setProgressMedia(pm);
          const cPhotos = pm.filter((item) => item.mediaType === 'IMAGE');
          const cVideos = pm.filter((item) => item.mediaType === 'VIDEO');
          if (cPhotos.length < 1 && cVideos.length < 1) {
            toast.error('This plan does not contain any media');
            return;
          }
          if (selectedTransferMedia?.noOfVideos < 1 && selectedTransferMedia?.noOfImages < 1) {
            toast.error('You can not add media from any plan.');
            return;
          }

          if (cPhotos.length < 1 && cVideos.length > 0
            && selectedTransferMedia?.noOfImages > 0 && selectedTransferMedia?.noOfVideos < 1) {
            toast.error('This plan does not contain any media');
            return;
          }
          if (cPhotos.length > 0 && cVideos.length < 1
            && selectedTransferMedia?.noOfImages < 1 && selectedTransferMedia?.noOfVideos > 0) {
            toast.error('This plan does not contain any media');
            return;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          reAssignPhotos(pm, selectedTransferMedia.submittedUser);
        }
      })
      .catch((error) => {
        toast.error(`${error.message || error.response.message}`);
      });
  };

  const onNextClick = () => {
    if (enableImageSelect && currentImages.length !== selectedTransferMedia.noOfImages) {
      toast.error('You can not select more or less than selected plan images.');
      return;
    }
    if (enableVideoSelect && currentVideos.length !== selectedTransferMedia.noOfVideos) {
      toast.error('You can not select more or less than selected plan videos.');
      return;
    }
    transferMedia();
  };

  const getMediaCount = (date) => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan Tasks', feature: 'view' } };

    axios.get(`${apiList.GET_UNCATEGORIZED_MEDIA_BY_DATE}?loanId=${selectedTransferMedia.loanId}&date=${date}`, config)
      .then((response) => {
        if (response.data.data) {
          const pm = response.data.data.map(({
            url, submittedDate, lat, lng, submittedUserType, id, fileType, mapCircleRadius,
          }) => ({
            src: url,
            id,
            submittedDate,
            lat,
            lng,
            url,
            mediaType: fileType === 'mp4' ? 'VIDEO' : 'IMAGE',
            width: 4,
            height: 3,
            caption: '',
            title: submittedDate,
            disc: '',
            submittedUserType,
            mapCircleRadius,
          }));

          const customerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[0],
          );
          const relationshipManagerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[1],
          );
          const contractorMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[2],
          );
          const valuerMedia = pm.filter(
            (item) => item.submittedUserType === MEDIA_TABS[3],
          );
          setMediaAvailability({
            Customer: customerMedia.length > 0,
            'Relationship Manager': relationshipManagerMedia.length > 0,
            Contractor: contractorMedia.length > 0,
            Valuer: valuerMedia.length > 0,
          });
          setMediaTab(selectedTransferMedia.submittedUser);
          setProgressMedia(pm);
          const cPhotos = pm.filter((item) => item.mediaType === 'IMAGE');
          const cVideos = pm.filter((item) => item.mediaType === 'VIDEO');
          if (cPhotos.length < 1 && cVideos.length < 1) {
            toast.error('This plan does not contain any media');
            return;
          }
          if (selectedTransferMedia?.noOfVideos < 1 && selectedTransferMedia?.noOfImages < 1) {
            toast.error('You can not add media from any plan.');
            return;
          }

          if (cPhotos.length < 1 && cVideos.length > 0
            && selectedTransferMedia?.noOfImages > 0 && selectedTransferMedia?.noOfVideos < 1) {
            toast.error('This plan does not contain any media');
            return;
          }
          if (cPhotos.length > 0 && cVideos.length < 1
            && selectedTransferMedia?.noOfImages < 1 && selectedTransferMedia?.noOfVideos > 0) {
            toast.error('This plan does not contain any media');
            return;
          }
          setActiveStep((prevActiveStep) => prevActiveStep + 1);
          reAssignPhotos(pm, selectedTransferMedia.submittedUser);
        }
      })
      .catch((e) => {
        console.log('error', e);
      });
  };

  const handleNext = () => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          if (planVersionId === 0) {
            getMediaCount(uncategorizedMedia.date);
          } else {
            if (!plan) {
              toast.error('Choose plan to continue');
              break;
            }
            getProgressMedia();
          }
          break;
        case 1: {
          const mediaIDs = [...currentImages.map(({ id }) => id),
          // eslint-disable-next-line indent
          ...currentVideos.map(({ id }) => id)];
          if (mediaIDs.length > 0) {
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          } else {
            toast.error('There is no media avaialable.');
          }
          break;
        }
        case 2:
          if (planVersionId === 0) {
            transferMediaFromUncategorizedMedia();
          } else if (hasMedia) {
            setShowAlert(true);
          } else {
            setShowAlert(false);
            onNextClick();
          }
          break;
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    switch (activeStep) {
      case 0:
        return 'Continue';
      case 1:
        return 'Continue';
      case 2:
        return 'Continue';
      default:
        return 'Next';
    }
  };

  const selectedPlanFromTitleForStep2 = (adduserType) => {
    let title = '';
    if (planVersionId === 0) {
      title = 'Uncategorized Media';
    } else {
      title = `Construction Plan V${planVersionId} > `;
      title = `${title}${plan?.activityConfigName} > `;
      if (plan?.propertyComponentFloorName) {
        title = `${title}${plan?.propertyComponentFloorName}`;
      } else {
        title = `${title}${plan?.activityTypeConfigName}`;
      }
    }
    if (adduserType) {
      title = `${title} > ${MEDIA_TABS[mediaTab]}`;
    }
    return title;
  };

  const selectedPlanToTitleForStep2 = (adduserType) => {
    let title = ` Construction Plan V${selectedTransferMedia.constructionPlanVersion} > ${selectedTransferMedia.currentPlanActName} > ${selectedTransferMedia.currentPlanComponent}`;
    if (adduserType) {
      title = `${title} > ${MEDIA_TABS[mediaTab]}`;
    }
    return title;
  };

  return (
    <Dialog
      fullWidth
      fullScreen
      maxWidth='lg'
      open={open}
      onClose={() => handleClose(false)}
    >
      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton
        id='plan'
        onClose={() => handleClose(false)}
      />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >

            <div className={classes.slide}>
              <Plans
                setHasMedia={setHasMedia}
                plan={plan}
                setPlan={setPlan}
                planVersionId={planVersionId}
                setPlanVersionId={setPlanVersionId}
                loanId={selectedTransferMedia.loanId}
                constructionPlanVersion={selectedTransferMedia.constructionPlanVersion}
                uncategorizedMedia={uncategorizedMedia}
                setUncategorizedMedia={(element) => {
                  setUncategorizedMedia(element);
                }}
              />
            </div>

            <div className={classes.slide}>
              <div>
                <div className={classes.step2TitleDiv}>
                  <div className={classes.step2TitleInnerDiv1}>
                    <div className={classes.step2Title}>{selectedPlanFromTitleForStep2()}</div>
                    <div className={classes.title}>Available Data</div>
                    <div className={classes.step2Title}>{`Photos: ${plan?.noOfImages || photos.length}`}</div>
                    <div className={classes.step2Title}>{`Videos: ${plan?.noOfVideos || videos.length}`}</div>
                  </div>
                  <div className={classes.title}>{'----->'}</div>
                  <div className={classes.step2TitleInnerDiv2}>
                    <div className={classes.step2Title}>{selectedPlanToTitleForStep2()}</div>
                    <div className={classes.title}>Current Data</div>
                    <div className={classes.step2Title}>{`Photos: ${selectedTransferMedia.noOfImages}`}</div>
                    <div className={classes.step2Title}>{`Videos: ${selectedTransferMedia.noOfVideos}`}</div>
                  </div>
                </div>
                <StyledTabsMedia
                  value={mediaTab}
                  onChange={(event, newValue) => {
                    setMediaTab(newValue);
                    reAssignPhotos(progressMedia, newValue);
                  }}
                >
                  {MEDIA_TABS.map(
                    (item) => (
                      <StyledTabMedia
                        label={(
                          <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                            {item}
                            {mediaAvailability[item]
                              ? (
                                <CheckCircleOutlineIcon
                                  style={{ fontSize: 20, marginLeft: 4 }}
                                />
                              ) : null}
                          </div>
                        )}
                      />
                    ),
                  )}
                </StyledTabsMedia>
                {selectedTransferMedia.noOfImages && photos.length > 0
                  && (planVersionId === 0 || plan?.noOfImages) > 0
                  ? <div className={classes.subTitle}>Photos: </div> : null}
                {selectedTransferMedia.noOfImages && photos.length > 0
                  && (planVersionId === 0 || plan?.noOfImages) > 0
                  ? (
                    <PhotoGrid
                      onOpenClick={(index) => {
                        setLightBoxPref({ show: true, index });
                      }}
                      enableSelect={enableImageSelect}
                      photos={photos}
                      component='img'
                      currentImages={currentImages}
                      setCurrentImages={setCurrentImages}
                    />
                  ) : null}
                {selectedTransferMedia.noOfVideos && videos.length > 0
                  && (planVersionId === 0 || plan?.noOfVideos) > 0
                  ? <div className={classes.subTitle}>Video: </div> : null}
                {selectedTransferMedia.noOfVideos && videos.length > 0
                  && (planVersionId === 0 || plan?.noOfVideos) > 0
                  ? (
                    <PhotoGrid
                      onOpenClick={(index) => {
                        setVideoPref({ show: true, data: videos[index] });
                      }}
                      enableSelect={enableVideoSelect}
                      photos={videos}
                      component='video'
                      currentImages={currentVideos}
                      setCurrentImages={setCurrentVideos}
                    />
                  ) : null}
              </div>

            </div>
            <div className={classes.slide}>
              <div>
                <div className={classes.step2TitleDiv}>
                  <div className={classes.step3Title}>
                    Following media will be copied from
                  </div>
                  <div className={classes.title}>
                    {selectedPlanFromTitleForStep2(true)}
                  </div>
                  <div className={classes.step3Title}>
                    to
                  </div>
                  <div className={classes.title}>
                    {selectedPlanToTitleForStep2(true)}
                  </div>
                </div>

                {currentImages.length > 0 ? <div className={classes.subTitle}>Photos: </div> : null}
                {currentImages.length > 0 ? (
                  <PhotoGrid
                    onOpenClick={(index) => {
                      setLightBoxPref({ show: true, index });
                    }}
                    enableSelect={false}
                    photos={currentImages}
                    component='img'
                    currentImages={currentImages}
                    setCurrentImages={() => { }}
                  />
                ) : null}
                {currentVideos.length > 0
                  ? <div className={classes.subTitle}>Video: </div> : null}
                {currentVideos.length > 0
                  ? (
                    <PhotoGrid
                      onOpenClick={(index) => {
                        setVideoPref({ show: true, data: videos[index] });
                      }}
                      enableSelect={false}
                      photos={currentVideos}
                      component='video'
                      currentImages={currentVideos}
                      setCurrentImages={() => { }}
                    />
                  ) : null}
              </div>

            </div>
          </SwipeableViews>
        </div>

        {lightBoxPref?.show && photos.length > 0 && (
          <Lightbox
            onClose={() => setLightBoxPref({ show: false, index: 0 })}
            images={photos}
            allowRotate={false}
            startIndex={lightBoxPref.index}
            doubleClickZoom={0}
          />
        )}

        <VideoPlayer
          url={videoPref?.data?.src}
          isOpen={videoPref.show}
          disc={videoPref?.data?.disc}
          title={videoPref?.data?.title}
          onClose={() => setVideoPref({ show: false, data: null })}
        />

      </DialogContent>

      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' onClick={() => handleNext()}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
          </div>

        )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        )}
      />
      <CustomAlert
        open={showAlert}
        okTitle='Ok'
        handleYes={() => {
          setShowAlert(false);
          onNextClick();
        }}
        title='Media Conflict!'
        content={`${selectedTransferMedia.currentPlanActName} already have media, if continuing this, current media of ${selectedTransferMedia.currentPlanActName} will be lost.`}
        handleClose={() => setShowAlert(false)}
      />
    </Dialog>
  );
}
