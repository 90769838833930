import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  render() {
    const {
      classes, handleChange, name, desc,
    } = this.props;

    return (
      <div className={classes.root}>
        <CustomInput id='name' label='Task Name' value={name} onChange={(id) => handleChange(id)} />
        <CustomInput id='desc' label='Task Description' value={desc} onChange={(id) => handleChange(id)} />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
