import React, { Component } from 'react';

import { withStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { toast } from 'react-toastify';
// import ListItemText from '@material-ui/core/ListItemText';
// import Divider from '@material-ui/core/Divider';
// import List from '@material-ui/core/List';
// import ListSubheader from '@material-ui/core/ListSubheader';

import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { colors } from '@material-ui/core';
import {
  apiList,
  verifyLogin,
} from '../../helpers';
// import CustomInput from '../../components/CustomInput/CustomInput';

const useStyles = () => ({
  root: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
  },
  checkBoxForm: {
    minWidth: '300px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  main: {
    display: 'flex',
  },
  listDiv: {
    width: '300px',
    backgroundColor: colors.grey['100'],
    margin: 16,
    borderRadius: 5,
  },
  listItem: {
    paddingLeft: 16,
    padddingVertical: 16,
  },
  checkBox: {
    marginRight: '10px',
  },
});

class FormCustomerDetails extends Component {
  constructor() {
    super();
    this.state = {
      // contractor: '',
      // projectManager: '',
      // externalValuer: '',
      contractorID: -1,
      projectManagerID: -1,
      externalValuerID: -1,
      // suggestions: [],
      roleType: -1,
      mapCircleRadius: null,
      isMediaSubmissionAllowedWithoutConsPlan: 'false',
      disbursementSubmissionAllow: 'false',
    };
  }

  componentDidMount() {
    const {
      // contractor, projectManager,
      // externalValuer,
      contractorID, projectManagerID, externalValuerID,
      mapCircleRadius, isMediaSubmissionAllowedWithoutConsPlan, disbursementSubmissionAllow,
      gapForNextMediaSubmission,
    } = this.props;
    this.setState({
      // contractor,
      // projectManager,
      // externalValuer,
      contractorID,
      projectManagerID,
      externalValuerID,
      mapCircleRadius,
      isMediaSubmissionAllowedWithoutConsPlan,
      disbursementSubmissionAllow,
      gapForNextMediaSubmission,
    });
  }

  handleChange(key, userRoleId) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
    this.searchLoans(key.target.value, userRoleId);
  }

  getListHeaderTitle() {
    switch (this.state.roleType) {
      case 4:
        return 'Contractors';
      case 5:
        return 'Project Manager';
      case 6:
        return 'External Valuer';
      default:
        return 'Users';
    }
  }

  saveCustomerDetails = () => {
    const { session } = verifyLogin();
    const config = { headers: { session, permissionConfig: 'Loan', feature: 'edit' } };
    const {
      contractorID, projectManagerID, externalValuerID,
      mapCircleRadius, isMediaSubmissionAllowedWithoutConsPlan, disbursementSubmissionAllow,
      gapForNextMediaSubmission,
    } = this.state;
    const params = {
      id: this.props.loanID,
      isMediaSubmissionAllowedWithoutConsPlan,
      disbursementSubmissionAllow,
      gapForNextMediaSubmission,
    };
    if (this.props.geoTaggPermission?.edit) {
      params.mapCircleRadius = mapCircleRadius;
    } else {
      params.mapCircleRadius = 'NA';
    }

    const { complete } = this.props;
    if (contractorID !== -1) {
      params.contractorID = contractorID;
    }
    if (projectManagerID !== -1) {
      params.projectManagerID = projectManagerID;
    }
    if (externalValuerID !== -1) {
      params.externalValuerID = externalValuerID;
    }

    axios.post(`${apiList.SAVE_CUSTOMER_ID_DETAILS}`, params, config)
      .then((response) => {
        if (response) {
          complete();
        }
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  // searchLoans(searchQuery, role) {
  //   const { session, user } = verifyLogin();
  //   const { subscriberId, userRoleId } = user;

  //   const config = { headers: { session, permissionConfig: 'Customer', feature: 'view' } };

  //   let url = `${apiList.GET_USERS}?searchQuery=${searchQuery}&userRoleId=${role}`;
  //   if (Number(userRoleId) === 2) {
  //     url = `${url}&subscriberId=${Number(subscriberId)}`;
  //   }
  //   axios.get(url, config)
  //     .then((response) => {
  //       // this.setState({ roleType: role, suggestions: response.data.data.paginate });
  //       this.setState({ roleType: role, suggestions: response.data.data.paginate });
  //     })
  //     .catch(() => {
  //     });
  // }

  render() {
    const {
      classes, geoTaggPermission,
      mediaSubmissionNoPlanPermission,
      mediaSubmissionPeriodPermission,
    } = this.props;
    const {
      // contractor, projectManager,
      // externalValuer,
      // suggestions, roleType,
      mapCircleRadius,
      isMediaSubmissionAllowedWithoutConsPlan,
      gapForNextMediaSubmission,
      //  disbursementSubmissionAllow,
    } = this.state;
    return (
      <div className={classes.root}>
        {/* <CustomInput id='contractor' label='Contractor'
           value={contractor} onChange={(id) => this.handleChange(id, 4)} />
          <CustomInput id='projectManager' label='Project
          Manager' value={projectManager} onChange={(id) => this.handleChange(id, 5)} />
          <CustomInput id='externalValuer' label='External
          Valuer' value={externalValuer} onChange={(id) => this.handleChange(id, 6)} /> */}
        {geoTaggPermission?.edit ? (
          <FormControlLabel
            className={classes.checkBoxForm}
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={mapCircleRadius === '-1'}
                onChange={(event) => {
                  this.setState({ mapCircleRadius: event.target.checked ? '-1' : null });
                }}
                name='Applicable'
              />
            )}
            label='Disable Geotagging'
            labelPlacement='start'
          />
        ) : null}
        {mediaSubmissionNoPlanPermission.edit ? (
          <FormControlLabel
            className={classes.checkBoxForm}
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={isMediaSubmissionAllowedWithoutConsPlan === 'true'}
                onChange={(event) => {
                  this.setState({
                    isMediaSubmissionAllowedWithoutConsPlan:
                      event.target.checked ? 'true' : 'false',
                  });
                }}
                name='Applicable'
              />
            )}
            label='Allow Direct Media'
            labelPlacement='start'
          />
        ) : null}
        {mediaSubmissionPeriodPermission.edit ? (
          <FormControlLabel
            className={classes.checkBoxForm}
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={gapForNextMediaSubmission === 0}
                onChange={(event) => {
                  this.setState({
                    gapForNextMediaSubmission:
                      event.target.checked ? 0 : 2,
                  });
                }}
                name='Applicable'
              />
            )}
            label='Reset cooling period'
            labelPlacement='start'
          />
        ) : null}
        {/*
          <FormControlLabel
            className={classes.checkBoxForm}
            control={(
              <Checkbox
                className={classes.checkBox}
                checked={disbursementSubmissionAllow === 'true'}
                onChange={(event) => {
                  this.setState({ disbursementSubmissionAllow:
                    event.target.checked ? 'true' : 'false' });
                }}
                name='disbursementSubmissionAllow'
              />
)}
            label='Eligible for Request'
            labelPlacement='start'
          /> */}
      </div>
      // <div className={classes.main}>

    //   {/* <div className={classes.listDiv}>
    //     <List
    //       subheader={<ListSubheader>{this.getListHeaderTitle()}</ListSubheader>}
    //       className={classes.list}
    //     >
    //       {suggestions.map((suggestion) => (
    //         <>
    //           <ListItemText
    //             className={classes.listItem}
    //             onClick={() => {
    //               switch (roleType) {
    //                 case 4:
    //                   this.setState({
    //                     contractor: `${suggestion.firstName} ${suggestion.lastName}`,
    //                     contractorID: suggestion.id,
    //                     suggestions: [],
    //                     roleType: -1,
    //                   });
    //                   break;
    //                 case 5:
    //                   this.setState({
    //                     projectManager: `${suggestion.firstName} ${suggestion.lastName}`,
    //                     projectManagerID: suggestion.id,
    //                     suggestions: [],
    //                     roleType: -1,
    //                   });
    //                   break;
    //                 case 6:
    //                   this.setState({
    //                     externalValuer: `${suggestion.firstName} ${suggestion.lastName}`,
    //                     externalValuerID: suggestion.id,
    //                     suggestions: [],
    //                     roleType: -1,
    //                   });
    //                   break;
    //                 default:
    //                   break;
    //               }
    //             }}
    //             primary={`${suggestion.firstName} ${suggestion.lastName}`}
    //           />
    //           <Divider />
    //         </>
    //       ))}
    //     </List>
    //   </div> */}
    // </div>
    );
  }
}

export default withStyles(useStyles)(FormCustomerDetails);
