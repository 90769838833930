import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import ViewArrayIcon from '@material-ui/icons/ViewArray';
import {
  shortData,
} from '../../helpers';
import CustomTable from '../../components/CustomTable/CustomTable';
import Button from '../../components/CustomButtons/Button';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'name', label: 'Plan Name',
  },
  {
    id: 'type', label: 'Loan Type',
  },
  {
    id: 'description',
    label: 'Description',
  },
  {
    id: 'iscompleted', label: 'Completed',
  },
  {
    id: 'action',
    label: 'Action',
  },
];

const useStyles = () => ({
  root: {
    width: '100%',
  },
  container: {
    maxHeight: 440,
  },
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
    display: 'flex',
    overflowX: 'auto',
  },
  headerContainer: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    flex: 1,
    margin: 20,
  },
  buttonLink: {
    width: 20,
    height: 20,
    margin: 0,
    padding: 10,
  },
});

class SubmittedConstructionPlanTable extends Component {
  render() {
    const {
      classes,
      data,
      page,
      pageCount,
      handleChangePage,
      handleShowEditForm,
      handleDeleteData,
      handleShowAddForm,
    } = this.props;

    return (
      <div className={classes.mainContainer}>

        <CustomTable
          showPagination
          icons={[{ icon: AddCircleOutlineRoundedIcon, name: 'Add Config' }]}
          onIconClick={(key) => {
            handleShowAddForm(key);
          }}
          tableName='Construction Plan Configurations'
          tableHead={columns}
          itemComponent={data.length > 0 ? data.map((element, index) => (
            <TableRow
              className={classes.tablewo}
              tabIndex={-1}
              key={element.id}
            >
              <TableCell>
                {(page - 1) * 10 + index + 1}
              </TableCell>
              <TableCell>
                {shortData(element.planName)}
              </TableCell>
              <TableCell>
                {shortData(element.LoanType.loanType)}
              </TableCell>
              <TableCell>
                {shortData(element.description)}
              </TableCell>
              <TableCell>
                {shortData(element.isCompleted)}
              </TableCell>
              <TableCell>
                <Button
                  color='success'
                  justIcon
                  simple
                  round
                  onClick={(key) => { handleShowEditForm(key, element, false); }}
                  className={classes.buttonLink}
                >
                  <ViewArrayIcon className={classes.icons} />
                </Button>
                <Button
                  color='primary'
                  justIcon
                  simple
                  round
                  onClick={(key) => { handleShowEditForm(key, element, true); }}
                  className={classes.buttonLink}
                >
                  <EditIcon className={classes.icons} />
                </Button>

                <Button
                  color='danger'
                  justIcon
                  simple
                  round
                  onClick={(key) => { handleDeleteData(key, element.id); }}
                  className={classes.buttonLink}
                >
                  <DeleteIcon className={classes.icons} />
                </Button>

              </TableCell>
            </TableRow>
          )) : null}
          count={pageCount}
          rowsPerPage={0}
          page={page}
          handleChangePage={handleChangePage}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(SubmittedConstructionPlanTable);
