import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

export default function AlertDialog({
  open, handleYes, title, content, handleClose, negtiveBtn, okTitle,
  hideCancel,
}) {
  return (
    <Dialog
      open={open}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>{title || 'Are you sure you want to delete this item?'}</DialogTitle>
      <DialogContent>
        <DialogContentText id='alert-dialog-description'>
          {content || 'Your data will be lost forever'}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {handleYes && (
        <Button onClick={handleYes} color='primary'>
          {okTitle || 'Yes'}
        </Button>
        )}
        {!hideCancel ? (
          <Button onClick={handleClose} color='primary' autoFocus>
            {negtiveBtn || 'Cancel'}
          </Button>
        ) : null}
      </DialogActions>
    </Dialog>
  );
}
