/* eslint-disable no-unused-vars */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '../CustomButtons/Button';

import styles from '../../assets/jss/material-dashboard-react/components/headerLinksStyle';
import { variables } from '../../helpers';

const useStyles = makeStyles(styles);

const timeOut = variables.TIMEOUT;

export default function ToolBarItems() {
  const classes = useStyles();
  const [openNotification, setOpenNotification] = React.useState(null);
  const [openProfile, setOpenProfile] = React.useState(null);

  const handleUsersDashoard = (key) => {
    key.preventDefault();

    const redirectUrl = '/customers-accounts';
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, timeOut);
  };

  const handleLogout = (key) => {
    key.preventDefault();
    localStorage.removeItem('data');
    const redirectUrl = '/';
    setTimeout(() => {
      window.location.replace(redirectUrl);
    }, timeOut);
  };

  const handleClickNotification = (event) => {
    if (openNotification && openNotification.contains(event.target)) {
      setOpenNotification(null);
    } else {
      setOpenNotification(event.currentTarget);
    }
  };
  const handleCloseNotification = () => {
    setOpenNotification(null);
  };
  const handleClickProfile = (event) => {
    if (openProfile && openProfile.contains(event.target)) {
      setOpenProfile(null);
    } else {
      setOpenProfile(event.currentTarget);
    }
  };
  const handleCloseProfile = () => {
    setOpenProfile(null);
  };
  return (
    <div>
      <Button
        color='transparent'
        className={classes.buttonLink}
        onClick={handleUsersDashoard}
      >
        Home
      </Button>

      {/* <Button
        color='transparent'
        justIcon
        onClick={handleLogout}
        className={classes.buttonLink}
      >
        <ExitToApp className={classes.icons} />
        <Hidden mdUp implementation='css'>
          <p className={classes.linkText}>Profile</p>
        </Hidden>
      </Button> */}
    </div>
  );
}
