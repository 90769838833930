import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const useStyles = makeStyles(() => ({
  margin: {
    minWidth: 300,
    marginBottom: 16,
    marginTop: 16,
  },
}));

export default function CustomSelectOut({
  title, menuItems, handleChange, value, id, disabled, multiple,
}) {
  const classes = useStyles();

  return (
    <div>
      <FormControl variant='outlined' className={classes.margin} disabled={disabled}>
        <InputLabel id={id}>{title}</InputLabel>
        <Select
          native
          labelId={id}
          id={id}
          multiple={!!multiple}
          value={value}
          onChange={handleChange}
          label={title}
        >
          <option value='#78'>None</option>
          {menuItems}
        </Select>
      </FormControl>
    </div>
  );
}
