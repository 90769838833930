import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AddCircleOutlineRoundedIcon from '@material-ui/icons/AddCircleOutlineRounded';
import CustomTable from '../../components/CustomTable/CustomTable';

const columns = [
  {
    id: 'Id', label: 'Id',
  },
  {
    id: 'branch', label: 'Branch Name',
  },
  {
    id: 'IFSCCode', label: 'IFSC Code',
  },
  {
    id: 'city', label: 'City',
  },
  {
    id: 'district',
    label: 'District',
  },
  {
    id: 'zone',
    label: 'Zone',
  },
  {
    id: 'state',
    label: 'State',
  },
  {
    id: 'country',
    label: 'Contry',
  },
];

const useStyles = () => ({
  tablewo: {
    cursor: 'pointer',
    '&:hover': {
      background: '#eeeeee',
    },
  },
  mainContainer: {
    flex: 1,
    backgroundColor: 'white',
    alignSelf: 'center',
    padding: 20,
    display: 'flex',
    overflowX: 'auto',
  },
  inputContainer: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  input: {
    width: 300,
    marginRight: 30,
    display: 'flex',
    alignItems: 'end',
  },
});

class table extends Component {
  render() {
    const {
      classes, data,
      handleChangePage, handleChangeRowsPerPage,
      handleShowAddForm,
    } = this.props;

    return (
      <>
        <div className={classes.mainContainer}>
          <CustomTable
            tableClass={{ width: '50%' }}
            icons={[{ icon: AddCircleOutlineRoundedIcon, name: 'Add Branch' }]}
            tableName='Branches'
            onIconClick={(key) => {
              handleShowAddForm(key);
            }}
            tableHead={columns}
            itemComponent={data.length > 0 ? data.map((element, index) => (
              <TableRow
                className={classes.tablewo}
                tabIndex={-1}
                key={element.id}
              // onClick={(key) => { handleViewLoanDetails(key, element.id); }}
              >
                <TableCell>
                  {index + 1}
                </TableCell>
                <TableCell>
                  {element.branchName || ''}
                </TableCell>
                <TableCell>
                  {element.ifscCode || ''}
                </TableCell>
                <TableCell>
                  {element.city || ''}
                </TableCell>
                <TableCell>
                  {element.district || ''}
                </TableCell>
                <TableCell>
                  {element.zone || ''}
                </TableCell>
                <TableCell>
                  {element.state || ''}
                </TableCell>
                <TableCell>
                  {element.country || ''}
                </TableCell>
              </TableRow>
            )) : null}
            count={1}
            rowsPerPage={0}
            page={1}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </div>
      </>
    );
  }
}

export default withStyles(useStyles)(table);
