/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import {
  VictoryChart, VictoryPie, VictoryContainer, VictoryBar,
} from 'victory';
import axios from 'axios';
import AccountBalanceRoundedIcon from '@material-ui/icons/AccountBalanceRounded';
import HomeWorkRoundedIcon from '@material-ui/icons/HomeWorkRounded';
import LineWeightRoundedIcon from '@material-ui/icons/LineWeightRounded';
import CollectionsRoundedIcon from '@material-ui/icons/CollectionsRounded';
import RecentActorsRoundedIcon from '@material-ui/icons/RecentActorsRounded';
import StorageRoundedIcon from '@material-ui/icons/StorageRounded';
import AccountTreeRoundedIcon from '@material-ui/icons/AccountTreeRounded';
import FlipCameraIosRoundedIcon from '@material-ui/icons/FlipCameraIosRounded';
import { PowerBIEmbed } from 'powerbi-client-react';
import { models } from 'powerbi-client';
import Card from '../../components/Card/Card';
import CardHeader from '../../components/Card/CardHeader';
import CardBody from '../../components/Card/CardBody';

import styles from '../../assets/jss/material-dashboard-react/views/dashboardStyle';

import Loading from '../../components/loading/loading';
import Navbar from '../../components/navBar/navBar';

import {
  apiList,
  verifyLogin,
} from '../../helpers';
import { groupRequests } from '../../helpers/utils';

const useStyles = makeStyles(styles);

export default function Home(props) {
  const classes = useStyles();
  const [result, setResult] = useState(false);
  const [topCardData, setTopCardData] = useState({});
  const [requestGrouped, setRequestGrouped] = useState([]);
  const [constructionGrouped, setConstructionGrouped] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [pageCount, setPageCount] = useState(0);
  const [requestData, setRequestData] = useState([{}, {}, {}, {}, {}]);

  const getData = () => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_DASHBOATD_DATA_API}`, config)
      .then((response) => {
        setTopCardData(response.data.data);
        const d1 = groupRequests(response.data.data.requestTrend);
        const d2 = groupRequests(response.data.data.constructionPlanTrend, true);
        setRequestGrouped(d1);
        setConstructionGrouped(d2);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getRequestTable = () => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_DASHBOARD_REQUEST_DATA_API}?page=${page}&limit=${rowsPerPage}`, config)
      .then((response) => {
        setRequestData(response.data.data.paginate);
        setPageCount(Math.ceil(response.data.data.pageCount));
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    getRequestTable();
  }, [topCardData, setTopCardData]);

  useEffect(() => {
    getData();
  }, [requestData, setRequestData]);
  return (
    <div className={classes.mainCon}>

      {result === true ? <Loading /> : null}

      <Navbar MainProps={props} />
      <div className={classes.mainRoot}>

        <PowerBIEmbed
          embedConfig={{
            type: 'report', // Supported types: report, dashboard, tile, visual and qna
            id: '90a7b5a6-4195-4820-b44c-2fb9f7448ddb',
            embedUrl: '',
            accessToken: '',
            tokenType: models.TokenType.Embed,
            settings: {
              panes: {
                filters: {
                  expanded: false,
                  visible: false,
                },
              },
              background: models.BackgroundType.Transparent,
            },
          }}
          eventHandlers={
            new Map([
              ['loaded', () => { console.log('Report loaded'); }],
              ['rendered', () => { console.log('Report rendered'); }],
              ['error', (event) => { console.log(event.detail); }],
            ])
          }
          cssClassName={classes.mainRoot}
          getEmbeddedComponent={(embeddedReport) => {
            window.report = embeddedReport;
          }}
        />
      </div>
    </div>
  );
}
