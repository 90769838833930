import * as variables from './variables';

// AUTH APIS
export const LOGIN_API = `${variables.BACKEND_URL}/auth/login`;
export const SAVE_WEB_USERS_API = `${variables.BACKEND_URL}/auth/register`;
export const FORGOT_PASSWORD = `${variables.BACKEND_URL}/auth/forgotPassword`;
export const RESET_PASSWORD = `${variables.BACKEND_URL}/auth/resetPassword`;
export const CHANGE_PASSWORD = `${variables.BACKEND_URL}/auth/changePassword`;

// WEB API
export const VIEW_CONSTRUCTION_PLAN_API = `${variables.HARSH_URL}/web/getExistingConstructionPlan`;
export const VIEW_CONSTRUCTION_DETAILS_API = `${variables.HARSH_URL}/web/getConstructionDetail`;
export const CREATE_CONSTRUCTION_PLAN_API = `${variables.HARSH_URL}/web/createConstructionPlan`;

// Customer APIS
export const VEIW_CUSTOMERS_API = `${variables.BACKEND_URL}/user/view-customers`;
export const VEIW_LOANS_WITHOUT_PROPERTY = `${variables.BACKEND_URL}/user/view-loans-without-property`;
export const VEIW_LOANS_WITHOUT_MAPPING = `${variables.BACKEND_URL}/user/view-loans-without-mapping`;
export const GET_DASHBOATD_DATA_API = `${variables.BACKEND_URL}/user/dashboard-data`;
export const GET_DASHBOARD_REQUEST_DATA_API = `${variables.BACKEND_URL}/user/dashboard-data-open-request`;
export const GET_NEARBY_PROPERTY = `${variables.BACKEND_URL}/user/view-nearby-property`;
export const FIND_LOANS_BY_NAME = `${variables.BACKEND_URL}/user/find-loans-by-name`;
export const VIEW_PROPERTY_BY_LOAN = `${variables.BACKEND_URL}/user/view-property-by-loan`;
export const VEIW_PROPERTY_WITHOUT_COMPONENT = `${variables.BACKEND_URL}/user/view-customers-loan-property-no-component`;
export const VEIW_CUSTOMERS_NO_CONSTRUCTION_PLAN_PROPERTY = `${variables.BACKEND_URL}/user/view-customers-property-no-plan`;
export const VEIW_CONSTRUCTION_PLAN = `${variables.BACKEND_URL}/user/view-construction-plan`;
export const GET_USERS = `${variables.BACKEND_URL}/user/getUsers`;
export const SAVE_CUSTOMER_ID_DETAILS = `${variables.BACKEND_URL}/user/saveCustomerDetailsIDs`;
export const VEIW_REQUEST_API = `${variables.BACKEND_URL}/user/view-request-details`;
export const VEIW_CUSTOMERS_DETAILS_API = `${variables.BACKEND_URL}/user/view-customers-details`;
export const SAVE_CUSTOMERS_PROPERTY_COMPONENT_API = `${variables.BACKEND_URL}/user/save-property-component`;
export const UPDATE_REQUEST_API = `${variables.BACKEND_URL}/user/update-request-details`;
export const UPDATE_CUSTOMERS_PROPERTY_COMPONENT_API = `${variables.BACKEND_URL}/user/update-property-component`;
export const UPDATE_CUSTOMERS_PROPERTY_DETAILS = `${variables.BACKEND_URL}/user/update-property-details`;
export const DELETE_CUSTOMERS_PROPERTY_COMPONENT_API = `${variables.BACKEND_URL}/user/delete-property-component`;
export const GET_LOAN_TASKS = `${variables.BACKEND_URL}/user/getLoanTask`;
export const GET_PENDING_LOAN_TASKS = `${variables.BACKEND_URL}/user/getPendingLoanTask`;
export const SAVE_LOAN_TASK = `${variables.BACKEND_URL}/user/saveLoanTask`;
export const DELETE_LOAN_TASK = `${variables.BACKEND_URL}/user/deleteLoanTask`;
export const DELETE_DISBURSEMENT = `${variables.BACKEND_URL}/user/deleteDisbursement`;
export const UPDATE_DISBURSEMENT = `${variables.BACKEND_URL}/user/updateDisbursement`;
export const CREATE_LOAN = `${variables.BACKEND_URL}/user/createLoan`;
export const DELETE_LOAN = `${variables.BACKEND_URL}/user/deleteLoan`;
export const UPDATE_LOAN = `${variables.BACKEND_URL}/user/updateLoan`;
export const VERIFY_CONSTRUCTION_PLAN = `${variables.BACKEND_URL}/user/verify-construction-plan`;
export const GET_USERS_LOAN_TASKS = `${variables.BACKEND_URL}/user/getUserForLoanTasks`;
export const GET_USER_DETAILS = `${variables.BACKEND_URL}/user/getUserDetails`;
export const VIEW_USERS_FLOOR_CONFIG_API = `${variables.BACKEND_URL}/user/view-users-floor-config`;
export const VIEW_USERS_COMPONENT_CONFIG_API = `${variables.BACKEND_URL}/user/view-users-component-config`;
export const GET_ACCESS_CONFIG = `${variables.BACKEND_URL}/user/getAccessLevelConfig`;
export const GET_SUBSCRIBERS_BRANCHES = `${variables.BACKEND_URL}/user/getBranches`;
export const GET_PERMISSION_CONFIG_BY_ROLEID = `${variables.BACKEND_URL}/user/getPermissionConfigByRole`;
export const VIEW_USERS_LOAN_TYPE_API = `${variables.BACKEND_URL}/user/view-users-loan-type`;
export const GET_LOAN_DISBURSAL_REQUESTS_API = `${variables.BACKEND_URL}/user/getLoanDisbursalRequestsList`;
export const GET_LOAN_DISBURSEMENT_API = `${variables.BACKEND_URL}/user/getLoanDisbursements`;
export const GET_PROPERTY_MEDIA = `${variables.BACKEND_URL}/user/getPropertyMedia`;
export const GET_ACCESS_LEVEL_BY_IFSC = `${variables.BACKEND_URL}/user/getAccessLevelByIfscCode`;
export const GET_ACCESS_LEVEL_BY_USERID = `${variables.BACKEND_URL}/user/getAccessLevelByUserId`;
export const GET_PROGRESS_MEDIA_BY_CONST_PLAN = `${variables.BACKEND_URL}/user/getProgressMediaByConstructionPlan`;
export const GET_CONSTRUCTION_PLAN_NAME = `${variables.BACKEND_URL}/user/getConstructionPlanName`;
export const GET_REPORTS = `${variables.BACKEND_URL}/user/getReports`;
export const UPDATE_FORM_DOWNLOAD_HISTORY = `${variables.BACKEND_URL}/user/updateFormDownloadHistory`;
export const TRANSFER_MEDIA = `${variables.BACKEND_URL}/user/transferMedia`;
export const TRANSFER_MEDIA_FROM_UNCATEGORIZED_MEDIA = `${variables.BACKEND_URL}/user/transferMediaFromUncategorizedMedia`;
export const GET_CONSTRUCTION_PLAN_PHOTO_COUNT = `${variables.BACKEND_URL}/user/getConstructionPlanPhotoCount`;
export const UPDATE_PROFILE = `${variables.BACKEND_URL}/user/updateProfile`;
export const GET_UNCATEGORIZED_MEDIA = `${variables.BACKEND_URL}/user/getUncategorizedMedia`;
export const GET_UNCATEGORIZED_MEDIA_BY_DATE = `${variables.BACKEND_URL}/user/getUncategorizedMediaByDate`;
export const GET_UNCATEGORIZED_MEDIA_LIST = `${variables.BACKEND_URL}/user/getUncategorizedMediaList`;
export const DELETE_UNCATEGORIZED_MEDIA = `${variables.BACKEND_URL}/user/deleteUncategorizedMedia`;
export const GET_SUBS_SOP = `${variables.BACKEND_URL}/user/getSubscriberSOP`;
export const GET_REPORT_FORMAT = `${variables.BACKEND_URL}/user/getReportFormat`;

// ADMIN APIS
export const VIEW_WEB_USERS_API = `${variables.BACKEND_URL}/admin/view-web-users`;
export const VIEW_USERS_ROLES_API = `${variables.BACKEND_URL}/admin/view-user-roles`;
export const VIEW_USERS_PRODUCTS_API = `${variables.BACKEND_URL}/admin/view-user-products`;
export const VIEW_USERS_LOANS_MAPPING_API = `${variables.BACKEND_URL}/admin/view-user-loans`;
export const GET_CONSTRUCTION_PLAN_MODULE = `${variables.BACKEND_URL}/admin/getConstructionPlanModule`;
export const UPDATE_CONSTRUCTION_PLAN_MODULE = `${variables.BACKEND_URL}/admin/updateConstructionPlanModule`;
export const MARK_COMPLETED_CONSTRUCTION_PLAN_MODULE = `${variables.BACKEND_URL}/admin/markCompleteConstructionPlanModule`;
export const DELETE_CONSTRUCTION_PLAN_MODULE = `${variables.BACKEND_URL}/admin/deleteConstructionPlanModule`;
export const VIEW_USERS_SUBSCRIBERS_API = `${variables.BACKEND_URL}/admin/view-users-subscribers`;
export const VIEW_USERS_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/view-users-activity-config`;
export const VIEW_USERS_ACTIVITY_TYPE_CONFIG_API = `${variables.BACKEND_URL}/admin/view-users-activity-type-config`;
export const VIEW_USERS_ACTIVITIES_CONFIG_SUB_API = `${variables.BACKEND_URL}/admin/view-users-activities-config-sub`;
export const VIEW_USERS_COMPONENT_ACTIVITIES_CONFIG = `${variables.BACKEND_URL}/admin/view-users-component-activities-config`;
export const VIEW_USERS_ACTIVITIES_CONFIG_SUB_BY_SUBID_AND_LOANID_API = `${variables.BACKEND_URL}/admin/view-users-activities-config-sub-by-plan-id`;
export const DELETE_USER_ROLE_API = `${variables.BACKEND_URL}/admin/delete-user-role`;
export const UPDATE_USER_ROLE_API = `${variables.BACKEND_URL}/admin/update-user-role`;
export const DELETE_USER_PRODUCT_API = `${variables.BACKEND_URL}/admin/delete-user-product`;
export const UPDATE_USER_PRODUCT_API = `${variables.BACKEND_URL}/admin/update-user-product`;
export const DELETE_USER_LOAN_MAPPING_API = `${variables.BACKEND_URL}/admin/delete-user-loan-mapping`;
export const UPDATE_USER_LOAN_MAPPING_API = `${variables.BACKEND_URL}/admin/update-user-loan-mapping`;
export const DELETE_SUBSCRIBER_API = `${variables.BACKEND_URL}/admin/delete-subscriber`;
export const UPDATE_SUBSCRIBER_API = `${variables.BACKEND_URL}/admin/update-subscriber`;
export const DELETE_LOAN_TYPE_API = `${variables.BACKEND_URL}/admin/delete-loan-type`;
export const UPDATE_LOAN_TYPE_API = `${variables.BACKEND_URL}/admin/update-loan-type`;
export const DELETE_COMPONENT_CONFIG_API = `${variables.BACKEND_URL}/admin/delete-component-config`;
export const UPDATE_COMPONENT_CONFIG_API = `${variables.BACKEND_URL}/admin/update-component-config`;
export const DELETE_ACTIVITY_TYPE_CONFIG_API = `${variables.BACKEND_URL}/admin/delete-activity-type-config`;
export const UPDATE_ACTIVITY_TYPE_CONFIG_API = `${variables.BACKEND_URL}/admin/update-activity-type-config`;
export const DELETE_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/delete-activity-config`;
export const UPDATE_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/update-activity-config`;
export const UPDATE_PERMISSION_CONFIG = `${variables.BACKEND_URL}/admin/updatePermissionConfig`;
export const GET_PERMISSION_CONFIG = `${variables.BACKEND_URL}/admin/getPermissionConfig`;
export const UPDATE_ACCESS_CONFIG = `${variables.BACKEND_URL}/admin/updateAccessLevelConfig`;
export const GET_USER_ACCESS_LEVEL = `${variables.BACKEND_URL}/admin/getUserAccessLevel`;
export const UPDATE_LOAN_TASK_CONFIG = `${variables.BACKEND_URL}/admin/updateLoanTaskConfig`;
export const GET_LOAN_TASK_CONFIG = `${variables.BACKEND_URL}/admin/getLoanTaskConfig`;
export const UPDATE_USERS_ACTIVITY_CONFIG_SUB_API = `${variables.BACKEND_URL}/admin/all-actions-users-activitiies-config-sub`;
export const UPDATE_USERS_COMPONENT_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/update-users-component-activities-config`;
export const DELETE_WEB_USERS_API = `${variables.BACKEND_URL}/admin/delete-web-user`;
export const DELETE_USERS_COMPONENT_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/delete-users-component-activities-config`;
export const SAVE_USERS_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/save-activities-configuration`;
export const SAVE_USERS_COMPONENT_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/save-users-component-activities-config`;
export const UPDATE_WEB_USERS_API = `${variables.BACKEND_URL}/admin/update-web-user`;
export const UPDATE_USERS_ACTIVITY_MEDIA_CONFIG_ROW_API = `${variables.BACKEND_URL}/admin/update-media-config`;
export const UPDATE_USERS_ACTIVITY_CONFIG_API = `${variables.BACKEND_URL}/admin/update-users-activitiies-config-sub`;
export const GET_REPORT_TYPE = `${variables.BACKEND_URL}/admin/getReportType`;
export const GET_REPORT_FORMAT_ADMIN = `${variables.BACKEND_URL}/admin/getReportFormat`;
export const UPDATE_REPORT_TYPE = `${variables.BACKEND_URL}/admin/updateReportType`;
export const UPDATE_REPORT_FORMAT_ADMIN = `${variables.BACKEND_URL}/admin/updateReportFormat`;
export const GET_SERVER_TOKEN = `${variables.BACKEND_URL}/admin/getServerToken`;
export const GENERATE_SERVER_TOKEN = `${variables.BACKEND_URL}/admin/generateServerToken`;
