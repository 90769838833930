import axios from 'axios';
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';

import CustomSelect from '../../components/CustomSelect/CustomSelect';
import Loading from '../../components/loading/loading';
import {
  apiList,
  verifyUser,
  verifyLogin,
} from '../../helpers';
import Table from './table';
import CustomModal from '../../components/CustomModal/CustomModal';
import CustomInput from '../../components/CustomInput/CustomInput';
import Form from './Form';

class AcessLevelConfig extends Component {
  constructor() {
    super();
    this.state = {
      result: false,
      data: [],
      elemenntId: null,
      ifscCode: '',
      branchName: '',
      city: '',
      district: '',
      zone: '',
      state: '',
      country: '',
      page: 0,
      rowsPerPage: 0,
      formOpen: false,
      subscribers: [],
      userRoleId: -1,
      subscriberId: -1,
    };
  }

  componentDidMount() {
    const { user } = verifyLogin();
    const { userRoleId, subscriberId } = user;
    verifyUser(Number(userRoleId));
    this.setState({
      userRoleId: Number(userRoleId),
      subscriberId: Number(userRoleId) === 2 ? Number(subscriberId) : -1,
    });
    this.handleFetchSubscribers();
    if (Number(userRoleId) === 2) {
      this.getPermissionConfig(Number(subscriberId));
    }
  }

  handleShowAddForm(key, element) {
    key.preventDefault();
    this.toggleModal(true);
    if (element) {
      this.setState({
        elemenntId: element.id,
        ifscCode: element.ifscCode,
        city: element.city,
        district: element.district,
        zone: element.zone,
        branchName: element.branchName,
        state: element.state,
        country: element.country,
      });
    } else {
      this.setState({
        ifscCode: '',
        city: '',
        district: '',
        zone: '',
        branchName: '',
        state: '',
        country: '',
        elemenntId: null,
      });
    }
  }

  handleChange(key) {
    key.preventDefault();
    this.setState({ [key.target.id]: key.target.value });
  }

  handleSaveData() {
    const {
      elemenntId, ifscCode, city, district, zone, branchName, state, country, subscriberId,
    } = this.state;
    if (ifscCode.length < 1 && city.length < 1 && branchName.length < 1
      && district.length < 1 && zone.length < 1 && state.length < 1
      && country.length < 1 && subscriberId === -1) {
      toast.error('There is no change/modification found');
      return;
    }

    const { session } = verifyLogin();
    const config = { headers: { session } };
    const params = {
      ifscCode,
      city,
      district,
      subscriberId,
      zone,
      branchName,
      state,
      country,
    };
    if (elemenntId) {
      params.id = elemenntId;
    }
    axios.post(`${apiList.UPDATE_ACCESS_CONFIG}`, params, config)
      .then((response) => {
        toast.success(response.data.message);
        this.setState({
          ifscCode: '',
          city: '',
          district: '',
          zone: '',
          branchName: '',
          state: '',
          country: '',
          elemenntId: null,
        });
        this.toggleModal(false);
        this.getPermissionConfig(this.state.subscriberId);
        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);

        setTimeout(() => {
          this.setState({ result: false });
        }, 1000);
      });
  }

  handleFetchSubscribers() {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_SUBSCRIBERS_API}`, config)
      .then((response) => {
        this.setState({ subscribers: response.data.data.paginate });
        this.setState({ result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ result: false });
      });
  }

  getPermissionConfig(subscriberId, searchQuery) {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    let url = `${apiList.GET_ACCESS_CONFIG}?subscriberId=${subscriberId}`;
    if (searchQuery) {
      url = `${url}&searchQuery=${searchQuery}`;
    }
    axios.get(url, config)
      .then((response) => {
        this.setState({ data: [...response.data.data.paginate], result: false });
      })
      .catch(() => {
        // toast.error(`${error.response.data.error || error.response.data.message}`);
        this.setState({ data: [], result: false });
      });
  }

  body = () => (
    <Form
      handleChange={(id) => this.handleChange(id)}
      ifscCode={this.state.ifscCode}
      city={this.state.city}
      district={this.state.district}
      zone={this.state.zone}
      state={this.state.state}
      branchName={this.state.branchName}
      country={this.state.country}
      elemenntId={this.state.elemenntId}
    />
  )

  toggleModal(value) {
    this.setState({ formOpen: value });
  }

  render() {
    const {
      result,
      formOpen,
      data,
      page,
      rowsPerPage,
      userRoleId,
      subscribers,
      subscriberId,
      elemenntId,
      dataLoaded,
    } = this.state;
    const { MainProps } = this.props;
    verifyLogin();
    return (
      <div className='users'>

        <ToastContainer />

        {result === true && !dataLoaded ? <Loading MainProps={MainProps} /> : null }

        <div className='select-fields'>
          {userRoleId === 1 ? (
            <CustomSelect
              id='subscriberId'
              title='Subscribers Name'
              menuItems={subscribers.map((element) => (
                <option value={element.id} key={element.id}>{element.subscriberName}</option>
              ))}
              handleChange={(event) => {
                this.setState({ subscriberId: event.target.value });
                if (event.target.value !== '#78') {
                  this.getPermissionConfig(event.target.value);
                } else {
                  this.setState({ data: [] });
                }
              }}
              value={subscriberId}
            />
          ) : null}
          <CustomInput
            height
            btargin
            disabled={subscriberId < 1}
            onChange={(event) => {
              if (subscriberId > 0) {
                this.getPermissionConfig(subscriberId, event.target.value);
              }
            }}
            id='branches'
            label='Search Branch'
          />
        </div>

        {result === false && (
          <Table
            subscriberId={subscriberId}
            data={Array.isArray(data) ? data : []}
            MainProps={this.props}
            handleDeleteData={(key, id) => this.handleDeleteData(key, id)}
            handleShowEditForm={(key, element) => this.handleShowAddForm(key, element)}
            handleShowAddForm={(key) => this.handleShowAddForm(key)}
            rowsPerPage={rowsPerPage}
            page={page}
            handleChangePage={(event, newPage) => this.handleChangePage(event, newPage)}
            handleChangeRowsPerPage={(event) => this.handleChangeRowsPerPage(event)}
          />
        )}

        <CustomModal
          open={formOpen}
          setOpen={(value) => this.toggleModal(value)}
          newForm={!elemenntId}
          body={this.body()}
          handleSaveData={() => this.handleSaveData()}
          title={!elemenntId ? 'Create Branch' : 'Update Branch'}
        />

      </div>
    );
  }
}

export default AcessLevelConfig;
