import React from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { makeStyles } from '@material-ui/core/styles';

// import Checkbox from '@material-ui/core/Checkbox';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const useStyles = makeStyles(() => ({
  mainContainer: {
    display: 'flex',
  },
  container: {
    width: '400',
    display: 'flex',
    paddingLeft: 20,
    paddingRight: 20,
    flexDirection: 'column',
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
    justifyContent: 'space-between',
  },
}));
const STRUCTURE_TYPE = [{ id: '1', name: 'RCC Frame' }, { id: '2', name: 'Load Bearing' }];
const FLOOR_NUMBERS = [{ id: 1, name: 'GROUND' }, { id: 2, name: 'GROUND +1' }, { id: 3, name: 'GROUND +2' }];

export default function FormLoanProperty({ newProperty = {}, setNewProperty }) {
  const classes = useStyles();

  return (

    <div className={classes.mainContainer}>
      <div className={classes.container}>
        {/* <CustomInput
          id='propertyName'
          label='Property Name*'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.propertyName}
          onChange={(id) => {
            const obj = { propertyName: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        /> */}
        <CustomInput
          multiline
          rowsMax={3}
          rows={3}
          id='propertyDescription'
          label='Property Details'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.propertyDescription}
          onChange={(id) => {
            const obj = { propertyDescription: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='landArea'
          label='Land Area(Sqft)'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newProperty.landArea}
          onChange={(id) => {
            const obj = { landArea: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />

        <CustomInput
          id='builtUpArea'
          label='Built Up Area(Sqft)*'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newProperty.builtUpArea}
          onChange={(id) => {
            const obj = { builtUpArea: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomSelect
          id='noOfFloors'
          title='No of floors*'
          menuItems={FLOOR_NUMBERS.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            const obj = { noOfFloors: event.target.value === '#78' ? null : event.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
          value={newProperty.noOfFloors}
        />

      </div>
      <div className={classes.container}>
        <CustomSelect
          id='structureType'
          title='Structure Type*'
          menuItems={STRUCTURE_TYPE.map((element) => (
            <option value={element.id} key={element.id}>{element.name}</option>
          ))}
          handleChange={(event) => {
            const obj = { structureType: event.target.value === '#78' ? null : event.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
          value={newProperty.structureType}
        />
        {/* <FormControlLabel
          className={classes.checkBoxForm}
          control={(
            <Checkbox
              checked={newProperty.basement === 'true'}
              onChange={(event) => {
                const obj = { basement: event.target.checked ? 'true' : 'false' };
                const data = { ...newProperty, ...obj };
                setNewProperty(data);
              }}
              name='Basement'
            />
)}
          label='Basement'
          labelPlacement='start'
        /> */}
        <CustomInput
          id='pincode'
          label='Pincode'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newProperty.pincode}
          onChange={(id) => {
            const obj = { pincode: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='lat'
          label='Latitude'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newProperty.lat}
          onChange={(id) => {
            const obj = { lat: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='lng'
          label='Longitude'
          InputLabelProps={{
            shrink: true,
          }}
          type='number'
          value={newProperty.lng}
          onChange={(id) => {
            const obj = { lng: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />

      </div>

      <div className={classes.container}>

        <CustomInput
          multiline
          rowsMax={2}
          rows={2}
          id='addressLine1'
          label='Address Line 1'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.addressLine1}
          onChange={(id) => {
            const obj = { addressLine1: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          multiline
          rowsMax={2}
          rows={2}
          id='addressLine2'
          label='Address Line 2'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.addressLine2}
          onChange={(id) => {
            const obj = { addressLine2: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='cityTown'
          label='City'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.cityTown}
          onChange={(id) => {
            const obj = { cityTown: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='district'
          label='District'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.district}
          onChange={(id) => {
            const obj = { district: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
        <CustomInput
          id='state'
          label='State'
          InputLabelProps={{
            shrink: true,
          }}
          value={newProperty.state}
          onChange={(id) => {
            const obj = { state: id.target.value };
            const data = { ...newProperty, ...obj };
            setNewProperty(data);
          }}
        />
      </div>

    </div>
  );
}
