import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

import { withStyles } from '@material-ui/core/styles';
import CustomInput from '../../components/CustomInput/CustomInput';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';

const useStyles = () => ({
  root: {
    width: '400',
    display: 'flex',
    flexDirection: 'column',
  },
});

class Form extends Component {
  constructor() {
    super();
    this.state = {
      Level: [{ id: 'Building Level', level: 'Building Level' },
        { id: 'Component Level', level: 'Component Level' },
        { id: 'Floor Level', level: 'Floor Level' }],
    };
  }

  render() {
    const {
      classes, handleChange, activityName,
      activitylevel, activityTypeId,
      activityTypeList, setActivityType, setActivityLevel,
      title,
      instruction,
    } = this.props;
    return (
      <div className={classes.root}>
        <CustomSelect
          id='activityTypeId'
          title='Activity Type'
          menuItems={activityTypeList.paginate.map((element) => (
            <option value={element.id} key={element.id}>{element.activityType}</option>
          ))}
          handleChange={(event) => {
            setActivityType(event);
          }}
          value={activityTypeId}
        />
        <CustomInput id='activityName' label='Activity Name' value={activityName} onChange={(id) => handleChange(id)} />
        <CustomSelect
          id='activitylevel'
          title='Level'
          menuItems={this.state.Level.map((element) => (
            <option value={element.id} key={element.id}>{element.level}</option>
          ))}
          handleChange={(event) => {
            setActivityLevel(event);
          }}
          value={activitylevel}
        />
        <CustomInput
          id='title'
          label='Title'
          InputLabelProps={{
            shrink: true,
          }}
          value={title}
          onChange={(id) => {
            handleChange(id);
          }}
        />
        <CustomInput
          id='instruction'
          label='Instruction'
          InputLabelProps={{
            shrink: true,
          }}
          multiline
          rowsMax={3}
          rows={3}
          value={instruction}
          onChange={(id) => {
            handleChange(id);
          }}
        />
      </div>
    );
  }
}

export default withStyles(useStyles)(Form);
