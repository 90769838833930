/* eslint-disable func-names */
import React, { Component } from 'react';

import { Alert, AlertTitle } from '@material-ui/lab';
// import { Detector } from 'react-detect-offline';
import { ToastContainer, toast } from 'react-toastify';

export default function (ComposedComponent) {
  class NetworkDetector extends Component {
    constructor() {
      super();
      this.state = {
        isDisconnected: false,
      };
    }

    componentDidMount() {
      this.handleConnectionChange();
      window.addEventListener('online', this.handleConnectionChange);
      window.addEventListener('offline', this.handleConnectionChange);
    }

    componentWillUnmount() {
      window.removeEventListener('online', this.handleConnectionChange);
      window.removeEventListener('offline', this.handleConnectionChange);
    }

    handleConnectionChange = () => {
      const condition = navigator.onLine ? 'online' : 'offline';
      if (condition === 'online') {
        const webPing = setInterval(() => {
          fetch('//google.com', {
            mode: 'no-cors',
          })
            .then(() => {
              this.setState({ isDisconnected: false }, () => clearInterval(webPing));
            })
            .catch((error) => console.log(error));
        }, 2000);
      } else {
        toast.error('Your device is offline!');
        this.setState({ isDisconnected: true });
      }
    };

    render() {
      const { isDisconnected } = this.state;
      return (
        <div>
          <ToastContainer />
          {isDisconnected && (
            <Alert severity='error'>
              <AlertTitle>Connection Error!</AlertTitle>
              Oops! Looks like your device is not connected to the interenet.
              Please contact your IT department.
            </Alert>
          )}
          <ComposedComponent {...this.props} />
        </div>
      );
    }
  }
  return NetworkDetector;
}
