import React, { useEffect, useState } from 'react';
import axios from 'axios';

import { Typography, ListItem, ListItemText } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Navbar from '../../components/navBar/navBar';
import {
  apiList,
  dencrypt,
} from '../../helpers';
import ConstructionPlanTable from '../../components/ConstructionPlanTable/ConstructionPlanTable';

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    minHeight: '91vh',
    display: 'flex',
    overflow: 'none',
  },
  plan: {
    display: 'flex',
    flex: 1,
    overflow: 'auto',
  },
  planInner: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  list: {
    backgroundColor: '#EEEEEE',
    width: '20%',
    padding: 20,
    minWidth: 200,
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
  },
  title: {
    fontSize: 14,
    fontWeight: 'bold',
    marginBottom: 12,
  },
  subTitle: {
    fontSize: 12,
    fontWeight: 'bold',
  },
  listItem: {
    cursor: 'pointer',
    marginBottom: 8,
    height: 35,
  },
  selectedListItem: {
    cursor: 'pointer',
    border: '1px solid #00838F',
    marginBottom: 8,
    height: 35,
  },
  listItemText: {
    fontSize: 14,
  },
}));

export default function OldConstructionPlanList(props) {
  const paramsDec = dencrypt(props.match.params.data);
  const { version, propertyId, constructionPlanPermission } = JSON.parse(paramsDec);
  const versions = [...Array(version - 1).keys()];
  const classes = useStyles();
  const [planList, setPlanList] = useState([]);
  const [selectedPlan, setSelectedPlan] = useState(-1);

  const onPlanClick = (v) => {
    console.log(v, propertyId);
    axios.get(`${apiList.VIEW_CONSTRUCTION_PLAN_API}/${propertyId}/${v}`)
      .then((response) => {
        if (response?.data?.result?.constructionPlan) {
          const { constructionPlan } = response.data.result;
          console.log(constructionPlan);
          setPlanList([...constructionPlan]);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    onPlanClick(1);
  }, []);

  return (
    <div>
      <Navbar MainProps={props} />
      <div className={classes.slide}>
        <div className={classes.list}>
          <Typography h2>
            Old Plans
          </Typography>
          {versions.map((suggestion, index) => (
            <ListItem
              onClick={() => {
                setSelectedPlan(index);
                onPlanClick(index + 1);
              }}
              key={index}
              button
              className={selectedPlan === index ? classes.selectedListItem : classes.listItem}
            >
              <ListItemText
                primary={`Construction Plan Version ${index + 1}`}
                className={classes.listItemText}
                disableTypography
              />
            </ListItem>
          ))}
        </div>
        <div className={classes.plan}>
          <div className={classes.planInner}>
            {planList.length > 0 ? (
              <ConstructionPlanTable
                isCurrentPlan={false}
                propertyId={propertyId}
                constructionPlanPermission={constructionPlanPermission}
                data={planList.length > 0 ? planList : []}
              />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}
