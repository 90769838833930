import React, { useState, useEffect } from 'react';

import { makeStyles, withStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import clsx from 'clsx';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Check from '@material-ui/icons/Check';
import StepConnector from '@material-ui/core/StepConnector';
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import SwipeableViews from 'react-swipeable-views';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent';
import { toast } from 'react-toastify';
import axios from 'axios';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from 'react-select';
import CustomSelect from '../../components/CustomSelectOut/CustomSelectOut';
import CustomInput from '../../components/CustomInput/CustomInput';

import {
  verifyLogin,
  apiList,
} from '../../helpers';

const titlStyle = (theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

export const CloseButton = withStyles(titlStyle)((props) => {
  const { classes, onClose } = props;
  return (
    <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
      <CloseIcon />
    </IconButton>
  );
});

const QontoConnector = withStyles({
  alternativeLabel: {
    top: 10,
    left: 'calc(-50% + 16px)',
    right: 'calc(50% + 16px)',
  },
  active: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  completed: {
    '& $line': {
      borderColor: '#3D4977',
    },
  },
  line: {
    borderColor: '#eaeaf0',
    borderTopWidth: 3,
    borderRadius: 1,
  },
})(StepConnector);

const useQontoStepIconStyles = makeStyles({
  root: {
    color: '#eaeaf0',
    display: 'flex',
    height: 22,
    alignItems: 'center',
  },
  active: {
    color: '#3D4977',
  },
  circle: {
    width: 8,
    height: 8,
    borderRadius: '50%',
    backgroundColor: 'currentColor',
  },
  completed: {
    color: '#3D4977',
    zIndex: 1,
    fontSize: 18,
  },
});

function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles();
  const { active, completed } = props;

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? <Check className={classes.completed} /> : <div className={classes.circle} />}
    </div>
  );
}

function getSteps() {
  return ['Basic Details', 'Set Access'];
}

const useStyles = makeStyles(() => ({
  root: {
    maxWidth: '100%',
    flexGrow: 1,
  },
  bottomBar: {
    height: '35px',
    bottom: 0,
    right: 0,
    left: 0,
  },
  wrapper: {
    position: 'relative',
    overflow: 'hidden',
  },
  buttonProgress: {
    color: 'primary',
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  slide: {
    position: 'relative',
    height: 'calc(100vh - 250px)',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  swipeView: {
    position: 'relative',
    overflow: 'hidden',
  },
  stepper: {
    height: '80px',
  },
  list: {
    width: 300,
  },
  checkBoxForm: {
    minWidth: 300,
    display: 'flex',
  },
}));
const ACCESS_TYPES = ['IFSC', 'CITY', 'DISTRICT', 'ZONE', 'STATE', 'COUNTRY'];

const customStyles = {
  control: (styles) => ({
    ...styles,
    width: 500,
    fontSize: 14,
  }),
  option: (styles) => ({
    ...styles,
    fontSize: 14,
  }),
  multiValueLabel: (styles) => ({
    ...styles,
    fontSize: 10,
  }),
};

export default function OnBoardingDialog({
  open, handleClose, user,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const [newUser, setNewUser] = useState(user);
  const [userRoles, setUserRoles] = useState([]);
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [accessType, setAccessType] = useState(user.accessType);
  const steps = getSteps();
  const [checked, setChecked] = React.useState([]);
  const [resetPass, setResetPassword] = useState(false);
  const [autoPass, setAutoPass] = useState(true);
  const [selectedOption, setSelectedOption] = useState(null);

  const handleBack = () => {
    if (activeStep > 0) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1);
    }
  };

  const getPermissionConfig = (type, accessData) => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.GET_ACCESS_CONFIG}?subscriberId=${user.subscriberId}`, config)
      .then((response) => {
        let data = response.data.data.paginate;
        switch (type) {
          case 0:
            data = data.map((item) => ({ value: item.ifscCode, label: item.branchName }));
            break;
          case 1:
            {
              const ids = data.map((o) => o.city);
              data = data.filter(({ city }, index) => !ids.includes(city, index + 1));
              data = data.map((item) => ({ value: item.city, label: item.city }));
            }
            break;
          case 2:
            {
              const ids = data.map((o) => o.district);
              data = data.filter(({ district }, index) => !ids.includes(district, index + 1));
              data = data.map((item) => ({ value: item.district, label: item.district }));
            }
            break;
          case 3:
            {
              const ids = data.map((o) => o.zone);
              data = data.filter(({ zone }, index) => !ids.includes(zone, index + 1));
              data = data.map((item) => ({ value: item.zone, label: item.zone }));
            }
            break;
          case 4:
            {
              const ids = data.map((o) => o.state);
              data = data.filter(({ state }, index) => !ids.includes(state, index + 1));
              data = data.map((item) => ({ value: item.state, label: item.state }));
            }
            break;
          case 5:
            {
              const ids = data.map((o) => o.country);
              data = data.filter(({ country }, index) => !ids.includes(country, index + 1));
              data = data.map((item) => ({ value: item.country, label: item.country }));
            }
            break;
          default:
            data = data.map((item) => ({ value: item.ifscCode, label: item.branchName }));
            break;
        }
        if (user.elementId) {
          const selectedData = data.filter(
            (el) => accessData.find((element) => element === el.value),
          );
          setSelectedOption(selectedData);
        }
        setAccessLevelList([...data]);
      })
      .catch((error) => {
        toast.error(`${error.response.data.error || error.response.data.message}`);
      });
  };

  useEffect(() => {
    if (user.elementId) {
      const { session } = verifyLogin();
      const config = { headers: { session } };
      axios.get(`${apiList.GET_USER_ACCESS_LEVEL}?userId=${user.elementId}`, config)
        .then((response) => {
          const data = response.data.data.paginate.map((item) => item.accessValue);
          setChecked([...data]);
          getPermissionConfig(accessType, data);
        })
        .catch(() => {
          getPermissionConfig(accessType, checked);
        });
    }
  }, []);

  useEffect(() => {
    const { session } = verifyLogin();
    const config = { headers: { session } };
    axios.get(`${apiList.VIEW_USERS_ROLES_API}?subscriberID=${user.subscriberId}`, config)
      .then((response) => {
        setUserRoles([...response.data.data]);
      })
      .catch(() => {

      });
  }, []);

  const saveUser = (key) => {
    key.preventDefault();
    const {
      subscriberId,
      elementId,
      userRoleId,
      firstName,
      lastName,
      phone,
      email,
      password,
      confirmPassword,
    } = newUser;

    const { session } = verifyLogin();
    const config = { headers: { session } };
    if (!selectedOption || selectedOption.length < 1) {
      toast.error('Please Set Access Level.');
      return;
    }

    const accessData = selectedOption.map((item) => item.value);
    const params = {
      subscriberId,
      userRoleId: Number(userRoleId),
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      phone: phone.trim(),
      email: email.trim(),
      accessType,
      accessData,
    };
    if (elementId) {
      if (resetPass) {
        params.password = password;
        params.confirmPassword = confirmPassword;
      }
      axios.patch(`${apiList.UPDATE_WEB_USERS_API}/${Number(elementId)}`, params, config)
        .then((response) => {
          toast.success(response.data.message);
          handleClose(true);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    } else {
      params.userProduct = 1;
      if (!autoPass) {
        params.password = password;
        params.confirmPassword = confirmPassword;
      }
      axios.post(`${apiList.SAVE_WEB_USERS_API}`, params, config)
        .then((response) => {
          toast.success(response.data.message);
          handleClose(true);
        })
        .catch((error) => {
          toast.error(`${error.response.data.error || error.response.data.message}`);
        });
    }
  };

  const handleNext = (key) => {
    if (activeStep < 4) {
      switch (activeStep) {
        case 0:
          {
            const {
              subscriberId,
              elementId,
              userRoleId,
              firstName,
              lastName,
              email,
            } = newUser;

            if (elementId) {
              if (subscriberId.length < 1
                || userRoleId.length < 1
                || firstName.length < 2
                || lastName.length < 2
                || email.length < 1) {
                toast.error('Please fill all the fields');
                break;
              }
            } else if (!elementId) {
              if (subscriberId.length < 1
                || userRoleId.length < 1
                || firstName.length < 2
                || lastName.length < 2
                || email.length < 1) {
                toast.error('Please fill all the fields');
                break;
              }
            }
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
          }
          break;
        case 1:
          saveUser(key);
          break;
        default:
          break;
      }
    }
  };

  const handleChangeIndex = (index) => {
    setActiveStep(index);
  };

  const getNextButtonTitle = () => {
    switch (activeStep) {
      case 0:
        return 'CONTINUE';
      case 1:
        return 'SAVE';
      default:
        return 'Next';
    }
  };

  return (
    <Dialog
      fullWidth
      maxWidth='md'
      open={open}
      onClose={handleClose}
    >
      <div className={classes.stepper}>
        <Stepper
          alternativeLabel
          activeStep={activeStep}
          connector={<QontoConnector />}
        >
          {steps.map((label) => (
            <Step key={label}>
              <StepLabel StepIconComponent={QontoStepIcon}>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>
      </div>

      <CloseButton id='plan' onClose={handleClose} />
      <DialogContent>
        <div className={classes.wrapper}>
          <SwipeableViews
            className={classes.swipeView}
            index={activeStep}
            onChangeIndex={handleChangeIndex}
          >
            <div className={classes.slide}>
              <CustomSelect
                id='userRoleId'
                title='User Role'
                menuItems={userRoles.map((element) => (
                  <option value={element.id} key={element.id}>{element.role}</option>
                ))}
                value={newUser.userRoleId}
                handleChange={(id) => {
                  const obj = { userRoleId: id.target.value === '#78' ? '' : id.target.value };
                  const data = { ...newUser, ...obj };
                  setNewUser(data);
                }}
              />
              <CustomInput
                id='firstName'
                label='First Name'
                InputLabelProps={{
                  shrink: true,
                }}
                value={newUser.firstName}
                onChange={(id) => {
                  const obj = { firstName: id.target.value };
                  const data = { ...newUser, ...obj };
                  setNewUser(data);
                }}
              />
              <CustomInput
                id='lastName'
                label='Last Name'
                InputLabelProps={{
                  shrink: true,
                }}
                value={newUser.lastName}
                onChange={(id) => {
                  const obj = { lastName: id.target.value };
                  const data = { ...newUser, ...obj };
                  setNewUser(data);
                }}
              />
              <CustomInput
                id='phone'
                label='Mobile'
                type='number'
                InputLabelProps={{
                  shrink: true,
                }}
                value={newUser.phone}
                onChange={(id) => {
                  if (id.target.value.length < 11) {
                    const obj = { phone: id.target.value };
                    const data = { ...newUser, ...obj };
                    setNewUser(data);
                  }
                }}
              />
              <CustomInput
                id='email'
                label='Email'
                InputLabelProps={{
                  shrink: true,
                }}
                value={newUser.email}
                onChange={(id) => {
                  const obj = { email: id.target.value };
                  const data = { ...newUser, ...obj };
                  setNewUser(data);
                }}
              />
              {!newUser.elementId ? (
                <FormControlLabel
                  className={classes.checkBoxForm}
                  control={(
                    <Checkbox
                      checked={autoPass}
                      onChange={(event) => {
                        const obj = { password: null, confirmPassword: null };
                        const data = { ...newUser, ...obj };
                        setNewUser(data);
                        setAutoPass(event.target.checked);
                      }}
                    />
                  )}
                  label='Auto Generate Password'
                  labelPlacement='start'
                />
              ) : null}
              {newUser.elementId ? (
                <FormControlLabel
                  className={classes.checkBoxForm}
                  control={(
                    <Checkbox
                      checked={resetPass}
                      onChange={(event) => setResetPassword(event.target.checked)}
                    />
                  )}
                  label='Reset Password'
                  labelPlacement='end'
                />
              ) : null}
              {((!newUser.elementId && !autoPass) || (newUser.elementId && resetPass)) && (
                <CustomInput
                  id='password'
                  label='Password'
                  type='password'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newUser.password}
                  onChange={(id) => {
                    const obj = { password: id.target.value };
                    const data = { ...newUser, ...obj };
                    setNewUser(data);
                  }}
                />
              )}
              {((!newUser.elementId && !autoPass) || (newUser.elementId && resetPass)) && (
                <CustomInput
                  id='confirmPassword'
                  label='Confirm Password'
                  type='password'
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={newUser.confirmPassword}
                  onChange={(id) => {
                    const obj = { confirmPassword: id.target.value };
                    const data = { ...newUser, ...obj };
                    setNewUser(data);
                  }}
                />
              )}
            </div>
            <div className={classes.slide}>
              <CustomSelect
                id='accessType'
                title='Access Type'
                menuItems={ACCESS_TYPES.map((element, i) => (
                  <option value={i} key={element}>{element}</option>
                ))}
                value={accessType}
                handleChange={(id) => {
                  setAccessType(id.target.value);
                  setSelectedOption(null);
                  setChecked([]);
                  if (id.target.value !== '#78') {
                    getPermissionConfig(Number(id.target.value), checked);
                  } else {
                    setAccessLevelList([]);
                  }
                }}
              />
              {accessType > -1 ? (
                <Select
                  isMulti
                  value={selectedOption}
                  onChange={setSelectedOption}
                  styles={customStyles}
                  closeMenuOnSelect={false}
                  options={accessLevelList}
                />
              ) : null}
            </div>
          </SwipeableViews>
        </div>

      </DialogContent>

      <MobileStepper
        variant='dots'
        steps={2}
        position='static'
        activeStep={activeStep}
        className={classes.bottomBar}
        nextButton={(
          <div className={classes.wrapper}>
            <Button color='primary' size='small' onClick={handleNext}>
              {getNextButtonTitle()}
              <KeyboardArrowRight />
            </Button>
          </div>

        )}
        backButton={(
          <Button color='primary' size='small' onClick={handleBack} disabled={activeStep === 0}>
            <KeyboardArrowLeft />
            Back
          </Button>
        )}
      />
    </Dialog>
  );
}
